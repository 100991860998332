import { RepositoryFactory } from "@/data/repositories/repositoryFactory";

// initial state
const state = {
    vehicleDispatchStatus: null,
    vehicleCache: null
};

// getters
const getters = {};

// actions
const actions = {
    async getVehicleDispatchStatus({ commit }, id) {
        await RepositoryFactory.get("gateway").getVehicleDispatchStatus(id)
            .then(response => {
                commit("SET_VEHICLE_DISPATCH_STATUS", response.data.item);
            });
    },
    async getVehicleCache({ commit }, id) {
        await RepositoryFactory.get("gateway").getVehicleCache(id)
            .then(response => {
                commit("SET_VEHICLE_CACHE", response.data);
            });
    },
    async invalidateVehicleCache({ commit }, id) {
        await RepositoryFactory.get("gateway").invalidateVehicleCache(id);
    }
};

// mutations
const mutations = {
    SET_VEHICLE_DISPATCH_STATUS(state, vehicleDispatchStatus) {
        state.vehicleDispatchStatus = vehicleDispatchStatus;
    },
    SET_VEHICLE_CACHE(state, vehicleCache) {
        state.vehicleCache = vehicleCache;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
