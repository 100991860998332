import { render, staticRenderFns } from "./VehiclesTable.vue?vue&type=template&id=48ad2a8f&"
import script from "./VehiclesTable.vue?vue&type=script&lang=js&"
export * from "./VehiclesTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VDataTable,VIcon,VRow,VSnackbar,VSpacer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/working_dir/management-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48ad2a8f')) {
      api.createRecord('48ad2a8f', component.options)
    } else {
      api.reload('48ad2a8f', component.options)
    }
    module.hot.accept("./VehiclesTable.vue?vue&type=template&id=48ad2a8f&", function () {
      api.rerender('48ad2a8f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Vehicles/VehiclesTable.vue"
export default component.exports