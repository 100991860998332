var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "elevation-1 mb-1 pb-2" },
        [
          _c("v-card-title", [_vm._v(" Trips ")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.searchDialog = true
                    }
                  }
                },
                [_vm._v(" Search ")]
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "1000" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              { staticClass: "ml-2", attrs: { small: "" } },
                              on
                            ),
                            [_vm._v(" Toggle Columns ")]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.columnDialog,
                    callback: function($$v) {
                      _vm.columnDialog = $$v
                    },
                    expression: "columnDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { short: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: {
                                click: function($event) {
                                  _vm.columnDialog = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [_vm._v(" Toggle Columns ")]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", dark: "" },
                              on: { click: _vm.hideAllColumns }
                            },
                            [_vm._v(" Hide All ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { text: "", dark: "" },
                              on: { click: _vm.showAllColumns }
                            },
                            [_vm._v(" Show All ")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        {
                          staticClass: "mx-3",
                          attrs: { wrap: "", "align-center": "" }
                        },
                        _vm._l(_vm.headers, function(header, n) {
                          return _c(
                            "v-flex",
                            { key: n, attrs: { xs6: "", md3: "" } },
                            [
                              _c("v-switch", {
                                attrs: { label: header.text },
                                model: {
                                  value: header.show,
                                  callback: function($$v) {
                                    _vm.$set(header, "show", $$v)
                                  },
                                  expression: "header.show"
                                }
                              })
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-spacer"),
              _c("TripSearchDialog", {
                attrs: {
                  show: _vm.searchDialog,
                  searchParams: _vm.searchParams
                },
                on: {
                  search: function($event) {
                    return _vm.searchTrips($event, false)
                  },
                  close: function($event) {
                    _vm.searchDialog = false
                  }
                }
              }),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "pt-0 mt-0 mr-2",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: this.$t("common.filter"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.loading,
            "transition-group": "scale-transition",
            type: "table"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              "calculate-widths": "",
              headers: _vm.showableHeaders,
              items: _vm.trips,
              "item-key": "id",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              search: _vm.filter,
              loading: _vm.loadingPage,
              page: _vm.page,
              "items-per-page": _vm.itemsPerPage,
              "footer-props": {
                pagination: _vm.pagination,
                itemsPerPageOptions: _vm.itemsPerPageOptions
              }
            },
            on: {
              "update:page": function($event) {
                _vm.page = $event
              },
              "update:itemsPerPage": function($event) {
                _vm.itemsPerPage = $event
              },
              "update:items-per-page": function($event) {
                _vm.itemsPerPage = $event
              },
              "click:row": _vm.openTripDialog,
              "update:options": _vm.loadMoreTrips
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.tripStatusToString(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      [_c("v-btn", [_c("v-icon", [_vm._v("mdi-test")])], 1)],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("TripDialog", {
        attrs: { isOpen: _vm.tripDialog, trip: _vm.trip, offers: _vm.offers },
        on: {
          close: function($event) {
            _vm.tripDialog = false
          }
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }