export const InvoiceOptionsType = Object.freeze({
    0: "String",
    1: "Integer",
    2: "Decimal",
    3: "Date",
    4: "Boolean",
    5: "Guid",
    6: "DictionaryString",
    7: "DictionaryNumber",
    8: "DictionaryDecimal"
});

export const InvoiceOptionsTypeMixin = {
    data() {
        return {
            invoiceOptionsType: InvoiceOptionsType
        };
    },
    filters: {
        invoiceOptionsTypeToText(value) {
            return InvoiceOptionsType[value] || "Unknown";
        }
    },
    methods: {
        getOptionTypeLabel(type) {
            return this.invoiceOptionsType[type] || "Unknown";
        },
        toBoolean(value) {
            return value === "true";
        },
        fromBoolean(value) {
            return value ? "true" : "false";
        }
    }
};
