var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _vm.editableProperty
                ? _c(
                    "v-form",
                    { key: _vm.refreshValidation, ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.propertyOriginToString(
                                    _vm.editableProperty.origin
                                  ),
                                  label: "Origin",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Key",
                                  rules: [_vm.requiredRule],
                                  "error-messages": _vm.validationErrorsFor(
                                    "key"
                                  ),
                                  disabled: !_vm.isNew
                                },
                                model: {
                                  value: _vm.editableProperty.key,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editableProperty, "key", $$v)
                                  },
                                  expression: "editableProperty.key"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Value",
                                  rules: [_vm.requiredRule],
                                  "error-messages": _vm.validationErrorsFor(
                                    "value"
                                  )
                                },
                                model: {
                                  value: _vm.editableProperty.value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.editableProperty, "value", $$v)
                                  },
                                  expression: "editableProperty.value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Hidden" },
                                model: {
                                  value: _vm.editableProperty.hidden,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableProperty,
                                      "hidden",
                                      $$v
                                    )
                                  },
                                  expression: "editableProperty.hidden"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Protected" },
                                model: {
                                  value: _vm.editableProperty.protected,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableProperty,
                                      "protected",
                                      $$v
                                    )
                                  },
                                  expression: "editableProperty.protected"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Can Be Overwritten" },
                                model: {
                                  value: _vm.editableProperty.canBeOverwritten,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableProperty,
                                      "canBeOverwritten",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editableProperty.canBeOverwritten"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNew
                ? _c("v-btn", { on: { click: _vm.addNewProperty } }, [
                    _vm._v("Add")
                  ])
                : _vm._e(),
              !_vm.isNew
                ? _c("v-btn", { on: { click: _vm.saveProperty } }, [
                    _vm._v("Save")
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }