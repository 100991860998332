import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

function getYesterday() {
    let date = new Date();
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60000);
    date.setDate(date.getDate() - 1);
    return date.toISOString().split("T")[0];
}

// initial state
const state = {
    fromDate: null,
    toDate: getYesterday(),
    costCenterReadyForSalesInvoicingFetched: null,
    costCenterReadyForSalesInvoicingSummary: null,
    costCenterSalesInvoiceCreationTimestamp: null
};

// getters
const getters = {
    toDateLastMoment() {
        let date = new Date(state.toDate);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);
        return date;
    }
};

// actions
const actions = {
    async setFromDate({ commit }, fromDate) {
        commit("SET_FROM_DATE", fromDate);
    },
    async setToDate({ commit }, toDate) {
        commit("SET_TO_DATE", toDate);
    },
    async resetDates({ commit }) {
        commit("RESET_DATES");
    },
    async getCostCenterReadyForSalesInvoicing({ commit, state, getters }) {
        let timestamp = moment();
        commit("SET_COST_CENTER_READY_FOR_SALES_INVOICING_FETCHED", timestamp);
        await RepositoryFactory.get("invoicing").getCostCenterReadyForSalesInvoicing(state.fromDate, getters.toDateLastMoment)
            .then((response) => {
                if (timestamp !== state.costCenterReadyForSalesInvoicingFetched) { return; }
                commit("SET_COST_CENTER_READY_FOR_SALES_INVOICING", response.data);
            });
    },
    async createCostCenterSalesInvoices({ commit, state, getters }, costCenters) {
        await RepositoryFactory.get("invoicing").createCostCenterSalesInvoices(costCenters, state.fromDate, getters.toDateLastMoment)
            .then(() => {
                commit("SET_COST_CENTER_SALES_INVOICE_CREATION_TIMESTAMP", moment());
            });
    }
};

// mutations
const mutations = {
    SET_FROM_DATE(state, fromDate) {
        state.fromDate = fromDate;
    },
    SET_TO_DATE(state, toDate) {
        state.toDate = toDate;
    },
    RESET_DATES(state) {
        state.fromDate = null;
        state.toDate = getYesterday();
    },
    SET_COST_CENTER_READY_FOR_SALES_INVOICING_FETCHED(state, timestamp) {
        state.costCenterReadyForSalesInvoicingFetched = timestamp;
    },
    SET_COST_CENTER_READY_FOR_SALES_INVOICING(state, costCenterReadyForSalesInvoicing) {
        state.costCenterReadyForSalesInvoicingSummary = costCenterReadyForSalesInvoicing;
    },
    SET_COST_CENTER_SALES_INVOICE_CREATION_TIMESTAMP(state, timestamp) {
        state.costCenterSalesInvoiceCreationTimestamp = timestamp;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
