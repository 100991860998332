<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
        <v-card-title>
          <span class="headline">{{$t('customerDetails.codeDialog.createNewDiscountCode')}}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
              <template v-if="!codeCreated">
                <v-date-picker class="pm-10" full-width required v-model="dates" range>
                  <v-btn text color="primary" @click="dates = []">{{$t('common.clear')}}</v-btn>
                  <v-spacer></v-spacer>
                  <h3 class="primary--text">{{ validThroughText }}</h3>
                </v-date-picker>
                <v-radio-group
                  v-model="codeType"
                  row>
                  <v-radio
                    :label="$t('customerDetails.codeDialog.codeTypePercentage')"
                    value="percentage"
                  ></v-radio>
                  <v-radio
                    :label="$t('customerDetails.codeDialog.codeTypeFixed')"
                    value="fixed"
                  ></v-radio>
                </v-radio-group>
                <v-slider
                  v-model="discountValue"
                  color="teal"
                  :label="$t('customerDetails.codeDialog.discountValue')"
                  class="mt-5"
                  min="1"
                  max="100"
                  thumb-label="always"
                  :append-icon="codeType === 'percentage' ? 'mdi-percent' : 'mdi-currency-eur'"
                >
                </v-slider>
                <v-combobox
                  v-model="userProvidedCodes"
                  :hint="$t('customerDetails.codeDialog.userProvidedCodesHint')"
                  :label="$t('customerDetails.codeDialog.userProvidedCodesLabel')"
                  multiple
                  class="mt-5"
                  persistent-hint
                  chips
                  clearable
                >
                </v-combobox>
                <v-slider
                  v-model="codesAmount"
                  v-if="userProvidedCodes.length < 1"
                  color="teal"
                  class="mt-10"
                  :label="$t('customerDetails.codeDialog.codesAmount')"
                  min="1"
                  max="250"
                  :hint="$t('customerDetails.codeDialog.codeGenerationHint')"
                  thumb-label="always"
                >
                </v-slider>
                <v-radio-group
                  v-model="codeUseType"
                  row
                >
                  <v-radio
                    :label="$t('customerDetails.codeDialog.infiniteUse')"
                    value="infiniteUse"
                  ></v-radio>
                  <v-radio
                    :label="$t('customerDetails.codeDialog.multipleUse')"
                    value="multipleUse"
                  ></v-radio>
                  <v-radio
                    :label="$t('customerDetails.codeDialog.singleUse')"
                    value="singleUse"
                  ></v-radio>
                </v-radio-group>
                <v-slider
                  v-if="codeUseType === 'multipleUse' || codeUseType === 'infiniteUse'"
                  v-model="userMaxUseCount"
                  color="teal"
                  class="mt-5"
                  :label="$t('customerDetails.codeDialog.userMaxUseCount')"
                  min="1"
                  max="15"
                  :hint="$t('customerDetails.codeDialog.userMaxUseCountHint')"
                  thumb-label="always"
                >
                </v-slider>
                <v-slider
                  v-if="codeUseType === 'multipleUse'"
                  v-model="codeMaxUseCount"
                  color="teal"
                  class="mt-10 mb-5"
                  :label="$t('customerDetails.codeDialog.codeMaxUseCount')"
                  min="1"
                  max="250"
                  :hint="$t('customerDetails.codeDialog.codeMaxUseCountHint')"
                  thumb-label="always"
                >
                </v-slider>
                <v-btn color="primary" :disabled="codeCreated" @click="create">{{$t('common.create')}}</v-btn>
              </template>
              <template v-else>
                <h2>{{ createdCode }}</h2>
                <p>{{$t('customerDetails.codeDialog.pleaseCopy')}}</p>
                <v-btn color="primary" :disabled="!codeCreated" @click="close">{{$t('common.close')}}</v-btn>
              </template>
          </v-form>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";
// const UserManagementRepository = RepositoryFactory.get("userManagement");
const generatedTemplate = "<**GENERATED**>";
export default {
    props: {
        value: Boolean,
        customerId: String,
        operatorId: String
    },
    data: () => ({
        generateCode: false,
        codeGeneration: "machine",
        dates: [],
        userProvidedCodes: [],
        codesAmount: 100,
        codeInMemory: "",
        userMaxUseCount: 1,
        codeMaxUseCount: 125,
        codeCreated: false,
        createdCode: "",
        codeUseType: "multipleUse",
        codeType: "percentage",
        discountValue: 10
    }),
    methods: {
        generateCodeChange() {
            if (this.generateCode) {
                this.codeInMemory = this.code.toString();
                this.code = generatedTemplate;
                return;
            }
            this.code = this.codeInMemory;
        },
        async create() {
            var minDate = this.dates.reduce(function(a, b) { return a < b ? a : b; });
            var maxDate = this.dates.reduce(function(a, b) { return a > b ? a : b; });

            this.$emit("created", {
                codes: this.userProvidedCodes,
                validFor: {
                    start: minDate,
                    end: maxDate
                },
                maxUsages: this.codeMaxUseCount,
                maxUsagesPerUser: this.userMaxUseCount,
                value: this.discountValue,
                targetType: "Both",
                type: this.codeType,
                codesToGenerate: this.codesAmount
            });
        },
        async close() {
            this.show = false;
            this.generateCode = false;
            this.code = "";
            this.codeInMemory = "";
            this.codeCreated = false;
            this.createdCode = "";
        }
    },
    created() {
        this.dates.push(moment().format("YYYY-MM-DD"));
        this.dates.push(moment().add(1, "years").format("YYYY-MM-DD"));
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        validThroughText() {
            const formattedDates = this.dates.map(e => moment(e).format("DD.MM.YYYY"));
            return formattedDates.join(" -> ");
        }
    }
};
</script>

<style>

</style>
