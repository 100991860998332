import { fleetService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    // Fleet
    async find(params, top, page = 1) {
        return fleetService.get(`${resource}/companies/${params.companyId}/fleets`, {
            params: {
                ...params,
                top,
                page
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getFleet(companyId, fleetId) {
        return fleetService.get(`${resource}/companies/${companyId}/fleets/${fleetId}`);
    },
    async create(fleet) {
        return fleetService.post(`${resource}/companies/${fleet.companyId}/fleets`, fleet);
    },
    async patchContactInformation(companyId, fleetId, contactInformation) {
        return fleetService.patch(`${resource}/companies/${companyId}/fleets/${fleetId}/contactinformation`, contactInformation);
    },
    async patchFleetInfo(companyId, fleetId, fleetInfo) {
        return fleetService.patch(`${resource}/companies/${companyId}/fleets/${fleetId}/info`, fleetInfo);
    },
    async delete(companyId, fleetId) {
        return fleetService.delete(`${resource}/companies/${companyId}/fleets/${fleetId}`);
    },
    // Attributes
    async postNewAttribute(companyId, fleetId, attribute) {
        return fleetService.post(`${resource}/attributes/company/${companyId}/fleet/${fleetId}`, attribute);
    },
    async patchAttribute(companyId, fleetId, attribute) {
        return fleetService.patch(`${resource}/attributes/company/${companyId}/fleet/${fleetId}`, attribute);
    },
    async deleteAttribute(companyId, fleetId, attribute) {
        return fleetService.delete(`${resource}/attributes/company/${companyId}/fleet/${fleetId}/${attribute.origin}/${attribute.id}`);
    },
    // Properties
    async postNewProperty(companyId, fleetId, property) {
        return fleetService.post(`${resource}/configuration/company/${companyId}/fleet/${fleetId}`, property);
    },
    async patchProperty(companyId, fleetId, property) {
        return fleetService.patch(`${resource}/configuration/company/${companyId}/fleet/${fleetId}`, property);
    },
    async deleteProperty(companyId, fleetId, property) {
        return fleetService.delete(`${resource}/configuration/company/${companyId}/fleet/${fleetId}/${property.key}`);
    },
    // Tariffs
    async postNewTariffImport(companyId, fleetId, tariffImport) {
        return fleetService.post(`${resource}/tariffs/company/${companyId}/fleet/${fleetId}/imports`, tariffImport);
    },
    async patchTariffImport(companyId, fleetId, tariffImport) {
        return fleetService.patch(`${resource}/tariffs/company/${companyId}/fleet/${fleetId}/imports`, tariffImport);
    },
    async patchTariffId(companyId, fleetId, tariffId) {
        return fleetService.patch(`${resource}/tariffs/company/${companyId}/fleet/${fleetId}/id`, { tariffId });
    },
    async deleteTariffImport(companyId, fleetId, tariffImport) {
        return fleetService.delete(`${resource}/tariffs/company/${companyId}/fleet/${fleetId}/imports/${tariffImport.id}`);
    }
};
