<template>
  <v-form ref="form">
    <v-row class="mt-3">
      <v-col cols="4">
          <v-text-field
              v-model="editableCompany.id"
              label="Company Id"
              :disabled="true"
          ></v-text-field>
      </v-col>
      <v-col cols="4">
          <v-text-field
              v-model="editableCompany.name"
              label="Name"
          ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="companyStatuses"
          v-model="editableCompany.status"
          label="Status"
          :disabled="true"
        >
          <template v-slot:selection="{ item }">
            {{ companyStatusToString(item) }}
          </template>
          <template v-slot:item="{ item }">
            {{ companyStatusToString(item) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="mt-3" v-if="editable">
      <v-col cols="12">
        <v-btn
          class="mr-4"
          @click="save"
          :disabled="!dirty"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { CompanyStatus, CompanyStatusToString } from "../../../models/CompanyStatus";
import moment from "moment";
import _ from "lodash";

export default {
    props: {
        company: {
            type: Object,
            default: function() {
                return null;
            }
        },
        editable: Boolean
    },
    data() {
        const editableCompany = _.cloneDeep(this.company);
        editableCompany.contactInformationForm = editableCompany.contactInformationForm ?? {};
        const editableIntegrationSources = [];
        for (var i = 0; i < 12; i++) {
            const flag = editableCompany.integrationSources & 1 << i;
            if (flag > 0) {
                editableIntegrationSources.push(flag);
            }
        }

        return {
            editableCompany,
            dirty: false,
            companyStatuses: Object.values(CompanyStatus)
        };
    },
    watch: {
        company(val) {
            this.editableCompany = (!val) ? {} : _.cloneDeep(val);
        },
        editableCompany:
    {
        handler() {
            this.dirty = true;
        },
        deep: true
    }
    },
    methods: {
        save: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", {
            });
        },
        companyStatusToString(status) {
            return CompanyStatusToString(status);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
