var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { key: _vm.refreshValidation, ref: "form" },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("h3", [_vm._v(" Tariff Id ")])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Tariff Id",
                  "error-messages": _vm.validationErrorsFor("id")
                },
                model: {
                  value: _vm.editableTariffId,
                  callback: function($$v) {
                    _vm.editableTariffId = $$v
                  },
                  expression: "editableTariffId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c("v-btn", { on: { click: _vm.saveTariffId } }, [_vm._v("Save")])
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "3" } }, [_c("v-label")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }