var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              "calculate-widths": "",
              headers: _vm.headers,
              items: _vm.editableAttributes,
              "item-key": "id",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              search: _vm.filter,
              "items-per-page": 10
            },
            scopedSlots: _vm._u([
              {
                key: "item.origin",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.attributeOriginToString(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.type",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.attributeTypeToString(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.id",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.name",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.value",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.contentType",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.attributeContentTypeToString(value))
                      )
                    ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: !_vm.isEditable(item) },
                            on: {
                              click: function($event) {
                                return _vm.editAttribute(item)
                              }
                            }
                          },
                          [_vm._v(" Edit ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: !_vm.isEditable(item) },
                            on: {
                              click: function($event) {
                                return _vm.deleteAttribute(item)
                              }
                            }
                          },
                          [_vm._v(" Delete ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pb-4" },
        [
          _c("v-spacer"),
          _c("v-btn", { on: { click: _vm.createAttribute } }, [
            _vm._v("Create")
          ]),
          _c("v-spacer")
        ],
        1
      ),
      _c("AttributeForm", {
        attrs: {
          isOpen: _vm.isEditFormOpen,
          attribute: _vm.selectedAttribute,
          canAddNew: true,
          attributeOrigin: _vm.editableAttributeOrigin
        },
        on: {
          cancel: _vm.closeEditForm,
          addNewAttribute: _vm.addNewAttribute,
          saveAttribute: _vm.saveAttribute,
          deleteAttribute: _vm.deleteAttribute
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }