var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("h1", [_vm._v("Pricing")]),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.loading,
            "transition-group": "scale-transition",
            type: "table"
          }
        },
        [
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openNewPriceCatalogDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" Add new catalog ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _vm.operators.length === 1
                ? _c("label", [_vm._v(_vm._s(_vm.operators[0]))])
                : _vm.operators.length > 1
                ? _c("v-select", {
                    attrs: {
                      label: _vm.$t("userManagement.operator"),
                      items: _vm.operators,
                      value: _vm.operator
                    },
                    on: { change: _vm.changeOperator }
                  })
                : _vm._e()
            ],
            1
          ),
          _c("v-select", {
            attrs: {
              items: _vm.priceCatalogs,
              "item-value": "id",
              label: "Price catalog"
            },
            on: { change: _vm.changePriceCatalog },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.name) +
                        " (OperatorId: " +
                        _vm._s(data.item.operatorId) +
                        ", ProductId: " +
                        _vm._s(data.item.productId) +
                        ") "
                    )
                  ]
                }
              },
              {
                key: "item",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(data.item.name) +
                        " (OperatorId: " +
                        _vm._s(data.item.operatorId) +
                        ", ProductId: " +
                        _vm._s(data.item.productId) +
                        ") "
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedPriceCatalog,
              callback: function($$v) {
                _vm.selectedPriceCatalog = $$v
              },
              expression: "selectedPriceCatalog"
            }
          }),
          _vm.selectedPriceCatalog !== null
            ? [
                _c("PriceCatalogInfo", {
                  ref: "catalogInfo",
                  attrs: { priceCatalog: _vm.selectedPriceCatalog },
                  on: { "catalog-info-updated": _vm.updateSelectedCatalogInfo }
                }),
                _c("PricePanels", {
                  ref: "baseFees",
                  staticClass: "mb-5",
                  attrs: {
                    header: "Base fees",
                    prices: _vm.selectedPriceCatalog.baseFeePrices
                      ? _vm.selectedPriceCatalog.baseFeePrices.priceCatalogItems
                      : null,
                    allowedRuleTypes: [
                      _vm.ruleTypes.DayTypeTimeRange,
                      _vm.ruleTypes.CarType,
                      _vm.ruleTypes.PassengerCount
                    ]
                  },
                  on: { update: _vm.updateBaseFees }
                }),
                _c("PricePanels", {
                  ref: "travelFeeKilometers",
                  staticClass: "mb-5",
                  attrs: {
                    header: "Travel fees (kilometers)",
                    prices: _vm.selectedPriceCatalog.kilometerPrices
                      ? _vm.selectedPriceCatalog.kilometerPrices
                          .priceCatalogItems
                      : null,
                    allowedRuleTypes: [
                      _vm.ruleTypes.DayTypeTimeRange,
                      _vm.ruleTypes.CarType,
                      _vm.ruleTypes.PassengerCount,
                      _vm.ruleTypes.Range
                    ]
                  },
                  on: { update: _vm.updateTravelFeesKilometers }
                }),
                _c("PricePanels", {
                  ref: "travelFeeMinutes",
                  staticClass: "mb-5",
                  attrs: {
                    header: "Travel fees (minutes)",
                    prices: _vm.selectedPriceCatalog.minutePrices
                      ? _vm.selectedPriceCatalog.minutePrices.priceCatalogItems
                      : null,
                    allowedRuleTypes: [
                      _vm.ruleTypes.DayTypeTimeRange,
                      _vm.ruleTypes.CarType,
                      _vm.ruleTypes.PassengerCount
                    ]
                  },
                  on: { update: _vm.updateTravelFeesMinutes }
                }),
                _c("PricePanels", {
                  ref: "preBookFees",
                  staticClass: "mb-5",
                  attrs: {
                    header: "Pre-book fees",
                    prices: _vm.selectedPriceCatalog.preBookPrices
                      ? _vm.selectedPriceCatalog.preBookPrices.priceCatalogItems
                      : null,
                    allowedRuleTypes: [
                      _vm.ruleTypes.PreBooked,
                      _vm.ruleTypes.DayTypeTimeRange,
                      _vm.ruleTypes.CarType,
                      _vm.ruleTypes.PassengerCount
                    ]
                  },
                  on: { update: _vm.updatePreBookFees }
                }),
                _c("PricePanels", {
                  ref: "fixedPrices",
                  staticClass: "mb-5",
                  attrs: {
                    header: "Fixed prices",
                    prices: _vm.selectedPriceCatalog.fixedPrices
                      ? _vm.selectedPriceCatalog.fixedPrices.priceCatalogItems
                      : null,
                    allowedRuleTypes: [_vm.ruleTypes.FixedRoute]
                  },
                  on: { update: _vm.updateFixedPrices }
                }),
                _c("PricePanels", {
                  ref: "extraPrices",
                  attrs: {
                    header: "Extra prices",
                    prices: _vm.selectedPriceCatalog.extraPrices
                      ? _vm.selectedPriceCatalog.extraPrices.priceCatalogItems
                      : null,
                    allowedRuleTypes: [
                      _vm.ruleTypes.StairClimber,
                      _vm.ruleTypes.StairAssistWithoutClimber,
                      _vm.ruleTypes.UnobstructedExtraRule,
                      _vm.ruleTypes.ElevatorExtraRule,
                      _vm.ruleTypes.AssistToDoor
                    ]
                  },
                  on: { update: _vm.updateExtraPrices }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" Close ")]
          )
        ],
        1
      ),
      _c("PriceCatalogDialog", {
        ref: "priceCatalogDialog",
        attrs: {
          isOpen: _vm.createPriceCatalogDialog,
          priceCatalogs: _vm.priceCatalogs
        },
        on: {
          create: _vm.createPriceCatalog,
          close: _vm.closePriceCatalogDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }