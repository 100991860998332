<template>
    <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
                <span class="headline">
                    {{$t(isNewFee ? "invoicing.addNewFee" : "invoicing.editFee")}}
                </span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col class="pl-0">
                                <v-select
                                v-model="selectedFeeType"
                                :items="allowedFeeTypes"
                                :rules="[v => !!v || 'Fee type is required']"
                                :label="this.$t('invoicing.feeType')"
                                required
                            >
                                <template slot="selection" slot-scope="{ item }">
                                    <span v-if="useLikumaTexts">
                                        {{ item | feeTypeToLikumaText }}
                                    </span>
                                    <span v-else-if="useKelaTexts">
                                        {{ item | feeTypeToKelaText }}
                                    </span>
                                    <span v-else>
                                        {{ item | feeTypeToText }}
                                    </span>
                                </template>
                                <template slot="item" slot-scope="{ item }">
                                    <span v-if="useLikumaTexts">
                                        {{ item | feeTypeToLikumaText }}
                                    </span>
                                    <span v-else-if="useKelaTexts">
                                        {{ item | feeTypeToKelaText }}
                                    </span>
                                    <span v-else>
                                        {{ item | feeTypeToText }}
                                    </span>
                                </template>
                            </v-select>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="fee.price"
                                    :label="this.$t('invoicing.price')"
                                    type="number"
                                    :rules="[
                                        v => !!v || 'Price cannot be empty',
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Price is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Price cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="!useKelaTexts" class="pl-0">
                                <v-select
                                    v-model="fee.vat"
                                    :label="this.$t('invoicing.vat')"
                                    :items="[0.0, 10.0, 24.0, 25.5]"
                                    :rules="[ requiredRule ]"
                                    suffix="%"
                                ></v-select>
                            </v-col>
                            <v-col v-if="!useKelaTexts && !useLikumaTexts" class="pl-0">
                                <v-text-field
                                    v-model="fee.name"
                                    :label="this.$t('invoicing.feeName')"
                                    :disabled="true"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn v-if="isNewFee"
                    :disabled="!valid"
                    @click="createFee"
                    color="success">
                    {{$t("invoicing.addFee")}}
                </v-btn>
                <v-btn v-else
                    :disabled="!valid"
                    @click="saveFee"
                    color="primary">
                    {{$t("common.saveChanges")}}
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.close")}}
                  </v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { FeeTypeMixin } from "../../../models/FeeTypes";
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import _ from "lodash";

export default {
    mixins: [FeeTypeMixin, InputRuleMixin],
    props: {
        originalFee: {
            type: Object,
            default: () => {}
        },
        allowedFeeTypes: Array,
        isNewFee: {
            type: Boolean,
            default: true
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        useLikumaTexts: {
            type: Boolean,
            default: false
        },
        useKelaTexts: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fee: {},
            valid: true,
            selectedFeeType: this.originalFee.type
        };
    },
    watch: {
        originalFee: function(newVal, oldVal) {
            this.fee = _.cloneDeep(this.originalFee);
            this.selectedFeeType = this.fee.type;
        },
        isOpen: function(val, oldVal) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        selectedFeeType: function(val, oldVal) {
            this.fee.type = val;
            this.fee.name = this.$t(`invoicing.${this.feeTypeToTranslationKey(val)}`);
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        }
    },
    methods: {
        saveFee() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", this.fee);
        },
        createFee() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", this.fee);
        }
    }
};
</script>

<style scoped>
</style>
