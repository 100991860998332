var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customer
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c("FavoriteAddressDialog", {
            attrs: {
              editFavoriteAddress: _vm.editFavoriteAddress,
              favoriteAddress: _vm.selectedFavoriteAddress
            },
            on: {
              created: _vm.newAddressCreated,
              closed: function($event) {
                _vm.showFavoriteAddressDialog = false
              },
              updated: _vm.updateFavoriteAddress,
              deleted: _vm.deleteFavoriteAddress
            },
            model: {
              value: _vm.showFavoriteAddressDialog,
              callback: function($$v) {
                _vm.showFavoriteAddressDialog = $$v
              },
              expression: "showFavoriteAddressDialog"
            }
          }),
          _c("UserDialog", {
            attrs: {
              header: _vm.userHeader,
              user: _vm.selectedUser,
              editUser: _vm.editUser,
              operator: _vm.customerOperator
            },
            on: {
              created: _vm.createUser,
              updated: _vm.updateUser,
              deleted: _vm.deleteUser,
              closed: function($event) {
                _vm.showUserDialog = false
              }
            },
            model: {
              value: _vm.showUserDialog,
              callback: function($$v) {
                _vm.showUserDialog = $$v
              },
              expression: "showUserDialog"
            }
          }),
          _c("CustomerDialog", {
            attrs: {
              header: _vm.$t("userManagement.dialog.updateCustomer"),
              customer: _vm.customer
            },
            on: { created: _vm.modify },
            model: {
              value: _vm.showCustomerDialog,
              callback: function($$v) {
                _vm.showCustomerDialog = $$v
              },
              expression: "showCustomerDialog"
            }
          }),
          _c("RegistrationCodeDialog", {
            attrs: {
              customerId: _vm.customerId,
              operatorId: _vm.customerOperator
            },
            model: {
              value: _vm.showRegistrationCodeDialog,
              callback: function($$v) {
                _vm.showRegistrationCodeDialog = $$v
              },
              expression: "showRegistrationCodeDialog"
            }
          }),
          _c("DiscountCodeDialog", {
            attrs: {
              customerId: _vm.customerId,
              operatorId: _vm.customerOperator
            },
            on: { created: _vm.discountCreated },
            model: {
              value: _vm.showDiscountCodeDialog,
              callback: function($$v) {
                _vm.showDiscountCodeDialog = $$v
              },
              expression: "showDiscountCodeDialog"
            }
          }),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _c("h1", [_vm._v(_vm._s(_vm.customer.name))])
              ]),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      fab: "",
                                      dark: "",
                                      elevation: "2",
                                      color: "orange"
                                    },
                                    on: { click: _vm.openForm }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { dark: "" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-pencil ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        876697290
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("customerDetails.modifyCustomer")))
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      fab: "",
                                      dark: "",
                                      elevation: "2",
                                      color: "success"
                                    },
                                    on: { click: _vm.addRegistrationCode }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { dark: "" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-numeric ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        864595088
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("customerDetails.createRegistrationCode")
                          )
                        )
                      ])
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "mx-2",
                                    attrs: {
                                      fab: "",
                                      dark: "",
                                      elevation: "2",
                                      color: "success"
                                    },
                                    on: { click: _vm.addDiscountCode }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { dark: "" } },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" mdi-sale ")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3899985004
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("customerDetails.createDiscountCode"))
                        )
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "d-flex mb-6", attrs: { flat: "", tile: "" } },
            [
              _c(
                "v-card",
                { staticClass: "pa-2", attrs: { outlined: "", tile: "" } },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("customerDetails.operator")) + ":")
                  ]),
                  _vm._v(" " + _vm._s(_vm.customerOperator) + " ")
                ]
              ),
              _c(
                "v-card",
                { staticClass: "pa-2", attrs: { outlined: "", tile: "" } },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("customerDetails.externalId")) + ":")
                  ]),
                  _vm._v(" " + _vm._s(_vm.customer.externalId) + " ")
                ]
              ),
              _c("v-card", { staticClass: "pa-2", attrs: { outlined: "" } }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("customerDetails.businessId")) + ":")
                ]),
                _vm._v(" " + _vm._s(_vm.customer.businessIdentityCode) + " ")
              ]),
              _c(
                "v-card",
                { staticClass: "pa-2", attrs: { outlined: "", tile: "" } },
                [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("customerDetails.contact")) + ":")
                  ]),
                  _vm._v(" " + _vm._s(_vm.customer.contact) + " ")
                ]
              ),
              _c("v-card", { staticClass: "pa-2", attrs: { outlined: "" } }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("customerDetails.billingInfo")) + ":")
                ]),
                _vm._v(" " + _vm._s(_vm.customer.billingInfo) + " ")
              ]),
              _vm.customer.additionalData
                ? _vm._l(Object.keys(_vm.customer.additionalData), function(
                    key
                  ) {
                    return _c(
                      "v-card",
                      {
                        key: key,
                        staticClass: "pa-2",
                        attrs: { outlined: "" }
                      },
                      [
                        _c("strong", [_vm._v(_vm._s(key) + ":")]),
                        _vm._v(
                          " " + _vm._s(_vm.customer.additionalData[key]) + " "
                        )
                      ]
                    )
                  })
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-card-title",
            [
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "pt-0 mt-0 mr-2",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: this.$t("common.filter"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          ),
          _c("h2", { staticClass: "pb-5 mt-0 mr-2" }, [
            _vm._v(_vm._s(_vm.$t("customerDetails.usersTitle")))
          ]),
          _vm.users
            ? _c("v-data-table", {
                attrs: {
                  items: _vm.users,
                  search: _vm.filter,
                  headers: _vm.headers,
                  "item-key": "userId"
                },
                on: { "click:row": _vm.modifyUser },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.addUser }
                            },
                            [_vm._v(_vm._s(_vm.$t("customerDetails.addUser")))]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "item.allowBilling",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  item.allowBilling === true
                                    ? "success"
                                    : "error"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.allowBilling
                                      ? "mdi-checkbox-marked-circle"
                                      : "mdi-close-circle"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.sourceId",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  item.sourceId === "taksini"
                                    ? "success"
                                    : "error"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.sourceId === "taksini"
                                      ? "mdi-checkbox-marked-circle"
                                      : "mdi-close-circle"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.allowCard",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color:
                                  item.allowCard === true ? "success" : "error"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.allowCard
                                      ? "mdi-checkbox-marked-circle"
                                      : "mdi-close-circle"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "item.phone",
                      fn: function(ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s("+" + item.phone) + " ")]
                      }
                    }
                  ],
                  null,
                  false,
                  1935487170
                )
              })
            : _vm._e(),
          _c("h2", { staticClass: "pb-5 mt-0 mr-2" }, [
            _vm._v(_vm._s(_vm.$t("customerDetails.favoriteAddressesTitle")))
          ]),
          _vm.favoriteAddresses
            ? _c("v-data-table", {
                attrs: {
                  items: _vm.favoriteAddresses,
                  search: _vm.addressFilter,
                  headers: _vm.favoriteAddressHeaders,
                  "item-key": "favoriteAddressId"
                },
                on: { "click:row": _vm.modifyAddress },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "top",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.addFavoriteAddress }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("customerDetails.addFavoriteAddress")
                                )
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  634936014
                )
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }