<template>
    <v-card color="blue-grey lighten-5">
        <v-row justify="space-between" align="center" class="py-2 px-6">
            <span class="price-panel-header">{{ header }}</span>
            <v-btn
                text
                color="primary"
                @click="openNewPriceDialog()"
                >
                <v-icon left>mdi-plus</v-icon>
                Add new price
            </v-btn>
        </v-row>
        <v-expansion-panels
            multiple
            accordion>
            <PricePanelItem
                ref="pricePanelItem"
                v-for="(price, i) in prices"
                :key="i"
                :price="price"
                :allowedRuleTypes="allowedRuleTypes"
                :isUpdating="isUpdating"
                @modify="openPriceDialog(price, i)"
                @delete="deletePrice(i)"
                @update="modifyPrice(i, ...arguments)"
            >
            </PricePanelItem>
        </v-expansion-panels>
        <PriceDialog
            :isOpen="priceDialog"
            :originalPrice="selectedPrice"
            :isNewPrice="newPrice"
            :allowedRuleTypes="allowedRuleTypes"
            :isUpdating="isUpdating"
            @create="addPrice"
            @save="modifySelectedPriceIndex"
            @close="closePriceDialog"
        ></PriceDialog>
    </v-card>
</template>

<script>
import PricePanelItem from "./PricePanelItem";
import PriceDialog from "./PriceDialog";

export default {
    props: {
        header: String,
        prices: Array,
        allowedRuleTypes: Array
    },
    data() {
        return {
            priceDialog: false,
            selectedPrice: {},
            selectedPriceIndex: -1,
            newPrice: true,
            isUpdating: false
        };
    },
    components: {
        PricePanelItem,
        PriceDialog
    },
    methods: {
        openNewPriceDialog() {
            this.selectedPrice = {};
            this.newPrice = true;
            this.priceDialog = true;
        },
        openPriceDialog(price, index) {
            this.selectedPrice = price;
            this.selectedPriceIndex = index;
            this.newPrice = false;
            this.priceDialog = true;
        },
        deletePrice(index) {
            if (confirm("Are you sure that you want to delete price?")) {
                this.isUpdating = true;
                let newPriceList = this.prices ? Array.from(this.prices) : [];
                newPriceList.splice(index, 1);
                this.$emit("update", newPriceList);
            }
        },
        addPrice(price) {
            this.isUpdating = true;
            let newPriceList = this.prices ? Array.from(this.prices) : [];
            newPriceList.push(price);
            this.$emit("update", newPriceList);
        },
        modifySelectedPriceIndex(price) {
            this.modifyPrice(this.selectedPriceIndex, price);
        },
        modifyPrice(index, price) {
            this.isUpdating = true;
            let newPriceList = this.prices ? Array.from(this.prices) : [];
            newPriceList[index] = price;
            this.$emit("update", newPriceList);
        },
        closePriceDialog() {
            this.selectedPrice = {};
            this.priceDialog = false;
        },
        updateSucceeded() {
            this.closePriceDialog();
            this.isUpdating = false;
            this.$refs.pricePanelItem.forEach(i => i.updateSucceeded());
        },
        updateFailed() {
            this.isUpdating = false;
            this.$refs.pricePanelItem.forEach(i => i.updateFailed());
        }
    }
};
</script>

<style>
.price-panel-header {
    font-size: 1rem;
    font-weight: bold;
}
</style>
