<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Drivers
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = true"
          class="mx-2">
          Search
        </v-btn>
        <v-btn
          small
          @click="driverCreateDialog = true"
          class="mx-2">
         Create
        </v-btn>
        <v-dialog v-model="columnDialog" max-width="1000">
          <template v-slot:activator="{ on }">
            <v-btn small v-on="on" class="ml-2">
              Toggle Columns
            </v-btn>
          </template>
          <v-card>
            <v-toolbar short dark color="primary">
              <v-btn icon dark @click="columnDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                Toggle Columns
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text dark @click="hideAllColumns">
                Hide All
              </v-btn>
              <v-btn text dark @click="showAllColumns">
                Show All
              </v-btn>
            </v-toolbar>
            <v-layout wrap align-center class="mx-3">
              <v-flex v-for="(header, n) in headers" :key="n" xs6 md3>
                <v-switch v-model="header.show" :label="header.text"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <DriverSearchDialog
          :show="searchDialog"
          :searchParams="searchParams"
          @search="searchDrivers($event, false)"
          @close="searchDialog = false"
        ></DriverSearchDialog>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="Filter"
          single-line
          hide-details
          class="pt-0 mt-0 mr-2"></v-text-field>
      </v-card-actions>
    </div>
    <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table">
      <v-data-table
        calculate-widths
        :headers="showableHeaders"
        :items="drivers"
        item-key="id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="filter"
        :loading="loadingPage"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
        @click:row="openDriverDialog"
        @update:options="loadMoreDrivers"
      >
        <template v-slot:item.id="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.name="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.status="{ value }">
          <span> {{ driverStatusToString(value) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <span>
            <v-btn
              @click.stop="deleteDriver(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </span>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <DriverDialog
      :isOpen="driverDialog"
      :driver="driver"
      @close="driverDialog = false"
      @saveContactInformation="saveContactInformation"
      @saveDriverInfo="saveDriverInfo"
      @addNewAttribute="addNewAttribute"
      @saveAttribute="saveAttribute"
      @deleteAttribute="deleteAttribute"
      @addNewProperty="addNewProperty"
      @saveProperty="saveProperty"
      @deleteProperty="deleteProperty"
    ></DriverDialog>
    <DriverCreateDialog
      :isOpen="driverCreateDialog"
      @close="driverCreateDialog = false"
      @create="createDriver"
    >
    </DriverCreateDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { DriverStatusToString } from "../../models/DriverStatus";
import DriverSearchDialog from "./Dialogs/DriverSearchDialog";
import DriverCreateDialog from "./Dialogs/DriverCreateDialog";
import DriverDialog from "./Dialogs/DriverDialog";

export default {
    components: { DriverDialog, DriverSearchDialog, DriverCreateDialog },
    data() {
        return {
            loading: true,
            loadingCSV: false,
            filter: "",
            columnDialog: false,
            searchDialog: false,
            headers: [
                { text: "Id", value: "id", show: true },
                { text: "Name", value: "name", show: true },
                { text: "Status", value: "status", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            sortBy: "id",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            searchParams: {
                id: ""
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            driverDialog: false,
            driverCreateDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        drivers() {
            return this.$store.state.drivers.searchedDrivers;
        },
        driver() {
            return this.$store.state.drivers.fetchedDriver;
        },
        searchTotalCount() {
            return this.$store.state.drivers.searchTotalCount;
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    },
    methods: {
        async getDrivers(searchParams = null, forceUpdate = false) {
            this.loading = true;
            this.$store.dispatch("drivers/searchDrivers", { searchParams, top: 100, forceUpdate: forceUpdate })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getDriver(driverId) {
            this.loading = true;
            this.$store.dispatch("drivers/getDriver", driverId)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async loadMoreDrivers() {
            if (!this.drivers) return;
            if (this.drivers.length === this.searchTotalCount) return;
            if ((this.page + 1) * this.itemsPerPage < this.drivers.length) {
                return;
            }
            this.loadingPage = true;
            this.$store.dispatch("drivers/loadMoreDrivers", {
                top: 100
            })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingPage = false;
                });
        },
        openDriverDialog(driver) {
            this.getDriver(driver.id);
            this.driverDialog = true;
        },
        createDriver(driver) {
            this.$store.dispatch("drivers/createDriver", driver)
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        deleteDriver(driverId) {
            if (!window.confirm("Are you sure you wish to delete driver: " + driverId + "?")) {
                return;
            }
            this.$store.dispatch("drivers/deleteDriver", driverId)
                .then(() => {
                    this.driverDeleted();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveContactInformation(driverId, contactInformation) {
            this.$store.dispatch("drivers/updateDriverContactInformation", { driverId, contactInformation: { contactInformation } })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveDriverInfo(driverId, driverInfo) {
            this.$store.dispatch("drivers/updateDriverInfo", { driverId, driverInfo })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        addNewAttribute(driverId, attribute) {
            this.$store.dispatch("drivers/addNewAttribute", { driverId, attribute })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveAttribute(driverId, attribute) {
            this.$store.dispatch("drivers/saveAttribute", { driverId, attribute })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        deleteAttribute(driverId, attribute) {
            this.$store.dispatch("drivers/deleteAttribute", { driverId, attribute })
                .then(() => {
                    this.driverIdSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        addNewProperty(driverId, property) {
            this.$store.dispatch("drivers/addNewProperty", { driverId, property })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveProperty(driverId, property) {
            this.$store.dispatch("drivers/saveProperty", { driverId, property })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        deleteProperty(driverId, property) {
            this.$store.dispatch("drivers/deleteProperty", { driverId, property })
                .then(() => {
                    this.driverSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        driverSaved() {
            this.snackbarText = "Driver was successfully saved.";
            this.snackbarColor = "success";
            this.snackbar = true;
        },
        driverDeleted() {
            this.snackbarText = "Driver was successfully deleted.";
            this.snackbarColor = "success";
            this.snackbar = true;
        },
        closeDriverDialog() {
            this.driverDialog = false;
        },
        hideAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = false;
            }
        },
        showAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = true;
            }
        },
        driverStatusToString(status) {
            return DriverStatusToString(status);
        },
        searchDrivers(searchParams = null, forceUpdate = false) {
            if (searchParams) this.searchParams = searchParams;
            this.getDrivers(this.searchParams, forceUpdate);
            this.searchDialog = false;
        }
    },
    created() {
        this.searchDrivers(this.$route.query);
    }
};
</script>

<style scoped>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
</style>
