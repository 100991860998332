import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedTrips: [],
    fetchedTrip: null,
    fetchedTripOffers: [],
    searchParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null
};

// getters
const getters = {};

// actions
const actions = {
    // Trips
    async searchTrips({ commit }, { searchParams, top = 100, forceUpdate = false }) {
        if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
            return;
        }
        await RepositoryFactory.get("trip").find(searchParams, top)
            .then(response => {
                commit("SET_TRIPS", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_FETCHED_TIMESTAMP", moment());
            });
    },
    async getTrip({ commit }, id) {
        await RepositoryFactory.get("trip").getTrip(id)
            .then(response => {
                commit("SET_TRIP", response.data.item);
            });
    },
    async getTripOffers({ commit }, id) {
        await RepositoryFactory.get("trip").getTripOffers(id)
            .then(response => {
                commit("SET_TRIP_OFFERS", response.data.results);
            });
    },
    async loadMoreTrips({ commit }, { top = 100 }) {
        await RepositoryFactory.get("trip").find(
            state.searchParams,
            top,
            Math.floor(state.searchedTrips.length / top) + 1)
            .then(response => {
                commit("ADD_SEARCHED_TRIPS", response.data.results);
            });
    }
};

// mutations
const mutations = {
    SET_TRIPS(state, trips) {
        state.searchedTrips = trips;
    },
    SET_TRIP(state, trip) {
        state.fetchedTrip = trip;
    },
    SET_TRIP_OFFERS(state, offers) {
        state.fetchedTripOffers = offers;
    },
    ADD_SEARCHED_TRIPS(state, trips) {
        state.searchedTrips = state.searchedTrips.concat(trips);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
