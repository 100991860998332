<template>
  <div id="paytrailTransactions" style="display: flex; justify-content: center;">
    <v-card class="mt-1" :width="1080">
      <div class="elevation-1 mb-1 pb-2">
        <v-row>
          <v-col cols="auto">
            <v-card-title>
              {{ $t("invoicing.paytrail.title") }}
              <v-icon right>mdi-archive-search-outline</v-icon>
            </v-card-title>
          </v-col>
          <v-col :style="{ maxWidth: '50%' }">
            <v-card-text v-if="reportTotalAmount > 0" class="pb-0">
              Raportin summa (saatu raha): {{ reportTotalAmount | formatCurrency }}
            </v-card-text>
            <v-card-text v-if="reportTransactionAndProvisionFees != 0" class=pt-0>
              Transaktiomaksut ja provisio: {{ reportTransactionAndProvisionFees | formatCurrency }}
            </v-card-text>
            <v-card-text v-if="reportUnsettledAmount > 0" class="pb-0">
              Tilittämätön summa: {{ reportUnsettledAmount | formatCurrency }}
            </v-card-text>
            <v-card-text v-if="reportUnrefundedAmount < 0" class="pt-0">
              Hyvittämättä jäljellä: {{ reportUnrefundedAmount | formatCurrency }}
            </v-card-text>
          </v-col>
          <v-col :style="{ maxWidth: '50%' }">
            <v-card-text v-if="reportDate" class="pb-0">
              Raportin pvm: {{ reportDate }}
            </v-card-text>
            <v-card-text v-if="reportTransactionAndProvisionFees != 0" class=pt-0>
              Tapahtumia ajalla: {{ reportMinDate }} - {{ reportMaxDate }}
            </v-card-text>
          </v-col>
          <v-card-actions>
            <v-col cols="auto" class="d-flex align-center">
              <v-select v-model="selectedSettlementId" :items="settlementIds"
                :label="this.$t('invoicing.paytrail.settlementId')" class="mt-2 mr-"
                :style="{ maxWidth: '150px' }"></v-select>
            </v-col>
          </v-card-actions>
        </v-row>
      </div>
      <CreateEventsFromPaytrailTransactionsDialog ref="createPaytrailEventsDialog" v-model="createPaytrailEventsDialog"
        @refreshTableData="refreshData()" @closed="createPaytrailEventsDialog = false">
      </CreateEventsFromPaytrailTransactionsDialog>
      <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table"
        name="paytrailTransactionsTable">
        <v-data-table :headers="headers" :items="transactionsRows" :sort-by="sortBy" :sort-desc="sortDesc"
          v-model="selectedPaytrailTransactions" show-select item-key="checkoutReference" :loading="loadingPage"
          :footer-props="footerProps" :items-per-page="itemsPerPage">
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDateTime }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount | formatCurrency }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-1" v-bind="attrs" v-on="on"
                  @click="searchTransportEvent(item.createdAt, item.amount)"><v-icon>mdi-magnify</v-icon></v-btn>
              </template>
              <span>{{ $t("invoicing.paytrail.search") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-1" v-bind="attrs" v-on="on"
                  @click="settleEvent(selectedSettlementId, item.archiveNumber, item.checkoutReference)"><v-icon>mdi-archive-cog-outline</v-icon></v-btn>
              </template>
              <span>{{ $t("invoicing.paytrail.settle") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"
                  @click="setAsHandled(selectedSettlementId, item.checkoutReference)"><v-icon>mdi-format-list-checks</v-icon></v-btn>
              </template>
              <span>{{ $t("invoicing.paytrail.handle") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-skeleton-loader>
      <v-row>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn :disabled="selectedPaytrailTransactions.length < 1" color="success" @click=showCreateEventsDialog
            class="mb-3">
            {{ $t("invoicing.paytrail.createEvents") }}
          </v-btn>
        </v-col>
        <v-col v-if="selectedPaytrailTransactions.length > 0">
          <div class="text-md-body-1 font-weight-bold">Valittujen tapahtumien summa: {{ selectedTotalSum |
      formatCurrency }}
            <div v-if="selectedPaytrailTransactions.some(e => e.amount < 0)"
              class="text-md-body-1 font-weight-bold red--text">
              Huom! Valittuihin tapahtumiin sisältyy palautuksia.
            </div>
          </div>
        </v-col>
        <v-spacer></v-spacer>

      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn text @click="snackbar = false">
          {{ $t("common.close")}}
        </v-btn>
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import CreateEventsFromPaytrailTransactionsDialog from "@/components/Invoicing/CreateEventsFromPaytrailTransactionsDialog";

export default {
    mixins: [TransportEventInvoicingMixin],
    data() {
        return {
            loading: true,
            selectedPaytrailTransactions: [],
            selectedId: null,
            sortBy: "createdAt",
            sortDesc: true,
            page: 1,
            footerProps: {
                itemsPerPageOptions: [10, 20, 50, -1]
            },
            itemsPerPage: 20,
            loadingPage: false,
            createPaytrailEventsDialog: false,
            transportEventDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        paytrailTransactionsRoot() {
            return this.$store.state.paytrailTransactions.allPaytrailTransactions;
        },
        settlementIds() {
            if (!this.paytrailTransactionsRoot) return [];

            return this.paytrailTransactionsRoot.map((i) => i.settlementId);
        },
        selectedSettlementId: {
            get() {
                if (this.selectedId === null && this.settlementIds.length > 0) {
                    return this.settlementIds[0];
                }
                return this.selectedId;
            },
            set(value) {
                if (this.settlementIds.length > 0) {
                    this.selectedId = value;
                }
            }
        },
        transactionsRows() {
            if (!this.selectedSettlementId) { return []; }

            const rowsBysettlementId = this.paytrailTransactionsRoot.find((i) =>
                i.settlementId === this.selectedSettlementId);

            return rowsBysettlementId
                ? rowsBysettlementId.paytrailReportSummaryRows.map((r) => ({ ...r }))
                : [];
        },
        reportTotalAmount: {
            get() {
                if (!this.selectedSettlementId) return 0;

                const rowsBysettlementId = this.paytrailTransactionsRoot.find((i) =>
                    i.settlementId === this.selectedSettlementId);

                return rowsBysettlementId.totalAmount;
            }
        },
        reportTransactionAndProvisionFees: {
            get() {
                if (!this.selectedSettlementId) return 0;

                const rowsBysettlementId = this.paytrailTransactionsRoot.find((i) =>
                    i.settlementId === this.selectedSettlementId);

                return rowsBysettlementId.transactionAndProvisionFees;
            }
        },
        reportUnsettledAmount: {
            get() {
                if (!this.selectedSettlementId) return 0;

                const rowsBysettlementId = this.paytrailTransactionsRoot.find((i) =>
                    i.settlementId === this.selectedSettlementId);

                return rowsBysettlementId.totalUnsettledAmount;
            }
        },
        reportUnrefundedAmount: {
            get() {
                if (!this.selectedSettlementId) return 0;

                const rowsBysettlementId = this.paytrailTransactionsRoot.find((i) =>
                    i.settlementId === this.selectedSettlementId);

                return rowsBysettlementId.totalUnrefundedAmount;
            }
        },
        reportDate: {
            get() {
                if (!this.selectedSettlementId) return null;

                const rowsBysettlementId = this.paytrailTransactionsRoot.find((i) =>
                    i.settlementId === this.selectedSettlementId);

                return new Date(rowsBysettlementId.settlementDate).toLocaleDateString("fi-FI");
            }
        },
        reportMinDate: {
            get() {
                if (!this.selectedSettlementId) return null;

                return new Date(Math.min.apply(null,
                    this.transactionsRows.map(trn => new Date(trn.createdAt)))).toLocaleDateString("fi-FI");
            }
        },
        reportMaxDate: {
            get() {
                if (!this.selectedSettlementId) return null;

                return new Date(Math.max.apply(null,
                    this.transactionsRows.map(trn => new Date(trn.createdAt)))).toLocaleDateString("fi-FI");
            }
        },
        headers() {
            let headers = [
                { text: "Maksu tehty", value: "createdAt" },
                { text: "Arkistointitunnus", value: "archiveNumber" },
                { text: "Summa", value: "amount" },
                { text: "Toiminnot", value: "actions" }
            ];
            return headers;
        },
        selectedTotalSum() {
            return this.selectedPaytrailTransactions.reduce((sum, e) => sum + e.amount, 0);
        },
        footerTotalSum() {
            return [];
        }
    },
    watch: {
        tenant(val) {
            this.getPaytrailTransactions();
        },
        itemsPerPage(val) {
            // this.loadMoreTransportEvents();
        },
        selectedHandlingTypes: {
            immediate: true,
            handler(val) {
                this.getPaytrailTransactions(val);
            }
        }
    },
    methods: {
        async getPaytrailTransactions() {
            this.selectedPaytrailTransactions = [];
            this.loading = true;
            this.$store.dispatch("paytrailTransactions/getAllPaytrailTransactions")
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        searchTransportEvent(createdAt, amount) {
            // Convert createdAt to a Date object
            const createdAtDate = new Date(createdAt);

            // Calculate tripStartedFrom and tripStartedTo
            const tripStartedFrom = new Date(createdAtDate.getTime() - 4 * 60 * 60 * 1000); // Subtract 4 hours
            const tripStartedTo = new Date(createdAtDate.getTime() + 8 * 60 * 60 * 1000); // Add 8 hours

            // Format the dates for the query parameters
            const formattedTripStartedFrom = tripStartedFrom.toISOString();
            const formattedTripStartedTo = tripStartedTo.toISOString();

            // Calculate costFrom and costTo
            const costFrom = amount - 0.05;
            const costTo = amount + 0.05;

            this.$router.push({
                name: "Invoicing",
                query: {
                    tripStartedFrom: formattedTripStartedFrom,
                    tripStartedTo: formattedTripStartedTo,
                    costFrom: costFrom,
                    costTo: costTo
                }
            });
        },
        async setAsHandled(settlementId, checkoutReference) {
            if (!window.confirm("Oletko varma että haluat käsitellä tapahtuman? Käsittely EI luo uutta ajotapahtumaa.")) {
                return;
            }
            RepositoryFactory.get("invoicing").handlePaytrailTransaction("taksini", settlementId, checkoutReference);
            this.refreshData();
        },
        async settleEvent(settlementId, archiveNumber, checkoutReference) {
            this.loading = true;
            RepositoryFactory.get("invoicing").setPaytrailEventReadyForPurchasing("taksini", settlementId, archiveNumber, checkoutReference)
                .then(response => {
                    setTimeout(() => {
                        this.refreshData();
                        this.loading = false;
                        this.$router.push({
                            name: "Invoicing",
                            query: { orderId: response.data, costCenter: "20056", purchaseInvoiceState: this.purchaseInvoiceStates.Waiting }
                        });
                    }, 5000);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error.response.data;
                });
        },
        showCreateEventsDialog() {
            const paytrailDto = this.selectedPaytrailTransactions.map(({ checkoutReference }) =>
                ({
                    operatorId: "taksini",
                    settlementId: this.selectedSettlementId,
                    checkoutReference
                }));
            this.$refs.createPaytrailEventsDialog.openDialog(paytrailDto,
                this.selectedPaytrailTransactions.length, this.selectedTotalSum);
        },
        refreshData() {
            this.loading = true;
            let timeout = 0;
            if (this.selectedPaytrailTransactions.length < 10) {
                timeout = 1000;
            } else {
                timeout = 5000;
            }
            setTimeout(() => {
                this.getPaytrailTransactions();
            }, timeout);
        }
    },
    filters: {
        handlingStatusToColor: function(value) {
            if (value) return "success";
            return "error";
        },
        handlingStatusToIcon: function(value) {
            if (value) return "mdi-check-circle-outline";
            return "mdi-close-circle-outline";
        },
        handlingStatusToToolTipText: function(value) {
            if (value) return "Käsitelty onnistuneesti";
            return "Käsittelemätön";
        }
    },
    components: { CreateEventsFromPaytrailTransactionsDialog }
};
</script>
