<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        {{$t("invoicing.transportEvents")}}
        <v-btn
          small
          @click="openTransportEventDialog(null)"
          color="primary"
          class="ml-3"
        >
          <v-icon left>mdi-plus</v-icon>
          {{$t("common.addNew")}}
        </v-btn>
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = !searchDialog"
          class="mx-2">
          {{$t("invoicing.changeSearchCriteria")}}
        </v-btn>
        <v-btn
          small
          @click="columnDialog = !columnDialog"
          class="ml-2">
            {{$t("invoicing.toggleColumns")}}
        </v-btn>
        <v-col cols="2">
        <!--<v-select
          label="View as"
          v-model="headerModel"
          :items="modelOptions"
          class="mb-0"
          ></v-select>-->
        </v-col>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="this.$t('common.filter')"
          single-line
          hide-details
          class="pt-0 mt-0 mr-2"></v-text-field>
      </v-card-actions>
    </div>
    <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table">
      <v-data-table
        :headers="showableHeaders"
        :items="transportEvents"
        item-key="Id"
        :item-class="transportEventRowClass"
        :options.sync="options"
        :searchTotalCount="searchTotalCount"
        :multi-sort="multisortEnabled"
        :search="filter"
        :loading="loadingPage"
        calculate-widths
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
        @click:row="openTransportEventDialog"
        @update:page="loadMoreTransportEvents"
      >
        <template v-slot:item.eventType="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on" :color="eventTypeColor(item)">
                  {{ "mdi-square-rounded" }}
                </v-icon>
              </template>
              <span>{{ $t(transportEventTypeToTranslationKey(item)) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.validationStatus="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  {{ "mdi-car-clock" }}
                </v-icon>
              </template>
              <span>{{ header.text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.salesInvoiceStatus="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  {{ "mdi-file-clock-outline" }}
                </v-icon>
              </template>
              <span>{{ header.text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.purchaseInvoiceStatus="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  {{ "mdi-archive-clock-outline" }}
                </v-icon>
              </template>
              <span>{{ header.text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:header.fare.paymentMethod="{ header }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  {{ "mdi-hand-coin-outline" }}
                </v-icon>
              </template>
              <span>{{ header.text }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.fare.paymentMethod="{ value, item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">{{ value | paymentMethodToIcon }}</v-icon>
            </template>
            <span>{{ $t(paymentMethodToTranslationKey(value)) }}</span>
            <span v-if="value === paymentMethods.Invoice && item.customer.costCentreId"><br/>Kustannuspaikka: {{ item.customer.costCentreId }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.trip.started="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.trip.ended="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.modified="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.sentToExternal="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.confirmationFromExternal="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.salesInvoiceSent="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.purchaseInvoiceSent="{ value }">
          <span>{{ value | formatDateTime }}</span>
        </template>
        <template v-slot:item.fare.cost="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.reimbursement="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.reimbursementVat="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.deductible="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.deductibleVat="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.waitingCost="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.waitingVat="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.discountAmount="{ value }">
          <span>{{ value | formatCurrency }}</span>
        </template>
        <template v-slot:item.fare.distance="{ value }">
          <span>{{ value | formatDistanceToKm }}</span>
        </template>
        <template v-slot:item.fare.duration="{ value }">
          <span>{{ value | formatTimespan }}</span>
        </template>
        <template v-slot:item.fare.slowDrive="{ value }">
          <span>{{ value | formatTimespan }}</span>
        </template>
        <template v-slot:item.fare.waitingTime="{ value }">
          <span>{{ value | formatTimespan }}</span>
        </template>
        <template v-slot:item.comments="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-on="on"
                v-bind="attrs"
                class="line-limit-3"
                >{{ value }}</span>
            </template>
            <span class="comments-tooltip">{{ value }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.validationStatus="{ value, item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | validationStatusToColor">{{ value | validationStatusToIcon }}</v-icon>
            </template>
            <span>{{ value | validationStatusToText }}</span>
            <span v-if="item.validationFailReason">
              <br>{{$t("invoicing.validationFailReason", {failReason: item.validationFailReason})}}
            </span>
            <br/>
            <span v-if="item.sentToExternal">
              {{ $t("invoicing.sentToExternalValidation") }} {{ item.sentToExternal | formatDateTime }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.salesInvoiceStatus="{ value, item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | salesInvoiceStatusToColor">{{ value | salesInvoiceStatusToIcon }}</v-icon>
            </template>
            <span>{{ value | salesInvoiceStatusToText }}</span>
            <span v-if="item.salesInvoiceNumber">
              <br/>{{$t("invoicing.salesInvoiceNumber", {invoiceNumber: item.salesInvoiceNumber})}}
            </span>
            <span v-if="item.salesInvoiceSent">
              <br/>
               {{$t("invoicing.salesInvoiceSent")}}:
               {{ item.salesInvoiceSent | formatDateTime }}            </span>
          </v-tooltip>
        </template>
        <template v-slot:item.purchaseInvoiceStatus="{ value, item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="value | purchaseInvoiceStatusToColor">{{ value | purchaseInvoiceStatusToIcon }}</v-icon>
            </template>
            <span>{{ value | purchaseInvoiceStatusToText }}</span>
            <span v-if="item.purchaseInvoiceNumber">
              <br>{{$t("invoicing.purchaseInvoiceNumber", {invoiceNumber: item.purchaseInvoiceNumber})}}
            </span>
            <span v-if="item.purchaseInvoiceSent">
              <br/>
               {{$t("invoicing.purchaseInvoiceSent")}}:
               {{ item.purchaseInvoiceSent | formatDateTime }}
            </span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
            <v-col cols="12" style="position: absolute" class="text-center">
              <v-card-text style="padding-top: 5px">
                {{$t("invoicing.totalReimbursementSum")}}:
                {{ totalReimbursementSum | formatCurrency }}
              </v-card-text>
            </v-col>
        </template>
        <template v-slot:item.fare.extras="{ value }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ (!value ? 0 : value.reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}
              </span>
            </template>
            <ul class="extras-list">
              <li>{{$t("invoicing.preBookingExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.PrebookFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.goodsExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.GoodsFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.airportExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.AirportFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.serviceExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.ServiceFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.aidExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.AidFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.handicapExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.HandicapFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.stretcherExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.StretcherFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.approachExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.ApproachFee ).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
              <li>{{$t("invoicing.otherExtra")}}:
                <span>{{ (!value ? 0 : value.filter(extra => extra.type === feeTypes.Unknown || extra.type === feeTypes.OtherFee).reduce((a, b) => ({ price: a.price + b.price }), {price: 0.00}).price) | formatCurrency }}</span>
              </li>
            </ul>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-row v-if="searchTotalCount > 0 && searchTotalCount <= 10000">
        <v-spacer></v-spacer>
            <v-btn
              color="primary lighten-1"
              @click="downloadTransportEvents"
              :loading="loadingCSV"
              class="mb-3">
              <v-icon class="mr-2">
                mdi-download
              </v-icon>
              {{$t("invoicing.downloadCSV")}}
            </v-btn>
            <v-spacer></v-spacer>
      </v-row>
    </v-skeleton-loader>
    <TransportEventSearchDialog
      :show="searchDialog"
      :params="searchParams"
      @search="searchTransportEvents($event, true)"
      @close="searchDialog = false"
    ></TransportEventSearchDialog>
    <TransportEventHeadersDialog
      :isOpen="columnDialog"
      @close="columnDialog = false">
    </TransportEventHeadersDialog>
    <TransportEventDialog
      :isOpen="transportEventDialog"
      :value="selectedTransportEvent"
      @save="transportEventSaved"
      @changed="transportEventChanged"
      @delete="transportEventDeleted"
      @close="closeTransportEventDialog"
    ></TransportEventDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import TransportEventDialog from "./TransportEventDialog";
import TransportEventSearchDialog from "./TransportEventSearchDialog";
import TransportEventHeadersDialog from "./TransportEventHeadersDialog";
import { PaymentMethodMixin } from "@/models/PaymentMethods";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import { TransportEventTypeMixin } from "@/models/TransportEventTypes";
import { FeeTypeMixin } from "@/models/FeeTypes";
import InputRuleMixin from "@/components/Inputs/InputRuleMixin";
import moment from "moment";

export default {
    components: { TransportEventDialog, TransportEventSearchDialog, TransportEventHeadersDialog },
    mixins: [
        TransportEventStatusMixin, TransportEventInvoicingMixin,
        FeeTypeMixin, InputRuleMixin, TransportEventTypeMixin,
        PaymentMethodMixin ],
    data() {
        return {
            loading: true,
            loadingCSV: false,
            filter: "",
            columnDialog: false,
            searchDialog: false,
            modelOptions: ["All", "Likuma", "Kela"],
            loadingPage: false,
            searchParams: {
                validationStates: [],
                modifiedFrom: moment().subtract(14, "days").format()
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            transportEventDialog: false,
            selectedTransportEvent: null,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        transportEvents() {
            return this.$store.state.transportEventTable.searchedTransportEvents;
        },
        searchTotalCount() {
            return this.$store.state.transportEventTable.searchTotalCount;
        },
        multisortEnabled() {
            return this.searchTotalCount <= 50000; // Production backend can handle with ease
        },
        options: {
            get() {
                let options = this.$store.state.transportEventTable.options;
                // hack to trigger this getter
                // eslint-disable-next-line no-unused-vars
                let { page, itemsPerPage, sortBy, sortDesc } = options;
                return options;
            },
            set(val) {
                this.$store.dispatch("transportEventTable/updateOptions", val);
            }
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.options.page,
                itemsPerPage: this.options.itemsPerPage,
                pageStart: ((this.options.page - 1) * this.options.itemsPerPage),
                pageStop: (this.options.page * this.options.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.options.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headers() {
            return this.$store.state.transportEventTablePersisted.headers;
        },
        extensions() {
            return this.$store.state.transportEventTable.extensions || {};
        },
        tenant() {
            return this.$store.state.user.tenant;
        },
        totalReimbursementSum() {
            return this.extensions.TotalReimbursementSum || 0;
        },
        sortByParams() {
            if (!this.options.sortBy || this.options.sortBy.length < 1) return null;
            let sortByValues = [];
            for (let [index, val] of this.options.sortBy.entries()) {
                let orderById = this.headers.find((header) => header.value === val).orderById;
                let direction = this.options.sortDesc[index] ? "desc" : "asc";
                sortByValues.push(orderById + ":" + direction);
            }
            return { orderBy: sortByValues };
        }
    },
    watch: {
        tenant(val) {
            this.resetColumns();
            this.searchTransportEvents(null, true);
        },
        options: {
            handler(val) {
                this.searchTransportEvents(null, false, true);
            },
            deep: true
        },
        multisortEnabled(val) {
            this.options.multiSort = val;
        }

    },
    methods: {
        async getTransportEvents(searchParams = null, forceUpdate = false, sortByUpdate = false) {
            if (sortByUpdate) {
                this.loadingPage = true; // For smoother UX if only sorting is updated
            } else {
                this.loading = true;
            }
            this.$store.dispatch("transportEventTable/searchTransportEvents", { searchParams, sortByParams: this.sortByParams, top: 200, forceUpdate: forceUpdate })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingPage = false;
                    this.loading = false;
                });
        },
        async loadMoreTransportEvents() {
            if (!this.transportEvents) return;
            if (this.transportEvents.length === this.searchTotalCount) return;
            if ((this.options.page + 1) * this.options.itemsPerPage < this.transportEvents.length) {
                return;
            }
            this.loadingPage = true;
            this.$store.dispatch("transportEventTable/loadMoreTransportEvents", {
                top: 100
            })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingPage = false;
                });
        },
        async downloadTransportEvents() {
            if (!this.transportEvents) return;
            this.loadingCSV = true;
            this.$store.dispatch("transportEventTable/downloadTransportEvents")
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingCSV = false;
                });
        },
        openTransportEventDialog(transportEvent) {
            this.selectedTransportEvent = transportEvent;
            this.transportEventDialog = true;
        },
        transportEventSaved(transportEvent) {
            this.snackbarText = "Transport event was successfully saved.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.closeTransportEventDialog();
        },
        transportEventChanged(transportEvent) {
            this.snackbarText = "Transport event was successfully saved.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.selectedTransportEvent = transportEvent;
        },
        transportEventDeleted() {
            this.snackbarText = "Transport event was successfully deleted.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.closeTransportEventDialog();
        },
        closeTransportEventDialog() {
            this.selectedTransportEvent = null;
            this.transportEventDialog = false;
        },
        searchTransportEvents(searchParams = null, forceUpdate = false, sortByUpdate = false) {
            if (searchParams) this.searchParams = searchParams;
            // this.options.page = 1;
            this.getTransportEvents(this.searchParams, forceUpdate, sortByUpdate);
            this.searchDialog = false;
        },
        transportEventRowClass(item) {
            if (item.removed) return "itemRow-removed";
            return "";
        },
        resetColumns() {
            this.$store.dispatch("transportEventTablePersisted/resetHeaders");
        }
    },
    filters: {
        statusToColor: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "";
            case 3: return "success";
            default: return "error";
            };
        },
        statusToIcon: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "mdi-av-timer";
            case 3: return "mdi-check-circle-outline";
            default: return "mdi-alert";
            };
        },
        statusToToolTipText: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "Waiting";
            case 3: return "No errors";
            default: return "Contains error(s)";
            };
        }
    },
    created() {
        if (!this.headers || this.headers.length < 1) this.resetColumns();
        this.searchTransportEvents(this.$route.query);
    }
};
</script>

<style>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
.extras-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.extras-list > li > span:last-of-type {
    float: right;
    margin-left:10px
}

.itemRow-removed {
  background-color: #bfbfbf;
}

.itemRowSideColor-grey {
    border: 5px solid #BDBDBD;;
    background-color: #BDBDBD;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-blue {
    border: 5px solid #42A5F5;
    background-color: #42A5F5;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-green {
    border: 5px solid #66BB6A;
    background-color: #66BB6A;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-yellow {
    border: 5px solid #FFEE58;
    background-color: #FFEE58;
    border-radius: 3px;
    display: inline;
}
.itemRowSideColor-orange {
    border: 5px solid #FFA726;
    background-color: #FFA726;
    border-radius: 3px;
    display: inline;
}
.line-limit-3 {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3;
   -webkit-box-orient: vertical;
}
.comments-tooltip {
  white-space: pre-line;
  max-width: 400px;
}

</style>
