export const IsochroneType = {
    Unknown: 0,
    Distance: 1,
    Time: 2
};

export function IsochroneTypeToString(isochroneType) {
    switch (isochroneType) {
    case IsochroneType.Unknown:
        return "-";
    case IsochroneType.Distance:
        return "Distance";
    case IsochroneType.Time:
        return "Time";
    }
};
