<template>
  <v-dialog
    v-model="isOpen"
    :max-width="this.openTransportEvent.eventType === transportEventType.Kela ? '800' : '1200'"
    @click:outside="$emit('close')">
    <v-card>
      <v-row>
      <v-card-title class="ml-5">
        Etsi yhdistettävä
          {{(this.openTransportEvent.eventType === transportEventType.Kela ||
            this.openTransportEvent.eventType === transportEventType.Receipt) ? ' tilaus' : ' kuitti'}}
      </v-card-title>
      <v-checkbox v-if="this.openTransportEvent.eventType !== transportEventType.Kela"
        v-model="emptyOrderId"
        label="Tyhjä tilaustunnus">
      </v-checkbox>
    </v-row>
      <v-card-subtitle v-if="isCombinedTransportEventPreviewAvailable()">
        Uudet tiedot -
         Tilaustunnus: {{ this.eventPreview.orderId }},
          Summa: {{ this.eventPreview.fare.cost | formatCurrency }},
            Maksutapa: {{ $t(paymentMethodToTranslationKey(this.eventPreview.fare.paymentMethod)) }}
      </v-card-subtitle >
      <v-card-text>
        <v-row>
        <v-col cols="12" sm="6">
        <v-text-field
          v-model="input"
          label="Tilaustunnus"
          hint="Anna tilaustunnus"
          class="mb-1"
          :disabled="emptyOrderId"
          :type="typeCheck"
          >
          <template v-if="this.openTransportEvent.eventType === transportEventType.Kela" v-slot:prepend>
            <v-select
              v-model="kelaOrderPrefix"
              :items="kelaOrderPrefixes"
              label="Etuliite"
              item-text="name"
              item-value="value"
              placeholder="Tyhjä"
              dense
              style="width: 85px; margin-top: 2px">
            </v-select>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="12" sm="1">
        <v-text-field
          v-if="this.openTransportEvent.eventType !== transportEventType.Kela"
          v-model="centDiff"
          label="Senttierotus"
          hint="+/- erotus senteissä"
          maxlength = "4"
          type="number">
        </v-text-field>
      </v-col>
      </v-row>
        <v-data-table
          v-model="selectedTransportEvent"
          :headers="this.headersToUse"
          :items="transportEvents"
          single-select
          show-select
          :loading="loading"
          :items-per-page="100"
          hide-default-footer
          dense
        >
          <template v-slot:item.trip.started="{ value }">
            <span>{{ value | formatDateTime }}</span>
          </template>
          <template v-slot:item.trip.ended="{ value }">
            <span>{{ value | formatDateTime }}</span>
          </template>
          <template v-slot:item.fare.paymentMethod="{ value, item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">{{ value | paymentMethodToIcon }}</v-icon>
            </template>
            <span>{{ $t(paymentMethodToTranslationKey(value)) }}</span>
            <span v-if="value === paymentMethods.Invoice && item.customer.costCentreId"><br/>Kustannuspaikka: {{ item.customer.costCentreId }}</span>
          </v-tooltip>
        </template>
          <template v-slot:item.fare.cost="{ value }">
            <span>{{ value | formatCurrency }}</span>
          </template>
          <template v-slot:item.orderId="{ value }">
            <div :inner-html.prop="value | highlight(searchValue)"></div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.native="combineTransportEvents"
          :disabled="isDisabled"
          :loading="combining"
          color="primary"
        >
          Yhdistä
        </v-btn>
        <v-btn @click.native="emitClose"> Peruuta </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";
import { TransportEventTypeMixin } from "../../models/TransportEventTypes";
import { PaymentMethodMixin } from "@/models/PaymentMethods";

export default {
    props: {
        isOpen: Boolean,
        openTransportEvent: Object,
        transportEventId: String,
        transportEventOrderId: String
    },
    mixins: [TransportEventTypeMixin, PaymentMethodMixin],
    data() {
        return {
            emptyOrderId: false,
            disableInput: false,
            orderIdValue: "",
            centDiffValue: 5,
            tripEventId: null,
            receiptEventId: null,
            eventPreview: null,
            kelaOrderId: this.orderIdParseNumber(this.transportEventOrderId),
            kelaOrderPrefix: this.orderIdParsePrefix(this.transportEventOrderId),
            kelaOrderPrefixes: [
                { name: "-", value: "" },
                { name: "UMB", value: "UMB" },
                { name: "LAC", value: "LAC" },
                { name: "KHC", value: "KHC" },
                { name: "KLC", value: "KLC" },
                { name: "PHB", value: "PHB" }
            ],
            searchValue: "",
            transportEvents: [],
            selectedTransportEvent: [],
            timeout: null,
            loading: false,
            combining: false,
            kelaHeaders: [
                { text: "Tilaustunnus", value: "orderId", show: true },
                { text: "Autonumero", value: "trip.carNumber", show: true },
                { text: "Matka alkoi", value: "trip.started", show: true },
                { text: "Lähtöosoite", value: "trip.startingPoint", show: true }
            ],
            headers: [
                { text: "Tilaustunnus", value: "orderId", show: true },
                { text: "Autonumero", value: "trip.carNumber", show: true },
                { text: "Matka alkoi", value: "trip.started", show: true },
                { text: "Matka loppui", value: "trip.ended", show: true },
                { text: "Lähtöosoite", value: "trip.startingPoint", show: true },
                { text: "Summa", value: "fare.cost", show: true },
                { text: "Maksutapa", value: "fare.paymentMethod", show: true }
            ]
        };
    },
    watch: {
        transportEventOrderId(val) {
            if (this.openTransportEvent.eventType === this.transportEventType.Kela) {
                this.input = this.orderIdParseNumber(val);
                this.kelaOrderPrefix = this.orderIdParsePrefix(val);
            } else {
                this.input = "";
            }
        },
        emptyOrderId(val) {
            if (val) {
                this.eventPreview = null;
                this.orderIdValue = "";
                this.input = "";
                this.searchValue = "";
            }
        },
        selectedTransportEvent: {
            handler(newVal, oldVal) {
                if (newVal.length === 0 ||
            this.openTransportEvent.eventType === this.transportEventType.Kela) {
                    this.eventPreview = null;
                }
                if (newVal.length === 1 && oldVal.length === 0 &&
            this.openTransportEvent.eventType !== this.transportEventType.Kela) {
                    this.getCombinedTransportEventPreview();
                }
            },
            deep: true
        }
    },
    computed: {
        typeCheck() {
            if (this.openTransportEvent.eventType === this.transportEventType.Kela) {
                if (this.kelaOrderPrefix && this.kelaOrderPrefix.length > 0) {
                    return "number";
                }
                return "text";
            }
            return "number";
        },
        isDisabled() {
            return this.selectedTransportEvent.length === 0;
        },
        input: {
            get() {
                if (this.openTransportEvent.eventType === this.transportEventType.Kela) {
                    return this.kelaOrderId;
                }
                return this.orderIdValue === (null || undefined) ? "" : this.orderIdValue;
            },
            set(val) {
                if (this.timeout) clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    if (this.openTransportEvent.eventType === this.transportEventType.Kela) {
                        this.kelaOrderId = val;
                        this.searchKelaTransportEvents(this.kelaOrderId);
                    } else {
                        this.orderIdValue = val === (null || undefined) ? "" : val;
                        this.searchTransportEvents(this.orderIdValue);
                    }
                }, 800);
            }
        },
        centDiff: {
            get() {
                return this.centDiffValue;
            },
            set(val) {
                this.centDiffValue = val;
                this.searchTransportEvents(this.orderIdValue);
            }
        },
        headersToUse() {
            if (this.openTransportEvent.eventType === this.transportEventType.Kela) {
                return this.kelaHeaders;
            }
            return this.headers;
        }
    },
    methods: {
        emitClose() {
            this.$emit("close");
            this.clearDataAndFields();
        },
        searchTransportEvents(orderIdValue) {
            if (this.clearSearchData(orderIdValue)) return;
            this.loading = true;

            const started = new Date(this.openTransportEvent.trip.started);
            const tripStartedFromTimestamp = new Date(started.getTime() + (-210 * 60000));
            const tripStartedToTimestamp = new Date(started.getTime() + 210 * 80000);
            const tripEventTypes = [
                this.transportEventType.Normal,
                this.transportEventType.Unknown
            ];

            const receiptEventType = [this.transportEventType.Receipt];
            let searchParams = {
                orderId: orderIdValue !== (null || undefined || "") ? orderIdValue + "*" : null,
                eventTypes: this.openTransportEvent.eventType === this.transportEventType.Receipt ? tripEventTypes : receiptEventType,
                tripStartedFrom: tripStartedFromTimestamp,
                tripStartedTo: tripStartedToTimestamp,
                costFrom: this.openTransportEvent.fare.cost - (this.centDiff > 0 ? (this.centDiff / 100) : 0),
                costTo: this.openTransportEvent.fare.cost + (this.centDiff > 0 ? (this.centDiff / 100) : 0)
            };

            this.searchValue = orderIdValue;

            RepositoryFactory.get("invoicing")
                .find(searchParams, null, 15)
                .then((response) => {
                    this.transportEvents = response.data.results;
                    this.loading = false;
                });
        },
        searchKelaTransportEvents(value) {
            if (this.clearSearchData(value)) return;
            this.loading = true;
            let searchParams = {
                orderId: this.kelaOrderId = value.toUpperCase(),
                missingCharge: true
            };
            this.searchValue = value;
            if (!this.kelaOrderPrefix) {
                searchParams.orderId = "*" + this.kelaOrderId + "*";
            } else {
                searchParams.orderId = this.kelaOrderPrefix + "*" + this.kelaOrderId + "*";
            }
            RepositoryFactory.get("invoicing")
                .find(searchParams, 100)
                .then((response) => {
                    this.transportEvents = response.data.results;
                    this.loading = false;
                });
        },
        combineTransportEvents() {
            if (
                !confirm(
                    "Haluatko varmasti yhdistää tilausten? Yhdistäminen poistaa tilauksen tiedot eikä tätä voi enää perua."
                )
            ) {
                return;
            }
            this.combining = true;
            RepositoryFactory.get("invoicing")
                .combine(this.transportEventId, this.selectedTransportEvent[0].id)
                .then(() => {
                    RepositoryFactory.get("invoicing")
                        .get(this.transportEventId)
                        .then((response) => {
                            this.$emit("close");
                            this.$emit("combined", response.data.item);
                        });
                })
                .catch((error) => {
                    this.$emit("failed", error);
                })
                .finally(() => {
                    this.combining = false;
                });
        },
        isCombinedTransportEventPreviewAvailable() {
            if (this.openTransportEvent.eventType === this.transportEventType.Kela) return false;
            if (this.selectedTransportEvent.length === 0) return false;

            return this.eventPreview !== (null && undefined);
        },
        getCombinedTransportEventPreview() {
            this.tripEventId = this.openTransportEvent.eventType === this.transportEventType.Receipt
                ? this.selectedTransportEvent[0].id : this.openTransportEvent.id;
            this.receiptEventId = this.openTransportEvent.eventType === this.transportEventType.Receipt
                ? this.openTransportEvent.id : this.selectedTransportEvent[0].id;
            RepositoryFactory.get("invoicing")
                .getCombinedEvent(this.tripEventId, this.receiptEventId)
                .then((response) => {
                    this.eventPreview = response.data;
                })
                .catch((error) => {
                    this.$emit("failed", error);
                });
        },
        clearDataAndFields() {
            this.clearData();
            this.searchValue = "";
            this.input = "";
            this.timeout = null;
            this.loading = false;
            this.combining = false;
            this.kelaOrderPrefix = "";
            this.eventPreview = null;
            this.tripEventId = null;
            this.receiptEventId = null;
        },
        clearSearchData(value) {
            if (this.openTransportEvent.eventType === this.transportEventType.Kela) {
                if (!value || value.length === 0) {
                    this.clearData();
                    return true;
                }
                if (!this.kelaOrderPrefix && value.length < 5) {
                    this.clearData();
                    return true;
                }
                if (this.kelaOrderPrefix && this.kelaOrderPrefix.length + value.length < 4) {
                    this.clearData();
                    return true;
                }
                return false;
            }

            if (this.emptyOrderId) {
                return false;
            }

            if (!value || value.length === 0) {
                this.clearData();
                return true;
            }
            return false;
        },
        clearData() {
            this.loading = true;
            this.abortSearch();
            this.transportEvents = [];
            this.selectedTransportEvent = [];
            this.loading = false;
            this.emptyOrderId = false;
        },
        abortSearch() {
            let cancelReq = RepositoryFactory.get("invoicing").getCancelRequest();
            if (cancelReq) {
                cancelReq.cancel("Search cancelled.");
            }
        },
        orderIdParseNumber(orderId) {
            if (!orderId) return "";
            this.input = orderId.substring(3);
            return orderId.substring(3);
        },
        orderIdParsePrefix(orderId) {
            if (!orderId) return "";
            return orderId.substring(0, 3);
        }
    },
    filters: {
        highlight: function(value, query) {
            if (query === (null || undefined) || query === "" || query === "*") return value;
            return value.replace(
                new RegExp(query, "ig"),
                "<span style='background: #B0C4DE; border-radius: 5px; padding: 1px'>" +
          query +
          "</span>"
            );
        }
    }
};
</script>

<style>
</style>
