<template>
    <v-dialog v-model="isOpen" persistent max-width="800" @keydown.esc="$emit('cancel')">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ this.header }}
          </span>
        </v-card-title>
        <v-card-text style="color: black;">
          {{ this.message }}
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('save')"
            color="primary"
          >
              {{$t("common.save")}}
          </v-btn>
          <v-btn @click="$emit('cancel')" class="ml-4">
              {{$t("common.cancel")}}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>

<script>
export default {
    props: {
        header: {
            type: String,
            default: ""
        },
        message: {
            type: String,
            default: ""
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    }
};
</script>
