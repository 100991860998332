var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-row", { attrs: { align: "center", justify: "center" } }, [
        _c("img", {
          staticClass: "hidden-sm-and-up",
          staticStyle: { "max-width": "80vw" },
          attrs: { src: require("@/assets/logo.png"), alt: "Router Group logo" }
        }),
        _c("img", {
          staticClass: "hidden-xs-only",
          staticStyle: { "max-height": "50vh" },
          attrs: {
            src: require("@/assets/logo_full.png"),
            alt: "Router Group logo"
          }
        })
      ]),
      _c("v-row", { attrs: { align: "center", justify: "center" } }, [
        _c("h1", [_vm._v("Management Portal")])
      ]),
      _c(
        "v-row",
        { staticClass: "mt-10", attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-btn",
            {
              attrs: { large: "", color: "primary" },
              on: { click: _vm.login }
            },
            [_vm._v(_vm._s(_vm.$t("login.loginButton")))]
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-5", attrs: { align: "center", justify: "center" } },
        [_c("p", [_vm._v(_vm._s(_vm.$t("login.enablePopUps")))])]
      ),
      _c(
        "v-overlay",
        { attrs: { value: _vm.loading } },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }