<template>
  <v-container fluid>
        <v-row justify="end">
          <v-col>
            <h1>{{$t("invoicing.title")}}</h1>
          </v-col>
          <v-col v-if="filterByTenant" cols="auto">
              <v-btn
                class="ma-2"
                color="blue-grey white--text"
                @click="navigate('SalesInvoicing')">
                {{$t("invoicing.sales.title")}}
                <v-icon right>mdi-archive-outline</v-icon>
              </v-btn>
              <v-btn
                class="ma-2"
                color="blue-grey white--text"
                @click="navigate('PurchaseInvoicing')">
                {{$t("invoicing.purchasing.title")}}
                <v-icon right>mdi-archive-outline</v-icon>
              </v-btn>
          </v-col>
          <v-col v-if="ltxSpecificNavItems" cols="auto">
              <v-btn
                class="ma-2"
                color="light-blue white--text"
                @click="navigate('MissingVerifoneEvents')">
                {{$t("invoicing.verifone.title")}}
                <v-icon right>mdi-credit-card-search-outline</v-icon>
              </v-btn>
              <v-btn
                class="ma-2"
                color="pink white--text"
                @click="navigate('PaytrailTransactions')">
                {{$t("invoicing.paytrail.title")}}
                <v-icon right>mdi-credit-card-search</v-icon>
              </v-btn>
          </v-col>
        </v-row>
        <TransportEventTable></TransportEventTable>
  </v-container>
</template>

<script>
import TransportEventTable from "../components/Invoicing/TransportEventTable";

export default {
    components: { TransportEventTable },
    computed: {
        ltxSpecificNavItems() {
            return this.$store.state.user.tenant === "ltx";
        },
        filterByTenant() {
            return this.$store.state.user.tenant === "ltx" ||
             this.$store.state.user.tenant === "kaista";
        }
    },
    methods: {
        navigate(name) {
            this.$router.push({ name: name });
        }
    }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
