var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transportEvent
    ? _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("v-subheader", { staticClass: "mt-3" }, [
            _vm._v(
              _vm._s(_vm.$t("invoicing.transportEventTypes.kela.kelaTripInfo"))
            )
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Henkilötunnus",
                              placeholder: "Ei haettu"
                            },
                            model: {
                              value: _vm.transportEvent.user.ssn,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent.user, "ssn", $$v)
                              },
                              expression: "transportEvent.user.ssn"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                color: "primary",
                                loading: _vm.ssnLoading
                              },
                              on: { click: _vm.getSSN }
                            },
                            [_vm._v("Hae")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      label: "Matka alkoi",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.dateTimeRule],
                      type: _vm.tripStartedTextFieldType,
                      placeholder: "yyyy-MM-dd HH:mm",
                      max: "2099-12-31T00:00",
                      required: ""
                    },
                    on: {
                      dblclick: _vm.dblClickTripStartedTypeChange,
                      focus: function($event) {
                        return $event.target.select()
                      }
                    },
                    model: {
                      value: _vm.transportEvent.trip.started,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "started", $$v)
                      },
                      expression: "transportEvent.trip.started"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Matka loppui",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.dateTimeRule],
                      type: _vm.tripEndedTextFieldType,
                      placeholder: "yyyy-MM-dd HH:mm",
                      max: "2099-12-31T00:00",
                      required: ""
                    },
                    on: {
                      dblclick: _vm.dblClickTripEndedTypeChange,
                      focus: function($event) {
                        return $event.target.select()
                      }
                    },
                    model: {
                      value: _vm.transportEvent.trip.ended,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "ended", $$v)
                      },
                      expression: "transportEvent.trip.ended"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.carTypes,
                      rules: [_vm.enumValueRequiredRule],
                      label: "Autotyyppi",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.carType,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "carType", $$v)
                      },
                      expression: "transportEvent.trip.carType"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.tripTypes,
                      rules: [_vm.enumValueRequiredRule],
                      label: "Matkakohde",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.tripType,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "tripType", $$v)
                      },
                      expression: "transportEvent.trip.tripType"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Tilaustunnus",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(20)],
                      counter: "20",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.orderId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent, "orderId", $$v)
                      },
                      expression: "transportEvent.orderId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Reittitunnus",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(20)],
                      counter: "20",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.trip.routeNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "routeNumber", $$v)
                      },
                      expression: "transportEvent.trip.routeNumber"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.ordererTypes,
                      rules: [_vm.enumValueRequiredRule],
                      label: "Tilaaja",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.kelaData.ordererType,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.kelaData,
                          "ordererType",
                          $$v
                        )
                      },
                      expression: "transportEvent.kelaData.ordererType"
                    }
                  }),
                  _c("v-select", {
                    staticClass: "mt-0",
                    attrs: {
                      items: _vm.carPoolValues,
                      label: "Yhdistelymatka",
                      disabled: _vm.readOnly
                    },
                    model: {
                      value: _vm.transportEvent.trip.carPool,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "carPool", $$v)
                      },
                      expression: "transportEvent.trip.carPool"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.carTypeRequirements,
                      rules: [_vm.enumValueRequiredRule],
                      label: "Autotyyppivaatimus",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.kelaData.carTypeRequirement,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.kelaData,
                          "carTypeRequirement",
                          $$v
                        )
                      },
                      expression: "transportEvent.kelaData.carTypeRequirement"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Lähtöpisteen osoite",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(100)],
                      counter: "100"
                    },
                    model: {
                      value: _vm.waypoints.startingPoint.address,
                      callback: function($$v) {
                        _vm.$set(_vm.waypoints.startingPoint, "address", $$v)
                      },
                      expression: "waypoints.startingPoint.address"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.municipalities,
                      label: "Lähtöpisteen kuntakoodi",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.waypoints.startingPoint.municipalityCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.waypoints.startingPoint,
                          "municipalityCode",
                          $$v
                        )
                      },
                      expression: "waypoints.startingPoint.municipalityCode"
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Lähtöpisteen koordinaatit",
                              placeholder: "Ei haettu",
                              disabled: ""
                            },
                            model: {
                              value: _vm.waypoints.startingPoint.coordinates,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.waypoints.startingPoint,
                                  "coordinates",
                                  $$v
                                )
                              },
                              expression: "waypoints.startingPoint.coordinates"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: { color: "primary" },
                              on: { click: _vm.updateStartingPointCoordinates }
                            },
                            [_vm._v("Hae")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      label: "Päätepisteen osoite",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(100)],
                      counter: "100"
                    },
                    model: {
                      value: _vm.waypoints.destination.address,
                      callback: function($$v) {
                        _vm.$set(_vm.waypoints.destination, "address", $$v)
                      },
                      expression: "waypoints.destination.address"
                    }
                  }),
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.municipalities,
                      label: "Päätepisteen kuntakoodi",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    model: {
                      value: _vm.waypoints.destination.municipalityCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.waypoints.destination,
                          "municipalityCode",
                          $$v
                        )
                      },
                      expression: "waypoints.destination.municipalityCode"
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "8" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Päätepisteen koordinaatit",
                              placeholder: "Ei haettu",
                              disabled: ""
                            },
                            model: {
                              value: _vm.waypoints.destination.coordinates,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.waypoints.destination,
                                  "coordinates",
                                  $$v
                                )
                              },
                              expression: "waypoints.destination.coordinates"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-3",
                              attrs: { color: "primary" },
                              on: { click: _vm.updateDestinationCoordinates }
                            },
                            [_vm._v("Hae")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      label: "SaattajaLkm",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(1)
                      ],
                      counter: "1",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.kelaData.escortCount,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.kelaData,
                          "escortCount",
                          $$v
                        )
                      },
                      expression: "transportEvent.kelaData.escortCount"
                    }
                  }),
                  _c(
                    "v-slide-y-transition",
                    { attrs: { group: "", "hide-on-leave": "" } },
                    [
                      _vm.transportEvent.trip.carPool === "K"
                        ? _c("v-text-field", {
                            key: "1",
                            attrs: {
                              label: "MatkustajaLkm - reitillä",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(2)
                              ],
                              counter: "2",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.kelaData.passengerCount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.kelaData,
                                  "passengerCount",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.kelaData.passengerCount"
                            }
                          })
                        : _c("v-switch", {
                            key: "2",
                            staticClass: "mt-0",
                            attrs: {
                              label: "Ei sovellu yhteiskuljetukseen",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value:
                                _vm.transportEvent.kelaData
                                  .customerNotSuitableForJointRide,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.kelaData,
                                  "customerNotSuitableForJointRide",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.kelaData.customerNotSuitableForJointRide"
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "4" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Matkaketju",
                      disabled: _vm.readOnly,
                      items: _vm.tripChainValues
                    },
                    model: {
                      value: _vm.transportEvent.kelaData.tripChain,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.kelaData, "tripChain", $$v)
                      },
                      expression: "transportEvent.kelaData.tripChain"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      label: "Matkan aihe",
                      disabled: _vm.readOnly,
                      items: _vm.tripTopicValues,
                      rules: [_vm.enumValueRequiredRule],
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.kelaData.tripTopic,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.kelaData, "tripTopic", $$v)
                      },
                      expression: "transportEvent.kelaData.tripTopic"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Palveluntuottaja Y-tunnus",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthWithoutDashRule(8)],
                      counter: "9",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.serviceProviderVatId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "serviceProviderVatId",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.serviceProviderVatId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Palveluntuottaja nimi",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(35)],
                      counter: "35"
                    },
                    model: {
                      value: _vm.transportEvent.fare.serviceProviderName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "serviceProviderName",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.serviceProviderName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Autonumero",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(8)],
                      counter: "8"
                    },
                    model: {
                      value: _vm.transportEvent.trip.carNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "carNumber", $$v)
                      },
                      expression: "transportEvent.trip.carNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Rekisterinumero",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.maxLengthWithoutDashRule(6)
                      ],
                      counter: "7"
                    },
                    model: {
                      value: _vm.transportEvent.trip.licenceNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "licenceNumber", $$v)
                      },
                      expression: "transportEvent.trip.licenceNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Palvelutunnus",
                      disabled: _vm.readOnly,
                      rules: [_vm.requiredRule, _vm.maxLengthRule(12)],
                      counter: "14"
                    },
                    model: {
                      value: _vm.transportEvent.fare.paymentTerminalId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "paymentTerminalId",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.paymentTerminalId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      value: _vm._f("formatDistanceToKm")(
                        this.transportEvent.fare.distance
                      ),
                      label: "Kokonaismatka (km)",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthRule(8)
                      ],
                      counter: "8",
                      required: ""
                    },
                    on: { change: _vm.updateFareDistance }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("v-subheader", { staticClass: "mt-3" }, [
            _vm._v(
              _vm._s(_vm.$t("invoicing.transportEventTypes.kela.kelaCharge"))
            )
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Kuljettajanumero",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(12)],
                      counter: "12"
                    },
                    model: {
                      value: _vm.transportEvent.trip.driverId,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.trip, "driverId", $$v)
                      },
                      expression: "transportEvent.trip.driverId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Kuittinumero",
                      disabled: _vm.readOnly,
                      rules: [
                        _vm.requiredRule,
                        _vm.digitRule,
                        _vm.maxLengthRule(20)
                      ],
                      counter: "20",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.receiptNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "receiptNumber", $$v)
                      },
                      expression: "transportEvent.fare.receiptNumber"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: [0.0, 10.0, 24.0, 25.5],
                      rules: [_vm.requiredRule],
                      label: "ALV",
                      disabled: _vm.readOnly,
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item) + " % ")]
                          }
                        },
                        {
                          key: "item",
                          fn: function(ref) {
                            var item = ref.item
                            return [_vm._v(" " + _vm._s(item) + " % ")]
                          }
                        }
                      ],
                      null,
                      false,
                      3334045436
                    ),
                    model: {
                      value: _vm.transportEvent.fare.vatPercent,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "vatPercent", $$v)
                      },
                      expression: "transportEvent.fare.vatPercent"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Perusmaksu",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.maxLengthWithoutDecimalSeparatorRule(6),
                        _vm.nonZeroValueRequiredRule
                      ],
                      counter: "7"
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.kelaData.baseCost,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.kelaData, "baseCost", $$v)
                      },
                      expression: "transportEvent.kelaData.baseCost"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Reittihinta",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [_vm.maxLengthWithoutDecimalSeparatorRule(6)],
                      counter: "7"
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.kelaData.routePrice,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.kelaData, "routePrice", $$v)
                      },
                      expression: "transportEvent.kelaData.routePrice"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Kustannus",
                      disabled:
                        _vm.readOnly || _vm.transportEvent.trip.carPool !== "K",
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.fare.cost,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "cost", $$v)
                      },
                      expression: "transportEvent.fare.cost"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Korvaus",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9",
                      required: ""
                    },
                    model: {
                      value: _vm.transportEvent.fare.reimbursement,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "reimbursement", $$v)
                      },
                      expression: "transportEvent.fare.reimbursement"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Korvaus ALV",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(8)
                      ],
                      counter: "9"
                    },
                    model: {
                      value: _vm.transportEvent.fare.reimbursementVat,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.fare,
                          "reimbursementVat",
                          $$v
                        )
                      },
                      expression: "transportEvent.fare.reimbursementVat"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Omavastuu",
                      disabled: _vm.readOnly,
                      type: "number",
                      rules: [
                        _vm.requiredRule,
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7",
                      required: ""
                    },
                    on: { input: _vm.calculateSums },
                    model: {
                      value: _vm.transportEvent.fare.deductible,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "deductible", $$v)
                      },
                      expression: "transportEvent.fare.deductible"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Omavastuu ALV",
                      disabled: "",
                      type: "number",
                      rules: [
                        _vm.decimalRule,
                        _vm.maxLengthWithoutDecimalSeparatorRule(6)
                      ],
                      counter: "7"
                    },
                    model: {
                      value: _vm.transportEvent.fare.deductibleVat,
                      callback: function($$v) {
                        _vm.$set(_vm.transportEvent.fare, "deductibleVat", $$v)
                      },
                      expression: "transportEvent.fare.deductibleVat"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: "Maksusitoumuksen tunnus",
                      disabled: _vm.readOnly,
                      rules: [_vm.maxLengthRule(10)],
                      counter: "10"
                    },
                    model: {
                      value: _vm.transportEvent.kelaData.paymentCommitment,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.transportEvent.kelaData,
                          "paymentCommitment",
                          $$v
                        )
                      },
                      expression: "transportEvent.kelaData.paymentCommitment"
                    }
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: "Suorakorvausoikeus",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value:
                                _vm.transportEvent.kelaData
                                  .passengerEligibility,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.kelaData,
                                  "passengerEligibility",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.kelaData.passengerEligibility"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-switch", {
                            staticClass: "mt-0",
                            attrs: {
                              label: "Omavastuuta ei ole peritty",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value: _vm.deductibleNotCollected,
                              callback: function($$v) {
                                _vm.deductibleNotCollected = $$v
                              },
                              expression: "deductibleNotCollected"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.fares")))
                  ]),
                  _c("FareChipGroup", {
                    ref: "fareChipGroup",
                    attrs: {
                      fares: _vm.transportEvent.fare.tariffs,
                      allowedFareTypes: _vm.allowedFareTypes,
                      maxAmount: 3,
                      readOnly: _vm.readOnly,
                      verbose: true
                    },
                    on: { update: _vm.updateFares }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.extraCharges")))
                  ]),
                  _c("FeeChipGroup", {
                    ref: "feeChipGroup",
                    attrs: {
                      fees: _vm.transportEvent.fare.extras,
                      allowedFeeTypes: _vm.extraChargeTypes,
                      maxAmount: 5,
                      readOnly: _vm.readOnly,
                      useKelaTexts: true
                    },
                    on: { update: _vm.updateFees }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "auto" } },
                [
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.waitFees")))
                  ]),
                  _c("WaitFeeChipGroup", {
                    ref: "waitFeeChipGroup",
                    attrs: {
                      waitFees: _vm.transportEvent.fare.waitFees,
                      allowedWaitFeeTypes: ["O", "H"],
                      maxAmount: 2,
                      readOnly: _vm.readOnly,
                      verbose: true
                    },
                    on: { update: _vm.updateWaitFees }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-6" },
            [
              _c("v-textarea", {
                staticClass: "mx-2",
                attrs: {
                  label: "Kommentit (ei mene Kelaan)",
                  outlined: "",
                  rows: "3"
                },
                model: {
                  value: _vm.transportEvent.comments,
                  callback: function($$v) {
                    _vm.$set(_vm.transportEvent, "comments", $$v)
                  },
                  expression: "transportEvent.comments"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-textarea", {
                staticClass: "mx-2",
                attrs: {
                  label: "Korvauskäsittelyyn liittyvä lisätieto Kelalle",
                  outlined: "",
                  rows: "3"
                },
                model: {
                  value: _vm.transportEvent.kelaData.extraInfo,
                  callback: function($$v) {
                    _vm.$set(_vm.transportEvent.kelaData, "extraInfo", $$v)
                  },
                  expression: "transportEvent.kelaData.extraInfo"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-textarea", {
                staticClass: "mx-2",
                attrs: {
                  label: "Lisätietoja tilitysraportille",
                  disabled: _vm.readOnly,
                  outlined: "",
                  rows: "3"
                },
                model: {
                  value: _vm.transportEvent.purchaseInvoiceMessage,
                  callback: function($$v) {
                    _vm.$set(_vm.transportEvent, "purchaseInvoiceMessage", $$v)
                  },
                  expression: "transportEvent.purchaseInvoiceMessage"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.commentUpdating },
                      on: { click: _vm.updateComment }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("invoicing.updateComments")) + " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.transportEvent.validationFailReasonCode
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Validointivirhe", readonly: "" },
                        model: {
                          value: _vm.transportEvent.validationFailReason,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.transportEvent,
                              "validationFailReason",
                              $$v
                            )
                          },
                          expression: "transportEvent.validationFailReason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: {
                      label: "Älä lähetä Kelaan",
                      disabled: _vm.readOnly,
                      hint:
                        "Tapahtumaa ei lähetetä Kelalle tilitettäväksi kunnes tätä arvoa muutetaan",
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.toNotBeValidated,
                      callback: function($$v) {
                        _vm.toNotBeValidated = $$v
                      },
                      expression: "toNotBeValidated"
                    }
                  })
                ],
                1
              ),
              _c("v-spacer")
            ],
            1
          ),
          _c("SaveIncompleteConfirmationDialog", {
            attrs: {
              isOpen: _vm.saveIncompleteDialog,
              header: "Tallenna keskeneräisenä",
              message:
                "Tapahtumassa oli puutteita, haluatko tallentaa sen keskeneräisenä?"
            },
            on: {
              save: _vm.saveIncomplete,
              cancel: function($event) {
                _vm.saveIncompleteDialog = false
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }