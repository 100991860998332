var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", "clipped-left": "", color: "black", dark: "" } },
    [
      _c("v-app-bar-nav-icon", {
        staticClass: "navigation-toggle hidden-lg-and-up",
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("sideMenuToggled")
          }
        }
      }),
      _c("v-spacer"),
      _c(
        "v-toolbar-title",
        { staticClass: "ml-0 pl-3", staticStyle: { "max-width": "800px" } },
        [
          _c("img", {
            staticClass: "site-logo hidden-xs-only",
            attrs: {
              src: require("@/assets/logo.png"),
              alt: "Router Group logo"
            }
          }),
          _c("a", { attrs: { rel: "author", href: "/" } }, [
            _c("span", { staticClass: "site-title" }, [
              _vm._v(_vm._s(_vm.header))
            ])
          ])
        ]
      ),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { text: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _c("span", { staticClass: "tenant-button" }, [
                        _vm._v(_vm._s(_vm.tenant))
                      ]),
                      _vm.tenants.length > 1
                        ? _c("v-icon", [_vm._v("mdi-chevron-down")])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            { staticClass: "px-2" },
            [
              _c("strong", [_vm._v(" Tenant ")]),
              _vm._l(_vm.tenants, function(tenant, index) {
                return _c(
                  "v-list-item",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        return _vm.changeTenant(tenant)
                      }
                    }
                  },
                  [_c("v-list-item-title", [_vm._v(_vm._s(tenant))])],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b({ attrs: { text: "" } }, "v-btn", attrs, false),
                      on
                    ),
                    [
                      _vm._v(" " + _vm._s(_vm.language) + " "),
                      _c("v-icon", [_vm._v("mdi-chevron-down")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            _vm._l(_vm.languages, function(language, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  on: {
                    click: function($event) {
                      return _vm.changeLanguage(language.value)
                    }
                  }
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(language.text))])],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { icon: "" }, on: { click: _vm.logOut } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t("login.logOut")))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }