var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      _vm.isNewWaitFee
                        ? "invoicing.addNewWaitFee"
                        : "invoicing.editWaitFee"
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.allowedWaitFeeTypes,
                                  rules: [
                                    function(v) {
                                      return !!v || "Wait fee type is required"
                                    }
                                  ],
                                  label: this.$t("invoicing.waitFeeType"),
                                  required: ""
                                },
                                model: {
                                  value: _vm.selectedWaitFeeType,
                                  callback: function($$v) {
                                    _vm.selectedWaitFeeType = $$v
                                  },
                                  expression: "selectedWaitFeeType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.hourPrice"),
                                  type: "number",
                                  rules: [
                                    function(v) {
                                      return !!v || "Hour price cannot be empty"
                                    },
                                    function(v) {
                                      return (
                                        /^\d+((.|,)\d+)?$/.test(v) ||
                                        "Hour price is not valid decimal value"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        !v ||
                                        v
                                          .toString()
                                          .replace(".", "")
                                          .replace(",", "").length <= 8 ||
                                        "Hour price cannot be longer than 8 characters without decimal point"
                                      )
                                    }
                                  ],
                                  counter: "9",
                                  required: ""
                                },
                                model: {
                                  value: _vm.waitFee.hourPrice,
                                  callback: function($$v) {
                                    _vm.$set(_vm.waitFee, "hourPrice", $$v)
                                  },
                                  expression: "waitFee.hourPrice"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.time"),
                                  type: "number",
                                  counter: "4",
                                  required: ""
                                },
                                model: {
                                  value: _vm.waitFee.minutes,
                                  callback: function($$v) {
                                    _vm.$set(_vm.waitFee, "minutes", $$v)
                                  },
                                  expression: "waitFee.minutes"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.price"),
                                  type: "number",
                                  rules: [
                                    function(v) {
                                      return !!v || "Price cannot be empty"
                                    },
                                    function(v) {
                                      return (
                                        /^\d+((.|,)\d+)?$/.test(v) ||
                                        "Price is not valid decimal value"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        !v ||
                                        v
                                          .toString()
                                          .replace(".", "")
                                          .replace(",", "").length <= 8 ||
                                        "Price cannot be longer than 8 characters without decimal point"
                                      )
                                    }
                                  ],
                                  counter: "9",
                                  required: ""
                                },
                                model: {
                                  value: _vm.waitFee.price,
                                  callback: function($$v) {
                                    _vm.$set(_vm.waitFee, "price", $$v)
                                  },
                                  expression: "waitFee.price"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-col")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNewWaitFee
                ? _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "success" },
                      on: { click: _vm.createWaitFee }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("invoicing.addWaitFee")) + " ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "primary" },
                      on: { click: _vm.saveWaitFee }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.saveChanges")) + " ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }