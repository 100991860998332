import { bookingService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    // Booking
    async find(params, top, page = 1) {
        return bookingService.get(`${resource}/bookings`, {
            params: {
                ...params,
                top,
                page
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getBooking(bookingId) {
        return bookingService.get(`${resource}/bookings/${bookingId}`);
    }
};
