export const InvoicingBatchStatus = {
    Unknown: 0,
    Started: 1,
    InvoiceCreationInProgress: 2,
    InvoiceCreationCompleted: 3,
    InvoiceVerificationInProgress: 4,
    InvoicingCompleted: 5,
    Cancelled: 6
};

export const InvoicingBatchStatusMixin = {
    data() {
        return {
            invoicingBatchStatuses: InvoicingBatchStatus
        };
    },
    filters: {
        invoicingBatchStatusToString(invoicingBatchStatus) {
            switch (invoicingBatchStatus) {
            case InvoicingBatchStatus.Unknown:
                return "-";
            case InvoicingBatchStatus.Started:
                return "Started";
            case InvoicingBatchStatus.InvoiceCreationInProgress:
                return "In Progress";
            case InvoicingBatchStatus.InvoiceCreationCompleted:
                return "Creation Completed";
            case InvoicingBatchStatus.InvoiceVerificationInProgress:
                return "Verification In Progress";
            case InvoicingBatchStatus.InvoicingCompleted:
                return "Completed";
            case InvoicingBatchStatus.Cancelled:
                return "Cancelled";
            }
        },
        invoicingBatchStatusToColor(invoicingBatchStatus) {
            switch (invoicingBatchStatus) {
            case InvoicingBatchStatus.Unknown:
                return "grey darken-4";
            case InvoicingBatchStatus.Started:
                return "orange darken-4";
            case InvoicingBatchStatus.InvoiceCreationInProgress:
                return "blue darken-4";
            case InvoicingBatchStatus.InvoiceCreationCompleted:
                return "light-blue darken-4";
            case InvoicingBatchStatus.InvoiceVerificationInProgress:
                return "blue darken-4";
            case InvoicingBatchStatus.InvoicingCompleted:
                return "green darken-4";
            case InvoicingBatchStatus.Cancelled:
                return "red darken-4";
            }
        },
        invoicingBatchStatusToIcon(invoicingBatchStatus) {
            switch (invoicingBatchStatus) {
            case InvoicingBatchStatus.Unknown:
                return "mdi-help-circle";
            case InvoicingBatchStatus.Started:
                return "mdi-file-clock-outline";
            case InvoicingBatchStatus.InvoiceCreationInProgress:
                return "mdi-file-send-outline";
            case InvoicingBatchStatus.InvoiceCreationCompleted:
                return "mdi-file-document-check-outline";
            case InvoicingBatchStatus.InvoiceVerificationInProgress:
                return "mdi-file-sync-outline";
            case InvoicingBatchStatus.InvoicingCompleted:
                return "mdi-check-circle-outline";
            case InvoicingBatchStatus.Cancelled:
                return "mdi-file-undo-outline";
            }
        }
    }
};
