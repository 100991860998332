<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
        <v-card-title>
            <span class="headline">{{ header }}</span>
        </v-card-title>
        <v-card-text class="mt-5">
            <span v-if="isTaksiniUser">{{$t('customerDetails.userDialog.taksiniUserEditing')}}</span>
            <v-form class="mt-5" ref="userForm" v-model="valid">
                <v-text-field
                  v-if="userId"
                  v-model="userId"
                  :label="$t('customerDetails.userDialog.userId')"
                  readonly
                  disabled
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="firstName"
                  :label="$t('customerDetails.userDialog.firstName')"
                  :disabled="isTaksiniUser"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="middleName"
                  :label="$t('customerDetails.userDialog.middleName')"
                  :disabled="isTaksiniUser"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="lastName"
                  :label="$t('customerDetails.userDialog.lastName')"
                  :disabled="isTaksiniUser"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="costCenter"
                  :label="$t('customerDetails.userDialog.costCenter')"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="beneficiaryId"
                  :label="$t('customerDetails.userDialog.beneficiaryId')"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="email"
                  placeholder="matti@gmail.com"
                  :label="$t('customerDetails.userDialog.email')"
                  :disabled="isTaksiniUser"
                  :rules="[ requiredRule, emailRule ]"
                  required
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="phone"
                  placeholder="358501234567"
                  :label="$t('customerDetails.userDialog.phone')"
                  :disabled="isTaksiniUser"
                  :rules="[ requiredRule, phoneNumberRule ]"
                  required
                  outlined
                ></v-text-field>
                <v-switch
                  v-model="allowBilling"
                  :label="$t('customerDetails.userDialog.allowBilling')"
                  :rules="[ billingMethodRequiredRule ]"
                ></v-switch>
                <v-switch
                  v-model="allowTaksini"
                  :label="$t('customerDetails.userDialog.allowTaksini')"
                  :disabled="isTaksiniUser"
                  :hint="$t('customerDetails.userDialog.allowTaksiniHint')"
                  persistent-hint>
                </v-switch>
                <v-switch
                  v-if="allowTaksini"
                  v-model="allowCard"
                  :label="$t('customerDetails.userDialog.allowCard')"
                  :rules="[ billingMethodRequiredRule ]"
                ></v-switch>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn color="primary" v-if="!editUser" class="float-right" @click="create">{{$t('common.create')}}</v-btn>
          <v-btn color="primary" v-if="editUser" class="float-right" @click="update">{{$t('common.update')}}</v-btn>
          <v-btn color="error" v-if="userId" @click="deleteUser">{{$t('common.delete')}}</v-btn>
          <v-btn color="red" text @click="cancel">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputRuleMixin from "../Inputs/InputRuleMixin";

export default {
    mixins: [InputRuleMixin],
    props: {
        value: Boolean,
        header: String,
        user: Object,
        editUser: Boolean
    },
    data: () => ({
        valid: true,
        userId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        costCenter: "",
        beneficiaryId: "",
        email: "",
        phone: "",
        allowBilling: false,
        allowCard: false,
        allowTaksini: false,
        sourceId: ""
    }),
    methods: {
        cancel() {
            this.$refs.userForm.reset();
            this.$emit("closed");
        },
        deleteUser() {
            confirm("Are you sure you want to delete this user?") && this.$emit("deleted", this.userId);
        },
        create() {
            if (!this.$refs.userForm.validate()) {
                return;
            }

            this.$emit("created", {
                firstName: this.firstName,
                middleName: this.middleName,
                lastName: this.lastName,
                email: this.email,
                costCenter: this.costCenter,
                beneficiaryId: this.beneficiaryId,
                phoneNumber: this.phone,
                allowBilling: this.allowBilling,
                allowTaksini: this.allowTaksini,
                allowCard: this.allowCard
            });
        },
        update() {
            if (!this.$refs.userForm.validate()) {
                return;
            }

            this.$emit("updated", {
                userId: this.userId,
                firstName: this.firstName,
                middleName: this.middleName,
                lastName: this.lastName,
                costCenter: this.costCenter,
                beneficiaryId: this.beneficiaryId,
                email: this.email,
                phoneNumber: this.phone,
                allowBilling: this.allowBilling,
                allowTaksini: this.allowTaksini,
                allowCard: this.allowCard
            });
        }
    },
    watch: {
        user(selectedUser) {
            if (this.$refs.userForm) this.$refs.userForm.resetValidation();
            this.valid = true;
            this.userId = selectedUser.userId;
            this.firstName = selectedUser.firstName;
            this.middleName = selectedUser.middleName;
            this.lastName = selectedUser.lastName;
            this.costCenter = selectedUser.costCenter;
            this.beneficiaryId = selectedUser.beneficiaryId;
            this.email = selectedUser.email;
            this.phone = selectedUser.phone;
            this.allowCard = selectedUser.allowCard;
            this.allowTaksini = selectedUser.allowTaksini;
            this.allowBilling = selectedUser.allowBilling;
            this.sourceId = selectedUser.sourceId;
        }
    },
    computed: {
        isTaksiniUser() {
            return this.sourceId === "taksini";
        },
        billingMethodRequiredRule() {
            return () => this.allowBilling || this.allowCard || "One billing method must be selected";
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                // !value && this.cancel();
                this.$emit("input", value);
            }
        }
    }
};
</script>

<style>

</style>
