<template>
    <v-container fluid>
          <v-row justify="end">
            <v-col>
              <h1>{{$t("invoicing.paytrail.title")}}</h1>
            </v-col>
              <v-col cols="auto">
                  <v-btn
                    class="ma-2"
                    color="primary"
                    @click="navigate()">
                      {{$t("invoicing.transportEvents")}}
                  </v-btn>
              </v-col>
          </v-row>
          <PaytrailTransactionsTable></PaytrailTransactionsTable>
    </v-container>
  </template>

<script>
import PaytrailTransactionsTable from "../components/Invoicing/PaytrailTransactionsTable.vue";
export default {
    components: { PaytrailTransactionsTable },
    methods: {
        navigate() {
            this.$router.push({ name: "Invoicing" });
        }
    }
};
</script>

  <style scoped>
  h1 {
      margin-bottom: 1rem;
  }
  </style>
