<template>
  <v-container fluid v-if="customer">
    <FavoriteAddressDialog
      v-model="showFavoriteAddressDialog"
      :editFavoriteAddress="editFavoriteAddress"
      :favoriteAddress="selectedFavoriteAddress"
      @created="newAddressCreated"
      @closed="showFavoriteAddressDialog = false"
      @updated="updateFavoriteAddress"
      @deleted="deleteFavoriteAddress"
    >
      </FavoriteAddressDialog>
    <UserDialog
      @created="createUser"
      @updated="updateUser"
      @deleted="deleteUser"
      @closed="showUserDialog = false"
      :header="userHeader"
      :user="selectedUser"
      :editUser="editUser"
      :operator="customerOperator"
      v-model="showUserDialog"></UserDialog>
    <CustomerDialog
      v-model="showCustomerDialog"
      :header="$t('userManagement.dialog.updateCustomer')"
      :customer=customer
      v-on:created="modify"></CustomerDialog>
      <RegistrationCodeDialog
      v-model="showRegistrationCodeDialog"
      :customerId="customerId"
      :operatorId="customerOperator">
      </RegistrationCodeDialog>
      <DiscountCodeDialog
      v-model="showDiscountCodeDialog"
      :customerId="customerId"
      :operatorId="customerOperator"
      @created="discountCreated">
      </DiscountCodeDialog>
    <v-row>
      <v-col cols="auto">
        <h1>{{ customer.name }}</h1>
      </v-col>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <v-btn @click="openForm" class="mx-2" fab dark elevation="2" color="orange">
                <v-icon v-bind="attrs" v-on="on" dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
          <span>{{$t('customerDetails.modifyCustomer')}}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addRegistrationCode" class="mx-2" fab dark elevation="2" color="success">
              <v-icon v-bind="attrs" v-on="on" dark>
                mdi-numeric
              </v-icon>
            </v-btn>
          </template>
          <span>{{$t('customerDetails.createRegistrationCode')}}</span>
        </v-tooltip>
      </v-col>
      <v-col cols="auto">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="addDiscountCode" class="mx-2" fab dark elevation="2" color="success">
              <v-icon v-bind="attrs" v-on="on" dark>
                mdi-sale
              </v-icon>
            </v-btn>
          </template>
          <span>{{$t('customerDetails.createDiscountCode')}}</span>
        </v-tooltip>
      </v-col>
      <!-- <v-col cols="auto">
         <v-btn @click="deleteCustomer" class="mx-2 float-right" fab dark elevation="2" color="red">
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </v-col> -->
    </v-row>
    <v-card class="d-flex mb-6" flat tile>
      <v-card class="pa-2" outlined tile>
        <strong>{{$t('customerDetails.operator')}}:</strong> {{ customerOperator }}
      </v-card>
      <v-card class="pa-2" outlined tile>
        <strong>{{$t('customerDetails.externalId')}}:</strong> {{ customer.externalId }}
      </v-card>
      <v-card class="pa-2" outlined>
        <strong>{{$t('customerDetails.businessId')}}:</strong> {{ customer.businessIdentityCode }}
      </v-card>
      <v-card class="pa-2" outlined tile>
        <strong>{{$t('customerDetails.contact')}}:</strong> {{ customer.contact }}
      </v-card>
      <v-card class="pa-2" outlined>
        <strong>{{$t('customerDetails.billingInfo')}}:</strong> {{ customer.billingInfo }}
      </v-card>
      <template v-if="customer.additionalData">
        <v-card
          class="pa-2"
          outlined
          v-for="key in Object.keys(customer.additionalData)"
          :key="key"
        >
          <strong>{{ key }}:</strong> {{ customer.additionalData[key] }}
        </v-card>
      </template>
    </v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="this.$t('common.filter')"
          single-line
          hide-details
          class="pt-0 mt-0 mr-2"></v-text-field>
    </v-card-title>
    <h2 class="pb-5 mt-0 mr-2">{{$t('customerDetails.usersTitle')}}</h2>
     <v-data-table
     v-if="users"
     :items="users"
     :search="filter"
     :headers="headers"
     item-key="userId"
     @click:row="modifyUser">
      <template v-slot:top>
        <v-btn color="primary" @click="addUser">{{$t('customerDetails.addUser')}}</v-btn>
      </template>
      <template v-slot:item.allowBilling="{ item }">
          <v-icon :color="item.allowBilling === true ? 'success' : 'error'">
            {{ item.allowBilling ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle' }}
          </v-icon>
      </template>
            <template v-slot:item.sourceId="{ item }">
          <v-icon :color="item.sourceId === 'taksini' ? 'success' : 'error'">
            {{ item.sourceId === 'taksini' ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle' }}
          </v-icon>
      </template>
      <template v-slot:item.allowCard="{ item }">
          <v-icon :color="item.allowCard === true ? 'success' : 'error'">
            {{ item.allowCard ? 'mdi-checkbox-marked-circle' : 'mdi-close-circle' }}
          </v-icon>
      </template>
      <template v-slot:item.phone="{ item }">
          {{ `+${item.phone}`}}
      </template>
    </v-data-table>
    <h2 class="pb-5 mt-0 mr-2">{{$t('customerDetails.favoriteAddressesTitle')}}</h2>
    <v-data-table
     v-if="favoriteAddresses"
     :items="favoriteAddresses"
     :search="addressFilter"
     :headers="favoriteAddressHeaders"
     item-key="favoriteAddressId"
     @click:row="modifyAddress">
      <template v-slot:top>
        <v-btn color="primary" @click="addFavoriteAddress">{{$t('customerDetails.addFavoriteAddress')}}</v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";
import UserDialog from "../components/Users/UserDialog";
import CustomerDialog from "../components/Customers/CustomerDialog";
import RegistrationCodeDialog from "../components/Customers/RegistrationCodeDialog";
import DiscountCodeDialog from "../components/Customers/DiscountCodeDialog";
import FavoriteAddressDialog from "../components/Dialogs/FavoriteAddressDialog";

const UserManagementRepository = RepositoryFactory.get("userManagement");

export default {
    components: {
        UserDialog,
        CustomerDialog,
        RegistrationCodeDialog,
        DiscountCodeDialog,
        FavoriteAddressDialog
    },
    data: () => ({
        favoriteAddresses: [],
        customerId: null,
        customerOperator: null,
        showCustomerDialog: false,
        showRegistrationCodeDialog: false,
        showDiscountCodeDialog: false,
        showFavoriteAddressDialog: false,
        showUserDialog: false,
        selectedUser: {},
        selectedFavoriteAddress: {},
        editFavoriteAddress: false,
        editUser: false,
        filter: "",
        addressFilter: "",
        customer: null,
        newUser: {},
        users: []
    }),
    computed: {
        userHeader() {
            if (this.editUser) {
                return this.$t("customerDetails.userDialog.editUser");
            }
            return this.$t("customerDetails.userDialog.createUser");
        },
        headers() {
            return [
                // { text: "Id", value: "userId" },
                { text: this.$t("customerDetails.headers.name"), value: "fullName" },
                { text: this.$t("customerDetails.headers.email"), value: "email" },
                { text: this.$t("customerDetails.headers.phone"), value: "phone" },
                { text: this.$t("customerDetails.headers.billing"), value: "allowBilling", width: "50" },
                { text: this.$t("customerDetails.headers.taksini"), value: "sourceId", width: "50" },
                { text: this.$t("customerDetails.headers.card"), value: "allowCard", width: "50" }
            ];
        },
        favoriteAddressHeaders() {
            return [
                // { text: "Id", value: "userId" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.addressType"), value: "addressType" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.street"), value: "street" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.streetNumber"), value: "streetNumber" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.city"), value: "city" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.zipcode"), value: "zipcode" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.country"), value: "country" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.alias"), value: "alias" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.driverNotes"), value: "driverNotes" },
                { text: this.$t("customerDetails.favoriteAddressHeaders.door"), value: "door" }
            ];
        }
    },
    methods: {
        async createUser(userObj) {
            userObj.customerId = this.customerId;
            const { data: { itemId: userId } } = await UserManagementRepository.createUser(userObj, this.customerOperator);

            const userCustomerModel = {
                allowBilling: userObj.allowBilling,
                allowCard: userObj.allowCard,
                costCenter: userObj.costCenter,
                beneficiaryId: userObj.beneficiaryId,
                userId: userId,
                customerId: this.customerId
            };

            await UserManagementRepository.addUserToCustomer(userCustomerModel, this.customerId, this.customerOperator);

            await this.fetchUsers();
            this.showUserDialog = false;
        },
        async deleteUser(userId) {
            await UserManagementRepository.deleteCustomerUser(this.customerId, userId, this.customerOperator);
            await this.fetchUsers();
        },
        async discountCreated(discount) {
            // console.log(JSON.stringify(discount));
        },
        async updateUser(userObj) {
            if (this.selectedUser.costCenter !== userObj.costCenter ||
      this.selectedUser.beneficiaryId !== userObj.beneficiaryId ||
      this.selectedUser.allowBilling !== userObj.allowBilling ||
      this.selectedUser.allowCard !== userObj.allowCard ||
      this.selectedUser.userId !== userObj.userId) {
                const customerUserObj = {
                    costCenter: userObj.costCenter,
                    beneficiaryId: userObj.beneficiaryId,
                    allowBilling: userObj.allowBilling,
                    allowCard: userObj.allowCard,
                    userId: userObj.userId,
                    customerId: this.customerId
                };

                await UserManagementRepository.updateCustomerUser(customerUserObj, this.customerOperator);
            }

            if (this.selectedUser.firstName !== userObj.firstName ||
      this.selectedUser.middleName !== userObj.middleName ||
      this.selectedUser.lastName !== userObj.lastName ||
        this.selectedUser.email !== userObj.email ||
      this.selectedUser.phoneNumber !== userObj.phoneNumber) {
                const contactInfoObj = {
                    firstName: userObj.firstName,
                    middleName: userObj.middleName,
                    lastName: userObj.lastName,
                    email: userObj.email,
                    phoneNumber: userObj.phone,
                    userId: userObj.userId
                };

                await UserManagementRepository.updateUserContactInformation(contactInfoObj, this.customerOperator);
            }

            await this.fetchUsers();
            this.showUserDialog = false;
        },
        async modify(customerObj) {
            this.showCustomerDialog = false;

            await UserManagementRepository.updateCustomer(customerObj, this.customerId, this.customerOperator);
            await UserManagementRepository.addCustomerVehicleAttributes(customerObj.defaultAttributes, this.customerId, this.customerOperator);

            await this.fetchCustomer();
        },
        async fetchUsers() {
            const users = await UserManagementRepository.getUsersByCustomerId(
                this.customerId, this.customerOperator
            );
            this.users = users;
        },
        async openForm() {
            this.showCustomerDialog = true;
        },
        async addRegistrationCode() {
            this.showRegistrationCodeDialog = true;
        },
        async addDiscountCode() {
            this.showDiscountCodeDialog = true;
        },
        async codeCreated(codeObj) {
            this.showRegistrationCodeDialog = false;
        },
        async newAddressCreated(addressObj) {
            await UserManagementRepository.addCustomerFavoriteAddress(addressObj, this.customerId, this.customerOperator);
            this.showFavoriteAddressDialog = false;
            const { data: { results: favoriteAddresses } } = await UserManagementRepository.getCustomerFavoriteAddresses(this.customerId, this.customerOperator);

            this.favoriteAddresses = favoriteAddresses;
        },
        async updateFavoriteAddress(addressObj) {
            await UserManagementRepository.updateCustomerFavoriteAddress(addressObj, this.customerId, this.customerOperator);
            this.showFavoriteAddressDialog = false;

            const { data: { results: favoriteAddresses } } = await UserManagementRepository.getCustomerFavoriteAddresses(this.customerId, this.customerOperator);

            this.favoriteAddresses = favoriteAddresses;
            this.showFavoriteAddressDialog = false;
        },
        async modifyAddress(address) {
            this.selectedFavoriteAddress = address;
            this.editFavoriteAddress = true;
            this.showFavoriteAddressDialog = true;
        },
        async deleteFavoriteAddress(addressId) {
            await UserManagementRepository.deleteCustomerFavoriteAddress(this.customerId, addressId, this.customerOperator);
            this.showFavoriteAddressDialog = false;

            const { data: { results: favoriteAddresses } } = await UserManagementRepository.getCustomerFavoriteAddresses(this.customerId, this.customerOperator);

            this.favoriteAddresses = favoriteAddresses;
        },
        async addUser() {
            this.selectedUser = {};
            this.editUser = false;
            this.showUserDialog = true;
        },
        async addFavoriteAddress() {
            this.showFavoriteAddressDialog = true;
        },
        async modifyUser(user) {
            this.selectedUser = user;
            this.editUser = true;
            this.showUserDialog = true;
        },
        async fetchCustomer() {
            this.customerId = this.$route.params["id"];
            this.customerOperator = this.$route.query["operator"];
            const { data: { item: customer } } = await UserManagementRepository.getCustomer(
                this.customerId, this.customerOperator
            );

            const { data: { results: favoriteAddresses } } = await UserManagementRepository.getCustomerFavoriteAddresses(this.customerId, this.customerOperator);

            this.favoriteAddresses = favoriteAddresses ?? [];

            this.customer = customer;
        }
    // async deleteCustomer () {
    //   confirm("Are you sure you want to delete this customer? This is irreversable.")
    // }
    },
    async created() {
        await this.fetchCustomer();
        await this.fetchUsers();
    }
};
</script>

<style></style>
