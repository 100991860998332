// initial state
const state = {
    locale: null,
    tenants: [],
    tenant: null,
    operators: [],
    operator: null
};

// getters
const getters = {
    tenantId: state => {
        return state.tenant;
    },
    operatorId: state => {
        return state.operator;
    }
};

// actionst
const actions = {
    async setLocale({ commit }, locale) {
        commit("SET_LOCALE", locale);
    },
    async setTenants({ commit, getters }, tenants) {
        commit("SET_TENANTS", tenants);
        if (tenants && !tenants.includes(getters.tenantId) && tenants.length > 0) {
            // Hotfix before vuex-persist state can overwrite this
            if (tenants.includes("ltx")) {
                commit("SET_TENANT", "ltx");
            } else { commit("SET_TENANT", tenants[0]); }
        }
    },
    async setTenant({ commit }, tenant) {
        commit("SET_TENANT", tenant);
    },
    async setOperators({ commit }, operators) {
        commit("SET_OPERATORS", operators);
        if (operators && !operators.includes(state.operator) && operators.length > 0) {
            commit("SET_OPERATOR", operators[0]);
        }
    },
    async setOperator({ commit }, operator) {
        commit("SET_OPERATOR", operator);
    }
};

// mutations
const mutations = {
    SET_LOCALE(state, locale) {
        state.locale = locale;
    },
    SET_TENANTS(state, tenants) {
        state.tenants = tenants;
    },
    SET_TENANT(state, tenant) {
        state.tenant = tenant;
    },
    SET_OPERATORS(state, operators) {
        state.operators = operators;
    },
    SET_OPERATOR(state, operator) {
        state.operator = operator;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
