var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitForm($event)
        }
      }
    },
    _vm._l(_vm.invoiceOptions.invoicingOptions, function(opt, key) {
      return _c(
        "v-row",
        { key: key, staticClass: "mx-4" },
        [
          _c(
            "v-col",
            { attrs: { cols: "1" } },
            [
              _c("v-checkbox", {
                model: {
                  value: _vm.checkedValues[key],
                  callback: function($$v) {
                    _vm.$set(_vm.checkedValues, key, $$v)
                  },
                  expression: "checkedValues[key]"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "11" } },
            [
              _vm.getOptionTypeLabel(opt.type) === "String"
                ? [
                    _c("v-text-field", {
                      attrs: { label: opt.name, disabled: !opt.canBeModified },
                      model: {
                        value: opt.value,
                        callback: function($$v) {
                          _vm.$set(opt, "value", $$v)
                        },
                        expression: "opt.value"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.getOptionTypeLabel(opt.type) === "Integer"
                ? [
                    _c("v-text-field", {
                      attrs: {
                        label: opt.name,
                        type: "number",
                        disabled: !opt.canBeModified
                      },
                      model: {
                        value: opt.value,
                        callback: function($$v) {
                          _vm.$set(opt, "value", $$v)
                        },
                        expression: "opt.value"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.getOptionTypeLabel(opt.type) === "Decimal"
                ? [
                    _c("v-text-field", {
                      attrs: {
                        label: opt.name,
                        type: "number",
                        step: "0.01",
                        disabled: !opt.canBeModified
                      },
                      model: {
                        value: _vm.decimalValues[key],
                        callback: function($$v) {
                          _vm.$set(_vm.decimalValues, key, $$v)
                        },
                        expression: "decimalValues[key]"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.getOptionTypeLabel(opt.type) === "Date"
                ? [
                    _c("v-date-picker", {
                      attrs: { label: opt.name, disabled: !opt.canBeModified },
                      model: {
                        value: opt.value,
                        callback: function($$v) {
                          _vm.$set(opt, "value", $$v)
                        },
                        expression: "opt.value"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.getOptionTypeLabel(opt.type) === "Boolean"
                ? [
                    _c("v-switch", {
                      attrs: { label: opt.name, disabled: !opt.canBeModified },
                      model: {
                        value: _vm.booleanValues[key],
                        callback: function($$v) {
                          _vm.$set(_vm.booleanValues, key, $$v)
                        },
                        expression: "booleanValues[key]"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.getOptionTypeLabel(opt.type) === "Guid"
                ? [
                    _c("v-text-field", {
                      attrs: { label: opt.name, disabled: !opt.canBeModified },
                      model: {
                        value: opt.value,
                        callback: function($$v) {
                          _vm.$set(opt, "value", $$v)
                        },
                        expression: "opt.value"
                      }
                    })
                  ]
                : _vm._e(),
              _vm.getOptionTypeLabel(opt.type).startsWith("Dictionary")
                ? [
                    _c("v-subheader", [_vm._v(_vm._s(opt.name))]),
                    _vm._l(opt.items, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("v-text-field", {
                            attrs: { disabled: !opt.canBeModified },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend",
                                  fn: function() {
                                    return [
                                      _c("v-chip", [
                                        _vm._v(
                                          _vm._s(_vm.formatPercentage(item.key))
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.value,
                              callback: function($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value"
                            }
                          })
                        ],
                        1
                      )
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }