<template>
  <div id="readyForPurchaseInvoicing">
    <v-card class="mt-1">
      <div class="elevation-1 mb-1 pb-2">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-card-title>
              {{$t("invoicing.purchasing.creationTitle")}}
              <v-icon right>mdi-archive-search-outline</v-icon>
            </v-card-title>
          </v-col>
          <v-col cols="auto">
            <v-select
            v-model="invoiceEventType"
            :items="invoiceEventTypes"
            :label="$t('invoicing.purchasing.invoiceEventTypes')">
            </v-select>
          </v-col>
          <v-col cols="auto" class="d-flex align-center">
            <v-card-subtitle class="d-flex flex-row-reverse" style="white-space: pre;">
              {{ lastInvoiceCreated }}
            </v-card-subtitle>
            <v-card-actions>
            <v-btn
              :disabled="purchaseInvoicesPaid"
              color="success"
              @click=showSendToPurchasingDialog>
              {{$t("invoicing.purchasing.sendToPurchasing")}}
            </v-btn>
          </v-card-actions>
          </v-col>
        </v-row>
      </div>
      <SendToPurchasingDialog
      ref="purchasingDialog"
      v-model="sendToPurchasingDialog"
      @closed="sendToPurchasingDialog = false">
      </SendToPurchasingDialog>
      <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table" name="readyForPurchaseInvoicingTable">
        <v-data-table
          calculate-widths
          :headers="showableHeaders"
          :items="readyForPurchaseInvoicingRowData"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loadingPage"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
          @click:row="searchEvents">
          <template v-slot:item.reimbursementSum="{ item }">
            {{ item.reimbursementSum | formatCurrency }}
          </template>
          <template v-slot:item.totalSum="{ item }">
            {{ item.totalSum | formatCurrency }}
          </template>
          <template v-if="invoiceEventType == 'Likuma'" v-slot:item.salesInvoiceNumbers="{ item }">
            <td>
              <a v-for="(invoiceNumber, index) in item.salesInvoiceNumbers"
                class="pr-2 text-decoration-none"
                :key="index"
                :href="`/invoicing?salesInvoiceNumber=${invoiceNumber}`"
                @click.prevent.stop="searchEventsBySalesInvoiceNumber(invoiceNumber)">
                {{ invoiceNumber }}
              </a>
            </td>
          </template>
          <template v-slot:body.append>
            <tr  v-if="readyForPurchaseInvoicingRowData != null" style="outline: thick solid">
              <th class="text-md-body-1 font-weight-bold">{{ footerTotalServiceNumberCount }} kpl</th>
              <th class="text-md-body-1 font-weight-bold"></th>
              <th class="text-md-body-1 font-weight-bold">{{ footerTotalTransportEventCount }} kpl</th>
              <th class="text-md-body-1 font-weight-bold">{{ footerTotalSum | formatCurrency }}</th>
              <th v-if="invoiceEventType == 'Likuma'" class="text-md-body-1 font-weight-bold">
                {{ footerTotalSalesInvoiceNumberCount }} kpl
              </th>
            </tr>
        </template>
        </v-data-table>
      </v-skeleton-loader>
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn text @click="snackbar = false">
          {{$t("common.close")}}
        </v-btn>
      </v-snackbar>
    </v-card>
  </div>
  </template>

<script>
import moment from "moment";
import { InvoiceCreationTypeMixin } from "@/models/InvoiceCreationType";
import { PaymentMethodMixin } from "@/models/PaymentMethods";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import { TransportEventTypeMixin } from "@/models/TransportEventTypes";
import { FeeTypeMixin } from "@/models/FeeTypes";
import InputRuleMixin from "@/components/Inputs/InputRuleMixin";
import SendToPurchasingDialog from "@/components/Invoicing/SendToPurchasingDialog";

export default {
    mixins: [
        TransportEventStatusMixin,
        TransportEventInvoicingMixin,
        FeeTypeMixin,
        InputRuleMixin,
        TransportEventTypeMixin,
        PaymentMethodMixin,
        InvoiceCreationTypeMixin
    ],
    data() {
        return {
            loading: true,
            invoiceEventType: "Likuma",
            likumaReadyForPurchaseInvoiceHeaders: [
                { text: "Palvelutunnus", value: "serviceNumber", show: true },
                { text: "Auton numero", value: "carNumber", show: true },
                { text: "Taphtumien määrä", value: "transportEventCount", show: true },
                { text: "Yhteensä €", value: "reimbursementSum", show: true },
                { text: "Myyntilaskut", value: "salesInvoiceNumbers", show: true }
            ],
            emvTaksiniReadyForPurchaseInvoiceHeaders: [
                { text: "Palvelutunnus", value: "serviceNumber", show: true },
                { text: "Auton numero", value: "carNumber", show: true },
                { text: "Taphtumien määrä", value: "transportEventCount", show: true },
                { text: "Yhteensä €", value: "totalSum", show: true }
            ],
            sortBy: "serviceNumber",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            sendToPurchasingDialog: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            transportEventDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        purchaseInvoicesPaid() {
            if (!this.$store.state.invoicing.createdPurchaseInvoices) {
                return true;
            }
            if (this.invoiceEventType === "Likuma") {
                // If any invoice is created createdPurchaseInvoices state
                if (!this.$store.state.invoicing.createdPurchaseInvoices.some((invoice) => invoice.invoiceCreationType === this.invoiceCreationTypes.Likuma)) {
                    return false;
                } else {
                    // If any invoice is not paid return false
                    if (this.$store.state.invoicing.createdPurchaseInvoices.some((invoice) => invoice.invoiceCreationType === this.invoiceCreationTypes.Likuma && invoice.paidAt === null)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            if (this.invoiceEventType === "Korttimaksu") {
                // If any invoice is created createdPurchaseInvoices state
                if (!this.$store.state.invoicing.createdPurchaseInvoices.some((invoice) => invoice.invoiceCreationType === this.invoiceCreationTypes.DebitCredit)) {
                    return false;
                } else {
                    // If any invoice is not paid return false
                    if (this.$store.state.invoicing.createdPurchaseInvoices.some((invoice) => invoice.invoiceCreationType === this.invoiceCreationTypes.DebitCredit && invoice.paidAt === null)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            if (this.invoiceEventType === "Taksini") {
                // If any invoice is created createdPurchaseInvoices state
                if (!this.$store.state.invoicing.createdPurchaseInvoices.some((invoice) => invoice.invoiceCreationType === this.invoiceCreationTypes.Taksini)) {
                    return false;
                } else {
                    // If any invoice is not paid return false
                    if (this.$store.state.invoicing.createdPurchaseInvoices.some((invoice) => invoice.invoiceCreationType === this.invoiceCreationTypes.Taksini && invoice.paidAt === null)) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }

            return false;
        },
        readyForPurchaseInvoicingRowData() {
            return this.readyForPurchaseInvoicingData?.rowData;
        },
        readyForPurchaseInvoicingData() {
            if (this.invoiceEventType === "Likuma") {
                return this.$store.state.invoicing.likumaReadyForPurchaseInvoicingSummary;
            }
            if (this.invoiceEventType === "Korttimaksu") {
                return this.$store.state.invoicing.emvReadyForPurchaseInvoicingSummary;
            }
            if (this.invoiceEventType === "Taksini") {
                return this.$store.state.invoicing.taksiniReadyForPurchaseInvoicingSummary;
            }
            return [];
        },
        lastInvoiceCreated() {
            if (this.invoiceEventType === "Likuma") {
                if (!this.$store.state.invoicing.likumaInvoiceCreationTimestamp) {
                    return this.$t("invoicing.purchasing.noInvoiceCreated");
                } else {
                    return this.$t("invoicing.purchasing.lastInvoiceCreated") + "\n " + moment(this.$store.state.invoicing.likumaInvoiceCreationTimestamp).format("HH:mm DD.MM.YYYY");
                }
            } else if (this.invoiceEventType === "Korttimaksu") {
                if (!this.$store.state.invoicing.emvInvoiceCreationTimestamp) {
                    return this.$t("invoicing.purchasing.noInvoiceCreated");
                } else {
                    return this.$t("invoicing.purchasing.lastInvoiceCreated") + "\n " + moment(this.$store.state.invoicing.emvInvoiceCreationTimestamp).format("HH:mm DD.MM.YYYY");
                }
            } else if (this.invoiceEventType === "Taksini") {
                if (!this.$store.state.invoicing.taksiniInvoiceCreationTimestamp) {
                    return this.$t("invoicing.purchasing.noInvoiceCreated");
                } else {
                    return this.$t("invoicing.purchasing.lastInvoiceCreated") + "\n " + moment(this.$store.state.invoicing.taksiniInvoiceCreationTimestamp).format("HH:mm DD.MM.YYYY");
                }
            } else {
                return this.$t("invoicing.purchasing.noInvoiceCreated");
            }
        },
        extensions() {
            return this.$store.state.invoicing.extensions;
        },
        // Headers
        headers() {
            if (this.invoiceEventType === "Likuma") {
                return this.likumaReadyForPurchaseInvoiceHeaders;
            }
            if (this.invoiceEventType === "Korttimaksu") {
                return this.emvTaksiniReadyForPurchaseInvoiceHeaders;
            }
            if (this.invoiceEventType === "Taksini") {
                return this.emvTaksiniReadyForPurchaseInvoiceHeaders;
            }
            return [];
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },

        // Footer
        footerTotalServiceNumberCount() {
            return this.readyForPurchaseInvoicingData?.totalServiceNumberCount;
        },
        footerTotalTransportEventCount() {
            return this.readyForPurchaseInvoicingData?.totalTransportEventCount;
        },
        footerTotalSum() {
            if (this.invoiceEventType === "Likuma") {
                return this.readyForPurchaseInvoicingData?.totalReimbursementSum || 0;
            }
            if (this.invoiceEventType === "Korttimaksu" || this.invoiceEventType === "Taksini") {
                return this.readyForPurchaseInvoicingData?.totalSum || 0;
            }
            return [];
        },
        footerTotalSalesInvoiceNumberCount() {
            if (this.invoiceEventType === "Likuma") {
                return this.readyForPurchaseInvoicingData?.totalSalesInvoiceNumberCount;
            } else {
                return 1;
            }
        },

        // Pagination
        searchTotalCount() {
            return this.readyForPurchaseInvoicingData?.totalServiceNumberCount;
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        tenant() {
            return this.$store.state.user.tenant;
        },
        invoiceEventTypes() {
            if (this.tenant === "ltx") {
                return ["Likuma", "Korttimaksu", "Taksini"];
                // } else if (this.tenant === "kaista") {
                //   return ["TaksiKaista", "SoteKaista", "YritysKaista"];
            } else {
                return [];
            }
        }
    },
    watch: {
        tenant(val) {
            this.getReadyForPurchaseInvoicing();
        },
        invoiceEventType: {
            immediate: true,
            handler(val) {
                if (val === "Likuma" || val === "Korttimaksu" || val === "Taksini") {
                    this.getReadyForPurchaseInvoicing(val);
                }
            }
        }
    },
    methods: {
        async getReadyForPurchaseInvoicing(invoiceEventType) {
            if (invoiceEventType === "Likuma") {
                this.loading = true;
                this.$store.dispatch("invoicing/getLikumaReadyForPurchaseInvoicing")
                    .catch(error => {
                        this.snackbar = true;
                        this.snackbarColor = "error";
                        this.snackbarText = error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            if (invoiceEventType === "Korttimaksu") {
                this.loading = true;
                this.$store.dispatch("invoicing/getEmvReadyForPurchaseInvoicing")
                    .catch(error => {
                        this.snackbar = true;
                        this.snackbarColor = "error";
                        this.snackbarText = error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
            if (invoiceEventType === "Taksini") {
                this.loading = true;
                this.$store.dispatch("invoicing/getTaksiniReadyForPurchaseInvoicing")
                    .catch(error => {
                        this.snackbar = true;
                        this.snackbarColor = "error";
                        this.snackbarText = error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        searchEventsBySalesInvoiceNumber(salesInvoiceNumber) {
            this.$router.push({
                name: "Invoicing",
                query: { salesInvoiceNumbers: salesInvoiceNumber }
            });
        },
        showSendToPurchasingDialog() {
            this.$refs.purchasingDialog.openDialog(this.footerTotalSalesInvoiceNumberCount, this.footerTotalTransportEventCount, this.footerTotalSum, this.invoiceEventType);
        },
        searchEvents(rowData) {
            if (this.invoiceEventType === "Likuma") {
                this.$router.push({
                    name: "Invoicing",
                    query:
          {
              serviceNumber: rowData.serviceNumber,
              salesInvoiceNumbers: rowData.salesInvoiceNumbers,
              purchaseInvoiceStates: this.purchaseInvoiceStates.Waiting
          }
                });
            }
            if (this.invoiceEventType === "Korttimaksu") {
                this.$router.push({
                    name: "Invoicing",
                    query:
          {
              serviceNumber: rowData.serviceNumber,
              paymentMethods: [ this.paymentMethods.CreditCard, this.paymentMethods.DebitCard ],
              purchaseInvoiceStates: this.purchaseInvoiceStates.Waiting
          }
                });
            }
            if (this.invoiceEventType === "Taksini") {
                this.$router.push({
                    name: "Invoicing",
                    query:
          {
              serviceNumber: rowData.serviceNumber,
              paymentMethods: [ this.paymentMethods.App ],
              purchaseInvoiceStates: this.purchaseInvoiceStates.Waiting
          }
                });
            }
        }
    },
    filters: {
        statusToColor: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "";
            case 3: return "success";
            default: return "error";
            }
            ;
        },
        statusToIcon: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "mdi-av-timer";
            case 3: return "mdi-check-circle-outline";
            default: return "mdi-alert";
            }
            ;
        },
        statusToToolTipText: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "Waiting";
            case 3: return "No errors";
            default: return "Contains error(s)";
            }
            ;
        }
    },
    components: { SendToPurchasingDialog }
};
</script>

  <style>
  .v-text-field_search {
      padding-top: 0;
      margin-top: 0;
  }
  .extras-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
  }

  .extras-list > li > span:last-of-type {
      float: right;
      margin-left:10px
  }

  .itemRowSideColor-grey {
      border: 5px solid #BDBDBD;;
      background-color: #BDBDBD;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-blue {
      border: 5px solid #42A5F5;
      background-color: #42A5F5;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-green {
      border: 5px solid #66BB6A;
      background-color: #66BB6A;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-yellow {
      border: 5px solid #FFEE58;
      background-color: #FFEE58;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-orange {
      border: 5px solid #FFA726;
      background-color: #FFA726;
      border-radius: 3px;
      display: inline;
  }

  </style>
