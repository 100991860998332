<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Geo Vehicles
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchNearDialog = true"
          class="mx-2">
          Search Near Location
        </v-btn>
        <v-btn
          small
          @click="searchInPolygonDialog = true"
          class="mx-2">
          Search In Polygon
        </v-btn>
        <v-btn
          small
          @click="searchInIsochroneDialog = true"
          class="mx-2">
          Search In Isochrone
        </v-btn>
        <v-spacer></v-spacer>
        <VehiclesNearLocationSearchDialog
          :show="searchNearDialog"
          :searchParams="searchNearParams"
          @search="searchNearLocationVehicles($event, false)"
          @close="searchNearDialog = false"
        ></VehiclesNearLocationSearchDialog>
        <VehiclesInPolygonSearchDialog
          :show="searchInPolygonDialog"
          :searchParams="searchInPolygonParams"
          @search="searchInPolygonVehicles($event, false)"
          @close="searchInPolygonDialog = false"
        ></VehiclesInPolygonSearchDialog>
        <VehiclesInIsochroneSearchDialog
          :show="searchInIsochroneDialog"
          :searchParams="searchInIsochroneParams"
          @search="searchInPolygonVehicles($event, false)"
          @close="searchInIsochroneDialog = false"
        ></VehiclesInIsochroneSearchDialog>
      </v-card-actions>
    </div>
      <DispatchVehiclesTable
        :loading="loading"
        :vehicles="vehicles"
        @vehicleSelected="vehicleSelected"
      >
      </DispatchVehiclesTable>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { VehicleStatusToString } from "../../models/VehicleStatus";
import { TaxiMeterStateToString } from "../../models/TaxiMeterState";
import { DispatchStateToString } from "../../models/DispatchState";
import VehiclesNearLocationSearchDialog from "./Dialogs/VehiclesNearLocationSearchDialog";
import VehiclesInPolygonSearchDialog from "./Dialogs/VehiclesInPolygonSearchDialog";
import VehiclesInIsochroneSearchDialog from "./Dialogs/VehiclesInIsochroneSearchDialog";
import DispatchVehiclesTable from "./DispatchVehiclesTable";
import DataTableOptionsMixin from "../../Mixins/DataTableOptionsMixin";
import EventSourceMixin from "../../Mixins/EventSourceMixin";

export default {
    components: {
        VehiclesNearLocationSearchDialog,
        DispatchVehiclesTable,
        VehiclesInPolygonSearchDialog,
        VehiclesInIsochroneSearchDialog
    },
    mixins: [ DataTableOptionsMixin, EventSourceMixin ],
    data() {
        return {
            loading: false,
            searchNearDialog: false,
            searchInPolygonDialog: false,
            searchInIsochroneDialog: false,
            searchNearParams: {},
            searchInPolygonParams: {
                companyId: "ltx",
                fleetId: "kela",
                latitude1: 60.479218,
                longitude1: 23.157746,
                latitude2: 60.492449,
                longitude2: 23.3482197,
                latitude3: 60.420310,
                longitude3: 23.172235,
                latitude4: 60.412808,
                longitude4: 23.329137
            },
            searchInIsochroneParams: {},
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        vehicles() {
            return this.$store.state.dispatching.vehiclesInGeoArea;
        },
        searchTotalCount() {
            return this.vehicles.length;
        }
    },
    methods: {
        async getNearLocationVehicles(searchParams) {
            this.loading = true;
            this.$store.dispatch("dispatching/searchVehiclesNearLocation", searchParams)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getInPolygonVehicles(searchParams) {
            this.loading = true;
            this.$store.dispatch("dispatching/searchVehiclesInPolygon", searchParams)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        vehicleStatusToString(vehicleStatus) {
            return VehicleStatusToString(vehicleStatus);
        },
        taxiMeterStateToString(taxiMeterState) {
            return TaxiMeterStateToString(taxiMeterState);
        },
        dispatchStateToString(dispatchState) {
            return DispatchStateToString(dispatchState);
        },
        vehicleSelected(vehicle) {
            this.$emit("vehicleSelected", vehicle);
        },
        searchNearLocationVehicles(searchParams) {
            this.searchNearDialog = false;
            this.searchNearParams = { ...this.searchNearParams, ...searchParams };
            this.getNearLocationVehicles(this.searchNearParams);
        },
        searchInPolygonVehicles(searchParams) {
            this.searchInPolygonDialog = false;
            this.searchInPolygonParams = { ...this.searchInPolygonParams, ...searchParams };
            this.getInPolygonVehicles(this.searchInPolygonParams);
        }
    }
};
</script>
