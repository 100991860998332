<template>
  <FleetMembershipsTable
    :fleetMemberships="editableVehicle.memberships"
    @addFleetMembership="addFleetMembership"
    @saveFleetMembership="saveFleetMembership"
    @deleteFleetMembership="deleteFleetMembership">
  </FleetMembershipsTable>
</template>

<script>
import FleetMembershipsTable from "../../Shared/FleetMembershipsTable";
import _ from "lodash";

export default {
    components: { FleetMembershipsTable },
    props: {
        vehicle: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            editableVehicle: _.cloneDeep(this.vehicle)
        };
    },
    watch: {
        vehicle(val) {
            this.editableVehicle = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        addFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("addFleetMembership", fleetMembership, eventTargetId);
        },
        saveFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("saveFleetMembership", fleetMembership, eventTargetId);
        },
        deleteFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("deleteFleetMembership", fleetMembership, eventTargetId);
        }
    }
};
</script>
