<template>
  <v-card color="blue-grey lighten-5">
    <v-row v-if="$route.query.edit" justify="space-between" align="center" class="py-2 px-6">
      <span class="invoicing-rule-panel-header">{{ header }}</span>
      <v-btn
        text
        color="primary"
        @click="openNewRuleDialog()"
      >
        <v-icon left>mdi-plus</v-icon>
        Add new rule
      </v-btn>
    </v-row>
    <v-expansion-panels
      multiple
      accordion>
      <InvoicingRulePanelItem
        v-for="(rule, i) in rules"
        :key="i"
        :rule="rule"
        @modify="openRuleDialog(rule, i)"
        @delete="deleteRule(i)"
      ></InvoicingRulePanelItem>
    </v-expansion-panels>
    <InvoicingRuleDialog
      :isOpen="ruleDialog"
      :originalRule="selectedRule"
      :isNew="newRule"
      :isUpdating="isUpdating"
      @create="addRule"
      @save="modifySelectedRule"
      @close="closeRuleDialog"
    ></InvoicingRuleDialog>
  </v-card>
</template>

<script>
import InvoicingRulePanelItem from "./InvoicingRulePanelItem";
import InvoicingRuleDialog from "./InvoicingRuleDialog";

export default {
    props: {
        header: String,
        rules: Array
    },
    components: {
        InvoicingRulePanelItem,
        InvoicingRuleDialog
    },
    data() {
        return {
            ruleDialog: false,
            selectedRule: {},
            selectedRuleIndex: -1,
            newRule: true,
            isUpdating: false
        };
    },
    methods: {
        openNewRuleDialog() {
            this.selectedRule = {};
            this.newRule = true;
            this.ruleDialog = true;
        },
        openRuleDialog(rule, index) {
            this.selectedRule = rule;
            this.selectedRuleIndex = index;
            this.newRule = false;
            this.ruleDialog = true;
        },
        deleteRule(index) {
            if (confirm("Are you sure that you want to delete rule?")) {
                this.isUpdating = true;
                let newRuleList = this.rules ? Array.from(this.rules) : [];
                newRuleList.splice(index, 1);
                this.$emit("update", newRuleList);
            }
        },
        addRule(rule) {
            this.isUpdating = true;
            let newRuleList = this.rules ? Array.from(this.rules) : [];
            newRuleList.push(rule);
            this.$emit("update", newRuleList);
        },
        modifySelectedRule(rule) {
            this.modifyRule(this.selectedRuleIndex, rule);
        },
        modifyRule(index, rule) {
            this.isUpdating = true;
            let newRuleList = this.rules ? Array.from(this.rules) : [];
            newRuleList[index] = rule;
            this.$emit("update", newRuleList);
        },
        closeRuleDialog() {
            this.isUpdating = false;
            this.selectedRule = {};
            this.ruleDialog = false;
        }
    }
};
</script>

<style>
.invoicing-rule-panel-header {
    font-size: 1rem;
    font-weight: bold;
}
</style>
