<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Fleets
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = true"
          class="mx-2">
          Search
        </v-btn>
        <v-btn
          small
          @click="fleetCreateDialog = true"
          class="mx-2">
          Create
        </v-btn>
        <v-spacer></v-spacer>
        <FleetSearchDialog
          :show="searchDialog"
          :searchParams="searchParams"
          @search="searchFleets($event, false)"
          @close="searchDialog = false"
        ></FleetSearchDialog>
      </v-card-actions>
    </div>
      <v-data-table
        calculate-widths
        :headers="headers"
        :items="fleets"
        item-key="id"
        :loading="loading"
        :server-items-length="searchTotalCount"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: itemsPerPageOptions}"
        @click:row="openFleetDialog"
      >
      <template v-slot:item.id="{ value }">
        <span> {{ value }}</span>
      </template>
      <template v-slot:item.companyId="{ value }">
        <span> {{ value }}</span>
      </template>
      <template v-slot:item.name="{ value }">
        <span> {{ value }}</span>
      </template>
      <template v-slot:item.status="{ value }">
        <span> {{ fleetStatusToString(value) }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <span>
          <v-btn
            @click.stop="deleteFleet(item.companyId, item.id)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <FleetDialog
      :isOpen="fleetDialog"
      :fleet="fleet"
      @close="fleetDialog = false"
      @saveContactInformation="saveContactInformation"
      @saveFleetInfo="saveFleetInfo"
      @addNewAttribute="addNewAttribute"
      @saveAttribute="saveAttribute"
      @deleteAttribute="deleteAttribute"
      @addNewProperty="addNewProperty"
      @saveProperty="saveProperty"
      @deleteProperty="deleteProperty"
      @addNewTariffImport="addNewTariffImport"
      @saveTariffImport="saveTariffImport"
      @saveTariffId="saveTariffId"
      @deleteTariffImport="deleteTariffImport"
    ></FleetDialog>
    <FleetCreateDialog
      :isOpen="fleetCreateDialog"
      @close="fleetCreateDialog = false"
      @create="createFleet"
    >
    </FleetCreateDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { FleetStatusToString } from "../../models/FleetStatus";
import FleetSearchDialog from "./Dialogs/FleetSearchDialog";
import FleetCreateDialog from "./Dialogs/FleetCreateDialog";
import FleetDialog from "./Dialogs/FleetDialog";
import DataTableOptionsMixin from "../../Mixins/DataTableOptionsMixin";
import EventSourceMixin from "../../Mixins/EventSourceMixin";
import _ from "lodash";

export default {
    components: { FleetDialog, FleetSearchDialog, FleetCreateDialog },
    mixins: [ DataTableOptionsMixin, EventSourceMixin ],
    data() {
        return {
            loading: true,
            loadingCSV: false,
            filter: "",
            columnDialog: false,
            searchDialog: false,
            headers: [
                { text: "Id", value: "id", show: true },
                { text: "Company", value: "companyId", show: true },
                { text: "Name", value: "name", show: true },
                { text: "Status", value: "status", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            options: {},
            loadingPage: false,
            searchParams: {},
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            fleetDialog: false,
            fleetCreateDialog: false,
            selectedFleet: null,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        defaultSearchParams() {
            return {
                companyId: _.filter(this.$store.state.user.operators, function(o) { return o !== "all"; })[0],
                page: 1,
                top: 10
            };
        },
        fleets() {
            return this.$store.state.fleets.searchedFleets;
        },
        fleet() {
            return this.$store.state.fleets.fetchedFleet;
        },
        searchTotalCount() {
            return this.$store.state.fleets.searchTotalCount;
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    },
    watch: {
        options: {
            handler(val) {
                const query = this.convertOptionsToApiQuery(val);
                this.searchParams = { ...this.searchParams, ...query };
                this.setRouteIfChanged();
            },
            deep: true
        },
        "$route"(to) {
            this.getFleets(to.query);
        }
    },
    methods: {
        setRouteIfChanged() {
            const queryOptions = this.convertApiQueryToOptions(this.$route.query);
            const paramOptions = this.convertApiQueryToOptions(this.searchParams);
            if (JSON.stringify(queryOptions) !== JSON.stringify(paramOptions)) {
                this.$router.push({ path: "/fleets", query: this.searchParams });
            }
        },
        async getFleets(searchParams = null, forceUpdate = false) {
            this.loading = true;
            this.$store.dispatch("fleets/searchFleets", { searchParams, forceUpdate: forceUpdate })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getFleet(companyId, fleetId) {
            this.loading = true;
            this.$store.dispatch("fleets/getFleet", { companyId, fleetId })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openFleetDialog(fleet) {
            this.getFleet(fleet.companyId, fleet.id);
            this.fleetDialog = true;
        },
        createFleet(fleet) {
            this.$store.dispatch("fleets/createFleet", fleet)
                .then(() => {
                    this.fleetSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        deleteFleet(companyId, fleetId) {
            if (!window.confirm("Are you sure you wish to delete fleet: " + fleetId + "?")) {
                return;
            }
            this.$store.dispatch("fleets/deleteFleet", { companyId, fleetId })
                .then(() => {
                    this.fleetDeleted();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveContactInformation(companyId, fleetId, contactInformation) {
            this.$store.dispatch("fleets/updateFleetContactInformation", { companyId, fleetId, contactInformation })
                .then(() => {
                    this.fleetSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveFleetInfo(companyId, fleetId, fleetInfo) {
            this.$store.dispatch("fleets/updateFleetInfo", { companyId, fleetId, fleetInfo })
                .then(() => {
                    this.fleetSaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        // Attributes
        addNewAttribute(companyId, fleetId, attribute, eventTargetId) {
            this.$store.dispatch("fleets/addNewAttribute", { companyId, fleetId, attribute })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveAttribute(companyId, fleetId, attribute, eventTargetId) {
            this.$store.dispatch("fleets/saveAttribute", { companyId, fleetId, attribute })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteAttribute(companyId, fleetId, attribute, eventTargetId) {
            this.$store.dispatch("fleets/deleteAttribute", { companyId, fleetId, attribute })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Properties
        addNewProperty(companyId, fleetId, property, eventTargetId) {
            this.$store.dispatch("fleets/addNewProperty", { companyId, fleetId, property })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveProperty(companyId, fleetId, property, eventTargetId) {
            this.$store.dispatch("fleets/saveProperty", { companyId, fleetId, property })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteProperty(companyId, fleetId, property, eventTargetId) {
            this.$store.dispatch("fleets/deleteProperty", { companyId, fleetId, property })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Tariff Imports
        addNewTariffImport(companyId, fleetId, tariffImport, eventTargetId) {
            this.$store.dispatch("fleets/addNewTariffImport", { companyId, fleetId, tariffImport })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveTariffImport(companyId, fleetId, tariffImport, eventTargetId) {
            this.$store.dispatch("fleets/saveTariffImport", { companyId, fleetId, tariffImport })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveTariffId(companyId, fleetId, tariffId, eventTargetId) {
            this.$store.dispatch("fleets/saveTariffId", { companyId, fleetId, tariffId })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteTariffImport(companyId, fleetId, tariffImport, eventTargetId) {
            this.$store.dispatch("fleets/deleteTariffImport", { companyId, fleetId, tariffImport })
                .then(() => {
                    this.fleetSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        fleetSaved() {
            this.snackbarText = "Fleet was successfully saved.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.getFleet(this.fleet.companyId, this.fleet.id);
        },
        fleetDeleted() {
            this.snackbarText = "Fleet was successfully deleted.";
            this.snackbarColor = "success";
            this.snackbar = true;
        },
        closeFleetDialog() {
            this.fleetDialog = false;
        },
        hideAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = false;
            }
        },
        showAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = true;
            }
        },
        fleetStatusToString(status) {
            return FleetStatusToString(status);
        },
        searchFleets(searchParams) {
            this.searchDialog = false;
            this.searchParams = { ...this.searchParams, ...searchParams };
            this.$router.push({ path: "/fleets", query: searchParams });
        }
    },
    created() {
        this.searchParams = this.$route.query;
        if (!this.searchParams.companyId) {
            this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
        }
        this.options = this.convertApiQueryToOptions(this.searchParams);
        this.getFleets(this.searchParams);
    }
};
</script>

<style scoped>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
</style>
