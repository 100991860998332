import { dispatchingService } from "./repository";

const resource = "v1";

export default {
    async getVehiclesInArea(companyId, fleetId, areaId) {
        return dispatchingService.get(`${resource}/areas/${areaId}/${companyId}/${fleetId}/vehicles`);
    },
    async getVehiclesInPostalCode(companyId, fleetId, areaId) {
        return dispatchingService.get(`${resource}/postalcodes/${areaId}/${companyId}/${fleetId}/vehicles`);
    },
    async getVehiclesNearLocation(companyId, fleetId, query) {
        return dispatchingService.post(`${resource}/geo/${companyId}/${fleetId}/vehicles/near`, query);
    },
    async getVehiclesInPolygon(companyId, fleetId, query) {
        return dispatchingService.post(`${resource}/geo/${companyId}/${fleetId}/vehicles/polygon`, query);
    }
};
