var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _vm.editableFleetMembership
                ? _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Company",
                                  rules: [_vm.requiredRule],
                                  "error-messages": _vm.validationErrorsFor(
                                    "companyId"
                                  ),
                                  disabled: !_vm.isNew
                                },
                                model: {
                                  value: _vm.editableFleetMembership.companyId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableFleetMembership,
                                      "companyId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "editableFleetMembership.companyId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Fleet",
                                  rules: [_vm.requiredRule],
                                  "error-messages": _vm.validationErrorsFor(
                                    "fleetId"
                                  )
                                },
                                model: {
                                  value: _vm.editableFleetMembership.fleetId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableFleetMembership,
                                      "fleetId",
                                      $$v
                                    )
                                  },
                                  expression: "editableFleetMembership.fleetId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "4" } },
                            [
                              _c("v-checkbox", {
                                attrs: { label: "Is Main Fleet" },
                                model: {
                                  value: _vm.editableFleetMembership.isMain,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableFleetMembership,
                                      "isMain",
                                      $$v
                                    )
                                  },
                                  expression: "editableFleetMembership.isMain"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNew
                ? _c("v-btn", { on: { click: _vm.addFleetMembership } }, [
                    _vm._v("Add")
                  ])
                : _vm._e(),
              !_vm.isNew
                ? _c("v-btn", { on: { click: _vm.saveFleetMembership } }, [
                    _vm._v("Save")
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }