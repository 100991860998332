var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { staticStyle: { "font-weight": "bold", "font-size": "1rem" } },
                [_vm._v(_vm._s(_vm.rule.name))]
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                { attrs: { sm: "2" } },
                [
                  _vm.$route.query.edit
                    ? _c(
                        "v-row",
                        { staticClass: "mr-2", attrs: { justify: "end" } },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("modify", _vm.rule)
                                }
                              }
                            },
                            [_vm._v("mdi-pencil")]
                          ),
                          _c(
                            "v-icon",
                            {
                              staticClass: "ml-2",
                              attrs: { color: "error" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.$emit("delete", _vm.rule)
                                }
                              }
                            },
                            [_vm._v("delete")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-expansion-panel-content",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "4", "align-self": "start" } },
                [
                  _c("b", [_vm._v("Conditions")]),
                  _c(
                    "ul",
                    _vm._l(_vm.rule.conditions, function(condition, i) {
                      return _c("li", { key: i }, [
                        _vm._v(
                          " " +
                            _vm._s(condition.value) +
                            " " +
                            _vm._s(
                              _vm._f("comparatorToSymbol")(condition.comparator)
                            ) +
                            " " +
                            _vm._s(condition.target) +
                            " "
                        )
                      ])
                    }),
                    0
                  )
                ]
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", "align-self": "start" } },
                [
                  _c("b", [_vm._v("Actions")]),
                  _c(
                    "ul",
                    _vm._l(_vm.rule.actions, function(action, i) {
                      return _c("li", { key: i }, [
                        _vm._v(
                          " " + _vm._s(_vm._f("actionToText")(action)) + " "
                        )
                      ])
                    }),
                    0
                  )
                ]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }