import { tripService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    // Trip
    async find(params, top, page = 1) {
        return tripService.get(`${resource}/trips`, {
            params: {
                ...params,
                top,
                page
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getTrip(tripId) {
        return tripService.get(`${resource}/trips/${tripId}`);
    },
    async getTripOffers(tripId) {
        return tripService.get(`${resource}/trips/${tripId}/offers`);
    }
};
