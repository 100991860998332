var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(_vm._s(this.$t("invoicing.sales.summary.title")))
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            [
                              _vm.fromDate
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Alkaen",
                                          type: "date",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.fromDate,
                                          callback: function($$v) {
                                            _vm.fromDate = $$v
                                          },
                                          expression: "fromDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.toDate
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Asti",
                                          type: "date",
                                          readonly: ""
                                        },
                                        model: {
                                          value: _vm.toDate,
                                          callback: function($$v) {
                                            _vm.toDate = $$v
                                          },
                                          expression: "toDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: this.$t(
                                "invoicing.sales.summary.invoiceCount"
                              ),
                              readonly: ""
                            },
                            model: {
                              value: _vm.invoiceCount,
                              callback: function($$v) {
                                _vm.invoiceCount = $$v
                              },
                              expression: "invoiceCount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: this.$t(
                                "invoicing.sales.summary.transportEventCount"
                              ),
                              readonly: ""
                            },
                            model: {
                              value: _vm.transportEventCount,
                              callback: function($$v) {
                                _vm.transportEventCount = $$v
                              },
                              expression: "transportEventCount"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm._f("formatCurrency")(_vm.totalSum),
                              label: this.$t(
                                "invoicing.sales.summary.reimbursementSum"
                              ),
                              readonly: ""
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: _vm.sendToPurchasing }
                },
                [_vm._v(_vm._s(_vm.$t("invoicing.sales.sendToSales")))]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "error", text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(_vm._s(this.$t("common.close")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }