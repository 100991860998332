import { companyService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    // Company
    async find(params, top, page = 1) {
        return companyService.get(`${resource}/companies`, {
            params: {
                ...params,
                top,
                page
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getCompany(companyId) {
        return companyService.get(`${resource}/companies/${companyId}`);
    },
    async create(company) {
        return companyService.post(`${resource}/companies`, company);
    },
    async activate(companyId) {
        return companyService.post(`${resource}/companies/${companyId}/activate`);
    },
    async patchContactInformation(companyId, contactInformation) {
        return companyService.patch(`${resource}/companies/${companyId}/contactinformation`, contactInformation);
    },
    async patchCompanyInfo(companyId, companyInfo) {
        return companyService.patch(`${resource}/companies/${companyId}/info`, companyInfo);
    },
    // Attributes
    async postNewAttribute(companyId, attribute) {
        return companyService.post(`${resource}/attributes/company/${companyId}`, attribute);
    },
    async patchAttribute(companyId, attribute) {
        return companyService.patch(`${resource}/attributes/company/${companyId}`, attribute);
    },
    async deleteAttribute(companyId, attribute) {
        return companyService.delete(`${resource}/attributes/company/${companyId}/${attribute.origin}/${attribute.id}`);
    },
    // Properties
    async postNewProperty(companyId, property) {
        return companyService.post(`${resource}/configuration/company/${companyId}`, property);
    },
    async patchProperty(companyId, property) {
        return companyService.patch(`${resource}/configuration/company/${companyId}`, property);
    },
    async deleteProperty(companyId, property) {
        return companyService.delete(`${resource}/configuration/company/${companyId}/${property.key}`);
    },
    // Tariffs
    async postNewTariffImport(companyId, tariffImport) {
        return companyService.post(`${resource}/tariffs/company/${companyId}/imports`, tariffImport);
    },
    async patchTariffImport(companyId, tariffImport) {
        return companyService.patch(`${resource}/tariffs/company/${companyId}/imports`, tariffImport);
    },
    async patchTariffId(companyId, tariffId) {
        return companyService.patch(`${resource}/tariffs/company/${companyId}/id`, { tariffId });
    },
    async deleteTariffImport(companyId, tariffImport) {
        return companyService.delete(`${resource}/tariffs/company/${companyId}/imports/${tariffImport.id}`);
    }
};
