<template>
  <v-container fluid>
        <VehiclesTable></VehiclesTable>
  </v-container>
</template>

<script>
import VehiclesTable from "../components/Vehicles/VehiclesTable";

export default {
    components: { VehiclesTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
