<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ isNew ? 'Add new action' : 'Edit action' }}
        </span>
      </v-card-title>
      <v-card-text style="color: black;">
        <v-form
          ref="actionForm"
          v-model="valid"
          lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="action.type"
                  :items="Object.values(actionTypes).filter(a => a !== 0)"
                  label="Action type"
                  :rules="[ requiredRule ]"
                  required
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item | actionTypeToText }}
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    {{ item | actionTypeToText }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="action.type === 1">
              <v-col>
                <v-select
                  v-model="action.property"
                  :items="Object.values(transportEventConditionProperties)"
                  label="Property"
                  :rules="[ requiredRule ]"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="action.value"
                  label="Value"
                  :rules="[ requiredRule ]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else-if="action.type === 3">
              <v-col>
                <v-text-field
                  v-model="action.value"
                  label="Validation fail reason code"
                  :rules="[ requiredRule ]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn v-if="isNew"
          :disabled="!valid"
          @click="createAction"
          color="success">
          Add action
        </v-btn>
        <v-btn v-else
          :disabled="!valid"
          @click="saveAction"
          color="primary">
          Save changes
        </v-btn>
        <v-btn @click="$emit('close')" class="ml-4">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { RulesMixin } from "@/models/Rules";
import InputRuleMixin from "../Inputs/InputRuleMixin";

export default {
    props: {
        originalAction: {
            type: Object,
            default: () => {}
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: true
        }
    },
    mixins: [ RulesMixin, InputRuleMixin ],
    data() {
        return {
            action: {},
            valid: true
        };
    },
    watch: {
        isOpen: function(val, oldVal) {
            if (this.$refs.actionForm) {
                this.$refs.actionForm.resetValidation();
            }
        },
        originalAction: function(val, oldVal) {
            let action = _.cloneDeep(val);
            this.action = action;
        }
    },
    methods: {
        saveAction() {
            if (!this.$refs.actionForm.validate()) {
                return;
            }
            this.$emit("save", this.action);
        },
        createAction() {
            if (!this.$refs.actionForm.validate()) {
                return;
            }
            this.$emit("create", this.action);
        }
    }
};
</script>

<style scoped>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}

.v-btn:not(.v-btn--round).v-size--default.add-new-btn {
  padding: 0;
}
</style>
