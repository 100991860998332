var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip-group",
    { attrs: { column: "", dark: "" } },
    [
      _vm._l(_vm.fees, function(fee, n) {
        return [
          _c(
            "v-tooltip",
            {
              key: n,
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              key: n,
                              attrs: {
                                close: "",
                                disabled: _vm.readOnly,
                                "close-icon": "mdi-delete",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openFeeDialog(fee, n)
                                },
                                "click:close": function($event) {
                                  return _vm.deleteFee(n)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _vm.useLikumaTexts
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("feeTypeToLikumaText")(fee.type)
                                      ) +
                                      ": " +
                                      _vm._s(fee.price) +
                                      " "
                                  )
                                ])
                              : _vm.useKelaTexts
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("feeTypeToKelaText")(fee.type)
                                      ) +
                                      ": " +
                                      _vm._s(fee.price) +
                                      " "
                                  )
                                ])
                              : _vm.isKaista
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(fee.name) +
                                      ": " +
                                      _vm._s(
                                        _vm._f("formatCurrency")(fee.price)
                                      ) +
                                      " "
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("feeTypeToText")(fee.type)
                                      ) +
                                      ": " +
                                      _vm._s(fee.price) +
                                      " "
                                  )
                                ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [_c("span", [_vm._v(_vm._s(_vm._f("feeToToolTipText")(fee)))])]
          )
        ]
      }),
      !_vm.readOnly && _vm.fees.length < _vm.maxAmount
        ? _c(
            "v-chip",
            {
              attrs: { color: "success" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.openNewFeeDialog($event)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("invoicing.addNewFee")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")])
            ],
            1
          )
        : _vm._e(),
      _c("FeeDialog", {
        attrs: {
          isOpen: _vm.feeDialog,
          originalFee: _vm.selectedFee,
          isNewFee: _vm.newFee,
          allowedFeeTypes: _vm.allowedFeeTypes,
          useLikumaTexts: _vm.useLikumaTexts,
          useKelaTexts: _vm.useKelaTexts
        },
        on: {
          create: _vm.addFee,
          save: _vm.modifyFee,
          close: _vm.closeFeeDialog
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }