var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                _vm._s(
                  _vm.$t("customerDetails.codeDialog.createNewRegistrationCode")
                )
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  !_vm.codeCreated
                    ? [
                        _c(
                          "v-date-picker",
                          {
                            staticClass: "pm-10",
                            attrs: {
                              "full-width": "",
                              required: "",
                              range: ""
                            },
                            model: {
                              value: _vm.dates,
                              callback: function($$v) {
                                _vm.dates = $$v
                              },
                              expression: "dates"
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.dates = []
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.$t("common.clear")))]
                            ),
                            _c("v-spacer"),
                            _c("h3", { staticClass: "primary--text" }, [
                              _vm._v(_vm._s(_vm.validThroughText))
                            ])
                          ],
                          1
                        ),
                        _c("v-text-field", {
                          staticClass: "pt-10",
                          attrs: {
                            disabled: _vm.generateCode,
                            outlined: "",
                            placeholder: "TESTCODE",
                            label: _vm.$t("customerDetails.codeDialog.code")
                          },
                          model: {
                            value: _vm.code,
                            callback: function($$v) {
                              _vm.code = $$v
                            },
                            expression: "code"
                          }
                        }),
                        _c("v-switch", {
                          attrs: {
                            label: _vm.$t(
                              "customerDetails.codeDialog.generateCode"
                            )
                          },
                          on: { change: _vm.generateCodeChange },
                          model: {
                            value: _vm.generateCode,
                            callback: function($$v) {
                              _vm.generateCode = $$v
                            },
                            expression: "generateCode"
                          }
                        }),
                        _c(
                          "v-radio-group",
                          {
                            attrs: { row: "" },
                            model: {
                              value: _vm.codeType,
                              callback: function($$v) {
                                _vm.codeType = $$v
                              },
                              expression: "codeType"
                            }
                          },
                          [
                            _c("v-radio", {
                              attrs: {
                                label: _vm.$t(
                                  "customerDetails.codeDialog.multipleUse"
                                ),
                                value: "multipleUse"
                              }
                            }),
                            _c("v-radio", {
                              attrs: {
                                label: _vm.$t(
                                  "customerDetails.codeDialog.singleUse"
                                ),
                                value: "singleUse"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled: _vm.codeCreated
                            },
                            on: { click: _vm.create }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.create")))]
                        )
                      ]
                    : [
                        _c("h2", [_vm._v(_vm._s(_vm.createdCode))]),
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("customerDetails.codeDialog.pleaseCopy")
                            )
                          )
                        ]),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              color: "primary",
                              disabled: !_vm.codeCreated
                            },
                            on: { click: _vm.close }
                          },
                          [_vm._v(_vm._s(_vm.$t("common.close")))]
                        )
                      ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }