<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Vehicles
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = true"
          class="mx-2">
          Search
        </v-btn>
        <v-btn
          small
          @click="vehicleCreateDialog = true"
          class="mx-2">
         Create
        </v-btn>
        <v-spacer></v-spacer>
        <VehicleSearchDialog
          :show="searchDialog"
          :searchParams="searchParams"
          @search="searchVehicles($event, false)"
          @close="searchDialog = false"
        ></VehicleSearchDialog>
      </v-card-actions>
    </div>
      <v-data-table
        calculate-widths
        :headers="headers"
        :items="vehicles"
        item-key="id"
        :loading="loading"
        :server-items-length="searchTotalCount"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: itemsPerPageOptions }"
        @click:row="openVehicleDialog"
      >
        <template v-slot:item.vehicleId="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.vehicleNumber="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.fleetId="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.status="{ value }">
          <span>{{ vehicleStatusToString(value) }}</span>
        </template>
        <template v-slot:item.dispatchState="{ value }">
          <span> {{ dispatchStateToString(value) }}</span>
        </template>
        <template v-slot:item.taxiMeterState="{ value }">
          <span> {{ taxiMeterStateToString(value) }}</span>
        </template>
        <template v-slot:item.lastActive="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row align="center" justify="space-around">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon
                  v-on="on"
                  @click.stop="openVehicleMap(item)">
                  <v-icon>mdi-map-marker</v-icon>
                </v-btn>
              </template>
              <span>Open map</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon
                  v-on="on"
                  @click.stop="deleteVehicle(item.vehicleId)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete vehicle</span>
            </v-tooltip>
            <v-tooltip top v-if="$AuthService.hasServiceRole('gateway', 'edit')">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on"
                    @click.stop="openVehicleCacheDialog(item.vehicleId)">
                  <v-icon>mdi-car-info</v-icon>
                </v-btn>
              </template>
              <span>Show Cache</span>
            </v-tooltip>
        </v-row>
        </template>
      </v-data-table>
    <VehicleDialog
      :isOpen="vehicleDialog"
      :vehicle="vehicle"
      @close="vehicleDialog = false"
      @saveContactInformation="saveContactInformation"
      @saveVehicleInfo="saveVehicleInfo"
      @addFleetMembership="addFleetMembership"
      @saveFleetMembership="saveFleetMembership"
      @deleteFleetMembership="deleteFleetMembership"
      @addNewAttribute="addNewAttribute"
      @saveAttribute="saveAttribute"
      @deleteAttribute="deleteAttribute"
      @addNewProperty="addNewProperty"
      @saveProperty="saveProperty"
      @deleteProperty="deleteProperty"
      @addNewTariffImport="addNewTariffImport"
      @saveTariffImport="saveTariffImport"
      @saveTariffId="saveTariffId"
      @deleteTariffImport="deleteTariffImport"
    ></VehicleDialog>
    <VehicleCreateDialog
      :isOpen="vehicleCreateDialog"
      @close="vehicleCreateDialog = false"
      @create="createVehicle"
    >
    </VehicleCreateDialog>
    <VehicleCacheDialog
      :isOpen="vehicleCacheDialog"
      :cache="vehicleCache"
      :vehicleId="vehicleId"
      @removeFromIndex="id => removeVehicleFromIndex(id)"
      @invalidateCache="id => invalidateVehicleCache(id)"
      @refreshCache="id => getVehicleCache(id)"
      @close="vehicleCacheDialog = false"
      ></VehicleCacheDialog>
    <VehicleMapDialog
      :isOpen="vehicleMapDialog"
      :vehicleDispatchStatus="vehicleDispatchStatus"
      @close="vehicleMapDialog = false"
    ></VehicleMapDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { VehicleStatusToString } from "../../models/VehicleStatus";
import { TaxiMeterStateToString } from "../../models/TaxiMeterState";
import { DispatchStateToString } from "../../models/DispatchState";
import VehicleSearchDialog from "./Dialogs/VehicleSearchDialog";
import VehicleCreateDialog from "./Dialogs/VehicleCreateDialog";
import VehicleCacheDialog from "./Dialogs/VehicleCacheDialog";
import VehicleDialog from "./Dialogs/VehicleDialog";
import VehicleMapDialog from "./Dialogs/VehicleMapDialog";
import DataTableOptionsMixin from "../../Mixins/DataTableOptionsMixin";
import EventSourceMixin from "../../Mixins/EventSourceMixin";

export default {
    components: { VehicleDialog, VehicleSearchDialog, VehicleCreateDialog, VehicleMapDialog, VehicleCacheDialog },
    mixins: [ DataTableOptionsMixin, EventSourceMixin ],
    data() {
        return {
            loading: true,
            columnDialog: false,
            searchDialog: false,
            headers: [
                { text: "Id", value: "vehicleId", show: true },
                { text: "Number", value: "vehicleNumber", show: true },
                { text: "Fleet", value: "fleetId", show: true },
                { text: "Status", value: "status", show: true },
                { text: "Dispatch", value: "dispatchState", show: true },
                { text: "Taximeter", value: "taxiMeterState", show: true },
                { text: "Last Active", value: "lastActive", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            loadingPage: false,
            options: {},
            searchParams: {
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            vehicleDialog: false,
            vehicleCreateDialog: false,
            vehicleMapDialog: false,
            vehicleCacheDialog: false,
            vehicleId: "",
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        defaultSearchParams() {
            return {
                companyId: this.setCompanyId,
                page: 1,
                top: 10
            };
        },
        vehicles() {
            return this.$store.state.vehicles.searchedVehicles;
        },
        vehicle() {
            return this.$store.state.vehicles.fetchedVehicle;
        },
        vehicleDispatchStatus() {
            return this.$store.state.gateway.vehicleDispatchStatus;
        },
        vehicleCache() {
            return this.$store.state.gateway.vehicleCache;
        },
        searchTotalCount() {
            return this.$store.state.vehicles.searchTotalCount;
        },
        tenant() {
            return this.$store.state.user.tenant;
        },
        setCompanyId() {
            return this.$store.state.user.operators.includes("all") || this.$store.state.user.operators.length > 1
                ? this.tenant
                : this.$store.state.user.operators[0];
        }
    },
    watch: {
        options: {
            handler(val) {
                const query = this.convertOptionsToApiQuery(val);
                this.searchParams = { ...this.searchParams, ...query };
                this.searchVehicles(this.searchParams);
            },
            deep: true
        },
        tenant(val) {
            this.searchParams = { ...this.searchParams, companyId: val };
            this.getVehicles(this.searchParams, true);
        }
    },
    methods: {
        async getVehicles(searchParams = null, forceUpdate = false) {
            this.loading = true;
            this.$store.dispatch("vehicles/searchVehicles", { searchParams, forceUpdate: forceUpdate })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getVehicle(vehicleId) {
            this.loading = true;
            this.$store.dispatch("vehicles/getVehicle", vehicleId)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openVehicleDialog(vehicle) {
            this.getVehicle(vehicle.vehicleId);
            // set timeout of 100ms before setting vehicleDialog to true
            // this is to allow the vehicle to be fetched before the dialog is opened
            setTimeout(() => {
                this.vehicleDialog = true;
            }, 100);
        },
        openVehicleMap(vehicle) {
            this.getVehicleDispatchStatus(vehicle.vehicleId);
            this.vehicleMapDialog = true;
        },
        openVehicleCacheDialog(vehicleId) {
            this.getVehicleCache(vehicleId);
            this.vehicleId = vehicleId;
            this.vehicleCacheDialog = true;
        },
        createVehicle(vehicle, eventTargetId) {
            this.$store.dispatch("vehicles/createVehicle", vehicle)
                .then(() => {
                    this.vehicleCreated();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteVehicle(vehicleId, eventTargetId) {
            if (!window.confirm("Are you sure you wish to delete vehicle: " + vehicleId + "?")) {
                return;
            }
            this.$store.dispatch("vehicles/deleteVehicle", vehicleId)
                .then(() => {
                    this.vehicleDeleted();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        removeVehicleFromIndex(vehicleId, eventTargetId) {
            if (!window.confirm("Are you sure you wish to remove vehicle from index: " + vehicleId + "?")) {
                return;
            }
            this.$store.dispatch("vehicles/removeFromIndex", vehicleId)
                .then(() => {
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        invalidateVehicleCache(vehicleId, eventTargetId) {
            if (!window.confirm("Are you sure you wish to invalidate vehicle cache: " + vehicleId + "?")) {
                return;
            }
            this.$store.dispatch("gateway/invalidateVehicleCache", vehicleId)
                .then(() => {
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        getVehicleCache(vehicleId, eventTargetId) {
            this.$store.dispatch("gateway/getVehicleCache", vehicleId)
                .then(() => {
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        async getVehicleDispatchStatus(vehicleId) {
            this.loading = true;
            this.$store.dispatch("gateway/getVehicleDispatchStatus", vehicleId)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        saveContactInformation(vehicleId, contactInformation, eventTargetId) {
            this.$store.dispatch("vehicles/updateVehicleContactInformation", { vehicleId, contactInformation })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveVehicleInfo(vehicleId, vehicleInfo, eventTargetId) {
            this.$store.dispatch("vehicles/updateVehicleInfo", { vehicleId, vehicleInfo })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Fleet Memberships
        addFleetMembership(vehicleId, fleetMembership, eventTargetId) {
            this.$store.dispatch("vehicles/addFleetMembership", { vehicleId, fleetMembership })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveFleetMembership(vehicleId, fleetMembership, eventTargetId) {
            this.$store.dispatch("vehicles/saveFleetMembership", { vehicleId, fleetMembership })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteFleetMembership(vehicleId, fleetMembership, eventTargetId) {
            this.$store.dispatch("vehicles/deleteFleetMembership", { vehicleId, fleetMembership })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Attribute
        addNewAttribute(vehicleId, attribute, eventTargetId) {
            this.$store.dispatch("vehicles/addNewAttribute", { vehicleId, attribute })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveAttribute(vehicleId, attribute, eventTargetId) {
            this.$store.dispatch("vehicles/saveAttribute", { vehicleId, attribute })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteAttribute(vehicleId, attribute, eventTargetId) {
            this.$store.dispatch("vehicles/deleteAttribute", { vehicleId, attribute })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Properties
        addNewProperty(vehicleId, property, eventTargetId) {
            this.$store.dispatch("vehicles/addNewProperty", { vehicleId, property })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveProperty(vehicleId, property, eventTargetId) {
            this.$store.dispatch("vehicles/saveProperty", { vehicleId, property })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteProperty(vehicleId, property, eventTargetId) {
            this.$store.dispatch("vehicles/deleteProperty", { vehicleId, property })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Tariff Imports
        addNewTariffImport(vehicleId, tariffImport, eventTargetId) {
            this.$store.dispatch("vehicles/addNewTariffImport", { vehicleId, tariffImport })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveTariffImport(vehicleId, tariffImport, eventTargetId) {
            this.$store.dispatch("vehicles/saveTariffImport", { vehicleId, tariffImport })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveTariffId(vehicleId, tariffId, eventTargetId) {
            this.$store.dispatch("vehicles/saveTariffId", { vehicleId, tariffId })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteTariffImport(vehicleId, tariffImport, eventTargetId) {
            this.$store.dispatch("vehicles/deleteTariffImport", { vehicleId, tariffImport })
                .then(() => {
                    this.vehicleSaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        vehicleCreated() {
            this.snackbarText = "Vehicle was successfully created.";
            this.snackbarColor = "success";
            this.snackbar = true;
        },
        vehicleSaved() {
            this.snackbarText = "Vehicle was successfully saved.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.getVehicle(this.vehicle.id);
        },
        vehicleDeleted() {
            this.snackbarText = "Vehicle was successfully deleted.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.getVehicles(this.searchParams);
        },
        closeVehicleDialog() {
            this.vehicleDialog = false;
        },
        closeVehicleMap() {
            this.vehicleMapDialog = false;
        },
        vehicleStatusToString(vehicleStatus) {
            return VehicleStatusToString(vehicleStatus);
        },
        taxiMeterStateToString(taxiMeterState) {
            return TaxiMeterStateToString(taxiMeterState);
        },
        dispatchStateToString(dispatchState) {
            return DispatchStateToString(dispatchState);
        },
        searchVehicles(searchParams) {
            this.searchDialog = false;
            this.searchParams = { ...this.searchParams, ...searchParams };
            this.getVehicles(this.searchParams);
        }
    },
    created() {
        this.searchParams = this.$route.query;
        if (!this.searchParams.companyId) {
            this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
        }
        this.options = this.convertApiQueryToOptions(this.searchParams);
        this.getVehicles(this.searchParams);
    }
};
</script>
