var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          on: { input: _vm.onDialogInput },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { short: "", dark: "", color: "primary" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              model: {
                                value: _vm.modelTab,
                                callback: function($$v) {
                                  _vm.modelTab = $$v
                                },
                                expression: "modelTab"
                              }
                            },
                            [
                              _vm._l(_vm.localInvoiceOptions, function(option) {
                                return _c(
                                  "v-tab",
                                  {
                                    key: option.id,
                                    attrs: { href: "#" + option.id }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(option.optionsName) + " "
                                    )
                                  ]
                                )
                              }),
                              _c(
                                "v-tab",
                                { on: { click: _vm.createNewInvoiceOption } },
                                [_vm._v("+ " + _vm._s(_vm.$t("common.addNew")))]
                              )
                            ],
                            2
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: { click: _vm.cancel }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("invoicing.invoicingOptions")) + " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticStyle: { color: "black", "margin-top": "16px" } },
                [
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.modelTab,
                        callback: function($$v) {
                          _vm.modelTab = $$v
                        },
                        expression: "modelTab"
                      }
                    },
                    _vm._l(_vm.localInvoiceOptions, function(option) {
                      return _c(
                        "v-tab-item",
                        { key: option.id, attrs: { value: option.id } },
                        [
                          _c("InvoiceOptionsForm", {
                            ref: "invoiceOptionsForm",
                            refInFor: true,
                            attrs: { invoiceOptions: option },
                            on: { submit: _vm.submitForm }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pb-4" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.triggerFormSubmit }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          return _vm.confirmDeleteOption(_vm.modelTab)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.delete")) + " ")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("NewInvoiceOptionDialog", {
        attrs: {
          show: _vm.showNewOptionDialog,
          defaultInvoicingOptions: _vm.defaultInvoicingOptions
        },
        on: {
          "update:show": function($event) {
            _vm.showNewOptionDialog = $event
          },
          create: _vm.handleCreateNewOptions,
          cancel: _vm.handleCancelNewOptionDialog
        }
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.showConfirmDialog,
            callback: function($$v) {
              _vm.showConfirmDialog = $$v
            },
            expression: "showConfirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("common.delete")))
              ]),
              _c("v-card-text", [
                _vm._v(_vm._s(_vm.$t("invoicing.options.confirmDelete")))
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.cancelDelete }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.deleteOption }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.delete")))]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }