<template>
  <v-form ref="form" v-if="editableContactInformation">
        <v-row class="mt-3">
            <v-col cols="6">
                <v-text-field
                    v-model="editableContactInformation.phoneNumber"
                    :rules="[ phoneNumberRule ]"
                    label="Phone Number"
                    :disabled="!editable"
                ></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="editableContactInformation.email"
                    :rules="[ emailRule ]"
                    label="Email"
                    :disabled="!editable"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-3" v-if="editable">
          <v-col cols="12">
            <v-btn
              class="mr-4"
              @click="save"
              :disabled="!dirty"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
    </v-form>
</template>

<script>
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import _ from "lodash";

export default {
    mixins: [ InputRuleMixin ],
    components: { },
    props: {
        contactInformation: {
            type: Object,
            default: function() {
                return null;
            }
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editableContactInformation: this.contactInformation ? _.cloneDeep(this.contactInformation) : {},
            dirty: false
        };
    },
    watch: {
        contactInformation(val) {
            this.editableContactInformation = (!val) ? {} : _.cloneDeep(val);
        },
        editableContactInformation:
    {
        handler() {
            this.dirty = true;
        },
        deep: true
    }
    },
    methods: {
        save: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", this.editableContactInformation);
        }
    }
};
</script>
