export const BookingStatus = {
    Unknown: 0,
    New: 1,
    LookingForCar: 2,
    SentToCar: 4,
    Ongoing: 8,
    Done: 16,
    Cancelled: 32
};

export function BookingStatusToString(bookingStatus) {
    switch (bookingStatus) {
    case BookingStatus.Unknown:
        return "-";
    case BookingStatus.New:
        return "New";
    case BookingStatus.LookingForCar:
        return "Looking for car";
    case BookingStatus.SentToCar:
        return "Sent to car";
    case BookingStatus.Ongoing:
        return "Ongoing";
    case BookingStatus.Done:
        return "Done";
    case BookingStatus.Cancelled:
        return "Cancelled";
    }
};
