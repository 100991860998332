<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
  <v-card>
    <v-toolbar short dark color="primary">
      <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
          Create Vehicle
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" :key="refreshValidation">
        <v-row class="mt-3">
          <v-col cols="6">
              <v-select
                :items="operators"
                v-model="newVehicle.companyId"
                :rules="[ requiredRule ]"
                :error-messages="validationErrorsFor('companyId')"
                label="Company Id"
              ></v-select>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="newVehicle.fleetId"
                :rules="[ requiredRule ]"
                :error-messages="validationErrorsFor('fleetId')"
                label="Fleet Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="newVehicle.vehicleId"
                :rules="[ requiredRule ]"
                :error-messages="validationErrorsFor('vehicleId')"
                label="Vehicle Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              :items="vehicleTwinTypes"
              v-model="newVehicle.twinType"
              :rules="[ requiredRule ]"
              :error-messages="validationErrorsFor('twinType')"
              label="Twin Type"
            >
              <template v-slot:selection="{ item }">
                {{ vehicleTwinTypeToString(item) }}
              </template>
              <template v-slot:item="{ item }">
                {{ vehicleTwinTypeToString(item) }}
              </template>
            </v-select>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="newVehicle.vehicleNumber"
                :rules="[ requiredRule ]"
                :error-messages="validationErrorsFor('vehicleNumber')"
                label="Vehicle Number"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="newVehicle.registrationPlateNumber"
                label="Registration Plate Number"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="newVehicle.email"
                :rules="[ emailRule, requiredRule ]"
                :error-messages="validationErrorsFor('email')"
                label="Email"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                v-model="newVehicle.phoneNumber"
                :rules="[ phoneNumberRule, requiredRule ]"
                :error-messages="validationErrorsFor('phoneNumber')"
                label="Phone Number"
              ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      class="ml-2"
      @click="create"
      >
        Create
      </v-btn>
      <v-btn
        @click="cancel"
        class="ml-2">
        {{$t("common.cancel")}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { VehicleTwinType, VehicleTwinTypeToString } from "../../../models/VehicleTwinType";
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import InputValidationMixin from "../../Inputs/InputValidationMixin";
import EventListenerMixin from "../../../Mixins/EventListenerMixin";

export default {
    mixins: [ InputRuleMixin, InputValidationMixin, EventListenerMixin ],
    props: {
        isOpen: Boolean
    },
    data() {
        return {
            newVehicle: {},
            vehicleTwinTypes: Object.values(VehicleTwinType)
        };
    },
    computed: {
        operators() {
            return this.$store.state.user.operators;
        }
    },
    methods: {
        create: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", {
                vehicleId: this.newVehicle.vehicleId,
                vehicleNumber: this.newVehicle.vehicleNumber,
                registrationPlateNumber: this.newVehicle.registrationPlateNumber,
                companyId: this.newVehicle.companyId,
                fleetId: this.newVehicle.fleetId,
                email: this.newVehicle.email,
                phoneNumber: this.newVehicle.phoneNumber,
                twinType: this.newVehicle.twinType
            }, this.eventTargetId);
        },
        cancel() {
            this.newVehicle = {};
            this.$emit("close");
        },
        vehicleTwinTypeToString(vehicleTwinType) {
            return VehicleTwinTypeToString(vehicleTwinType);
        },
        close: function() {
            this.$emit("close");
        }
    },
    created() {
        this.$on("success", (e) => {
            this.newVehicle = {};
            this.$emit("close");
        });
    }
};
</script>
