<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Companies
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = true"
          class="mx-2">
          Search
        </v-btn>
        <v-btn
          small
          @click="companyCreateDialog = true"
          class="mx-2">
          Create
        </v-btn>
        <v-spacer></v-spacer>
        <CompanySearchDialog
          :show="searchDialog"
          :searchParams="searchParams"
          @search="searchCompanies($event, false)"
          @close="searchDialog = false"
        ></CompanySearchDialog>
      </v-card-actions>
    </div>
    <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table">
      <v-data-table
        calculate-widths
        :headers="headers"
        :items="companies"
        item-key="id"
        :loading="loading"
        :server-items-length="searchTotalCount"
        :options.sync="options"
        :footer-props="{ itemsPerPageOptions: itemsPerPageOptions}"
        @click:row="openCompanyDialog"
      >
        <template v-slot:item.id="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.name="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.status="{ value }">
          <span> {{ companyStatusToString(value) }}</span>
        </template>
         <template v-slot:item.actions="{ item }">
          <span>
            <v-btn
              @click.stop="activateCompany(item.id)"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </span>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <CompanyDialog
      :isOpen="companyDialog"
      :company="company"
      @close="companyDialog = false"
      @saveContactInformation="saveContactInformation"
      @saveCompanyInfo="saveCompanyInfo"
      @addNewAttribute="addNewAttribute"
      @saveAttribute="saveAttribute"
      @deleteAttribute="deleteAttribute"
      @addNewProperty="addNewProperty"
      @saveProperty="saveProperty"
      @deleteProperty="deleteProperty"
      @addNewTariffImport="addNewTariffImport"
      @saveTariffImport="saveTariffImport"
      @saveTariffId="saveTariffId"
      @deleteTariffImport="deleteTariffImport"
    ></CompanyDialog>
    <CompanyCreateDialog
      :isOpen="companyCreateDialog"
      @close="companyCreateDialog = false"
      @create="createCompany"
    >
    </CompanyCreateDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { CompanyStatusToString } from "../../models/CompanyStatus";
import CompanySearchDialog from "./Dialogs/CompanySearchDialog";
import CompanyCreateDialog from "./Dialogs/CompanyCreateDialog";
import CompanyDialog from "./Dialogs/CompanyDialog";
import DataTableOptionsMixin from "../../Mixins/DataTableOptionsMixin";
import EventSourceMixin from "../../Mixins/EventSourceMixin";

export default {
    components: { CompanyDialog, CompanySearchDialog, CompanyCreateDialog },
    mixins: [ DataTableOptionsMixin, EventSourceMixin ],
    data() {
        return {
            loading: true,
            columnDialog: false,
            searchDialog: false,
            headers: [
                { text: "Id", value: "id", show: true },
                { text: "Name", value: "name", show: true },
                { text: "Status", value: "status", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            loadingPage: false,
            options: {},
            searchParams: {
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            companyDialog: false,
            companyCreateDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        defaultSearchParams() {
            return {
                page: 1,
                top: 10
            };
        },
        companies() {
            return this.$store.state.companies.searchedCompanies;
        },
        company() {
            return this.$store.state.companies.fetchedCompany;
        },
        searchTotalCount() {
            return this.$store.state.companies.searchTotalCount;
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    },
    watch: {
        options: {
            handler(val) {
                const query = this.convertOptionsToApiQuery(val);
                this.searchParams = { ...this.searchParams, ...query };
                this.setRouteIfChanged();
            },
            deep: true
        },
        "$route"(to) {
            this.getCompanies(to.query);
        }
    },
    methods: {
        setRouteIfChanged() {
            const queryOptions = this.convertApiQueryToOptions(this.$route.query);
            const paramOptions = this.convertApiQueryToOptions(this.searchParams);
            if (JSON.stringify(queryOptions) !== JSON.stringify(paramOptions)) {
                this.$router.push({ path: "/companies", query: this.searchParams });
            }
        },
        async getCompanies(searchParams = null, forceUpdate = false) {
            this.loading = true;
            this.$store.dispatch("companies/searchCompanies", { searchParams, top: 100, forceUpdate: forceUpdate })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getCompany(companyId) {
            this.loading = true;
            this.$store.dispatch("companies/getCompany", companyId)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async loadMoreCompanies() {
            if (!this.companies) return;
            if (this.companies.length === this.searchTotalCount) return;
            if ((this.page + 1) * this.itemsPerPage < this.companies.length) {
                return;
            }
            this.loadingPage = true;
            this.$store.dispatch("companies/loadMoreCompanies", {
                top: 100
            })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingPage = false;
                });
        },
        openCompanyDialog(company) {
            this.getCompany(company.id);
            this.companyDialog = true;
        },
        createCompany(company) {
            this.$store.dispatch("companies/createCompany", company)
                .then(() => {
                    this.companySaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        activateCompany(companyId) {
            this.$store.dispatch("companies/activateCompany", companyId)
                .then(() => {
                    this.companySaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveContactInformation(companyId, contactInformation) {
            this.$store.dispatch("companies/updateCompanyContactInformation", { companyId, contactInformation })
                .then(() => {
                    this.companySaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        saveCompanyInfo(companyId, companyInfo) {
            this.$store.dispatch("companies/updateCompanyInfo", { companyId, companyInfo })
                .then(() => {
                    this.companySaved();
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        // Attributes
        addNewAttribute(companyId, attribute, eventTargetId) {
            this.$store.dispatch("companies/addNewAttribute", { companyId, attribute })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveAttribute(companyId, attribute, eventTargetId) {
            this.$store.dispatch("companies/saveAttribute", { companyId, attribute })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteAttribute(companyId, attribute, eventTargetId) {
            this.$store.dispatch("companies/deleteAttribute", { companyId, attribute })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Properties
        addNewProperty(companyId, property, eventTargetId) {
            this.$store.dispatch("companies/addNewProperty", { companyId, property })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveProperty(companyId, property, eventTargetId) {
            this.$store.dispatch("companies/saveProperty", { companyId, property })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteProperty(companyId, property, eventTargetId) {
            this.$store.dispatch("companies/deleteProperty", { companyId, property })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        // Tariffs
        addNewTariffImport(companyId, tariffImport, eventTargetId) {
            this.$store.dispatch("companies/addNewTariffImport", { companyId, tariffImport })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveTariffImport(companyId, tariffImport, eventTargetId) {
            this.$store.dispatch("companies/saveTariffImport", { companyId, tariffImport })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        saveTariffId(companyId, tariffId, eventTargetId) {
            this.$store.dispatch("companies/saveTariffId", { companyId, tariffId })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        deleteTariffImport(companyId, tariffImport, eventTargetId) {
            this.$store.dispatch("companies/deleteTariffImport", { companyId, tariffImport })
                .then(() => {
                    this.companySaved();
                    this.triggerSuccessEvent(eventTargetId);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                    this.triggerErrorEvent(error, eventTargetId);
                });
        },
        companySaved() {
            this.snackbarText = "Company was successfully saved.";
            this.snackbarColor = "success";
            this.snackbar = true;
            this.getCompany(this.company.id);
        },
        companyDeleted() {
            this.snackbarText = "Company was successfully deleted.";
            this.snackbarColor = "success";
            this.snackbar = true;
        },
        closeCompanyDialog() {
            this.companyDialog = false;
        },
        hideAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = false;
            }
        },
        showAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = true;
            }
        },
        companyStatusToString(status) {
            return CompanyStatusToString(status);
        },
        searchCompanies(searchParams) {
            this.searchDialog = false;
            this.searchParams = { ...this.searchParams, ...searchParams };
            this.$router.push({ path: "/companies", query: searchParams });
        }
    },
    created() {
        this.searchParams = this.$route.query;
        if (!this.searchParams.companyId) {
            this.searchParams = { ...this.defaultSearchParams, ...this.searchParams };
        }
        this.options = this.convertApiQueryToOptions(this.searchParams);
        this.getCompanies(this.searchParams);
    }
};
</script>

<style scoped>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
</style>
