var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      staticClass: "mx-3 mt-2",
      model: {
        value: _vm.searchParamsValid,
        callback: function($$v) {
          _vm.searchParamsValid = $$v
        },
        expression: "searchParamsValid"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.searchParamProperties,
                  label: "Hae käyttäen"
                },
                model: {
                  value: _vm.searchParamProperty,
                  callback: function($$v) {
                    _vm.searchParamProperty = $$v
                  },
                  expression: "searchParamProperty"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-slide-x-transition",
                { attrs: { group: "", "hide-on-leave": "" } },
                [
                  _vm.searchParamProperty === "OrderId"
                    ? _c("v-text-field", {
                        key: "1",
                        attrs: { label: "Tilaustunnus" },
                        model: {
                          value: _vm.orderId,
                          callback: function($$v) {
                            _vm.orderId = $$v
                          },
                          expression: "orderId"
                        }
                      })
                    : _vm._e(),
                  _vm.searchParamProperty === "RouteId"
                    ? _c("v-text-field", {
                        key: "2",
                        attrs: { label: "Reittitunnus" },
                        model: {
                          value: _vm.routeId,
                          callback: function($$v) {
                            _vm.routeId = $$v
                          },
                          expression: "routeId"
                        }
                      })
                    : _vm._e(),
                  _vm.searchParamProperty === "ReceiptNumber"
                    ? _c("v-text-field", {
                        key: "3",
                        attrs: { label: "Kuittinumero" },
                        model: {
                          value: _vm.receiptNumber,
                          callback: function($$v) {
                            _vm.receiptNumber = $$v
                          },
                          expression: "receiptNumber"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }