import { vehicleService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    // Vehicle
    async find(params) {
        return vehicleService.get(`${resource}/vehicles`, {
            params: params,
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getVehicle(vehicleId) {
        return vehicleService.get(`${resource}/vehicles/${vehicleId}`);
    },
    async create(vehicle) {
        return vehicleService.post(`${resource}/vehicles`, vehicle);
    },
    async postNewFleetMembership(vehicleId, fleetMembership) {
        return vehicleService.post(`${resource}/vehicles/${vehicleId}/fleets`, fleetMembership);
    },
    async patchFleetMembership(vehicleId, fleetMembership) {
        return vehicleService.patch(`${resource}/vehicles/${vehicleId}/fleets`, fleetMembership);
    },
    async deleteFleetMembership(vehicleId, companyId, fleetId) {
        return vehicleService.delete(`${resource}/vehicles/${vehicleId}/companies/${companyId}/fleets/${fleetId}`);
    },
    async patchContactInformation(vehicleId, contactInformation) {
        return vehicleService.patch(`${resource}/vehicles/${vehicleId}/contactinformation`, contactInformation);
    },
    async patchVehicleInfo(vehicleId, vehicleInfo) {
        return vehicleService.patch(`${resource}/vehicles/${vehicleId}/info`, vehicleInfo);
    },
    async delete(vehicleId) {
        return vehicleService.delete(`${resource}/vehicles/${vehicleId}`);
    },
    // Attributes
    async postNewAttribute(vehicleId, attribute) {
        return vehicleService.post(`${resource}/attributes/vehicle/${vehicleId}`, attribute);
    },
    async patchAttribute(vehicleId, attribute) {
        return vehicleService.patch(`${resource}/attributes/vehicle/${vehicleId}`, attribute);
    },
    async deleteAttribute(vehicleId, attribute) {
        return vehicleService.delete(`${resource}/attributes/vehicle/${vehicleId}/${attribute.origin}/${attribute.id}`);
    },
    // Properties
    async postNewProperty(vehicleId, property) {
        return vehicleService.post(`${resource}/configuration/vehicle/${vehicleId}`, property);
    },
    async patchProperty(vehicleId, property) {
        return vehicleService.patch(`${resource}/configuration/vehicle/${vehicleId}`, property);
    },
    async deleteProperty(vehicleId, property) {
        return vehicleService.delete(`${resource}/configuration/vehicle/${vehicleId}/${property.key}`);
    },
    // Tariffs
    async postNewTariffImport(vehicleId, tariffImport) {
        return vehicleService.post(`${resource}/tariffs/vehicle/${vehicleId}/imports`, tariffImport);
    },
    async patchTariffImport(vehicleId, tariffImport) {
        return vehicleService.patch(`${resource}/tariffs/vehicle/${vehicleId}/imports`, tariffImport);
    },
    async patchTariffId(vehicleId, tariffId) {
        return vehicleService.patch(`${resource}/tariffs/vehicle/${vehicleId}/id`, { tariffId });
    },
    async deleteTariffImport(vehicleId, tariffImport) {
        return vehicleService.delete(`${resource}/tariffs/vehicle/${vehicleId}/imports/${tariffImport.id}`);
    },
    // index
    async removeFromIndex(vehicleId) {
        return vehicleService.delete(`${resource}/vehicles/removefromindex/${vehicleId}`);
    },
    // Logs
    async getLogs(vehicleId, from, to) {
        return vehicleService.get(`${resource}/vehicles/${vehicleId}/logs`, {
            params: { from, to },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    }
};
