<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="headline">{{this.$t('invoicing.paytrail.createEvents')}}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="eventCount"
                                :label="this.$t('invoicing.paytrail.eventCount')"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                            :value="sum | formatCurrency"
                            :label="this.$t('invoicing.paytrail.sum')"
                            readonly
                            ></v-text-field>
                        </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="primary" :loading="btnLoading" @click="createEvents">{{$t('invoicing.paytrail.createEventsActionButton')}}</v-btn>
                <v-btn color="error" text @click="dialog = false">{{this.$t('common.close')}}</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn text @click="snackbar = false">
          {{$t("common.close")}}
        </v-btn>
      </v-snackbar>
    </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";

export default {
    data: () => ({
        dialog: false,
        events: [],
        eventCount: 0,
        sum: 0,
        snackbar: false,
        snackbarText: "",
        snackbarColor: "",
        eventType: "",
        btnLoading: false
    }),
    mixins: [ TransportEventStatusMixin ],
    methods: {
        openDialog(events, eventCount, sum) {
            this.events = events;
            this.eventCount = eventCount;
            this.sum = sum;
            this.dialog = true;
        },
        async createEvents() {
            this.btnLoading = true;
            await RepositoryFactory.get("invoicing")
                .createEventsFromPaytrailTransactions(this.events)
                .then(response => {
                    this.snackbarText = response.data;
                    this.snackbarColor = "success";
                    this.snackbar = true;

                    setTimeout(() => {
                        this.dialog = false;
                        this.btnLoading = false;
                        this.$router.push({
                            name: "Invoicing",
                            query:
              {
                  validationStates: this.validationStatusTypes.ExternalValidationFailed,
                  validationFailReasonCodes: "PaytrailValidationFailed"
              }
                        });
                    }, 6000);
                })
                .catch(error => {
                    this.snackbarText = error;
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.dialog = false;
                    this.btnLoading = false;
                    this.$emit("refreshTableData");
                });
        }
    }
};
</script>
