<template>
    <div id="missingVerifoneEvents">
      <v-card class="mt-1">
        <div class="elevation-1 mb-1 pb-2">
          <v-row justify="space-between">
            <v-col cols="auto">
              <v-card-title>
                {{$t("invoicing.verifone.missing.title")}}
                <v-icon right>mdi-archive-search-outline</v-icon>
              </v-card-title>
            </v-col>
            <v-col cols="auto">
              <v-select
              v-model="selectedHandlingTypes"
              :items="handlingTypes"
              multiple
              :label="$t('invoicing.verifone.missing.handlingStatus')">
              </v-select>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <v-card-actions>
              <v-text-field
                v-model="filter"
                append-icon="mdi-magnify"
                :label="this.$t('common.filter')"
                single-line
                hide-details
                class="pt-0 mt-0 mr-2">
              </v-text-field>
            </v-card-actions>
            </v-col>
          </v-row>
        </div>
        <CreateEventsFromMissingDialog
        ref="createEventsDialog"
        v-model="createEventsDialog"
        @refreshTableData="refreshData()"
        @closed="createEventsDialog = false">
        </CreateEventsFromMissingDialog>
        <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table" name="missingVerifoneEventsTable">
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="indexedMissingVerifoneEvents"
            item-key="id"
            v-model="selectedMissingVerifoneEvents"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :search="filter"
            show-select
            :loading="loadingPage"
            :footer-props="footerProps"
            :items-per-page="itemsPerPage">
            <template v-slot:item.amount="{ item }">
              {{ (item.transactionType === "REFUND" ? item.amount * -1 : item.amount) | formatCurrency }}
            </template>
            <template v-slot:item.transactionTimeStamp="{ item }">
              {{ item.transactionTimeStamp | formatDateTime }}
            </template>
            <template v-slot:item.handledAt="{ item }">
              {{ item.handledAt | formatDateTime }}
            </template>
            <template v-slot:item.handled="{ value }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" :color="value | handlingStatusToColor">{{ value | handlingStatusToIcon }}</v-icon>
                </template>
                <span>{{ value | handlingStatusToToolTipText }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-skeleton-loader>
        <v-row>
          <v-spacer></v-spacer>
          <v-col>
              <v-btn
                  :disabled="selectedMissingVerifoneEvents.length < 1"
                  color="success"
                  @click=showCreateEventsDialog
                  class="mb-3">
                  {{$t("invoicing.verifone.missing.createEvents")}}
                </v-btn>
              </v-col>
              <v-col v-if="selectedMissingVerifoneEvents.length > 0">
                <div class="text-md-body-1 font-weight-bold">Valittujen tapahtumien summa: {{ selectedTotalSum | formatCurrency }}
                <div v-if="selectedMissingVerifoneEvents.some(e => e.transactionType === 'REFUND')" class="text-md-body-1 font-weight-bold red--text">
                  Huom! Valittuihin tapahtumiin sisältyy palautuksia.
                </div>
              </div>
              </v-col>
          <v-spacer></v-spacer>

          </v-row>
        <v-snackbar v-model="snackbar" :color="snackbarColor">
          {{ snackbarText }}
          <v-btn text @click="snackbar = false">
            {{$t("common.close")}}
          </v-btn>
        </v-snackbar>
      </v-card>
    </div>
    </template>

<script>
import CreateEventsFromMissingDialog from "@/components/Invoicing/CreateEventsFromMissingDialog";

export default {
    data() {
        return {
            loading: true,
            selectedHandlingTypes: [0],
            handlingTypes: [
                { text: "Käsittelemätön", value: 0 },
                { text: "Käsitelty", value: 1 }
            ],
            selectedMissingVerifoneEvents: [],
            filter: "",
            sortBy: "serviceNumber",
            sortDesc: true,
            page: 1,
            footerProps: {
                itemsPerPageOptions: [10, 20, 50, -1]
            },
            itemsPerPage: 20,
            loadingPage: false,
            createEventsDialog: false,
            transportEventDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        allMissingVerifoneEvents() {
            return this.$store.state.missingVerifones.missingVerifoneEvents;
        },
        missingVerifoneEvents() {
            let events = this.allMissingVerifoneEvents;// .map(x => ({ ...x, isSelectable: !x.handled }));
            let showUnhandled = this.selectedHandlingTypes.some(val => val === 0);
            let showHandled = this.selectedHandlingTypes.some(val => val === 1);
            if (showUnhandled && showHandled) {
                return events;
            }
            if (showUnhandled) {
                return events.filter(vf => !vf.handled);
            }
            if (showHandled) {
                return events.filter(vf => vf.handled);
            }
            return [];
        },
        selectedTotalSum() {
            return this.selectedMissingVerifoneEvents.reduce((sum, e) => sum + (e.transactionType === "REFUND" ? e.amount * -1 : e.amount), 0);
        },
        indexedMissingVerifoneEvents() {
            return this.missingVerifoneEvents.map((e, i) => ({ ...e, id: i }));
        },
        headers() {
            let headers = [
                { text: "Palvelutunnus", value: "serviceNumber" },
                { text: "Käsitelty", value: "handled", width: 125 },
                { text: "Maksu tehty", value: "transactionTimeStamp", width: 125 },
                { text: "Summa", value: "amount" },
                { text: "Viite", value: "transactionReference" },
                { text: "Tapahtumatyyppi", value: "transactionType" }
            ];
            if (this.selectedHandlingTypes.some(val => val === 1)) {
                headers.splice(2, 0, { text: "Käsittely tehty", value: "handledAt", width: 1 });
            };
            return headers;
        },
        footerTotalSum() {
            if (this.invoiceEventType === "Likuma") {
                return this.readyForPurchaseInvoicingData?.totalReimbursementSum || 0;
            }
            if (this.invoiceEventType === "Korttimaksu") {
                return this.readyForPurchaseInvoicingData?.totalSum || 0;
            }
            return [];
        }
    },
    watch: {
        tenant(val) {
            this.getMissingVerifoneEvents();
        },
        itemsPerPage(val) {
            // this.loadMoreTransportEvents();
        },
        selectedHandlingTypes: {
            immediate: true,
            handler(val) {
                this.getMissingVerifoneEvents(val);
            }
        }
    },
    methods: {
        async getMissingVerifoneEvents(invoiceEventType) {
            this.selectedMissingVerifoneEvents = [];
            this.loading = true;
            this.$store.dispatch("missingVerifones/getAllMissingVerifoneEvents")
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        showCreateEventsDialog() {
            this.$refs.createEventsDialog.openDialog(this.selectedMissingVerifoneEvents,
                this.selectedMissingVerifoneEvents.length, this.selectedTotalSum);
        },
        refreshData() {
            this.loading = true;
            let timeout = 0;
            if (this.selectedMissingVerifoneEvents.length < 10) {
                timeout = 1000;
            } else {
                timeout = 5000;
            }
            setTimeout(() => {
                this.getMissingVerifoneEvents();
            }, timeout);
        }
    },
    filters: {
        handlingStatusToColor: function(value) {
            if (value) return "success";
            return "error";
        },
        handlingStatusToIcon: function(value) {
            if (value) return "mdi-check-circle-outline";
            return "mdi-close-circle-outline";
        },
        handlingStatusToToolTipText: function(value) {
            if (value) return "Käsitelty onnistuneesti";
            return "Käsittelemätön";
        }
    },
    components: { CreateEventsFromMissingDialog }
};
</script>
