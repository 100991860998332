<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-text>
        <v-form ref="form" v-if="editableFleetMembership">
          <v-row>
              <v-col cols="4">
                  <v-text-field
                      v-model="editableFleetMembership.companyId"
                      label="Company"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('companyId')"
                      :disabled="!isNew"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      v-model="editableFleetMembership.fleetId"
                      label="Fleet"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('fleetId')"
                  ></v-text-field>
              </v-col>
               <v-col cols="4">
                <v-checkbox
                  v-model="editableFleetMembership.isMain"
                  label="Is Main Fleet"
                ></v-checkbox>
              </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="addFleetMembership" v-if="isNew">Add</v-btn>
        <v-btn @click="saveFleetMembership" v-if="!isNew">Save</v-btn>
        <v-btn @click="$emit('cancel')">Cancel</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import InputValidationMixin from "../../Inputs/InputValidationMixin";
import EventListenerMixin from "../../../Mixins/EventListenerMixin";
import _ from "lodash";

export default {
    mixins: [ InputRuleMixin, InputValidationMixin, EventListenerMixin ],
    props: {
        fleetMembership: {
            type: Number,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean,
        result: {
            type: Object,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            editableFleetMembership: this.fleetMembership ? _.cloneDeep(this.fleetMembership) : {}
        };
    },
    watch: {
        fleetMembership(val) {
            this.editableFleetMembership = (!val) ? {} : _.cloneDeep(val);
        }
    },
    computed: {
        isNew() {
            return !this.fleetMembership;
        }
    },
    methods: {
        addFleetMembership: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("addFleetMembership", this.editableFleetMembership, this.eventTargetId);
        },
        saveFleetMembership: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("saveFleetMembership", this.editableFleetMembership, this.eventTargetId);
        },
        deleteFleetMembership: function() {
            this.$emit("deleteFleetMembership", this.editableFleetMembership, this.eventTargetId);
        },
        isEditable(property) {
            return property.origin === this.propertyOrigin;
        }
    },
    created() {
        this.$on("success", (e) => {
            this.$emit("cancel");
        });
    }
};
</script>
