export const CompanyStatus = {
    Unknown: 0,
    Initialized: 1,
    Active: 2,
    InActive: 3,
    Deleted: 4
};

export function CompanyStatusToString(companyStatus) {
    switch (companyStatus) {
    case CompanyStatus.Unknown:
        return "-";
    case CompanyStatus.Initialized:
        return "Initialized";
    case CompanyStatus.Active:
        return "Active";
    case CompanyStatus.InActive:
        return "In Active";
    case CompanyStatus.Deleted:
        return "Deleted";
    }
};
