export const TransportEventSalesInvoiceStates = {
    Unknown: 0,
    Unprocessed: 1,
    NotToBeInvoiced: 2,
    Accepted: 3,
    Denied: 4,
    Waiting: 5,
    Completed: 6,
    Corrected: 7,
    ReadyForSalesInvoice: 8
};

export const TransportEventPurchaseInvoiceStates = {
    Unknown: 0,
    Unprocessed: 1,
    NotToBeInvoiced: 2,
    Accepted: 3,
    Denied: 4,
    Waiting: 5,
    Completed: 6
};

export const KelaTransportEventSalesInvoiceStates = {
    Unknown: 0,
    Unprocessed: 1,
    Accepted: 3,
    Completed: 6
};

export const KelaTransportEventPurchaseInvoiceStates = {
    Unknown: 0,
    Unprocessed: 1,
    Accepted: 3,
    Completed: 6
};

export const TransportEventInvoicingMixin = {
    data() {
        return {
            salesInvoiceStates: TransportEventSalesInvoiceStates,
            purchaseInvoiceStates: TransportEventPurchaseInvoiceStates,
            kelaSalesInvoiceStates: KelaTransportEventSalesInvoiceStates,
            kelaPurchaseInvoiceStates: KelaTransportEventPurchaseInvoiceStates
        };
    },
    filters: {
        salesInvoiceStatusToText: function(value) {
            switch (value) {
            case TransportEventSalesInvoiceStates.Unknown:
                return "Not determined";
            case TransportEventSalesInvoiceStates.Unprocessed:
                return "Not invoiced yet";
            case TransportEventSalesInvoiceStates.NotToBeInvoiced:
                return "Not to be invoiced";
            case TransportEventSalesInvoiceStates.Accepted:
                return "Accepted to invoicing";
            case TransportEventSalesInvoiceStates.Denied:
                return "Invoicing denied";
            case TransportEventSalesInvoiceStates.Waiting:
                return "Invoicing in process";
            case TransportEventSalesInvoiceStates.Completed:
                return "Invoicing completed";
            case TransportEventSalesInvoiceStates.Corrected:
                return "Corrected";
            case TransportEventSalesInvoiceStates.ReadyForSalesInvoice:
                return "Ready for sales invoicing";
            };
        },
        salesInvoiceStatusToIcon: function(value) {
            switch (value) {
            case TransportEventSalesInvoiceStates.Unprocessed:
                return "mdi-new-box";
            case TransportEventSalesInvoiceStates.Waiting:
            case TransportEventSalesInvoiceStates.ReadyForSalesInvoice:
                return "mdi-av-timer";
            case TransportEventSalesInvoiceStates.Accepted:
            case TransportEventSalesInvoiceStates.Completed:
            case TransportEventSalesInvoiceStates.Corrected:
                return "mdi-check-circle-outline";
            case TransportEventSalesInvoiceStates.NotToBeInvoiced:
            case TransportEventSalesInvoiceStates.Denied:
            default: return "mdi-alert";
            };
        },
        salesInvoiceStatusToColor: function(value) {
            switch (value) {
            case TransportEventSalesInvoiceStates.Waiting:
            case TransportEventSalesInvoiceStates.ReadyForSalesInvoice:
            case TransportEventSalesInvoiceStates.NotToBeInvoiced:
                return "";
            case TransportEventSalesInvoiceStates.Unprocessed:
                return "primary";
            case TransportEventSalesInvoiceStates.Accepted:
            case TransportEventSalesInvoiceStates.Completed:
            case TransportEventSalesInvoiceStates.Corrected:
                return "success";
            case TransportEventSalesInvoiceStates.Denied:
            default: return "error";
            };
        },
        purchaseInvoiceStatusToText: function(value) {
            switch (value) {
            case TransportEventPurchaseInvoiceStates.Unknown:
                return "Not determined";
            case TransportEventPurchaseInvoiceStates.Unprocessed:
                return "Not invoiced yet";
            case TransportEventPurchaseInvoiceStates.NotToBeInvoiced:
                return "Not to be invoiced";
            case TransportEventPurchaseInvoiceStates.Accepted:
                return "Accepted to invoicing";
            case TransportEventPurchaseInvoiceStates.Denied:
                return "Invoicing denied";
            case TransportEventPurchaseInvoiceStates.Waiting:
                return "Invoicing in process";
            case TransportEventPurchaseInvoiceStates.Completed:
                return "Invoicing completed";
            };
        },
        purchaseInvoiceStatusToIcon: function(value) {
            switch (value) {
            case TransportEventPurchaseInvoiceStates.Unprocessed:
                return "mdi-new-box";
            case TransportEventPurchaseInvoiceStates.Waiting:
                return "mdi-av-timer";
            case TransportEventPurchaseInvoiceStates.Accepted:
            case TransportEventPurchaseInvoiceStates.Completed:
                return "mdi-check-circle-outline";
            case TransportEventPurchaseInvoiceStates.NotToBeInvoiced:
            case TransportEventPurchaseInvoiceStates.Denied:
            default: return "mdi-alert";
            };
        },
        purchaseInvoiceStatusToColor: function(value) {
            switch (value) {
            case TransportEventPurchaseInvoiceStates.Waiting:
            case TransportEventPurchaseInvoiceStates.NotToBeInvoiced:
                return "";
            case TransportEventPurchaseInvoiceStates.Unprocessed:
                return "primary";
            case TransportEventPurchaseInvoiceStates.Accepted:
            case TransportEventPurchaseInvoiceStates.Completed:
                return "success";
            case TransportEventPurchaseInvoiceStates.Denied:
            default: return "error";
            };
        }
    }
};
