<template>
  <div id="availability-response">{{response}}</div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
const availabilityRepository = RepositoryFactory.get("availability");

export default {
    data() {
        return {
            response: null
        };
    },
    created() {
        availabilityRepository.getPaymentManagementAvailability()
            .then(response => { this.response = response.data; });
    }
};
</script>

<style>

</style>
