export const TransportEventType = Object.freeze({
    Unknown: 0,
    Normal: 1,
    Likuma: 2,
    Kela: 3,
    TaxiPlus: 4,
    Receipt: 5,
    Keusote: 6
});

export const TransportEventTypeMixin = {
    data() {
        return {
            transportEventType: TransportEventType
        };
    },
    filters: {
        transportEventTypeToText: function(value) {
            switch (value) {
            case TransportEventType.Unknown:
                return "Unknown";
            case TransportEventType.Normal:
                return "Normal";
            case TransportEventType.Likuma:
                return "Likuma";
            case TransportEventType.Kela:
                return "Kela";
            case TransportEventType.TaxiPlus:
                return "TaxiPlus";
            case TransportEventType.Receipt:
                return "Receipt";
            case TransportEventType.Keusote:
                return "Keusote";
            default: return "Unknown";
            };
        }
    },
    methods: {
        eventTypeColor: function(value) {
            switch (value.eventType) {
            case TransportEventType.Unknown:
                return "#BDBDBD"; // grey
            case TransportEventType.Normal:
                return "#42A5F5"; // blue
            case TransportEventType.Likuma:
                return "#66BB6A"; // green
            case TransportEventType.Kela:
                return value.trip.carPool === "K"
                    ? "#FFA726" : "#FFEE58"; // true = orange, false =  yellow
            case TransportEventType.TaxiPlus:
                return "#FFC0CB"; // pink
            case TransportEventType.Receipt:
                return "#42A5F5"; // blue
            case TransportEventType.Keusote:
                return "#545454"; // black
            default:
                return "#BDBDBD"; // grey
            }
        },
        itemChipColor: function(value) {
            switch (value) {
            case TransportEventType.Unknown:
                return "#BDBDBD"; // grey
            case TransportEventType.Normal:
                return "#42A5F5"; // blue
            case TransportEventType.Likuma:
                return "#66BB6A"; // green
            case TransportEventType.Kela:
                return "#FFA726"; // yellow
            case TransportEventType.TaxiPlus:
                return "#FFC0CB"; // pink
            case TransportEventType.Receipt:
                return "#42A5F5"; // blue
            case TransportEventType.Keusote:
                return "#545454"; // black
            default:
                return "#BDBDBD"; // grey
            }
        },
        transportEventTypeToTranslationKey(value) {
            switch (value.eventType) {
            case TransportEventType.Unknown:
                return "invoicing.transportEventTypes.unknown";
            case TransportEventType.Normal:
                return "invoicing.transportEventTypes.normal";
            case TransportEventType.Likuma:
                return "invoicing.transportEventTypes.likuma.title";
            case TransportEventType.Kela:
                return value.trip.carPool === "K" ? "invoicing.transportEventTypes.kela.kelaComboTrip" : "invoicing.transportEventTypes.kela.title";
            case TransportEventType.TaxiPlus:
                return "invoicing.transportEventTypes.taxiPlus.title";
            case TransportEventType.Receipt:
                return "invoicing.transportEventTypes.receipt.title";
            case TransportEventType.Keusote:
                return "invoicing.transportEventTypes.keusote.title";
            default: return "invoicing.transportEventTypes.unknown";
            };
        },
        transportEventTypeKeyToTranslationText(value) {
            switch (value) {
            case TransportEventType.Unknown:
                return "invoicing.transportEventTypes.unknown";
            case TransportEventType.Normal:
                return "invoicing.transportEventTypes.normal";
            case TransportEventType.Likuma:
                return "invoicing.transportEventTypes.likuma.title";
            case TransportEventType.Kela:
                return "invoicing.transportEventTypes.kela.title";
            case TransportEventType.TaxiPlus:
                return "invoicing.transportEventTypes.taxiPlus.title";
            case TransportEventType.Receipt:
                return "invoicing.transportEventTypes.receipt.title";
            case TransportEventType.Keusote:
                return "invoicing.transportEventTypes.keusote.title";
            default:
                return "invoicing.transportEventTypes.unknown";
            };
        }
    }
};
