<template>
 <v-dialog
    v-model="show"
    max-width="1000">
    <v-card
      v-if="show">
        <v-toolbar short dark color="primary">
          <v-btn icon dark @click="cancel">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
              Search
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="searchParamsValid" class="mx-3 mt-2">
            <v-row align="center" class="mt-3">
              <v-col cols=12 md=4>
                <v-select
                  :items="operators"
                  v-model="editableSearchParams.companyId"
                  label="Id"
                ></v-select>
              </v-col>
              <v-col cols=12 md=4>
                <v-text-field
                    v-model="editableSearchParams.fleetId"
                    label="Fleet Id"
                ></v-text-field>
              </v-col>
              <v-col cols=12 md=4>
                <v-text-field
                    v-model="editableSearchParams.name"
                    label="Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-3">
              <v-col cols=12 md=4>
                <v-select
                  :items="fleetStatuses"
                  v-model="editableSearchParams.fleetStatus"
                  label="Fleet Status"
                >
                  <template v-slot:selection="{ item }">
                    {{ fleetStatusToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ fleetStatusToString(item) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="search">
              {{$t("common.search")}}
            </v-btn>
            <v-btn
              @click="cancel"
              class="ml-2">
              {{$t("common.cancel")}}
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { FleetStatus, FleetStatusToString } from "../../../models/FleetStatus";
import _ from "lodash";

export default {
    props: {
        show: Boolean,
        searchParams: Object
    },
    data() {
        return {
            editableSearchParams: _.cloneDeep(this.searchParams),
            searchParamsValid: true,
            fleetStatuses: Object.values(FleetStatus)
        };
    },
    watch: {
        searchParams(val) {
            if (!val) {
                this.vehicleId = null;
            } else {
                this.vehicleId = _.cloneDeep(val.vehicleId);
            }
        }
    },
    computed: {
        operators() {
            return this.$store.state.user.operators;
        }
    },
    methods: {
        getSearchParameters() {
            switch (this.searchParamProperty) {
            case "VehicleId":
                return { vehicleId: this.vehicleId };
            }
            return {};
        },
        search() {
            let params = _.cloneDeep(this.editableSearchParams);
            this.$emit("search", params);
        },
        cancel() {
            this.searchParams = this.searchParams;
            this.$emit("close");
        },
        fleetStatusToString(status) {
            return FleetStatusToString(status);
        }
    }
};
</script>
