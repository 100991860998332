<template>
   <v-container fluid>
    <v-combobox
      v-model="model"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      :search-input.sync="search"
      :input="$emit('citiesUpdated', selectedCities)"
      hide-selected
      label="Search for a City"
      multiple
      small-chips
      solo
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading">Create </span>
          <v-chip
            :color="`${colors[nonce - 1]} lighten-3`"
            label
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color} lighten-3`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon
            small
            @click="parent.selectItem(item)"
          >close</v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-text-field
          v-if="editing === item"
          v-model="editing.text"
          autofocus
          flat
          background-color="transparent"
          hide-details
          solo
          @keyup.enter="edit(index, item)"
        ></v-text-field>
        <v-chip
          v-else
          :color="`${item.color} lighten-3`"
          dark
          label
          small
        >
          {{ item.text }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-list-item-action @click.stop>
          <v-btn
            icon
            @click.stop.prevent="edit(index, item)"
          >
            <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </v-container>
</template>

<script>
export default {
    name: "CitySelector",
    data: () => ({
        activator: null,
        attach: null,
        colors: ["green", "purple", "indigo", "cyan", "teal", "orange"],
        editing: null,
        index: -1,
        items: [
            { header: "Select a City or create one." },
            {
                text: "Helsinki",
                color: "blue"
            },
            {
                text: "Porvoo",
                color: "red"
            },
            {
                text: "Espoo",
                color: "indigo"
            },
            {
                text: "Utsjoki",
                color: "blue"
            },
            {
                text: "Sipoo",
                color: "red"
            },
            {
                text: "Pietarsaari",
                color: "orange"
            },
            {
                text: "Kirkkonummi",
                color: "orange"
            }
        ],
        nonce: 1,
        menu: false,
        model: [
            {
                text: "Helsinki",
                color: "blue"
            }
        ],
        x: 0,
        search: null,
        y: 0
    }),

    watch: {
        model(val, prev) {
            if (val.length === prev.length) return;

            this.model = val.map(v => {
                if (typeof v === "string") {
                    v = {
                        text: v,
                        color: this.colors[this.nonce - 1]
                    };

                    this.items.push(v);

                    this.nonce++;
                }

                return v;
            });
        }
    },
    computed: {
        selectedCities() {
            let cities = this.model.map(c => c.text);
            return cities;
        }
    },
    methods: {
        cityUpdated() {
            this.$emit("modified", this.model);
        },
        edit(index, item) {
            if (!this.editing) {
                this.editing = item;
                this.index = index;
            } else {
                this.editing = null;
                this.index = -1;
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false;

            const hasValue = val => val != null ? val : "";

            const text = hasValue(itemText);
            const query = hasValue(queryText);

            return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1;
        }
    }
};
</script>

<style>

</style>
