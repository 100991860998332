<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="company">
        <v-col
        class="pa-0">
          <v-card>
            <v-toolbar short dark color="primary">
              <v-btn icon dark @click="$emit('close')">
                  <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                Company - {{company.id}}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-tabs
                v-model="tab"
                fixed-tabs
                background-color="indigo"
                dark
            >
              <v-tab href="#tab-details">
                Details
              </v-tab>
              <v-tab href="#tab-attributes">
                Attributes
              </v-tab>
              <v-tab href="#tab-properties">
                Properties
              </v-tab>
               <v-tab href="#tab-tariffs">
                Tariffs
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab">
              <v-tab-item
                :key="1"
                :value="'tab-details'">
                <v-card>
                  <v-card-title>Company Info</v-card-title>
                  <v-card-text>
                    <CompanyBasicInfoForm
                      :company="company"
                      :editable="true"
                      @save="saveCompanyInfo">>
                    </CompanyBasicInfoForm>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-title>Contact Information</v-card-title>
                  <v-card-text>
                    <ContactInformationForm
                      :contactInformation="company.contactInformation"
                      :editable="true"
                      @save="saveContactInformation">
                    </ContactInformationForm>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'tab-attributes'">
                <CompanyAttributesForm
                  :company="company"
                  @addNewAttribute="addNewAttribute"
                  @saveAttribute="saveAttribute"
                  @deleteAttribute="deleteAttribute">
                </CompanyAttributesForm>
              </v-tab-item>
              <v-tab-item
                :key="3"
                :value="'tab-properties'">
                <CompanyPropertiesForm
                  :company="company"
                  @addNewProperty="addNewProperty"
                  @saveProperty="saveProperty"
                  @deleteProperty="deleteProperty">
                </CompanyPropertiesForm>
              </v-tab-item>
              <v-tab-item
                :key="4"
                :value="'tab-tariffs'">
                <CompanyTariffsForm
                  :company="company"
                  @addNewTariffImport="addNewTariffImport"
                  @saveTariffImport="saveTariffImport"
                  @saveTariffId="saveTariffId"
                  @deleteTariffImport="deleteTariffImport">
                </CompanyTariffsForm>
              </v-tab-item>
            </v-tabs-items>
            </v-card-text>
            </v-card>
          </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import moment from "moment";
import ContactInformationForm from "../../Shared/Forms/ContactInformationForm";
import CompanyBasicInfoForm from "../Forms/CompanyBasicInfoForm";
import CompanyAttributesForm from "../Forms/CompanyAttributesForm";
import CompanyPropertiesForm from "../Forms/CompanyPropertiesForm";
import CompanyTariffsForm from "../Forms/CompanyTariffsForm";

export default {
    components: {
        ContactInformationForm,
        CompanyBasicInfoForm,
        CompanyAttributesForm,
        CompanyPropertiesForm,
        CompanyTariffsForm
    },
    props: {
        company: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            tab: null
        };
    },
    methods: {
        saveContactInformation: function(contactInformation) {
            this.$emit("saveContactInformation", this.company.id, contactInformation);
        },
        saveCompanyInfo: function(companyInfo) {
            this.$emit("saveCompanyInfo", this.company.id, companyInfo);
        },
        // Attributes
        addNewAttribute: function(attribute, eventTargetId) {
            this.$emit("addNewAttribute", this.company.id, attribute, eventTargetId);
        },
        saveAttribute: function(attribute, eventTargetId) {
            this.$emit("saveAttribute", this.company.id, attribute, eventTargetId);
        },
        deleteAttribute: function(attribute, eventTargetId) {
            this.$emit("deleteAttribute", this.company.id, attribute, eventTargetId);
        },
        // Properties
        addNewProperty: function(property, eventTargetId) {
            this.$emit("addNewProperty", this.company.id, property, eventTargetId);
        },
        saveProperty: function(property, eventTargetId) {
            this.$emit("saveProperty", this.company.id, property, eventTargetId);
        },
        deleteProperty: function(property, eventTargetId) {
            this.$emit("deleteProperty", this.company.id, property, eventTargetId);
        },
        // Tariffs
        addNewTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("addNewTariffImport", this.company.id, tariffImport, eventTargetId);
        },
        saveTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("saveTariffImport", this.company.id, tariffImport, eventTargetId);
        },
        saveTariffId: function(tariffId, eventTargetId) {
            this.$emit("saveTariffId", this.company.id, tariffId, eventTargetId);
        },
        deleteTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("deleteTariffImport", this.company.id, tariffImport, eventTargetId);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
