<template>
    <v-container fluid>
        <h1>Pricing</h1>
        <v-skeleton-loader
            :loading="loading"
            :transition-group="'scale-transition'"
            type="table">
            <v-row>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="openNewPriceCatalogDialog()"
                    >
                    <v-icon left>mdi-plus</v-icon>
                    Add new catalog
                </v-btn>
            </v-row>
            <div>
              <label v-if="operators.length === 1">{{ operators[0] }}</label>
              <v-select
                v-else-if="operators.length > 1"
                :label="$t('userManagement.operator')"
                :items="operators"
                :value="operator"
                @change="changeOperator"
              ></v-select>
            </div>
            <v-select
                :items="priceCatalogs"
                v-model="selectedPriceCatalog"
                item-value="id"
                label="Price catalog"
                @change="changePriceCatalog"
            >
            <template slot="selection" slot-scope="data">
                {{ data.item.name }} (OperatorId: {{ data.item.operatorId }}, ProductId: {{ data.item.productId }})
            </template>
            <template slot="item" slot-scope="data">
                {{ data.item.name }} (OperatorId: {{ data.item.operatorId }}, ProductId: {{ data.item.productId }})
            </template>
            </v-select>
            <template v-if="selectedPriceCatalog !== null">
                <PriceCatalogInfo
                    ref="catalogInfo"
                    :priceCatalog="selectedPriceCatalog"
                    @catalog-info-updated="updateSelectedCatalogInfo"
                ></PriceCatalogInfo>
                <PricePanels
                    ref="baseFees"
                    header="Base fees"
                    :prices="selectedPriceCatalog.baseFeePrices ? selectedPriceCatalog.baseFeePrices.priceCatalogItems : null"
                    :allowedRuleTypes="[ruleTypes.DayTypeTimeRange, ruleTypes.CarType, ruleTypes.PassengerCount]"
                    class="mb-5"
                    @update="updateBaseFees"
                ></PricePanels>
                <PricePanels
                    ref="travelFeeKilometers"
                    header="Travel fees (kilometers)"
                    :prices="selectedPriceCatalog.kilometerPrices ? selectedPriceCatalog.kilometerPrices.priceCatalogItems: null"
                    :allowedRuleTypes="[ruleTypes.DayTypeTimeRange, ruleTypes.CarType, ruleTypes.PassengerCount, ruleTypes.Range]"
                    class="mb-5"
                    @update="updateTravelFeesKilometers"
                ></PricePanels>
                <PricePanels
                    ref="travelFeeMinutes"
                    header="Travel fees (minutes)"
                    :prices="selectedPriceCatalog.minutePrices ? selectedPriceCatalog.minutePrices.priceCatalogItems : null"
                    :allowedRuleTypes="[ruleTypes.DayTypeTimeRange, ruleTypes.CarType, ruleTypes.PassengerCount]"
                    class="mb-5"
                    @update="updateTravelFeesMinutes"
                ></PricePanels>
                <PricePanels
                    ref="preBookFees"
                    header="Pre-book fees"
                    :prices="selectedPriceCatalog.preBookPrices ? selectedPriceCatalog.preBookPrices.priceCatalogItems : null"
                    :allowedRuleTypes="[ruleTypes.PreBooked, ruleTypes.DayTypeTimeRange, ruleTypes.CarType, ruleTypes.PassengerCount]"
                    class="mb-5"
                    @update="updatePreBookFees"
                ></PricePanels>
                <PricePanels
                    ref="fixedPrices"
                    header="Fixed prices"
                    :prices="selectedPriceCatalog.fixedPrices ? selectedPriceCatalog.fixedPrices.priceCatalogItems : null"
                    :allowedRuleTypes="[ruleTypes.FixedRoute]"
                    class="mb-5"
                    @update="updateFixedPrices"
                ></PricePanels>
                <PricePanels
                    ref="extraPrices"
                    header="Extra prices"
                    :prices="selectedPriceCatalog.extraPrices ? selectedPriceCatalog.extraPrices.priceCatalogItems : null"
                    :allowedRuleTypes="[ruleTypes.StairClimber, ruleTypes.StairAssistWithoutClimber, ruleTypes.UnobstructedExtraRule, ruleTypes.ElevatorExtraRule, ruleTypes.AssistToDoor]"
                    @update="updateExtraPrices"
                ></PricePanels>
            </template>
        </v-skeleton-loader>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
        <PriceCatalogDialog
            ref="priceCatalogDialog"
            :isOpen="createPriceCatalogDialog"
            :priceCatalogs="priceCatalogs"
            @create="createPriceCatalog"
            @close="closePriceCatalogDialog"
        ></PriceCatalogDialog>
    </v-container>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";
import PricePanels from "../components/Pricing/PricePanels";
import PriceCatalogInfo from "../components/Pricing/PriceCatalogInfo";
import PriceCatalogDialog from "../components/Pricing/PriceCatalogDialog";
import { PriceRuleType } from "../models/PriceRuleTypes";
import _ from "lodash";

const PricingRepository = RepositoryFactory.get("pricing");

export default {
    components: {
        PriceCatalogInfo,
        PricePanels,
        PriceCatalogDialog
    },
    data() {
        return {
            loading: true,
            priceCatalogs: [],
            selectedPriceCatalog: null,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            updating: false,
            createPriceCatalogDialog: false,
            ruleTypes: PriceRuleType
        };
    },
    computed: {
        operators() {
            return this.$store.state.user.operators;
        },
        operator() {
            return this.$store.state.user.operator;
        }
    },
    watch: {
        operator(val) {
            this.getPriceCatalogs();
        }
    },
    methods: {
        async getPriceCatalogs() {
            this.loading = true;
            PricingRepository.find(this.operator)
                .then(response => {
                    let catalogs = response.data;
                    if (catalogs && catalogs.length > 0) {
                        catalogs.sort(function(a, b) {
                            if (a.name < b.name) { return -1; }
                            if (a.name > b.name) { return 1; }
                            return 0;
                        });
                        this.priceCatalogs = catalogs;
                        this.selectedPriceCatalog = this.priceCatalogs[0];
                    } else {
                        this.priceCatalogs = [];
                        this.selectedPriceCatalog = null;
                    }
                })
                .catch(e => {
                    this.showErrorMessage(e);
                });
            this.loading = false;
        },
        changePriceCatalog(value) {
            this.selectedPriceCatalog = this.priceCatalogs.find(p => p.id === value);
        },
        createPriceCatalog(value, previousCatalogId = null) {
            value.id = this.generateGuidV4();
            PricingRepository.create(value)
                .then(response => {
                    let newPriceCatalog = response.data;
                    this.priceCatalogs.push(newPriceCatalog);
                    this.selectedPriceCatalog = newPriceCatalog;
                    this.showSuccessMessage("Created price catalog");
                    if (previousCatalogId) {
                        let previousCatalog = this.priceCatalogs.find(p => p.id === previousCatalogId);
                        let updatedValidThrough = {
                            validThrough: {
                                startTime: previousCatalog.validThrough.startTime,
                                endTime: newPriceCatalog.validThrough.startTime
                            }
                        };
                        this.updateCatalogInfo(previousCatalog, updatedValidThrough);
                    }
                    this.$refs.priceCatalogDialog.closeDialog();
                })
                .catch(e => {
                    this.showErrorMessage(e);
                    this.$refs.priceCatalogDialog.isUpdating = false;
                });
        },
        updateSelectedCatalogInfo(value) {
            this.updateCatalogInfo(this.selectedPriceCatalog, value);
        },
        updateCatalogInfo(catalog, newInfo) {
            let updatedPriceCatalog = _.cloneDeep(catalog);
            Object.assign(updatedPriceCatalog, newInfo);
            PricingRepository.update(updatedPriceCatalog.id, updatedPriceCatalog)
                .then(response => {
                    Object.assign(catalog, updatedPriceCatalog);
                    this.$refs.catalogInfo.closeDialog();
                    this.showSuccessMessage("Updated price catalog");
                })
                .catch(e => {
                    this.showErrorMessage(e);
                    this.$refs.catalogInfo.isUpdating = false;
                });
        },
        showSuccessMessage(message) {
            this.snackbar = true;
            this.snackbarColor = "success";
            this.snackbarText = message;
        },
        showErrorMessage(message) {
            this.snackbar = true;
            this.snackbarColor = "error";
            this.snackbarText = message;
        },
        openNewPriceCatalogDialog() {
            this.createPriceCatalogDialog = true;
        },
        closePriceCatalogDialog() {
            this.createPriceCatalogDialog = false;
        },
        updatePreBookFees(prices) {
            let updatedPriceCatalog = _.cloneDeep(this.selectedPriceCatalog);
            if (!updatedPriceCatalog.preBookPrices) {
                updatedPriceCatalog.preBookPrices = { priceCatalogItemContainerType: "PreBookPriceCatalogContainer" };
            }
            updatedPriceCatalog.preBookPrices.priceCatalogItems = prices;
            let success = this.updatePriceCatalogToApi(updatedPriceCatalog);
            if (success) {
                this.$refs.preBookFees.updateSucceeded();
            } else {
                this.$refs.preBookFees.updateFailed();
            }
        },
        updateBaseFees(prices) {
            let updatedPriceCatalog = _.cloneDeep(this.selectedPriceCatalog);
            if (!updatedPriceCatalog.baseFeePrices) {
                updatedPriceCatalog.baseFeePrices = { priceCatalogItemContainerType: "BaseFeePriceCatalogContainer" };
            }
            updatedPriceCatalog.baseFeePrices.priceCatalogItems = prices;
            let success = this.updatePriceCatalogToApi(updatedPriceCatalog);
            if (success) {
                this.$refs.baseFees.updateSucceeded();
            } else {
                this.$refs.baseFees.updateFailed();
            }
        },
        updateFixedPrices(prices) {
            let updatedPriceCatalog = _.cloneDeep(this.selectedPriceCatalog);
            if (!updatedPriceCatalog.fixedPrices) {
                updatedPriceCatalog.fixedPrices = { priceCatalogItemContainerType: "FixedPriceCatalogContainer" };
            }
            updatedPriceCatalog.fixedPrices.priceCatalogItems = prices;
            let success = this.updatePriceCatalogToApi(updatedPriceCatalog);
            if (success) {
                this.$refs.fixedPrices.updateSucceeded();
            } else {
                this.$refs.fixedPrices.updateFailed();
            }
        },
        updateExtraPrices(prices) {
            let updatedPriceCatalog = _.cloneDeep(this.selectedPriceCatalog);
            if (!updatedPriceCatalog.extraPrices) {
                updatedPriceCatalog.extraPrices = { priceCatalogItemContainerType: "ExtraPriceCatalogContainer" };
            }
            updatedPriceCatalog.extraPrices.priceCatalogItems = prices;
            let success = this.updatePriceCatalogToApi(updatedPriceCatalog);
            if (success) {
                this.$refs.extraPrices.updateSucceeded();
            } else {
                this.$refs.extraPrices.updateFailed();
            }
        },
        updateTravelFeesKilometers(prices) {
            let updatedPriceCatalog = _.cloneDeep(this.selectedPriceCatalog);
            if (!updatedPriceCatalog.kilometerPrices) {
                updatedPriceCatalog.kilometerPrices = { priceCatalogItemContainerType: "KilometerPriceCatalogContainer" };
            }
            updatedPriceCatalog.kilometerPrices.priceCatalogItems = prices;
            let success = this.updatePriceCatalogToApi(updatedPriceCatalog);
            if (success) {
                this.$refs.travelFeeKilometers.updateSucceeded();
            } else {
                this.$refs.travelFeeKilometers.updateFailed();
            }
        },
        updateTravelFeesMinutes(prices) {
            let updatedPriceCatalog = _.cloneDeep(this.selectedPriceCatalog);
            if (!updatedPriceCatalog.minutePrices) {
                updatedPriceCatalog.minutePrices = { priceCatalogItemContainerType: "MinutePriceCatalogContainer" };
            }
            updatedPriceCatalog.minutePrices.priceCatalogItems = prices;
            let success = this.updatePriceCatalogToApi(updatedPriceCatalog);
            if (success) {
                this.$refs.travelFeeMinutes.updateSucceeded();
            } else {
                this.$refs.travelFeeMinutes.updateFailed();
            }
        },
        async updatePriceCatalogToApi(priceCatalog) {
            PricingRepository.update(priceCatalog.id, priceCatalog)
                .then(response => {
                    let index = this.priceCatalogs.findIndex(p => p.id === priceCatalog.id);
                    this.$set(this.priceCatalogs, index, priceCatalog);
                    this.changePriceCatalog(priceCatalog.id);
                    this.showSuccessMessage("Updated price catalog");
                    return true;
                })
                .catch(e => {
                    this.showErrorMessage(e);
                    return false;
                });
        },
        generateGuidV4() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        async changeOperator(operatorId) {
            this.$store.dispatch("user/setOperator", operatorId);
        }
    },
    created() {
        this.getPriceCatalogs();
    }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
