import { driverService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    // Driver
    async find(params, top, page = 1) {
        return driverService.get(`${resource}/drivers`, {
            params: {
                ...params,
                top,
                page
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getDriver(driverId) {
        return driverService.get(`${resource}/drivers/${driverId}`);
    },
    async create(driver) {
        return driverService.post(`${resource}/drivers`, driver);
    },
    async patchContactInformation(driverId, contactInformation) {
        return driverService.patch(`${resource}/drivers/${driverId}/contactinformation`, contactInformation);
    },
    async patchDriverInfo(driverId, driverInfo) {
        return driverService.patch(`${resource}/drivers/${driverId}/info`, driverInfo);
    },
    async delete(driverId) {
        return driverService.delete(`${resource}/drivers/${driverId}`);
    },
    // Attributes
    async postNewAttribute(driverId, attribute) {
        return driverService.post(`${resource}/attributes/driver/${driverId}`, attribute);
    },
    async patchAttribute(driverId, attribute) {
        return driverService.patch(`${resource}/attributes/driver/${driverId}`, attribute);
    },
    async deleteAttribute(driverId, attribute) {
        return driverService.delete(`${resource}/attributes/driver/${driverId}/${attribute.origin}/${attribute.id}`);
    },
    // Properties
    async postNewProperty(driverId, property) {
        return driverService.post(`${resource}/configuration/driver/${driverId}`, property);
    },
    async patchProperty(driverId, property) {
        return driverService.patch(`${resource}/configuration/driver/${driverId}`, property);
    },
    async deleteProperty(driverId, property) {
        return driverService.delete(`${resource}/configuration/driver/${driverId}/${property.key}`);
    }
};
