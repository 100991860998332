<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="booking">
        <v-col
        class="pa-0">
          <v-card>
            <v-toolbar short dark color="primary">
                <v-card-title>
                    <span>Booking {{booking.id}}</span>
                    <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.cancel")}}
                </v-btn>
                </v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-tabs
                v-model="tab"
                fixed-tabs
                background-color="indigo"
                dark
            >
              <v-tab href="#tab-details">
                Details
              </v-tab>
              <v-tab href="#tab-route">
                <MapBox
                  :routes="[trip.actualRoute, trip.plannedRoute]"
                ></MapBox>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab">
              <v-tab-item
                :key="1"
                :value="'tab-details'">
                <v-card>
                  <v-card-title>Booking Info</v-card-title>
                  <v-card-text>
                    TODO
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'tab-route'">
                ROUTE
              </v-tab-item>
            </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    props: {
        booking: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            tab: null
        };
    },
    methods: {
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
