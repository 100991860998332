export const FareType = {
    Unknown: 0,
    Single: 1,
    Double: 2,
    Fixed: 3,
    Wait: 4
};

export const FareTypeMitax = {
    Single: "S",
    Double: "D",
    Fixed: "F",
    Wait: "O" // Odotus
};

export const FareTypeMixin = {
    data() {
        return {
            fareTypes: FareType,
            fareTypesMitax: FareTypeMitax
        };
    },
    filters: {
        fareTypeToText: function(value) {
            switch (value) {
            case FareType.Unknown:
                return "Unknown";
            case FareType.Single:
            case FareTypeMitax.Single:
                return "Single";
            case FareType.Double:
            case FareTypeMitax.Double:
                return "Double";
            case FareType.Fixed:
            case FareTypeMitax.Fixed:
                return "Fixed";
            case FareType.Wait:
            case FareTypeMitax.Wait:
                return "Wait";
            }
        },
        fareToToolTipText: function(value) {
            let vat = !value.vat ? "" : ", Vat: " + value.vat;
            return "Sum: " + value.price + vat;
        }
    }
};
