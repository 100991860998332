var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "readyForPurchaseInvoicing" } },
    [
      _c(
        "v-card",
        { staticClass: "mt-1" },
        [
          _c(
            "div",
            { staticClass: "elevation-1 mb-1 pb-2" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invoicing.purchasing.creationTitle")
                              ) +
                              " "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-archive-search-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.invoiceEventTypes,
                          label: _vm.$t(
                            "invoicing.purchasing.invoiceEventTypes"
                          )
                        },
                        model: {
                          value: _vm.invoiceEventType,
                          callback: function($$v) {
                            _vm.invoiceEventType = $$v
                          },
                          expression: "invoiceEventType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-card-subtitle",
                        {
                          staticClass: "d-flex flex-row-reverse",
                          staticStyle: { "white-space": "pre" }
                        },
                        [_vm._v(" " + _vm._s(_vm.lastInvoiceCreated) + " ")]
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.purchaseInvoicesPaid,
                                color: "success"
                              },
                              on: { click: _vm.showSendToPurchasingDialog }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "invoicing.purchasing.sendToPurchasing"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("SendToPurchasingDialog", {
            ref: "purchasingDialog",
            on: {
              closed: function($event) {
                _vm.sendToPurchasingDialog = false
              }
            },
            model: {
              value: _vm.sendToPurchasingDialog,
              callback: function($$v) {
                _vm.sendToPurchasingDialog = $$v
              },
              expression: "sendToPurchasingDialog"
            }
          }),
          _c(
            "v-skeleton-loader",
            {
              attrs: {
                loading: _vm.loading,
                "transition-group": "scale-transition",
                type: "table",
                name: "readyForPurchaseInvoicingTable"
              }
            },
            [
              _c("v-data-table", {
                attrs: {
                  "calculate-widths": "",
                  headers: _vm.showableHeaders,
                  items: _vm.readyForPurchaseInvoicingRowData,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  loading: _vm.loadingPage,
                  page: _vm.page,
                  "items-per-page": _vm.itemsPerPage,
                  "footer-props": {
                    pagination: _vm.pagination,
                    itemsPerPageOptions: _vm.itemsPerPageOptions
                  }
                },
                on: {
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:itemsPerPage": function($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:items-per-page": function($event) {
                    _vm.itemsPerPage = $event
                  },
                  "click:row": _vm.searchEvents
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.reimbursementSum",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatCurrency")(item.reimbursementSum)
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "item.totalSum",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("formatCurrency")(item.totalSum)) +
                              " "
                          )
                        ]
                      }
                    },
                    _vm.invoiceEventType == "Likuma"
                      ? {
                          key: "item.salesInvoiceNumbers",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                _vm._l(item.salesInvoiceNumbers, function(
                                  invoiceNumber,
                                  index
                                ) {
                                  return _c(
                                    "a",
                                    {
                                      key: index,
                                      staticClass: "pr-2 text-decoration-none",
                                      attrs: {
                                        href:
                                          "/invoicing?salesInvoiceNumber=" +
                                          invoiceNumber
                                      },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.searchEventsBySalesInvoiceNumber(
                                            invoiceNumber
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(invoiceNumber) + " ")]
                                  )
                                }),
                                0
                              )
                            ]
                          }
                        }
                      : null,
                    {
                      key: "body.append",
                      fn: function() {
                        return [
                          _vm.readyForPurchaseInvoicingRowData != null
                            ? _c(
                                "tr",
                                { staticStyle: { outline: "thick solid" } },
                                [
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-md-body-1 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.footerTotalServiceNumberCount
                                        ) + " kpl"
                                      )
                                    ]
                                  ),
                                  _c("th", {
                                    staticClass:
                                      "text-md-body-1 font-weight-bold"
                                  }),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-md-body-1 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.footerTotalTransportEventCount
                                        ) + " kpl"
                                      )
                                    ]
                                  ),
                                  _c(
                                    "th",
                                    {
                                      staticClass:
                                        "text-md-body-1 font-weight-bold"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatCurrency")(
                                            _vm.footerTotalSum
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _vm.invoiceEventType == "Likuma"
                                    ? _c(
                                        "th",
                                        {
                                          staticClass:
                                            "text-md-body-1 font-weight-bold"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.footerTotalSalesInvoiceNumberCount
                                              ) +
                                              " kpl "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbarColor },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }