<template>
  <PropertiesTable
    :properties="editableDriver.properties"
    :editablePropertyOrigin="editablePropertyOrigin"
    @cancel="isEditFormOpen=false"
    @addNewProperty="addNewProperty"
    @saveProperty="saveProperty"
    @deleteProperty="deleteProperty">
  </PropertiesTable>
</template>

<script>
import PropertiesTable from "../../Shared/PropertiesTable";
import { PropertyOrigin } from "../../../models/PropertyOrigin";
import _ from "lodash";

export default {
    components: { PropertiesTable },
    props: {
        driver: {
            type: Object,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            editableDriver: _.cloneDeep(this.driver),
            editablePropertyOrigin: PropertyOrigin.DriverConfiguration,
            selectedProperty: null,
            isEditFormOpen: false
        };
    },
    watch: {
        driver(val) {
            this.editableDriver = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        addNewProperty: function(property) {
            this.$emit("addNewProperty", property);
        },
        saveProperty: function(property) {
            this.$emit("saveProperty", property);
        },
        deleteProperty: function(property) {
            this.$emit("deleteProperty", property);
        }
    }
};
</script>
