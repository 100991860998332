<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="driver">
        <v-col
        class="pa-0">
          <v-card>
            <v-toolbar short dark color="primary">
                <v-card-title>
                    <span>Driver {{driver.id}}</span>
                    <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.cancel")}}
                </v-btn>
                </v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-tabs
                v-model="tab"
                fixed-tabs
                background-color="indigo"
                dark
            >
              <v-tab href="#tab-details">
                Details
              </v-tab>
              <v-tab href="#tab-attributes">
                Attributes
              </v-tab>
              <v-tab href="#tab-properties">
                Properties
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab">
              <v-tab-item
                :key="1"
                :value="'tab-details'">
                <v-card>
                  <v-card-title>Driver Info</v-card-title>
                  <v-card-text>
                    <DriverBasicInfoForm
                      :driver="driver"
                      :editable="true"
                      @save="saveDriverInfo">>
                    </DriverBasicInfoForm>
                  </v-card-text>
                </v-card>
                <v-card>
                  <v-card-title>Contact Information</v-card-title>
                  <v-card-text>
                    <ContactInformationForm
                      :contactInformation="driver.contactInformation"
                      :editable="true"
                      @save="saveContactInformation">
                    </ContactInformationForm>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'tab-attributes'">
                <DriverAttributesForm
                  :driver="driver"
                  @addNewAttribute="addNewAttribute"
                  @saveAttribute="saveAttribute"
                  @deleteAttribute="deleteAttribute">
                </DriverAttributesForm>
              </v-tab-item>
              <v-tab-item
                :key="3"
                :value="'tab-properties'">
                <DriverPropertiesForm
                  :driver="driver"
                  @addNewProperty="addNewProperty"
                  @saveProperty="saveProperty"
                  @deleteProperty="deleteProperty">
                </DriverPropertiesForm>
              </v-tab-item>
            </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import moment from "moment";
import ContactInformationForm from "../../Shared/Forms/ContactInformationForm";
import DriverBasicInfoForm from "../Forms/DriverBasicInfoForm";
import DriverAttributesForm from "../Forms/DriverAttributesForm";
import DriverPropertiesForm from "../Forms/DriverPropertiesForm";

export default {
    components: {
        ContactInformationForm,
        DriverBasicInfoForm,
        DriverAttributesForm,
        DriverPropertiesForm
    },
    props: {
        driver: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            tab: null
        };
    },
    methods: {
        saveContactInformation: function(contactInformation) {
            this.$emit("saveContactInformation", this.driver.id, contactInformation);
        },
        saveDriverInfo: function(driverInfo) {
            this.$emit("saveDriverInfo", this.driver.id, driverInfo);
        },
        addNewAttribute: function(attribute) {
            this.$emit("addNewAttribute", this.driver.id, attribute);
        },
        saveAttribute: function(attribute) {
            this.$emit("saveAttribute", this.driver.id, attribute);
        },
        deleteAttribute: function(attribute) {
            this.$emit("deleteAttribute", this.driver.id, attribute);
        },
        addNewProperty: function(property) {
            this.$emit("addNewProperty", this.driver.id, property);
        },
        saveProperty: function(property) {
            this.$emit("saveProperty", this.driver.id, property);
        },
        deleteProperty: function(property) {
            this.$emit("deleteProperty", this.driver.id, property);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
