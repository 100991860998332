<template>
  <v-container fluid>
        <h1>Fleets</h1>
        <FleetsTable></FleetsTable>
  </v-container>
</template>

<script>
import FleetsTable from "../components/Fleets/FleetsTable";

export default {
    components: { FleetsTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
