export const KelaRegions = [
    {
        "text": "Kanta-Häme",
        "value": "KHC"
    },
    {
        "text": "Kymenlaakso",
        "value": "KLC"
    },
    {
        "text": "Lappi",
        "value": "LAC"
    },
    {
        "text": "Päijät-Häme",
        "value": "PHB"
    },
    {
        "text": "Uusimaa",
        "value": "UMB"
    }
];
