import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedVehicles: [],
    fetchedVehicle: null,
    searchParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null,
    searchedLogs: []
};

// getters
const getters = {};

// actions
const actions = {
    // Vehicle
    async searchVehicles({ commit }, { searchParams, forceUpdate = false }) {
        if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
            return;
        }
        await RepositoryFactory.get("vehicle").find(searchParams)
            .then(response => {
                commit("SET_VEHICLES", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_FETCHED_TIMESTAMP", moment());
            });
    },
    async getVehicle({ commit }, id) {
        await RepositoryFactory.get("vehicle").getVehicle(id)
            .then(response => {
                commit("SET_VEHICLE", response.data.item);
            });
    },
    async createVehicle({ commit }, vehicle) {
        await RepositoryFactory.get("vehicle").create(vehicle)
            .then(() => {
                commit("CREATE_VEHICLE", vehicle);
            });
    },
    async deleteVehicle({ commit }, id) {
        await RepositoryFactory.get("vehicle").delete(id)
            .then(() => {
                commit("REMOVE_VEHICLE", id);
            });
    },
    async updateVehicleContactInformation({ commit }, { vehicleId, contactInformation }) {
        await RepositoryFactory.get("vehicle").patchContactInformation(vehicleId, contactInformation);
    },
    async updateVehicleInfo({ commit }, { vehicleId, vehicleInfo }) {
        await RepositoryFactory.get("vehicle").patchVehicleInfo(vehicleId, vehicleInfo);
    },
    // Fleet Memberships
    async addFleetMembership({ commit }, { vehicleId, fleetMembership }) {
        await RepositoryFactory.get("vehicle").postNewFleetMembership(vehicleId, fleetMembership);
    },
    async saveFleetMembership({ commit }, { vehicleId, fleetMembership }) {
        await RepositoryFactory.get("vehicle").patchFleetMembership(vehicleId, fleetMembership);
    },
    async deleteFleetMembership({ commit }, { vehicleId, fleetMembership }) {
        await RepositoryFactory.get("vehicle").deleteFleetMembership(vehicleId, fleetMembership.companyId, fleetMembership.fleetId);
    },
    // Attributes
    async addNewAttribute({ commit }, { vehicleId, attribute }) {
        await RepositoryFactory.get("vehicle").postNewAttribute(vehicleId, { attribute });
    },
    async saveAttribute({ commit }, { vehicleId, attribute }) {
        await RepositoryFactory.get("vehicle").patchAttribute(vehicleId, { attribute });
    },
    async deleteAttribute({ commit }, { vehicleId, attribute }) {
        await RepositoryFactory.get("vehicle").deleteAttribute(vehicleId, attribute);
    },
    // Properties
    async addNewProperty({ commit }, { vehicleId, property }) {
        await RepositoryFactory.get("vehicle").postNewProperty(vehicleId, { property });
    },
    async saveProperty({ commit }, { vehicleId, property }) {
        await RepositoryFactory.get("vehicle").patchProperty(vehicleId, { property });
    },
    async deleteProperty({ commit }, { vehicleId, property }) {
        await RepositoryFactory.get("vehicle").deleteProperty(vehicleId, property);
    },
    // Tariffs
    async addNewTariffImport({ commit }, { vehicleId, tariffImport }) {
        await RepositoryFactory.get("vehicle").postNewTariffImport(vehicleId, tariffImport);
    },
    async saveTariffImport({ commit }, { vehicleId, tariffImport }) {
        await RepositoryFactory.get("vehicle").patchTariffImport(vehicleId, tariffImport);
    },
    async saveTariffId({ commit }, { vehicleId, tariffId }) {
        await RepositoryFactory.get("vehicle").patchTariffId(vehicleId, tariffId);
    },
    async deleteTariffImport({ commit }, { vehicleId, tariffImport }) {
        await RepositoryFactory.get("vehicle").deleteTariffImport(vehicleId, tariffImport);
    },
    // index
    async removeFromIndex({ commit }, vehicleId) {
        await RepositoryFactory.get("vehicle").removeFromIndex(vehicleId);
    },
    // Logs
    async searchLogs({ commit }, query) {
        await RepositoryFactory.get("vehicle").getLogs(query.vehicleId, query.from, query.to)
            .then(response => {
                commit("SET_VEHICLE_LOGS", response.data.results);
            });
    }
};

// mutations
const mutations = {
    SET_VEHICLES(state, vehicles) {
        state.searchedVehicles = vehicles;
    },
    SET_VEHICLE(state, vehicle) {
        state.fetchedVehicle = vehicle;
    },
    ADD_SEARCHED_VEHICLES(state, vehicles) {
        state.searchedVehicles = state.searchedVehicles.concat(vehicles);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    },
    CREATE_VEHICLE(state, vehicle) {
        state.searchedVehicles.push(vehicle);
    },
    UPDATE_VEHICLE(state, vehicle) {
        state.searchedVehicles = [
            ...state.searchedVehicles.map(
                item => item.id !== vehicle.id ? item : { ...item, ...vehicle })
        ];
    },
    REMOVE_VEHICLE(state, id) {
        let index = state.searchedVehicles.findIndex(te => te.id === id);
        state.searchedVehicles.splice(index, 1);
    },
    SET_VEHICLE_LOGS(state, logs) {
        state.searchedLogs = logs;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
