import _ from "lodash";

export default {
    props: {
        validationErrors: {
            type: Array,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            externalValidationErrors: {},
            refreshValidation: 1
        };
    },
    watch: {
        validationErrors(val) {
            this.parseValidationErrors(val);
        }
    },
    methods: {
        parseValidationErrors(validationErrors) {
            if (!validationErrors) {
                this.externalValidationErrors = {};
                return;
            }

            validationErrors.forEach(validationError => {
                if (!this.externalValidationErrors[_.camelCase(validationError.Field)]) {
                    this.externalValidationErrors[_.camelCase(validationError.Field)] = {};
                }
                this.externalValidationErrors[_.camelCase(validationError.Field)].errors = validationError.Errors;
            });

            this.refreshValidation += 1;
        },
        validationErrorsFor(field) {
            return this.externalValidationErrors[_.camelCase(field)]?.errors ?? [];
        }
    },
    created() {
        this.$on("newEventTriggered", (e) => {
            if (e.success) {
                this.$emit("success");
            } else {
                this.parseValidationErrors(e.validationErrors);
            }
        });
    }
};
