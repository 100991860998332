import { render, staticRenderFns } from "./OffersTable.vue?vue&type=template&id=283f65b6&scoped=true&"
import script from "./OffersTable.vue?vue&type=script&lang=js&"
export * from "./OffersTable.vue?vue&type=script&lang=js&"
import style0 from "./OffersTable.vue?vue&type=style&index=0&id=283f65b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "283f65b6",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VDataTable})


/* hot reload */
if (module.hot) {
  var api = require("/working_dir/management-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('283f65b6')) {
      api.createRecord('283f65b6', component.options)
    } else {
      api.reload('283f65b6', component.options)
    }
    module.hot.accept("./OffersTable.vue?vue&type=template&id=283f65b6&scoped=true&", function () {
      api.rerender('283f65b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Trips/OffersTable.vue"
export default component.exports