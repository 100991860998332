<template>
    <div>
        <v-dialog v-model="showDialog" max-width="500px">
            <v-card>
                <v-card-title>{{ $t("invoicing.options.newOption") }}</v-card-title>
                <v-card-text>
                    <v-text-field v-model="newOptionName" :label="$t('invoicing.options.optionName')" />
                    <v-select v-model="newOptionERPSystem" :items="erpSystems" item-text="erpSystemName"
                        item-value="erpSystem" :label="$t('invoicing.options.erpSystem')" />
                    <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="editNewOptions">{{ $t("common.next") }}</v-btn>
                    <v-btn @click="cancel">{{ $t("common.cancel") }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showEditOptionsDialog" max-width="800px">
            <v-card>
                <v-card-title>{{ $t("invoicing.options.editOptions") }}</v-card-title>
                <v-card-text>
                    <InvoiceOptionsForm ref="invoiceOptionsForm" :invoiceOptions="{ invoicingOptions: editableOptions }"
                        @submit="saveNewOption" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="triggerFormSubmit">{{ $t("common.save") }}</v-btn>
                    <v-btn @click="cancel">{{ $t("common.cancel") }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import InvoiceOptionsForm from "../InvoiceOptionsForm.vue";
import { InvoiceOptionsTypeMixin } from "../../../models/InvoiceOptionsType";

export default {
    components: { InvoiceOptionsForm },
    mixins: [InvoiceOptionsTypeMixin],
    props: {
        show: {
            type: Boolean,
            default: false
        },
        defaultInvoicingOptions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            showDialog: this.show,
            showEditOptionsDialog: false,
            newOptionName: "",
            newOptionERPSystem: null,
            erpSystems: [],
            editableOptions: [],
            errorMessage: ""
        };
    },
    watch: {
        show(val) {
            this.showDialog = val;
        },
        showDialog(val) {
            this.$emit("update:show", val);
        },
        defaultInvoicingOptions: {
            handler(newVal) {
                this.erpSystems = newVal.map(option => ({
                    erpSystem: option.erpSystem,
                    erpSystemName: option.erpSystemName
                }));
            },
            immediate: true
        }
    },
    methods: {
        editNewOptions() {
            this.errorMessage = "";
            if (this.newOptionName.length < 3) {
                this.errorMessage = this.$t("invoicing.options.minimumOptionNameLength");
                return;
            }
            if (!this.newOptionERPSystem) {
                this.errorMessage = this.$t("invoicing.options.erpSystemRequired");
                return;
            }
            const selectedERPSystem = this.defaultInvoicingOptions.find(option => option.erpSystem === this.newOptionERPSystem);
            this.editableOptions = selectedERPSystem ? selectedERPSystem.defaultOptions : [];
            this.showDialog = false;
            this.showEditOptionsDialog = true;
        },
        triggerFormSubmit() {
            this.$refs.invoiceOptionsForm.submitForm();
        },
        saveNewOption(updatedOptions) {
            const newOption = {
                optionsName: this.newOptionName,
                eRPSystem: this.newOptionERPSystem,
                invoicingOptions: updatedOptions.invoicingOptions
            };
            this.$emit("create", newOption);
            this.showEditOptionsDialog = false;
        },
        cancel() {
            this.showDialog = false;
            this.showEditOptionsDialog = false;
            this.$emit("cancel");
        }
    }
};
</script>
