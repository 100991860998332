import { render, staticRenderFns } from "./CompanyTariffsForm.vue?vue&type=template&id=0ac7957a&"
import script from "./CompanyTariffsForm.vue?vue&type=script&lang=js&"
export * from "./CompanyTariffsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/working_dir/management-portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ac7957a')) {
      api.createRecord('0ac7957a', component.options)
    } else {
      api.reload('0ac7957a', component.options)
    }
    module.hot.accept("./CompanyTariffsForm.vue?vue&type=template&id=0ac7957a&", function () {
      api.rerender('0ac7957a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Companies/Forms/CompanyTariffsForm.vue"
export default component.exports