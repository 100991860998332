<template>
    <v-select
        :value="editableIntegrationSources"
        :items="Object.values(integrationSources)"
        @input="$emit('update:editableIntegrationSources', $event)"
        multiple
        :disabled="!editable"
        >
        <!--label template-->
          <template v-slot:label>
            <v-icon style="margin-top: -2px">
              {{ "mdi-cog-transfer-outline" }}
            </v-icon>
            <span> {{ $t("vehicles.dialogs.integrationSources") }} </span>
          </template>
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index <= 2">
              {{ integrationSourceToString(item) }}
            </v-chip>
            <span v-if="index === 3" class="grey--text caption"
              >+{{ editableIntegrationSources.length - 3 }}</span>
          </template>
          <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ integrationSourceToString(item) }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
      </v-list-item>
      </template>
    </v-select>
</template>
<script>
import { IntegrationSource, IntegrationSourceToString } from "@/models/IntegrationSource";
export default {
    name: "IntegrationSourcesField",
    props: {
        editableIntegrationSources: {
            type: Array,
            required: true
        },
        editable: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            integrationSources: Object.values(IntegrationSource)
        };
    },
    methods: {
        integrationSourceToString(integrationSource) {
            return IntegrationSourceToString(integrationSource);
        }
    },
    emits: ["update:editableIntegrationSources"]
};
</script>
