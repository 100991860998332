<template>
  <v-card>
    <v-card-text>
      <v-data-table
          calculate-widths
          :headers="showableHeaders"
          :items="editableFleetMemberships"
          item-key="fleetId"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :search="filter"
          :items-per-page="10"
        >
          <template v-slot:item.companyId="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.fleetId="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.isMain="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <span>
              <v-btn
                @click="deleteFleetMembership(item)"
                :disabled="!isEditable(item)"
              >
                Delete
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer></v-spacer>
        <v-btn @click="createFleetMembership">Create</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <FleetMembershipForm
      :isOpen="isEditFormOpen"
      :fleetMembership="selectedFleetMembership"
      :canAddNew="true"
      :result="result"
      @cancel="isEditFormOpen=false"
      @addFleetMembership="addFleetMembership"
      @saveFleetMembership="saveFleetMembership"
      @deleteFleetMembership="deleteFleetMembership">
    </FleetMembershipForm>
  </v-card>
</template>

<script>
import FleetMembershipForm from "./Forms/FleetMembershipForm";
import _ from "lodash";

export default {
    components: { FleetMembershipForm },
    props: {
        fleetMemberships: {
            type: Array,
            default: function() {
                return null;
            }
        },
        result: {
            type: Object,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            filter: "",
            sortBy: "fleetId",
            sortDesc: true,
            headers: [
                { text: "Companyd", value: "companyId", show: true },
                { text: "Fleet", value: "fleetId", show: true },
                { text: "Is Main", value: "isMain", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            editableFleetMemberships: _.cloneDeep(this.fleetMemberships),
            selectedFleetMembership: null,
            isEditFormOpen: false
        };
    },
    computed: {
        showableHeaders() {
            return this.headers.filter(h => h.show);
        }
    },
    watch: {
        fleetMemberships(val) {
            this.editableFleetMemberships = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        createFleetMembership() {
            this.selectedFleetMembership = null;
            this.isEditFormOpen = true;
        },
        addFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("addFleetMembership", fleetMembership, eventTargetId);
        },
        saveFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("saveFleetMembership", fleetMembership, eventTargetId);
        },
        deleteFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("deleteFleetMembership", fleetMembership, eventTargetId);
        },
        isEditable(fleetMembership) {
            return true;
        }
    }
};
</script>
