export const VehicleStatus = {
    Unknown: 0,
    Initialized: 1,
    Active: 2,
    InActive: 3,
    Deleted: 4
};

export function VehicleStatusToString(vehicleStatus) {
    switch (vehicleStatus) {
    case VehicleStatus.Unknown:
        return "-";
    case VehicleStatus.Initialized:
        return "Initialized";
    case VehicleStatus.Active:
        return "Active";
    case VehicleStatus.InActive:
        return "In Active";
    case VehicleStatus.Deleted:
        return "Deleted";
    }
};
