var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1400" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { short: "", dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.cancel } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v(" Create Fleet ")])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.operators,
                              rules: [_vm.requiredRule],
                              label: "Company Id"
                            },
                            model: {
                              value: _vm.newFleet.companyId,
                              callback: function($$v) {
                                _vm.$set(_vm.newFleet, "companyId", $$v)
                              },
                              expression: "newFleet.companyId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.requiredRule],
                              label: "Fleet Id"
                            },
                            model: {
                              value: _vm.newFleet.fleetId,
                              callback: function($$v) {
                                _vm.$set(_vm.newFleet, "fleetId", $$v)
                              },
                              expression: "newFleet.fleetId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { rules: [_vm.requiredRule], label: "Name" },
                            model: {
                              value: _vm.newFleet.name,
                              callback: function($$v) {
                                _vm.$set(_vm.newFleet, "name", $$v)
                              },
                              expression: "newFleet.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { staticClass: "ml-2", on: { click: _vm.create } }, [
                _vm._v(" Create ")
              ]),
              _c("v-btn", { staticClass: "ml-2", on: { click: _vm.cancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")
              ]),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }