<template>
  <div id="createdPurchaseinvoices">
    <v-card class="mt-5">
      <div class="elevation-1 mb-1 pb-2">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-card-title>
              {{$t("invoicing.purchasing.createdInvoices")}}
              <v-icon right>mdi-archive-check-outline</v-icon>
              <v-btn
                icon
                color="success"
                @click="updateCreatedPurchaseInvoices()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </v-card-title>
          </v-col>
        </v-row>
      </div>
      <v-skeleton-loader :loading="loadingCreatedInvoices" :transition-group="'scale-transition'" type="table" name="createdPurchaseInvoicesTable">
        <v-data-table
          calculate-widths
          :headers="createdPurchaseInvoicesHeaders"
          :items="createdPurchaseInvoices"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loadingPage"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
          @click:row="searchPurchaseInvoice">
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | formatDateTimeShort }}
          </template>
          <template v-slot:item.paidAt="{ item }">
            {{ item.paidAt | formatDateTimeShort }}
          </template>
          <template v-slot:item.totalSum="{ item }">
            {{ item.totalSum | formatCurrency }}
          </template>
          <template v-slot:item.invoiceCreationType="{ item }">
            {{ item.invoiceCreationType | invoiceCreationTypeToString}}
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import { PaymentMethodMixin } from "@/models/PaymentMethods";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import { TransportEventTypeMixin } from "@/models/TransportEventTypes";
import { FeeTypeMixin } from "@/models/FeeTypes";
import { InvoiceCreationTypeMixin } from "@/models/InvoiceCreationType";
import InputRuleMixin from "@/components/Inputs/InputRuleMixin";

export default {
    mixins: [
        TransportEventStatusMixin,
        TransportEventInvoicingMixin,
        FeeTypeMixin,
        InputRuleMixin,
        TransportEventTypeMixin,
        PaymentMethodMixin,
        InvoiceCreationTypeMixin
    ],
    data() {
        return {
            loadingCreatedInvoices: true,
            invoiceEventType: "Likuma",
            invoiceEventTypes: ["Likuma", "Korttimaksu", "Taksini"],
            createdPurchaseInvoicesHeaders: [
                { text: "Ostolaskujen lukumäärä", value: "purchaseInvoiceNumbersCount", show: true },
                { text: "Ostolaskujen luontipvm", value: "createdAt", show: true },
                { text: "Ostolaskujen maksupvm", value: "paidAt", show: true },
                { text: "Ostolaskujen summa", value: "totalSum", show: true },
                { text: "Ostolaskujen tapahtumien määrä", value: "transportEventCount", show: true },
                { text: "Ostolaskujen tyyppi", value: "invoiceCreationType", show: true }
            ],
            sortBy: "createdAt",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            sendToPurchasingDialog: false,
            searchParams: {
                validationStates: [],
                salesInvoiceStates: [],
                purchaseInvoiceStates: [],
                eventTypes: [],
                operatorId: ""
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            transportEventDialog: false,
            selectedTransportEvent: null,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        createdPurchaseInvoices() {
            return this.$store.state.invoicing.createdPurchaseInvoices;
        },
        searchTotalCount() {
            return this.createdPurchaseInvoices.length ?? 0;
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        tenant() {
            return this.$store.state.user.tenant;
        }
    },
    watch: {
        tenant(val) {
            this.getCreatedPurchaseInvoices();
        },
        itemsPerPage(val) {
            // this.loadMoreTransportEvents();
        },
        invoiceEventType: {
            immediate: true,
            handler(val) {
                if (val === "Likuma") {
                    this.getCreatedPurchaseInvoices();
                } else if (val === "Korttimaksu") {
                    this.getCreatedPurchaseInvoices();
                } else if (val === "Taksini") {
                    this.getCreatedPurchaseInvoices();
                }
            }
        }
    },
    methods: {
        async getCreatedPurchaseInvoices() {
            this.loadingCreatedInvoices = true;
            this.$store.dispatch("invoicing/getCreatedPurchaseInvoices")
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingCreatedInvoices = false;
                });
        },
        updateCreatedPurchaseInvoices() {
            RepositoryFactory.get("invoicing").updateCreatedPurchaseInvoices()
                .then(() => {
                    this.snackbar = true;
                    this.snackbarColor = "success";
                    this.snackbarText = this.$t("invoicing.purchasing.summary.updated");
                    setTimeout(() => {
                        this.getCreatedPurchaseInvoices();
                    }, 2000);
                })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                });
        },
        searchPurchaseInvoice(purchaseInvoiceRow) {
            this.$router.push({
                name: "Invoicing",
                query: { purchaseInvoiceNumbers: purchaseInvoiceRow.purchaseInvoiceNumbers }
            });
        }
    },
    filters: {
        statusToColor: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "";
            case 3: return "success";
            default: return "error";
            }
            ;
        },
        statusToIcon: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "mdi-av-timer";
            case 3: return "mdi-check-circle-outline";
            default: return "mdi-alert";
            }
            ;
        },
        statusToToolTipText: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "Waiting";
            case 3: return "No errors";
            default: return "Contains error(s)";
            }
            ;
        }
    }
};
</script>

  <style>
  .v-text-field_search {
      padding-top: 0;
      margin-top: 0;
  }
  .extras-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
  }

  .extras-list > li > span:last-of-type {
      float: right;
      margin-left:10px
  }

  .itemRowSideColor-grey {
      border: 5px solid #BDBDBD;;
      background-color: #BDBDBD;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-blue {
      border: 5px solid #42A5F5;
      background-color: #42A5F5;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-green {
      border: 5px solid #66BB6A;
      background-color: #66BB6A;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-yellow {
      border: 5px solid #FFEE58;
      background-color: #FFEE58;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-orange {
      border: 5px solid #FFA726;
      background-color: #FFA726;
      border-radius: 3px;
      display: inline;
  }

  </style>
