<template>
  <v-container fluid>
    <h1>Invoicing Rules</h1>
    <v-skeleton-loader
      :loading="loading"
      :transition-group="'scale-transition'"
      type="table">
      <InvoicingRulePanels
        ref="panels"
        :rules="invoicingRules"
        @update="updateInvoicingRules"
      ></InvoicingRulePanels>
    </v-skeleton-loader>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>

import InvoicingRulePanels from "@/components/InvoicingRules/InvoicingRulePanels";

export default {
    components: {
        InvoicingRulePanels
    },
    data() {
        return {
            loading: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        invoicingRules() {
            return this.$store.state.invoiceRule.invoiceRules;
        }
    },
    methods: {
        updateInvoicingRules(rules) {
            this.invoicingRules = rules;
            this.$refs.panels.closeRuleDialog();
        }
    },
    created() {
        this.loading = true;
        this.$store.dispatch("invoiceRule/getInvoiceRules")
            .catch(error => {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = error;
            })
            .finally(() => {
                this.loading = false;
            });
    }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
