<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="trip">
        <v-col
        class="pa-0">
          <v-card>
            <v-toolbar short dark color="primary">
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                  Trip - {{trip.id}}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-tabs
                v-model="tab"
                fixed-tabs
                background-color="indigo"
                dark
            >
              <v-tab href="#tab-details">
                Details
              </v-tab>
              <v-tab href="#tab-route">
                Route
              </v-tab>
              <v-tab href="#tab-offers">
                Offers
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab">
              <v-tab-item
                :key="1"
                :value="'tab-details'">
                <v-card>
                  <v-card-title>Trip Info</v-card-title>
                  <v-card-text>
                    TODO
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'tab-route'">
                <MapBox
                  :routes="[trip.actualRoute, trip.plannedRoute]"
                ></MapBox>
              </v-tab-item>
              <v-tab-item
                :key="3"
                :value="'tab-offers'">
                <OffersTable
                  :offers="offers"
                ></OffersTable>
              </v-tab-item>
            </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import MapBox from "../../Geo/MapBox";
import OffersTable from "../OffersTable";
import moment from "moment";

export default {
    components: {
        OffersTable,
        MapBox
    },
    props: {
        trip: {
            type: Object,
            default: function() {
                return null;
            }
        },
        offers: {
            type: Array,
            default: function() {
                return [];
            }
        },
        isOpen: Boolean
    },
    watch: {
        offers(val) {
            if (!val) {
                this.offers = null;
            } else {
                this.offers = val;
            }
        }
    },
    data() {
        return {
            tab: null
        };
    },
    methods: {
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
