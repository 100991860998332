<template>
    <v-row class="cols-4">
        <v-col>
            <v-card class="mb-2">
                <v-card-title>{{ readyForInvoicingTitle }}
                    <v-btn @click="showInvoiceOptionsDialog = !showInvoiceOptionsDialog" class="ml-3">
                        <v-icon> mdi-cog </v-icon>{{ $t("invoicing.filters.title") }}
                    </v-btn>
                </v-card-title>
                <v-data-table :headers="headers" :items="allFilters" :items-per-page="-1" class="elevation-1"
                    disable-filtering disable-pagination disable-sort hide-default-footer>
                    <template v-slot:item.totalReimbursementSum="{ value }">
                        <span>{{ value | formatCurrency }}</span>
                    </template>
                    <template v-slot:item.oldestTripStart="{ value }">
                        <span>{{ value | formatDateTime }}</span>
                    </template>
                    <template v-slot:item.newestTripStart="{ value }">
                        <span>{{ value | formatDateTime }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small v-bind="attrs" v-on="on" class="mx-2" @click="startInvoicing(item)">
                                    <v-icon> mdi-motion-play-outline </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("invoicing.createInvoices") }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small v-bind="attrs" v-on="on" class="mx-2" @click="searchEvents(item)">
                                    <v-icon> mdi-magnify </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("invoicing.searchEvents") }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small v-bind="attrs" v-on="on" class="mx-2"
                                    @click="openInvoiceRowSettingsDialog(item, 'edit')">
                                    <v-icon> mdi-cog </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("common.edit") }} </span>
                        </v-tooltip>
                        <!-- <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small v-bind="attrs" v-on="on" class="mx-2"
                                    @click="openInvoiceRowSettingsDialog(item, 'delete')">
                                    <v-icon> mdi-delete </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("common.delete") }} </span>
                        </v-tooltip> -->
                    </template>
                    <template v-slot:footer>
                        <v-row justify="center">
                            <v-col cols="4">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn block v-bind="attrs" v-on="on" color="primary"
                                            @click="openInvoiceRowSettingsDialog(null, 'create')">
                                            <v-icon>mdi-plus</v-icon> {{ $t("common.addNew") }}
                                        </v-btn>
                                    </template>
                                    <span>{{ $t("common.addNew") }}</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <InvoiceOptionsSettingsDialog
            :show="showInvoiceOptionsDialog"
            :invoiceOptions="invoiceOptions"
            @save="handleSave({ dialog: 'invoiceOptionsSettingsDialog', item: $event })"
            @create="handleCreate({ dialog: 'invoiceOptionsSettingsDialog', item: $event })"
            @delete="handleDelete({ dialog: 'invoiceOptionsSettingsDialog', id: $event })"
            @cancel="handleCancel('invoiceOptionsSettingsDialog')" />
        <InvoiceRowSettingsDialog
            :show="showInvoiceRowSettingsDialog"
            :selectedRowItem="selectedInvoiceRowItem"
            :invoiceOptions="invoiceOptions"
            :allTransportEventFilters="allTransportEventFilters"
            :mode="rowDialogMode"
            @save="handleSave({ dialog: 'invoiceRowSettingsDialog', item: $event })"
            @create="handleCreate({ dialog: 'invoiceRowSettingsDialog', item: $event })"
            @cancel="handleCancel('invoiceRowSettingsDialog')" />
    </v-row>
</template>

<script>
import InvoiceOptionsSettingsDialog from "../Invoicing/Dialogs/InvoiceOptionsSettingsDialog";
import InvoiceRowSettingsDialog from "../Invoicing/Dialogs/InvoiceRowSettingsDialog";

export default {
    components: { InvoiceOptionsSettingsDialog, InvoiceRowSettingsDialog },
    name: "ReadyForInvoicingByFilter",
    props: {
        id: { type: String, required: true },
        allFilters: { type: Array, required: true },
        invoiceOptions: { type: Array, required: true },
        allTransportEventFilters: { type: Array, required: true }
    },
    mounted() {
    },
    data() {
        return {
            headers: [
                { text: this.$t("invoicing.filters.invoicingOptionsName"), value: "invoicingOptionsName" }, // New header
                { text: this.$t("invoicing.filters.transportEventFilterName"), value: "transportEventFilterName" },
                { text: this.$t("invoicing.filters.transportEventsCount"), value: "transportEventsCount" },
                { text: this.$t("invoicing.filters.customerCount"), value: "customerCount" },
                { text: this.$t("invoicing.filters.serviceProviderCount"), value: "serviceProviderCount" },
                { text: this.$t("invoicing.filters.totalReimbursementSum"), value: "totalReimbursementSum" },
                { text: this.$t("invoicing.filters.oldestTripStart"), value: "oldestTripStart" },
                { text: this.$t("invoicing.filters.newestTripStart"), value: "newestTripStart" },
                { text: this.$t("invoicing.filters.actions"), value: "actions" }
            ],
            selectedInvoiceRowItem: null,
            showInvoiceOptionsDialog: false,
            showInvoiceRowSettingsDialog: false,
            rowDialogMode: ""
        };
    },
    computed: {
        readyForInvoicingTitle() {
            if (this.id === "ReadyForPurchaseInvoicingByFilter") {
                return this.$t("invoicing.purchasing.creationTitle");
            } else if (this.id === "ReadyForSalesInvoicingByFilter") {
                return this.$t("invoicing.sales.creationTitle");
            }

            return "Ready for invoicing";
        }
    },
    methods: {
        startInvoicing(filter) {
            this.$emit("start-invoicing", filter.transportEventFilterId, filter.invoicingOptionsId);
        },
        searchEvents(filter) {
            this.$emit("search-events", filter.transportEventFilterId);
        },
        handleSave({ dialog, item }) {
            switch (dialog) {
            case "invoiceRowSettingsDialog":
                this.showInvoiceRowSettingsDialog = false;
                this.$emit("update-invoicing-filter", item);
                break;
            case "invoiceOptionsSettingsDialog":
                this.showInvoiceOptionsDialog = false;
                this.$emit("update-invoicing-options", item);
                break;
            default:
                // console.warn(`Unknown dialog: ${dialog}`);
            }
        },
        handleCreate({ dialog, item }) {
            switch (dialog) {
            case "invoiceRowSettingsDialog":
                this.$emit("create-invoicing-filter", item);
                this.showInvoiceRowSettingsDialog = false;
                break;
            case "invoiceOptionsSettingsDialog":
                this.showInvoiceOptionsDialog = false;
                this.$emit("create-invoicing-options", item);
                break;
            default:
                // console.warn(`Unknown dialog: ${dialog}`);
            }
        },
        handleCancel(dialog) {
            switch (dialog) {
            case "invoiceRowSettingsDialog":
                this.showInvoiceRowSettingsDialog = false;
                break;
            case "invoiceOptionsSettingsDialog":
                this.showInvoiceOptionsDialog = false;
                break;
            default:
                // console.warn(`Unknown dialog: ${dialog}`);
            }
        },
        handleDelete({ dialog, id }) {
            switch (dialog) {
            case "invoiceRowSettingsDialog":
                this.showInvoiceRowSettingsDialog = false;
                break;
            case "invoiceOptionsSettingsDialog":
                this.$emit("delete-invoicing-options", id);
                this.showInvoiceOptionsDialog = false;
                break;
            default:
                // console.warn(`Unknown dialog: ${dialog}`);
            }
        },
        openInvoiceRowSettingsDialog(item, mode) {
            this.selectedInvoiceRowItem = item;
            this.rowDialogMode = mode;
            this.showInvoiceRowSettingsDialog = true;
        }
    }
};
</script>
