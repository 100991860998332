var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      this.hasCache()
        ? _c(
            "v-card",
            [
              _vm._l(_vm.cache, function(value, key) {
                return _c(
                  "div",
                  { key: key },
                  [
                    _c("v-card-title", [
                      _c("span", { staticClass: "subtitle-1" }, [
                        _vm._v(_vm._s(key))
                      ])
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-textarea", {
                              staticClass: "small-text",
                              attrs: {
                                value: _vm.prettifyJson(value),
                                outlined: "",
                                "auto-grow": "",
                                filled: "",
                                readonly: ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.$AuthService.hasServiceRole("vehicle", "edit")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("removeFromIndex", _vm.vehicleId)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-broom")]),
                          _vm._v(" Remove from index ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$AuthService.hasServiceRole("gateway", "edit")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("invalidateCache", _vm.vehicleId)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-broom")]),
                          _vm._v(" Release cache ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$AuthService.hasServiceRole("gateway", "edit")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("refreshCache", _vm.vehicleId)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-refresh")]),
                          _vm._v(" Refresh cache ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            2
          )
        : _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [_vm._v("No cache")])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          "auto-grow": "",
                          filled: "",
                          readonly: "",
                          value: "No cache available"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm.$AuthService.hasServiceRole("vehicle", "edit")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("removeFromIndex", _vm.vehicleId)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-broom")]),
                          _vm._v(" Remove from index ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$AuthService.hasServiceRole("gateway", "edit")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "warning" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("invalidateCache", _vm.vehicleId)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-broom")]),
                          _vm._v(" Release cache ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$AuthService.hasServiceRole("gateway", "edit")
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("refreshCache", _vm.vehicleId)
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v("mdi-refresh")]),
                          _vm._v(" Refresh cache ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: { depressed: "", color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Close")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }