<template>
     <div>
        <v-data-table
        calculate-widths
        :headers="showableHeaders"
        :items="installations"
        item-key="id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="filter"
        :loading="loadingPage"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
      >
        <template v-slot:item.timestamp="{ value }">
          <span> {{ formatDateTime(value) }}</span>
        </template>
        <template v-slot:item.vehicleNumber="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.registrationNumber="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.companyName="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.phoneNumber="{ value }">
           <span> {{ value }}</span>
        </template>
        <template v-slot:item.imei="{ value }">
           <span> {{ value }}</span>
        </template>
        <template v-slot:item.installer="{ value }">
          <span> {{ value }}</span>
        </template>
      </v-data-table>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        installations: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {
            loading: true,
            filter: "",
            columnDialog: false,
            headers: [
                { text: "Created at", value: "timestamp", show: true },
                { text: "Number", value: "vehicleNumber", show: true },
                { text: "Registration Number", value: "registrationNumber", show: true },
                { text: "Company", value: "companyName", show: true },
                { text: "Phone Number", value: "phoneNumber", show: true },
                { text: "Imei", value: "imei", show: true },
                { text: "Installer", value: "installer", show: true }
            ],
            sortBy: "timestamp",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            searchParams: {
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100]
        };
    },
    computed: {
        searchTotalCount() {
            return this.installations.length;
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    },
    methods: {
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        }
    }
};
</script>
