<template>
    <v-container fluid fill-height >
        <h1>Geo service</h1>
        <v-row>
          <v-col cols="2">
          <v-select class="ma-2"
            v-model="client"
            :items="clients"
            label="Client"
            dense
          ></v-select>
          </v-col>
          <v-btn class="ma-2" color="primary" @click="showRoute = true">Calculate Route</v-btn>
          <v-btn class="ma-2" color="primary" @click="showIsochrone = true">Calculate Isochrone</v-btn>
          <v-btn class="ma-2" color="primary" @click="showGeoCode = true">Geo code address</v-btn>
        </v-row>
        <RouteDialog
          @getRoute="getRoute"
          :isOpen="showRoute"
          @close="showRoute = false"></RouteDialog>
        <IsochroneDialog
          @getIsochrone="getIsochrone"
          :isOpen="showIsochrone"
          @close="showIsochrone = false"></IsochroneDialog>
        <GeoCodeDialog
          @geoCodeAddress="geoCodeAddress"
          :isOpen="showGeoCode"
          @close="showGeoCode = false"></GeoCodeDialog>
        <v-row style="height: 600px">
          <Map
              :center="mapCenter"
              :routes="route ? [route] : []"
              :isochrones="isochrone ? [isochrone] : []"
              :locations="geoCodeLocation ? [geoCodeLocation] : []"
              >
            </Map>
        </v-row>
    </v-container>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";
import RouteDialog from "../components/Geo/RouteDialog";
import IsochroneDialog from "../components/Geo/IsochroneDialog";
import GeoCodeDialog from "../components/Geo/GeoCodeDialog";
import Map from "../components/Geo/Map";

const GeoRepository = RepositoryFactory.get("geo");

export default {
    components: {
        RouteDialog,
        IsochroneDialog,
        GeoCodeDialog,
        Map
    },
    data() {
        return {
            client: null,
            clients: [ {
                text: "Any",
                value: null
            }, {
                text: "Azure Maps",
                value: "azure"
            }, {
                text: "Here",
                value: "here"
            }],
            loading: true,
            showRoute: false,
            showIsochrone: false,
            showGeoCode: false,
            route: null,
            isochrone: null,
            geoCodeLocation: null,
            mapCenter: { lat: 60.326692, lng: 24.840489 }
        };
    },
    methods: {
        getRoute(routeRequest) {
            let vm = this;
            vm.loading = true;
            GeoRepository.calculateRoute(routeRequest, vm.client)
                .then(response => {
                    let resultRoute = response.data;
                    if (resultRoute) {
                        vm.route = resultRoute;
                        vm.mapCenter = { lat: resultRoute.points[0].latitude, lng: resultRoute.points[0].longitude };
                    } else {
                        vm.route = null;
                    }
                }).finally(() => {
                    vm.loading = false;
                });
        },
        getIsochrone(isochroneRequest) {
            let vm = this;
            vm.loading = true;
            GeoRepository.calculateIsochrone(isochroneRequest, vm.client)
                .then(response => {
                    let resultIsochrone = response.data;
                    if (resultIsochrone) {
                        vm.isochrone = resultIsochrone;
                        vm.mapCenter = { lat: resultIsochrone.points[0].latitude, lng: resultIsochrone.points[0].longitude };
                    } else {
                        vm.isochrone = null;
                    }
                }).finally(() => {
                    vm.loading = false;
                });
        },
        geoCodeAddress(geoCodeRequest) {
            let vm = this;
            vm.loading = true;
            GeoRepository.geoCodeAddress(geoCodeRequest, vm.client)
                .then(response => {
                    let location = response.data;
                    if (location) {
                        vm.geoCodeLocation = location;
                        vm.mapCenter = { lat: location.point.latitude, lng: location.point.longitude };
                    } else {
                        vm.geoCodeLocation = null;
                    }
                }).finally(() => {
                    vm.loading = false;
                });
        }
    }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
