<template>
    <v-dialog v-model="localVisible" max-width="500" @click:outside="closeDialog">
        <v-card>
            <v-card-title class="headline">Log Messages</v-card-title>
            <v-card-text>
                <div v-for="(message, index) in messages" :key="index">
                    {{ message }}
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDialog">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        messages: {
            type: Array,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localVisible: this.visible
        };
    },
    watch: {
        visible(newVal) {
            this.localVisible = newVal;
        },
        localVisible(newVal) {
            if (!newVal) {
                this.$emit("close");
            }
        }
    },
    methods: {
        closeDialog() {
            this.localVisible = false;
        }
    }
};
</script>

<style scoped>
/* Add any custom styles here if needed */
</style>
