export const AttributeOrigin = {
    Unknown: 0,
    RG: 1,
    SUTI: 2,
    MTI: 3,
    Vinka: 4
};

export function AttributeOriginToString(attributeOrigin) {
    switch (attributeOrigin) {
    case AttributeOrigin.Unknown:
        return "-";
    case AttributeOrigin.RG:
        return "RG";
    case AttributeOrigin.SUTI:
        return "SUTI";
    case AttributeOrigin.MTI:
        return "MTI";
    case AttributeOrigin.Vinka:
        return "Vinka";
    }
};
