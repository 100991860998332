var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "elevation-1 mb-1 pb-2" },
        [
          _c("v-card-title", [_vm._v(" Vehicles ")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.searchDialog = true
                    }
                  }
                },
                [_vm._v(" Search ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.vehicleCreateDialog = true
                    }
                  }
                },
                [_vm._v(" Create ")]
              ),
              _c("v-spacer"),
              _c("VehicleSearchDialog", {
                attrs: {
                  show: _vm.searchDialog,
                  searchParams: _vm.searchParams
                },
                on: {
                  search: function($event) {
                    return _vm.searchVehicles($event, false)
                  },
                  close: function($event) {
                    _vm.searchDialog = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "calculate-widths": "",
          headers: _vm.headers,
          items: _vm.vehicles,
          "item-key": "id",
          loading: _vm.loading,
          "server-items-length": _vm.searchTotalCount,
          options: _vm.options,
          "footer-props": { itemsPerPageOptions: _vm.itemsPerPageOptions }
        },
        on: {
          "update:options": function($event) {
            _vm.options = $event
          },
          "click:row": _vm.openVehicleDialog
        },
        scopedSlots: _vm._u([
          {
            key: "item.vehicleId",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.vehicleNumber",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.fleetId",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("span", [_vm._v(_vm._s(_vm.vehicleStatusToString(value)))])
              ]
            }
          },
          {
            key: "item.dispatchState",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.dispatchStateToString(value)))
                ])
              ]
            }
          },
          {
            key: "item.taxiMeterState",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("span", [
                  _vm._v(" " + _vm._s(_vm.taxiMeterStateToString(value)))
                ])
              ]
            }
          },
          {
            key: "item.lastActive",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-row",
                  { attrs: { align: "center", justify: "space-around" } },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.openVehicleMap(item)
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-map-marker")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Open map")])]
                    ),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.deleteVehicle(
                                              item.vehicleId
                                            )
                                          }
                                        }
                                      },
                                      on
                                    ),
                                    [_c("v-icon", [_vm._v("mdi-delete")])],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v("Delete vehicle")])]
                    ),
                    _vm.$AuthService.hasServiceRole("gateway", "edit")
                      ? _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            attrs: { icon: "" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.openVehicleCacheDialog(
                                                  item.vehicleId
                                                )
                                              }
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _c("v-icon", [_vm._v("mdi-car-info")])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [_c("span", [_vm._v("Show Cache")])]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _c("VehicleDialog", {
        attrs: { isOpen: _vm.vehicleDialog, vehicle: _vm.vehicle },
        on: {
          close: function($event) {
            _vm.vehicleDialog = false
          },
          saveContactInformation: _vm.saveContactInformation,
          saveVehicleInfo: _vm.saveVehicleInfo,
          addFleetMembership: _vm.addFleetMembership,
          saveFleetMembership: _vm.saveFleetMembership,
          deleteFleetMembership: _vm.deleteFleetMembership,
          addNewAttribute: _vm.addNewAttribute,
          saveAttribute: _vm.saveAttribute,
          deleteAttribute: _vm.deleteAttribute,
          addNewProperty: _vm.addNewProperty,
          saveProperty: _vm.saveProperty,
          deleteProperty: _vm.deleteProperty,
          addNewTariffImport: _vm.addNewTariffImport,
          saveTariffImport: _vm.saveTariffImport,
          saveTariffId: _vm.saveTariffId,
          deleteTariffImport: _vm.deleteTariffImport
        }
      }),
      _c("VehicleCreateDialog", {
        attrs: { isOpen: _vm.vehicleCreateDialog },
        on: {
          close: function($event) {
            _vm.vehicleCreateDialog = false
          },
          create: _vm.createVehicle
        }
      }),
      _c("VehicleCacheDialog", {
        attrs: {
          isOpen: _vm.vehicleCacheDialog,
          cache: _vm.vehicleCache,
          vehicleId: _vm.vehicleId
        },
        on: {
          removeFromIndex: function(id) {
            return _vm.removeVehicleFromIndex(id)
          },
          invalidateCache: function(id) {
            return _vm.invalidateVehicleCache(id)
          },
          refreshCache: function(id) {
            return _vm.getVehicleCache(id)
          },
          close: function($event) {
            _vm.vehicleCacheDialog = false
          }
        }
      }),
      _c("VehicleMapDialog", {
        attrs: {
          isOpen: _vm.vehicleMapDialog,
          vehicleDispatchStatus: _vm.vehicleDispatchStatus
        },
        on: {
          close: function($event) {
            _vm.vehicleMapDialog = false
          }
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }