import store from "../store";
import i18n from "../plugins/i18n";

const defaultHeaders = () => [
    { text: "",
        value: "eventType",
        show: true,
        width: 1,
        filterable: false,
        toggleText: i18n.t("transportEventTable.headers.eventType"),
        orderById: "EventType" },
    { text: i18n.t("transportEventTable.headers.orderId"), value: "orderId", show: true, orderById: "OrderId" },
    { text: i18n.t("transportEventTable.headers.validationStatus"), value: "validationStatus", width: 1, show: true, orderById: "ValidationStatus" },
    { text: i18n.t("transportEventTable.headers.salesInvoiceStatus"), value: "salesInvoiceStatus", width: 1, show: true, orderById: "SalesInvoiceStatus" },
    { text: i18n.t("transportEventTable.headers.purchaseInvoiceStatus"), value: "purchaseInvoiceStatus", width: 1, show: true, orderById: "PurchaseInvoiceStatus" },
    { text: i18n.t("transportEventTable.headers.startTime"), value: "trip.started", show: true, width: 125, orderById: "TripStarted" },
    { text: i18n.t("transportEventTable.headers.endTime"), value: "trip.ended", show: true, width: 125, orderById: "TripEnded" },
    { text: i18n.t("transportEventTable.headers.startingPoint"), value: "trip.startingPoint", show: true, sortable: false },
    { text: i18n.t("transportEventTable.headers.destination"), value: "trip.destination", show: true, sortable: false },
    { text: i18n.t("transportEventTable.headers.modified"), value: "modified", show: true, width: 125, orderById: "Modified" },
    { text: i18n.t("transportEventTable.headers.receiptNumber"), value: "fare.receiptNumber", show: true, orderById: "ReceiptNumber" },
    { text: i18n.t("transportEventTable.headers.carNumber"), value: "trip.carNumber", show: true, orderById: "CarNumber" },
    { text: i18n.t("transportEventTable.headers.serviceNumber"), value: "fare.paymentTerminalId", show: true, width: 120, orderById: "ServiceNumber" },
    { text: i18n.t("transportEventTable.headers.cost"), value: "fare.cost", show: true, orderById: "Cost" },
    { text: i18n.t("transportEventTable.headers.paymentMethod"), value: "fare.paymentMethod", show: true, orderById: "PaymentMethod" },
    { text: i18n.t("transportEventTable.headers.vatPercent"), value: "fare.vatPercent", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.deductible"), value: "fare.deductible", show: true, orderById: "Deductible" },
    { text: i18n.t("transportEventTable.headers.deductibleVat"), value: "fare.deductibleVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.reimbursement"), value: "fare.reimbursement", show: true, orderById: "Reimbursement" },
    { text: i18n.t("transportEventTable.headers.reimbursementVat"), value: "fare.reimbursementVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.comments"), value: "comments", show: true, width: 190, sortable: false }
];
const likumaHeaders = () => [
    { text: "",
        value: "eventType",
        show: true,
        width: 1,
        filterable: false,
        toggleText: i18n.t("transportEventTable.headers.eventType"),
        orderById: "EventType" },
    { text: i18n.t("transportEventTable.headers.orderId"), value: "orderId", show: true, width: 200, orderById: "OrderId" },
    { text: i18n.t("transportEventTable.headers.validationStatus"), value: "validationStatus", width: 1, show: true, orderById: "ValidationStatus" },
    { text: i18n.t("transportEventTable.headers.salesInvoiceStatus"), value: "salesInvoiceStatus", width: 1, show: true, orderById: "SalesInvoiceStatus" },
    { text: i18n.t("transportEventTable.headers.purchaseInvoiceStatus"), value: "purchaseInvoiceStatus", width: 1, show: true, orderById: "PurchaseInvoiceStatus" },
    { text: i18n.t("transportEventTable.headers.modified"), value: "modified", show: true, width: 125, orderById: "Modified" },
    { text: i18n.t("transportEventTable.headers.modifier"), value: "modifier", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.customerName"), value: "customer.name", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.costCenter"), value: "customer.costCentreId", show: false, orderById: "CostCenter" },
    { text: i18n.t("transportEventTable.headers.receiptNumber"), value: "fare.receiptNumber", show: true, orderById: "ReceiptNumber" },
    { text: i18n.t("transportEventTable.headers.cardNumber"), value: "fare.cardNumber", show: true, orderById: "CardNumber" },
    { text: i18n.t("transportEventTable.headers.likuma.welfareTripType"), value: "trip.welfareTripType", show: true, width: 100, sortable: false },
    { text: i18n.t("transportEventTable.headers.tripType"), value: "trip.tripType", show: true, sortable: false },
    { text: i18n.t("transportEventTable.headers.carNumber"), value: "trip.carNumber", show: true, orderById: "CarNumber" },
    { text: i18n.t("transportEventTable.headers.serviceNumber"), value: "fare.paymentTerminalId", show: true, width: 120, orderById: "ServiceNumber" },
    { text: i18n.t("transportEventTable.headers.startTime"), value: "trip.started", show: true, width: 125, orderById: "TripStarted" },
    { text: i18n.t("transportEventTable.headers.endTime"), value: "trip.ended", show: true, width: 125, orderById: "TripEnded" },
    { text: i18n.t("transportEventTable.headers.paymentMethod"), value: "fare.paymentMethod", show: true, orderById: "PaymentMethod" },
    { text: i18n.t("transportEventTable.headers.routeNumber"), value: "trip.routeNumber", show: false, orderById: "RouteId" },
    { text: i18n.t("transportEventTable.headers.discountAmount"), value: "fare.discountAmount", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.reimbursement"), value: "fare.reimbursement", show: true, orderById: "Reimbursement" },
    { text: i18n.t("transportEventTable.headers.reimbursementVat"), value: "fare.reimbursementVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.deductible"), value: "fare.deductible", show: true, orderById: "Deductible" },
    { text: i18n.t("transportEventTable.headers.deductibleVat"), value: "fare.deductibleVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.cost"), value: "fare.cost", show: true, orderById: "Cost" },
    { text: i18n.t("transportEventTable.headers.extras"), value: "fare.extras", show: true, sortable: false },
    { text: i18n.t("transportEventTable.headers.vatPercent"), value: "fare.vatPercent", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.waitingCost"), value: "fare.waitingCost", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.waitingVat"), value: "fare.waitingVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.startingPoint"), value: "trip.startingPoint", show: true, width: 190, sortable: false },
    { text: i18n.t("transportEventTable.headers.destination"), value: "trip.destination", show: true, width: 190, sortable: false },
    { text: i18n.t("transportEventTable.headers.distance"), value: "fare.distance", show: true, sortable: false },
    { text: i18n.t("transportEventTable.headers.duration"), value: "fare.duration", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.slowDrive"), value: "fare.slowDrive", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.waitingTime"), value: "fare.waitingTime", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.driverId"), value: "trip.driverId", show: true, sortable: false },
    { text: i18n.t("transportEventTable.headers.shiftNumber"), value: "trip.shiftNumber", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.taximeterSerialNumber"), value: "fare.taximeterSerialNumber", show: false, width: 120, orderById: "TaximeterSerialNumber" },
    { text: i18n.t("transportEventTable.headers.paymentType"), value: "fare.paymentType", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.likuma.highTariff"), value: "fare.highTariff", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.likuma.certificateId"), value: "likumaCertificateId", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.comments"), value: "comments", show: true, width: 190, sortable: false }
];
const kelaHeaders = () => [
    { text: "",
        value: "eventType",
        show: true,
        width: 1,
        filterable: false,
        toggleText: i18n.t("transportEventTable.headers.eventType"),
        orderById: "EventType" },
    { text: i18n.t("transportEventTable.headers.orderId"), value: "orderId", show: true, orderById: "OrderId" },
    { text: i18n.t("transportEventTable.headers.validationStatus"), value: "validationStatus", show: true, width: 1, orderById: "ValidationStatus" },
    { text: i18n.t("transportEventTable.headers.salesInvoiceStatus"), value: "salesInvoiceStatus", show: true, width: 1, orderById: "SalesInvoiceStatus" },
    { text: i18n.t("transportEventTable.headers.purchaseInvoiceStatus"), value: "purchaseInvoiceStatus", show: true, width: 1, orderById: "PurchaseInvoiceStatus" },
    { text: i18n.t("transportEventTable.headers.modified"), value: "modified", show: true, width: 125, orderById: "Modified" },
    { text: i18n.t("transportEventTable.headers.routeNumber"), value: "trip.routeNumber", show: false, orderById: "RouteId" },
    { text: i18n.t("transportEventTable.headers.receiptNumber"), value: "fare.receiptNumber", show: true, orderById: "ReceiptNumber" },
    { text: i18n.t("transportEventTable.headers.comments"), value: "comments", show: true, width: 150, sortable: false },
    { text: i18n.t("transportEventTable.headers.carNumber"), value: "trip.carNumber", show: true, orderById: "CarNumber" },
    { text: i18n.t("transportEventTable.headers.serviceNumber"), value: "fare.paymentTerminalId", show: false, orderById: "ServiceNumber" },
    { text: i18n.t("transportEventTable.headers.cost"), value: "fare.cost", show: true, orderById: "Cost" },
    { text: i18n.t("transportEventTable.headers.vatPercent"), value: "fare.vatPercent", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.deductible"), value: "fare.deductible", show: true, orderById: "Deductible" },
    { text: i18n.t("transportEventTable.headers.deductibleVat"), value: "fare.deductibleVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.reimbursement"), value: "fare.reimbursement", show: true, orderById: "Reimbursement" },
    { text: i18n.t("transportEventTable.headers.reimbursementVat"), value: "fare.reimbursementVat", show: false, sortable: false },
    { text: i18n.t("transportEventTable.headers.startTime"), value: "trip.started", show: true, width: 125, orderById: "TripStarted" },
    { text: i18n.t("transportEventTable.headers.endTime"), value: "trip.ended", show: true, width: 125, orderById: "TripEnded" },
    { text: i18n.t("transportEventTable.headers.startingPoint"), value: "trip.startingPoint", show: true, width: 190, sortable: false },
    { text: i18n.t("transportEventTable.headers.destination"), value: "trip.destination", show: true, width: 190, sortable: false },
    { text: i18n.t("transportEventTable.headers.kela.batchNumber"), value: "kelaData.batchNumber", show: true, sortable: false }
];

export const GetHeaders = () => {
    let tenant = store.getters["user/tenantId"];
    if (tenant === "ltx") return likumaHeaders();
    if (tenant === "taksikuutio") return kelaHeaders();
    return defaultHeaders();
};
