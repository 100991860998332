<template>
    <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
                <span class="headline">
                    {{$t(isNewWaitFee ? "invoicing.addNewWaitFee" : "invoicing.editWaitFee")}}
                </span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col class="pl-0">
                                <v-select
                                    v-model="selectedWaitFeeType"
                                    :items="allowedWaitFeeTypes"
                                    :rules="[v => !!v || 'Wait fee type is required']"
                                    :label="this.$t('invoicing.waitFeeType')"
                                    required
                                ></v-select>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="waitFee.hourPrice"
                                    :label="this.$t('invoicing.hourPrice')"
                                    type="number"
                                    :rules="[
                                        v => !!v || 'Hour price cannot be empty',
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Hour price is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Hour price cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="waitFee.minutes"
                                    :label="this.$t('invoicing.time')"
                                    type="number"
                                    counter=4
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="waitFee.price"
                                    :label="this.$t('invoicing.price')"
                                    type="number"
                                    :rules="[
                                        v => !!v || 'Price cannot be empty',
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Price is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Price cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn v-if="isNewWaitFee"
                    :disabled="!valid"
                    @click="createWaitFee"
                    color="success">
                    {{$t("invoicing.addWaitFee")}}
                </v-btn>
                <v-btn v-else
                    :disabled="!valid"
                    @click="saveWaitFee"
                    color="primary">
                    {{$t("common.saveChanges")}}
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.close")}}
                  </v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { WaitFeeTypeMixin } from "../../../models/WaitFeeTypes";
import { FeeType } from "../../../models/FeeTypes";
import { FareType } from "../../../models/FareTypes";
import _ from "lodash";

export default {
    mixins: [WaitFeeTypeMixin],
    props: {
        originalWaitFee: {
            type: Object,
            default: () => {}
        },
        allowedWaitFeeTypes: Array,
        isNewWaitFee: {
            type: Boolean,
            default: true
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        useLikumaTexts: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            waitFee: {
                type: FeeType.WaitFee,
                fareType: FareType.Wait },
            valid: true,
            selectedWaitFeeType: this.originalWaitFee.tariffCategory,
            selectedWaitFeeClass: this.originalWaitFee.class,
            waitFeeClasses: [
                { value: "K", text: "Kassa-ajo" },
                { value: "1", text: "1-4 henkilöä" },
                { value: "2", text: "Yli 4 henkilöä" }
            ]
        };
    },
    watch: {
        originalWaitFee: function(newVal, oldVal) {
            this.waitFee = _.cloneDeep(this.originalWaitFee);
            this.waitFee.type = FeeType.WaitFee;
            this.waitFee.fareType = FareType.Wait;
            this.selectedWaitFeeType = this.waitFee.tariffCategory;
        },
        isOpen: function(val, oldVal) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        selectedWaitFeeType: function(val, oldVal) {
            this.waitFee.tariffCategory = val;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        }
    },
    methods: {
        saveWaitFee() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", this.waitFee);
        },
        createWaitFee() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", this.waitFee);
        }
    }
};
</script>

<style scoped>
</style>
