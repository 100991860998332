import { GetHeaders } from "../../models/TransportEventTableHeaders";

// initial state
const state = {
    headers: []
};

// getters
const getters = {
};

// actions
const actions = {
    async updateHeaders({ commit }, headers) {
        commit("SET_HEADERS", headers);
    },
    async resetHeaders({ commit }) {
        commit("SET_HEADERS", GetHeaders());
    }
};

// mutations
const mutations = {
    SET_HEADERS(state, headers) {
        state.headers = headers;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
