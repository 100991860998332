<template>
    <v-dialog v-model="isOpen" max-width="1400" @click:outside="$emit('close')">
        <v-card>
            <v-toolbar short dark color="primary">
                <v-btn icon dark @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                {{$t("invoicing.toggleColumnsTitle")}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn text dark @click="hideAllColumns">
                {{$t("invoicing.hideAllColumns")}}
                </v-btn>
                <v-btn text dark @click="showAllColumns">
                    {{$t("invoicing.showAllColumns")}}
                </v-btn>
                <v-btn text dark @click="resetColumns">
                    {{$t("invoicing.resetDefaults")}}
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row class="mt-1">{{$t("invoicing.dragColumnsHint")}}</v-row>
                <draggable class="row" v-model="headers">
                    <v-col v-for="(header, n) in togglableHeaders" :key="n" cols="4" md="2">
                        <v-switch
                            v-model="header.show"
                            :label="header.toggleText || header.text"
                        />
                    </v-col>
                </draggable>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
export default {
    props: {
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    components: {
        draggable
    },
    computed: {
        headers: {
            get() {
                return this.$store.state.transportEventTablePersisted.headers;
            },
            set(value) {
                this.$store.dispatch("transportEventTablePersisted/updateHeaders", value);
            }
        },
        togglableHeaders() {
            return this.headers.filter(h => h.togglable !== false);
        }
    },
    watch: {
        headers: {
            handler(val) {
                this.$store.dispatch("transportEventTablePersisted/updateHeaders", val);
            },
            deep: true
        }
    },
    methods: {
        hideAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = false;
            }
        },
        showAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = true;
            }
        },
        resetColumns() {
            this.$store.dispatch("transportEventTablePersisted/resetHeaders");
        }
    }
};
</script>

<style>

</style>
