export const Comparators = {
    Unknown: 0,
    EqualTo: 1,
    NotEqualTo: 2,
    IsGreaterThan: 3,
    IsLesserThan: 4
};

export const ActionTypes = {
    Unknown: 0,
    ChangeValue: 1,
    UpdateVats: 2,
    SetInternalError: 3,
    RemoveExtraFee: 4
};

export const TransportEventConditionProperties = {
    EventType: "EventType",
    Cost: "Cost",
    TotalCharge: "TotalCharge",
    Deductible: "Deductible",
    WelfareTripType: "WelfareTripType",
    VatPercent: "VatPercent",
    HandicapFee: "HandicapFee",
    ValidationFailReasonCode: "ValidationFailReasonCode"
};

export const RulesMixin = {
    data() {
        return {
            comparators: Comparators,
            actionTypes: ActionTypes,
            transportEventConditionProperties: TransportEventConditionProperties
        };
    },
    filters: {
        comparatorToSymbol: function(value) {
            switch (value) {
            case Comparators.Unknown:
                return "Not determined";
            case Comparators.EqualTo:
                return "=";
            case Comparators.NotEqualTo:
                return "!=";
            case Comparators.IsGreaterThan:
                return ">";
            case Comparators.IsLesserThan:
                return "<";
            };
        },
        actionToText: function(value) {
            switch (value.type) {
            case ActionTypes.ChangeValue:
                return "Set " + value.property + " value to " + value.value;
            case ActionTypes.UpdateVats:
                return "Update VAT values.";
            case ActionTypes.SetInternalError:
                return "Set event to internal validation failed state. Fail reason: " + value.value;
            case ActionTypes.RemoveExtraFee:
                return "Remove Extra Fee of type " + value.value;
            default:
                return "Error in action. Please fix the action.";
            }
        },
        actionTypeToText: function(value) {
            switch (value) {
            case ActionTypes.ChangeValue:
                return "Set new value to property";
            case ActionTypes.UpdateVats:
                return "Update VAT values";
            case ActionTypes.SetInternalError:
                return "Set event to internal validation error";
            }
        }
    }
};
