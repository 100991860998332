export const IntegrationSource = {
    Unknown: 0,
    RG: 1,
    MTI: 2,
    Vinka: 4,
    Semel: 8
};

export function IntegrationSourceToString(integrationSource) {
    switch (integrationSource) {
    case IntegrationSource.Unknown:
        return "-";
    case IntegrationSource.RG:
        return "RG";
    case IntegrationSource.MTI:
        return "MTI";
    case IntegrationSource.Vinka:
        return "Vinka";
    case IntegrationSource.Semel:
        return "Semel";
    }
};
