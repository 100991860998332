<template>
	<v-navigation-drawer
		v-model="drawer"
		expand-on-hover
		mini-variant-width="75"
		:width="275"
		clipped
		app
		color="#f0f2f5"
		light
	>
		<SideMenuAvatar :username="username"></SideMenuAvatar>

		<v-divider></v-divider>

		<v-list nav dense>
			<NavItem
				v-if="showHome"
				:icon="'house'"
				:title="this.$t('home.title')"
				:routeName="'Home'"
			></NavItem>
			<NavItem
				v-if="showValidations"
				:icon="'playlist_add_check'"
				:title="this.$t('validations.title')"
				:routeName="'Validations'"
			></NavItem>
			<NavItem
				v-if="showPricing"
				:icon="'euro'"
				:title="this.$t('pricing.title')"
				:routeName="'Pricing'"
			></NavItem>
			<NavItem
				v-if="showUserManagement"
				:icon="'people'"
				:title="this.$t('userManagement.title')"
				:routeName="'User Management'"
			></NavItem>
			<NavItem
				v-if="showInvoicing"
				:icon="'mdi-receipt'"
				:title="this.$t('invoicing.title')"
				:routeName="'Invoicing'">
			</NavItem>
			<NavItem
				v-if="showInvoicing"
				:icon="'mdi-tune'"
				:title="this.$t('invoicingRules.title')"
				:routeName="'InvoicingRules'"
			></NavItem>
			<NavItem
				v-if="showVehicles"
				:icon="'mdi-car'"
				:title="this.$t('vehicles.title')"
				:routeName="'Vehicles'"
			></NavItem>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
import NavItem from "./NavItem";
import SideMenuAvatar from "./SideMenuAvatar";

export default {
    components: {
        NavItem,
        SideMenuAvatar
    },
    data() {
        return {
            drawer: null,
            showHome: true,
            showUserManagement: false,
            showValidations: false,
            showPricing: false,
            showInvoicing: false,
            showPurchaseInvoicing: false,
            showVehicles: false
        };
    },
    props: {
        user: Object
    },
    watch: {
        user: {
            handler(val) {
                this.showInvoicing = this.$AuthService.canAccessSite("paymentmanagement");
                this.showUserManagement = this.$AuthService.canAccessSite("usermanagement");
                this.showValidations = this.$AuthService.canAccessSite("validation");
                this.showPricing = this.$AuthService.canAccessSite("pricing");
                this.showVehicles = this.$AuthService.canAccessSite("vehicle") && this.ltxSpecificNavItems;
                this.showPurchaseInvoicing = this.$AuthService.canAccessSite("paymentmanagement") && this.ltxSpecificNavItems;
            },
            immediate: true,
            deep: true
        },
        ltxSpecificNavItems(val) {
            if (val) {
                this.showPurchaseInvoicing = this.$AuthService.canAccessSite("paymentmanagement");
                this.showVehicles = this.$AuthService.canAccessSite("vehicle");
            } else {
                this.showPurchaseInvoicing = false;
                this.showVehicles = false;
            }
        }
    },
    computed: {
        username() {
            return this.user.username;
        },
        ltxSpecificNavItems() {
            return this.$store.state.user.tenant === "ltx";
        }
    },
    methods: {
        toggle() {
            this.drawer = !this.drawer;
        }
    }
};

</script>

<style scoped>
.v-list-item {
	cursor: pointer;
}
.v-navigation-drawer--mini-variant .v-list-item {
	-webkit-box-pack: left;
	-ms-flex-pack: left;
	justify-content: left;
}
</style>
