<template>
  <AttributesTable
    :attributes="editableDriver.attributes"
    :editableAttributeOrigin="editableAttributeOrigin"
    @addNewAttribute="addNewAttribute"
    @saveAttribute="saveAttribute"
    @deleteAttribute="deleteAttribute">
  </AttributesTable>
</template>

<script>
import AttributesTable from "../../Shared/AttributesTable";
import { AttributeOrigin } from "../../../models/AttributeOrigin";
import _ from "lodash";

export default {
    components: { AttributesTable },
    props: {
        driver: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            editableDriver: _.cloneDeep(this.driver),
            editableAttributeOrigin: AttributeOrigin.RG
        };
    },
    watch: {
        driver(val) {
            this.editableDriver = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        addNewAttribute: function(newAttribute) {
            this.$emit("addNewAttribute", newAttribute);
        },
        saveAttribute: function(attribute) {
            this.$emit("saveAttribute", attribute);
        },
        deleteAttribute: function(attribute) {
            this.$emit("deleteAttribute", attribute);
        }
    }
};
</script>
