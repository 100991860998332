<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-text>
        <v-form ref="form" v-if="editableProperty" :key="refreshValidation">
          <v-row>
              <v-col cols="4">
                <v-text-field
                    :value="propertyOriginToString(editableProperty.origin)"
                    label="Origin"
                    :disabled="true"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      v-model="editableProperty.key"
                      label="Key"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('key')"
                      :disabled="!isNew"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      v-model="editableProperty.value"
                      label="Value"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('value')"
                  ></v-text-field>
              </v-col>
          </v-row>
          <v-row>
              <v-col cols="4">
                <v-checkbox
                  v-model="editableProperty.hidden"
                  label="Hidden"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="editableProperty.protected"
                  label="Protected"
                ></v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox
                  v-model="editableProperty.canBeOverwritten"
                  label="Can Be Overwritten"
                ></v-checkbox>
              </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="addNewProperty" v-if="isNew">Add</v-btn>
        <v-btn @click="saveProperty" v-if="!isNew">Save</v-btn>
        <v-btn @click="$emit('cancel')">Cancel</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { PropertyOrigin, PropertyOriginToString } from "../../../models/PropertyOrigin";
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import InputValidationMixin from "../../Inputs/InputValidationMixin";
import EventListenerMixin from "../../../Mixins/EventListenerMixin";
import _ from "lodash";

export default {
    mixins: [ InputRuleMixin, InputValidationMixin, EventListenerMixin ],
    props: {
        property: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean,
        propertyOrigin: PropertyOrigin
    },
    data() {
        return {
            editableProperty: this.property ? _.cloneDeep(this.property) : {
                origin: this.propertyOrigin
            },
            propertyOrigins: Object.values(PropertyOrigin)
        };
    },
    watch: {
        property(val) {
            this.editableProperty = (!val) ? {
                origin: this.propertyOrigin
            } : _.cloneDeep(val);
        }
    },
    computed: {
        isNew() {
            return !this.property;
        }
    },
    methods: {
        addNewProperty: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("addNewProperty", this.editableProperty, this.eventTargetId);
        },
        saveProperty: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("saveProperty", this.editableProperty, this.eventTargetId);
        },
        deleteProperty: function() {
            this.$emit("deleteProperty", this.editableProperty, this.eventTargetId);
        },
        propertyOriginToString(propertyOrigin) {
            return PropertyOriginToString(propertyOrigin);
        }
    },
    created() {
        this.$on("success", (e) => {
            this.$emit("cancel");
        });
    }
};
</script>
