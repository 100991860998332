import { userManagementService } from "./repository";

const version = "/v1";

// function getForOperator () {
//   return {
//     for: 1,
//     forId: this.$store.state.user.operator
//   };
// }

function getForQueryString(operatorId) {
    return `for=1&forId=${operatorId}`;
}

// function getForTenant() {
//   return {
//     for: 2,
//     forId: this.$store.state.user.tenant
//   }
// }

export default {
    async createUser(payload, operatorId) {
    // const forOperator = getForOperator();
        payload.for = 1;
        payload.forId = operatorId;
        if (payload.allowTaksini) {
            return userManagementService.post(`${version}/users/taksini`, payload);
        }
        return userManagementService.post(`${version}/users`, payload);
    },
    async findCustomers(operatorId) {
        return userManagementService.get(`${version}/companies?${getForQueryString(operatorId)}`);
    },
    async addUserToCustomer(payload, customerId, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;

        return userManagementService.post(`${version}/companies/${customerId}/users`, payload);
    },
    async getCustomer(customerId, operatorId) {
        return userManagementService.get(`${version}/companies/${customerId}?${getForQueryString(operatorId)}`);
    },
    async getCustomerFavoriteAddresses(customerId, operatorId) {
        return userManagementService.get(`${version}/companies/${customerId}/favoriteAddresses?${getForQueryString(operatorId)}`);
    },
    async addCustomerFavoriteAddress(payload, customerId, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;
        payload.customerId = customerId;

        return userManagementService.post(`${version}/companies/${customerId}/favoriteAddresses`, payload);
    },
    async updateCustomerFavoriteAddress(payload, customerId, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;
        payload.customerId = customerId;

        return userManagementService.put(`${version}/companies/${customerId}/favoriteAddresses/${payload.favoriteAddressId}`, payload);
    },
    async deleteCustomerFavoriteAddress(customerId, favoriteAddressId, operatorId) {
        return userManagementService.delete(`${version}/companies/${customerId}/favoriteAddresses/${favoriteAddressId}?${getForQueryString(operatorId)}`);
    },
    async addCustomerVehicleAttributes(attributes, customerId, operatorId) {
        const payload = {
            for: 1,
            forId: operatorId,
            customerId: customerId,
            defaultCarAttributes: attributes
        };

        return userManagementService.patch(`${version}/companies/${customerId}/defaultCarAttributes`, payload);
    },
    async getUsersByCustomerId(customerId, operatorId) {
        const { data: { results: users } } = await userManagementService.get(`${version}/companies/${customerId}/users?${getForQueryString(operatorId)}`);
        let usersList = [];
        for (let companyUser of users) {
            usersList.push({
                userId: companyUser.userId,
                email: companyUser.user.profiles[0].email,
                firstName: companyUser.user.profiles[0].firstName,
                middleName: companyUser.user.profiles[0].middleName,
                lastName: companyUser.user.profiles[0].lastName,
                fullName: companyUser.user.profiles[0].fullName,
                phone: companyUser.user.profiles[0].phoneNumber,
                costCenter: companyUser.costCenter,
                beneficiaryId: companyUser.beneficiaryId,
                allowBilling: companyUser.allowBilling,
                allowCard: companyUser.allowCard,
                sourceId: companyUser.user.profiles[0].sourceId
            });
        }

        return usersList;
    },
    async getCompaniesByUserId(userId, operatorId) {
        return userManagementService.get(`${version}/users/${userId}/companies?${getForQueryString(operatorId)}`);
    },
    async createCustomer(payload, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;

        return userManagementService.post(`${version}/companies`, payload);
    },
    async updateCustomer(payload, customerId, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;

        return userManagementService.patch(`${version}/companies/${customerId}`, payload);
    },
    async updateCustomerUser(payload, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;

        return userManagementService.patch(`${version}/companies/${payload.customerId}/users/${payload.userId}`, payload);
    },
    async updateUserContactInformation(payload, operatorId) {
        payload.for = 1;
        payload.forId = operatorId;

        return userManagementService.patch(`${version}/users/${payload.userId}/contactInformation`, payload);
    },
    async deleteCustomerUser(customerId, userId, operatorId) {
        return userManagementService.delete(`${version}/companies/${customerId}/users/${userId}?${getForQueryString(operatorId)}`);
    },
    async createRegistrationCode(payload, operatorId) {
    // const forOperator = getForOperator();
        payload.for = 1;
        payload.forId = operatorId;

        return userManagementService.post(`${version}/registrationcodes`, payload);
    }
};
