<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
  <v-card>
    <v-toolbar short dark color="primary">
      <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
          Create Driver
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-row class="mt-3">
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.driverId"
                  :rules="[ requiredRule ]"
                  label="Driver Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.userId"
                  label="User Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.companyId"
                  :rules="[ requiredRule ]"
                  label="Company Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.fleetId"
                  :rules="[ requiredRule ]"
                  label="Fleet Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.firstName"
                  :rules="[ requiredRule ]"
                  label="First Name"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.lastName"
                  :rules="[ requiredRule ]"
                  label="Last Name"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.email"
                  :rules="[ emailRule, requiredRule ]"
                  label="Email"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newDriver.phoneNumber"
                  :rules="[ phoneNumberRule, requiredRule ]"
                  label="Phone Number"
              ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      class="ml-2"
      @click="create"
      >
        Create
      </v-btn>
      <v-btn
        @click="cancel"
        class="ml-2">
        {{$t("common.cancel")}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import moment from "moment";

export default {
    mixins: [ InputRuleMixin ],
    props: {
        isOpen: Boolean
    },
    data() {
        return {
            newDriver: {}
        };
    },
    methods: {
        create: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", {
                driverId: this.newDriver.driverId,
                userId: this.newDriver.userId,
                companyId: this.newDriver.companyId,
                fleetId: this.newDriver.fleetId,
                firstName: this.newDriver.firstName,
                lastName: this.newDriver.lastName,
                email: this.newDriver.email,
                phoneNumber: this.newDriver.phoneNumber
            });
        },
        cancel() {
            this.newDriver = {};
            this.$emit("close");
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
