var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              "calculate-widths": "",
              headers: _vm.showableHeaders,
              items: _vm.editableFleetMemberships,
              "item-key": "fleetId",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              search: _vm.filter,
              "items-per-page": 10
            },
            scopedSlots: _vm._u([
              {
                key: "item.companyId",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.fleetId",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.isMain",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: !_vm.isEditable(item) },
                            on: {
                              click: function($event) {
                                return _vm.deleteFleetMembership(item)
                              }
                            }
                          },
                          [_vm._v(" Delete ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pb-4" },
        [
          _c("v-spacer"),
          _c("v-btn", { on: { click: _vm.createFleetMembership } }, [
            _vm._v("Create")
          ]),
          _c("v-spacer")
        ],
        1
      ),
      _c("FleetMembershipForm", {
        attrs: {
          isOpen: _vm.isEditFormOpen,
          fleetMembership: _vm.selectedFleetMembership,
          canAddNew: true,
          result: _vm.result
        },
        on: {
          cancel: function($event) {
            _vm.isEditFormOpen = false
          },
          addFleetMembership: _vm.addFleetMembership,
          saveFleetMembership: _vm.saveFleetMembership,
          deleteFleetMembership: _vm.deleteFleetMembership
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }