<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row align="center">
        <v-col style="font-weight: bold; font-size: 1rem;">{{ rule.name }}</v-col>
        <v-spacer></v-spacer>
        <v-col sm=2>
          <v-row v-if="$route.query.edit" justify="end" class="mr-2">
            <v-icon color="primary" @click.stop="$emit('modify', rule)">mdi-pencil</v-icon>
            <v-icon color="error" class="ml-2" @click.stop="$emit('delete', rule)">delete</v-icon>
          </v-row>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col cols="12" md="4" align-self="start">
          <b>Conditions</b>
          <ul>
            <li
              v-for="(condition, i) in rule.conditions"
              :key="i">
              {{ condition.value }} {{ condition.comparator | comparatorToSymbol }} {{ condition.target }}
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="8" align-self="start">
          <b>Actions</b>
          <ul>
            <li
              v-for="(action, i) in rule.actions"
              :key="i">
              {{ action | actionToText }}
            </li>
          </ul>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { RulesMixin } from "@/models/Rules";
export default {
    props: {
        rule: Object
    },
    mixins: [ RulesMixin ]
};
</script>

<style scoped>
.v-expansion-panel-header {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
