export default {
    props: {
    },
    data() {
    },
    watch: {
    },
    methods: {
        convertOptionsToApiQuery(options) {
            const orderBy = [];

            options.sortBy.forEach((element, index) => {
                const desc = options.sortDesc[index];
                if (desc) {
                    orderBy[index] = "Desc" + element;
                } else {
                    orderBy[index] = "Asc" + element;
                }
            });

            return {
                top: options.itemsPerPage,
                page: options.page,
                orderBy: orderBy
            };
        },
        convertApiQueryToOptions(query) {
            const sortBy = [];
            const sortDesc = [];

            if (query.orderBy) {
                if (Array.isArray(query.orderBy)) {
                    query.orderBy.forEach((element, index) => {
                        const desc = element.startsWith("Desc");
                        if (desc) {
                            sortBy[index] = element.substring(4);
                            sortDesc[index] = true;
                        } else {
                            sortBy[index] = element.substring(3);
                            sortDesc[index] = false;
                        }
                    });
                } else if (typeof query.orderBy === "string" && query.orderBy.length > 3) {
                    const desc = query.orderBy.startsWith("Desc");
                    if (desc) {
                        sortBy[0] = query.orderBy.substring(4);
                        sortDesc[0] = true;
                    } else {
                        sortBy[0] = query.orderBy.substring(3);
                        sortDesc[0] = false;
                    }
                }
            }
            return {
                itemsPerPage: +query.top,
                page: +query.page,
                sortBy: sortBy,
                sortDesc: sortDesc
            };
        }
    }
};
