<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
  <v-card>
    <v-toolbar short dark color="primary">
      <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
          Create Company
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" :key="refreshValidation">
        <v-row class="mt-3">
          <v-col cols="6">
              <v-text-field
                  v-model="newCompany.companyId"
                  :rules="[ requiredRule ]"
                  :error-messages="validationErrorsFor('companyId')"
                  label="Company Id"
              ></v-text-field>
          </v-col>
          <v-col cols="6">
              <v-text-field
                  v-model="newCompany.name"
                  :rules="[ requiredRule ]"
                  label="Name"
              ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      class="ml-2"
      @click="create"
      >
        Create
      </v-btn>
      <v-btn
        @click="cancel"
        class="ml-2">
        {{$t("common.cancel")}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import InputValidationMixin from "../../Inputs/InputValidationMixin";
import moment from "moment";

export default {
    mixins: [ InputRuleMixin, InputValidationMixin ],
    props: {
        isOpen: Boolean
    },
    data() {
        return {
            newCompany: {}
        };
    },
    methods: {
        create: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", {
                companyId: this.newCompany.companyId,
                name: this.newCompany.name
            });
        },
        cancel() {
            this.newCompany = {};
            this.$emit("close");
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
