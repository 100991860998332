var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.header))
            ])
          ]),
          _c(
            "v-card-text",
            { staticClass: "mt-5" },
            [
              _vm.isTaksiniUser
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("customerDetails.userDialog.taksiniUserEditing")
                      )
                    )
                  ])
                : _vm._e(),
              _c(
                "v-form",
                {
                  ref: "userForm",
                  staticClass: "mt-5",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _vm.userId
                    ? _c("v-text-field", {
                        attrs: {
                          label: _vm.$t("customerDetails.userDialog.userId"),
                          readonly: "",
                          disabled: "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.userId,
                          callback: function($$v) {
                            _vm.userId = $$v
                          },
                          expression: "userId"
                        }
                      })
                    : _vm._e(),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.firstName"),
                      disabled: _vm.isTaksiniUser,
                      required: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.firstName,
                      callback: function($$v) {
                        _vm.firstName = $$v
                      },
                      expression: "firstName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.middleName"),
                      disabled: _vm.isTaksiniUser,
                      outlined: ""
                    },
                    model: {
                      value: _vm.middleName,
                      callback: function($$v) {
                        _vm.middleName = $$v
                      },
                      expression: "middleName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.lastName"),
                      disabled: _vm.isTaksiniUser,
                      outlined: ""
                    },
                    model: {
                      value: _vm.lastName,
                      callback: function($$v) {
                        _vm.lastName = $$v
                      },
                      expression: "lastName"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.costCenter"),
                      outlined: ""
                    },
                    model: {
                      value: _vm.costCenter,
                      callback: function($$v) {
                        _vm.costCenter = $$v
                      },
                      expression: "costCenter"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.beneficiaryId"),
                      outlined: ""
                    },
                    model: {
                      value: _vm.beneficiaryId,
                      callback: function($$v) {
                        _vm.beneficiaryId = $$v
                      },
                      expression: "beneficiaryId"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "matti@gmail.com",
                      label: _vm.$t("customerDetails.userDialog.email"),
                      disabled: _vm.isTaksiniUser,
                      rules: [_vm.requiredRule, _vm.emailRule],
                      required: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "358501234567",
                      label: _vm.$t("customerDetails.userDialog.phone"),
                      disabled: _vm.isTaksiniUser,
                      rules: [_vm.requiredRule, _vm.phoneNumberRule],
                      required: "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  }),
                  _c("v-switch", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.allowBilling"),
                      rules: [_vm.billingMethodRequiredRule]
                    },
                    model: {
                      value: _vm.allowBilling,
                      callback: function($$v) {
                        _vm.allowBilling = $$v
                      },
                      expression: "allowBilling"
                    }
                  }),
                  _c("v-switch", {
                    attrs: {
                      label: _vm.$t("customerDetails.userDialog.allowTaksini"),
                      disabled: _vm.isTaksiniUser,
                      hint: _vm.$t(
                        "customerDetails.userDialog.allowTaksiniHint"
                      ),
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.allowTaksini,
                      callback: function($$v) {
                        _vm.allowTaksini = $$v
                      },
                      expression: "allowTaksini"
                    }
                  }),
                  _vm.allowTaksini
                    ? _c("v-switch", {
                        attrs: {
                          label: _vm.$t("customerDetails.userDialog.allowCard"),
                          rules: [_vm.billingMethodRequiredRule]
                        },
                        model: {
                          value: _vm.allowCard,
                          callback: function($$v) {
                            _vm.allowCard = $$v
                          },
                          expression: "allowCard"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              !_vm.editUser
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary" },
                      on: { click: _vm.create }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.create")))]
                  )
                : _vm._e(),
              _vm.editUser
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary" },
                      on: { click: _vm.update }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.update")))]
                  )
                : _vm._e(),
              _vm.userId
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.deleteUser }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.delete")))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }