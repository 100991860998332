export default {
    badge: "Infopiste",
    close: "Sulje",
    dataIterator: {
        noResultsText: "Ei osumia",
        loadingText: "Ladataan kohteita..."
    },
    dataTable: {
        itemsPerPageText: "Rivejä sivulla:",
        ariaLabel: {
            sortDescending: ": Järjestetty laskevasti. Poista järjestäminen aktivoimalla.",
            sortAscending: ": Järjestetty nousevasti. Järjestä laskevasti aktivoimalla.",
            sortNone: ": Ei järjestetty. Järjestä nousevasti aktivoimalla.",
            activateNone: "Aktivoi lajittelun poistamiseksi.",
            activateDescending: "Aktivoi laskevien laskevien lajittelemiseksi.",
            activateAscending: "Aktivoi lajitella nouseva."
        },
        sortBy: "Järjestä"
    },
    dataFooter: {
        itemsPerPageText: "Kohteita sivulla:",
        itemsPerPageAll: "Kaikki",
        nextPage: "Seuraava sivu",
        prevPage: "Edellinen sivu",
        firstPage: "Ensimmäinen sivu",
        lastPage: "Viimeinen sivu",
        pageText: "{0}-{1} ({2})"
    },
    datePicker: {
        itemsSelected: "{0} valittu",
        nextMonthAriaLabel: "Seuraava kuukausi",
        nextYearAriaLabel: "Ensi vuosi",
        prevMonthAriaLabel: "Edellinen kuukausi",
        prevYearAriaLabel: "Edellinen vuosi"
    },
    noDataText: "Ei dataa",
    carousel: {
        prev: "Edellinen kuva",
        next: "Seuraava kuva",
        ariaLabel: {
            delimiter: "Karusellin kuva {0}/{1}"
        }
    },
    calendar: {
        moreEvents: "{0} lisää"
    },
    fileInput: {
        counter: "{0} tiedostoa",
        counterSize: "{0} tiedostoa ({1} yhteensä)"
    },
    timePicker: {
        am: "ap.",
        pm: "ip."
    },
    pagination: {
        ariaLabel: {
            wrapper: "Pagination Navigation",
            next: "Next page",
            previous: "Previous page",
            page: "Goto Page {0}",
            currentPage: "Current Page, Page {0}"
        }
    }
}
;
