var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      "calculate-widths": "",
      headers: _vm.showableHeaders,
      items: _vm.offers,
      "item-key": "id",
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      search: _vm.filter,
      loading: _vm.loadingPage,
      page: _vm.page,
      "items-per-page": _vm.itemsPerPage,
      "footer-props": {
        pagination: _vm.pagination,
        itemsPerPageOptions: _vm.itemsPerPageOptions
      }
    },
    on: {
      "update:page": function($event) {
        _vm.page = $event
      },
      "update:itemsPerPage": function($event) {
        _vm.itemsPerPage = $event
      },
      "update:items-per-page": function($event) {
        _vm.itemsPerPage = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "item.id",
        fn: function(ref) {
          var value = ref.value
          return [_c("span", [_vm._v(" " + _vm._s(value))])]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }