<template>
  <v-container fluid>
        <DriversTable></DriversTable>
  </v-container>
</template>

<script>
import DriversTable from "../components/Drivers/DriversTable";

export default {
    components: { DriversTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
