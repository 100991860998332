<template>
  <PropertiesTable
    :properties="editableFleet.properties"
    :editablePropertyOrigin="editablePropertyOrigin"
    @cancel="isEditFormOpen=false"
    @addNewProperty="addNewProperty"
    @saveProperty="saveProperty"
    @deleteProperty="deleteProperty">
  </PropertiesTable>
</template>

<script>
import PropertiesTable from "../../Shared/PropertiesTable";
import { PropertyOrigin } from "../../../models/PropertyOrigin";
import _ from "lodash";

export default {
    components: { PropertiesTable },
    props: {
        fleet: {
            type: Object,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            editableFleet: _.cloneDeep(this.fleet),
            editablePropertyOrigin: PropertyOrigin.FleetConfiguration,
            selectedProperty: null,
            isEditFormOpen: false
        };
    },
    watch: {
        fleet(val) {
            this.editableFleet = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        addNewProperty: function(property, eventTargetId) {
            this.$emit("addNewProperty", property, eventTargetId);
        },
        saveProperty: function(property, eventTargetId) {
            this.$emit("saveProperty", property, eventTargetId);
        },
        deleteProperty: function(property, eventTargetId) {
            this.$emit("deleteProperty", property, eventTargetId);
        }
    }
};
</script>
