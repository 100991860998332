import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import invoicing from "./modules/invoicing";
import transportEventTable from "./modules/transportEventTable";
import transportEventTablePersisted from "./modules/transportEventTablePersisted";
import createSalesInvoices from "./modules/createSalesInvoices";
import missingVerifones from "./modules/missingVerifones";
import paytrailTransactions from "./modules/paytrailTransactions";
import invoiceRule from "./modules/invoiceRule";
import vehicles from "./modules/vehicles";
import companies from "./modules/companies";
import gateway from "./modules/gateway";
import fleets from "./modules/fleets";
import drivers from "./modules/drivers";
import bookings from "./modules/bookings";
import trips from "./modules/trips";
import dispatching from "./modules/dispatching";
import geo from "./modules/geo";

Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        user,
        invoicing,
        transportEventTable,
        transportEventTablePersisted,
        createSalesInvoices,
        missingVerifones,
        paytrailTransactions,
        invoiceRule,
        vehicles,
        companies,
        fleets,
        gateway,
        drivers,
        bookings,
        trips,
        dispatching,
        geo
    },
    strict: false,
    plugins: [createPersistedState({
        paths: ["user", "transportEventTablePersisted"]
    })]
});
