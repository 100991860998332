<template>
  <v-select
        v-model='carType'
        :items="carTypes"
        :input="$emit('carTypeUpdated', carTypeUpdated)"
        label="Car Type"
        solo
    ></v-select>
</template>

<script>
export default {
    data: () => ({
        carType: null,
        carTypes: ["Sedan", "Combi", "Minibus", "Any"]
    }),
    computed: {
        carTypeUpdated() {
            return this.carType;
        }
    }
};
</script>

<style>

</style>
