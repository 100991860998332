<template>
  <v-dialog v-model="dialog" max-width="550px">
        <template v-slot:activator="{ on }">
            <v-btn color="primary" v-on="on">New Validation</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Create new Validation</span>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-text-field placeholder="Location validation" :disabled="validated" outlined required v-model="name" label="Name"></v-text-field>
                    <v-text-field placeholder="taksipartners" :disabled="validated" outlined required v-model="operator" label="Operator"></v-text-field>
                    <v-radio-group v-model="status">
                        <template v-slot:label>
                            <h3>Validation status:</h3>
                        </template>
                        <v-radio value="experimental" color="purple">
                            <template v-slot:label>
                            <div><strong class="purple--text">Experiment</strong></div>
                            </template>
                        </v-radio>
                        <v-radio value="active" color="success">
                            <template v-slot:label>
                            <div><strong class="success--text">Active</strong></div>
                            </template>
                        </v-radio>
                        <v-radio value="inactive" color="warning">
                            <template v-slot:label>
                                <div><strong class="warning--text">Inactive</strong></div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group v-model="type">
                        <template v-slot:label>
                            <h3>Validation type:</h3>
                        </template>
                        <v-radio value="allow" color="success">
                            <template v-slot:label>
                            <div><strong class="success--text">Allow</strong></div>
                            </template>
                        </v-radio>
                        <v-radio value="block" color="error">
                            <template v-slot:label>
                            <div><strong class="error--text">Block</strong></div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <h2>Valid through:</h2>
                    <div class="pa-5">
                        <v-date-picker full-width :disabled="validated" required v-model="dates" range></v-date-picker>
                        <h2 class="pt-2" v-if="dates.length > 1">Validation will be valid through:</h2>
                        <h3 class="primary--text">{{ validThroughText }}</h3>
                    </div>
                    <v-btn :disabled="this.$v.$invalid || this.validated" color="success" class="mr-4" @click="validate">Validate / Confirm</v-btn>
                    <v-btn color="red" :disabled="!this.validated" text @click="cancel">Cancel</v-btn>
                    <v-btn color="primary" v-if="validated" class="float-right" @click="create">Create</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    validations: {
        name: { required },
        operator: { required },
        dates: {
            required,
            bothSelected(dates) {
                return dates.length > 1;
            }
        },
        status: { required },
        type: { required }
    },
    data: () => ({
        dialog: false,
        validated: false,
        name: "",
        operator: "",
        dates: ["2019-06-01", "2050-06-01"],
        status: "experimental",
        type: "allow"
    }),
    methods: {
        cancel() {
            this.$v.$reset();
            this.name = "";
            this.operator = "";
            this.dates = [];
            this.status = "experimental";
            this.type = "allow";
            this.validated = false;
        },
        validate() {
            this.validated = true;
        },
        create() {
            this.$emit("createValidation", this.newValidation);
            this.dialog = false;
        }
    },
    computed: {
        newValidation() {
            return {
                name: this.name,
                operatorId: this.operator,
                validThrough: {
                    startTime: this.dates[0],
                    endTime: this.dates[1]
                },
                validationType: this.type,
                status: this.status,
                rules: []
            };
        },
        validThroughText() {
            return this.dates.join(" -> ");
        }
    }
};
</script>

<style>

</style>
