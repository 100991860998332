<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="fleet">
        <v-col
        class="pa-0">
          <v-card>
            <v-toolbar short dark color="primary">
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
                <span>Fleet {{fleet.id}}</span>
            </v-toolbar-title>
          </v-toolbar>
            <v-card-text>
              <v-tabs
                v-model="tab"
                fixed-tabs
                background-color="indigo"
                dark
            >
              <v-tab href="#tab-details">
                Details
              </v-tab>
              <v-tab href="#tab-attributes">
                Attributes
              </v-tab>
              <v-tab href="#tab-properties">
                Properties
              </v-tab>
              <v-tab href="#tab-tariffs">
                Tariffs
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab">
              <v-tab-item
                :key="1"
                :value="'tab-details'">
                <v-card>
                  <v-card-title>Fleet Info</v-card-title>
                  <v-card-text>
                    <FleetBasicInfoForm
                      :fleet="fleet"
                      :editable="true"
                      @save="saveFleetInfo">>
                    </FleetBasicInfoForm>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                :key="2"
                :value="'tab-attributes'">
                <FleetAttributesForm
                  :fleet="fleet"
                  @addNewAttribute="addNewAttribute"
                  @saveAttribute="saveAttribute"
                  @deleteAttribute="deleteAttribute">
                </FleetAttributesForm>
              </v-tab-item>
              <v-tab-item
                :key="3"
                :value="'tab-properties'">
                <FleetPropertiesForm
                  :fleet="fleet"
                  @addNewProperty="addNewProperty"
                  @saveProperty="saveProperty"
                  @deleteProperty="deleteProperty">
                </FleetPropertiesForm>
              </v-tab-item>
              <v-tab-item
                :key="4"
                :value="'tab-tariffs'">
                <FleetTariffsForm
                  :fleet="fleet"
                  @addNewTariffImport="addNewTariffImport"
                  @saveTariffImport="saveTariffImport"
                  @saveTariffId="saveTariffId"
                  @deleteTariffImport="deleteTariffImport">
                </FleetTariffsForm>
              </v-tab-item>
            </v-tabs-items>
            </v-card-text>
            </v-card>
          </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import moment from "moment";
import FleetBasicInfoForm from "../Forms/FleetBasicInfoForm";
import FleetAttributesForm from "../Forms/FleetAttributesForm";
import FleetPropertiesForm from "../Forms/FleetPropertiesForm";
import FleetTariffsForm from "../Forms/FleetTariffsForm";

export default {
    components: {
        FleetBasicInfoForm,
        FleetAttributesForm,
        FleetPropertiesForm,
        FleetTariffsForm
    },
    props: {
        fleet: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            tab: null
        };
    },
    methods: {
        saveContactInformation: function(contactInformation) {
            this.$emit("saveContactInformation", this.fleet.id, contactInformation);
        },
        saveFleetInfo: function(fleetInfo) {
            this.$emit("saveFleetInfo", this.fleet.companyId, this.fleet.id, fleetInfo);
        },
        // Attributes
        addNewAttribute: function(attribute) {
            this.$emit("addNewAttribute", this.fleet.companyId, this.fleet.id, attribute);
        },
        saveAttribute: function(attribute) {
            this.$emit("saveAttribute", this.fleet.companyId, this.fleet.id, attribute);
        },
        deleteAttribute: function(attribute) {
            this.$emit("deleteAttribute", this.fleet.companyId, this.fleet.id, attribute);
        },
        // Properties
        addNewProperty: function(property) {
            this.$emit("addNewProperty", this.fleet.companyId, this.fleet.id, property);
        },
        saveProperty: function(property) {
            this.$emit("saveProperty", this.fleet.companyId, this.fleet.id, property);
        },
        deleteProperty: function(property) {
            this.$emit("deleteProperty", this.fleet.companyId, this.fleet.id, property);
        },
        // Tariff Imports
        addNewTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("addNewTariffImport", this.fleet.companyId, this.fleet.id, tariffImport, eventTargetId);
        },
        saveTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("saveTariffImport", this.fleet.companyId, this.fleet.id, tariffImport, eventTargetId);
        },
        saveTariffId: function(tariffId, eventTargetId) {
            this.$emit("saveTariffId", this.fleet.companyId, this.fleet.id, tariffId, eventTargetId);
        },
        deleteTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("deleteTariffImport", this.fleet.companyId, this.fleet.id, tariffImport, eventTargetId);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
