<template>
  <v-form v-model="searchParamsValid" class="mx-3 mt-2">
    <v-row>
      <v-col cols=12 md=6 class="pl-0 py-0">
        <v-autocomplete
          v-model="updatedSearchParams.cardNumber"
          :items="regions"
          :label="this.$t('invoicing.area')">
        </v-autocomplete>
      </v-col>
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <EventTypeField
          :updatedSearchParams="updatedSearchParams.eventTypes"
          @update:updatedSearchParams="newValues =>
            updatedSearchParams.eventTypes = newValues">
        </EventTypeField>
      </v-col>
    </v-row>
    <v-row align="center" class="mt-3">
      <v-col cols=12 sm=7 class="pl-0 py-0">
        <ValidationStatesField
          :updatedSearchParams="updatedSearchParams.validationStates"
          @update:updatedSearchParams="newValues =>
            updatedSearchParams.validationStates = newValues">
        </ValidationStatesField>
      </v-col>
      <v-col cols=12 md=3 class="pl-0 py-0">
        <v-btn
          @click="setFailedValidations"
          outlined
          small
          class="pt-0"
        >
          Search failed validations
        </v-btn>
      </v-col>
    </v-row>
    <v-slide-y-transition>
      <v-row v-if="updatedSearchParams.validationStates.includes(validationStatusTypes.ExternalValidationFailed)
                    && (updatedSearchParams.validationStates.length === 1 ||
                          (updatedSearchParams.validationStates.length === 2 && updatedSearchParams.validationStates.includes(validationStatusTypes.InternalValidationFailed)))">
        <v-col cols=12 sm=7 class="pl-0 py-0">
          <v-select
            v-model="updatedSearchParams.validationFailReasonCodes"
            :items="Object.keys(likumaValidationErrors)"
            label="Likuma error types"
            multiple
            clearable
          >
            <template slot="selection" slot-scope="{ item }">
              <v-chip>
                {{ item }} - {{ likumaValidationErrors[item] }}
              </v-chip>
            </template>
            <template v-slot:item="{item, attrs, on}">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      {{ item }} - {{ likumaValidationErrors[item] }}
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                      :input-value="active"
                ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-else-if="updatedSearchParams.validationStates.length === 1 && updatedSearchParams.validationStates.includes(validationStatusTypes.New)">
        <v-col cols=12 md=9 class="pl-0 py-0">
          <v-item-group>
            <v-row>
              <v-col>
              <v-item v-slot="{ active, toggle }">
                <v-checkbox
                  :value="updatedSearchParams.missingTrip = active"
                  label="Trip missing"
                  :ripple="false"
                  @click.prevent="toggle">
                </v-checkbox>
              </v-item>
              </v-col>
              <v-col>
              <v-item v-slot="{ active, toggle }">
                <v-checkbox
                :value="updatedSearchParams.missingCharge = active"
                  label="Charge missing"
                  :ripple="false"
                  @click.prevent="toggle">
                </v-checkbox>
              </v-item>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-item-group>
        </v-col>
      </v-row>
    </v-slide-y-transition>
    <v-row align="center">
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <SalesInvoiceStatesField
          :updatedSearchParams="updatedSearchParams.salesInvoiceStates"
          :disabled="!!updatedSearchParams.salesInvoiceNumbers"
          @update:updatedSearchParams="newValues =>
            updatedSearchParams.salesInvoiceStates = newValues">
        </SalesInvoiceStatesField>
      </v-col>
      <v-col cols=12 sm=1 class="py-0">
        <v-subheader>OR</v-subheader>
      </v-col>
      <v-col cols=12 sm=5 class="pl-0 py-0">
        <v-text-field
          v-model="updatedSearchParams.salesInvoiceNumbers"
          label="Sales invoice number"
          @change="updatedSearchParams.salesInvoiceStates = []"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <PurchaseInvoiceStatesField
          :updatedSearchParams="updatedSearchParams.purchaseInvoiceStates"
          :disabled="!!updatedSearchParams.purchaseInvoiceNumbers"
          @update:updatedSearchParams="newValues =>
            updatedSearchParams.purchaseInvoiceStates = newValues">
        </PurchaseInvoiceStatesField>
      </v-col>
      <v-col cols=12 sm=1 class="py-0">
        <v-subheader>OR</v-subheader>
      </v-col>
      <v-col cols=12 sm=5 class="pl-0 py-0">
        <v-text-field
          v-model="updatedSearchParams.purchaseInvoiceNumbers"
          label="Purchase invoice number"
          @change="updatedSearchParams.purchaseInvoiceStates = []"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.carNumber"
          label="Vehicle id"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.serviceNumber"
          label="Service number"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.cardNumber"
          label="Card number"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <PaymentMethodField
          :updatedSearchParams="updatedSearchParams.paymentMethods"
          @update:updatedSearchParams="newValues => updatedSearchParams.paymentMethods = newValues">
        </PaymentMethodField>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.costCenter"
          label="Asiakkuusnumero"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0">Modified</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.modifiedFrom"
          label="Modified from"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
        v-model="updatedSearchParams.modifiedTo"
        label="Modified to"
        type="datetime-local"
        max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0">Trip started</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedFrom"
          label="Trip started from"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedTo"
          label="Trip started to"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0">Trip ended</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedFrom"
          label="Trip ended from"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedTo"
          label="Trip ended to"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0 mb-2">Kustannus</v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.costFrom"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col sm=auto class="hidden-xs-only py-0 pb-3" style="font-size: 1.5em;">
        -
      </v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.costTo"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols=12 sm=2 class="py-0">
        <v-btn text @click="updatedSearchParams.costFrom = null; updatedSearchParams.costTo = null;">{{$t('common.clear')}}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0 mb-2">Korvaus</v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.reimbursementFrom"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col sm=auto class="hidden-xs-only py-0 pb-3" style="font-size: 1.5em;">
        -
      </v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.reimbursementTo"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols=12 sm=2 class="py-0">
        <v-btn text @click="updatedSearchParams.reimbursementFrom = null; updatedSearchParams.reimbursementTo = null;">{{$t('common.clear')}}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0 mb-2">Omavastuu</v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.deductibleFrom"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col sm=auto class="hidden-xs-only py-0 pb-3" style="font-size: 1.5em;">
        -
      </v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.deductibleTo"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols=12 sm=2 class="py-0">
        <v-btn text @click="updatedSearchParams.deductibleFrom = null; updatedSearchParams.deductibleTo = null;">{{$t('common.clear')}}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-switch
        v-model="updatedSearchParams.removed"
        label="Etsi myös poistettuja tapahtumia"
      ></v-switch>
    </v-row>
    </v-form>
</template>

<script>
import _ from "lodash";
import EventTypeField from "./SearchParamFields/EventTypeField";
import PaymentMethodField from "./SearchParamFields/PaymentMethodField";
import PurchaseInvoiceStatesField from "./SearchParamFields/PurchaseInvoiceStatesField";
import SalesInvoiceStatesField from "./SearchParamFields/SalesInvoiceStatesField";
import ValidationStatesField from "./SearchParamFields/ValidationStatesField";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TranportEventValidationErrorMixin } from "@/models/ValidationErrors";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import { PaymentMethodMixin } from "@/models/PaymentMethods";
import { TransportEventTypeMixin } from "@/models/TransportEventTypes";

export default {
    props: {
        searchParams: Object
    },
    components: {
        EventTypeField,
        PaymentMethodField,
        PurchaseInvoiceStatesField,
        SalesInvoiceStatesField,
        ValidationStatesField
    },
    mixins: [ TransportEventStatusMixin, TranportEventValidationErrorMixin, TransportEventInvoicingMixin,
        PaymentMethodMixin, TransportEventTypeMixin ],
    data() {
        return {
            updatedSearchParams: this.formatSearchParams(this.searchParams),
            searchParamsValid: true,
            regions: [
                { text: "Vantaa", value: "92461042092*" },
                { text: "Vantaa työmatka", value: "9246104209230*" },
                { text: "Vantaa asiointi", value: "9246104209240*" },
                { divider: true },
                { text: "Kerava", value: "92461042245*" },
                { text: "Kerava työmatka", value: "9246104224530*" },
                { text: "Kerava asiointi", value: "9246104224540*" },
                { divider: true },
                { text: "Inkoo", value: "92461042149*" },
                { text: "Inkoo työmatka", value: "9246104214950*" },
                { text: "Inkoo asiointi", value: "9246104214960*" },
                { divider: true },
                { text: "Siuntio", value: "92461042755*" },
                { text: "Siuntio työmatka", value: "9246104275550*" },
                { text: "Siuntio asiointi", value: "9246104275560*" },
                { divider: true },
                { text: "Lohja", value: "92461042444*" },
                { text: "Lohja työmatka", value: "9246104244450*" },
                { text: "Lohja asiointi", value: "9246104244460*" },
                { divider: true },
                { text: "Vihti", value: "92461042927*" },
                { text: "Vihti työmatka", value: "9246104292750*" },
                { text: "Vihti asiointi", value: "9246104292760*" },
                { divider: true },
                { text: "Karkkila", value: "92461042224*" },
                { text: "Karkkila työmatka", value: "9246104222450*" },
                { text: "Karkkila asiointi", value: "9246104222460*" },
                { divider: true },
                { text: "Raasepori", value: "92461042710*" },
                { text: "Raasepori työmatka", value: "9246104271050*" },
                { text: "Raasepori asiointi", value: "9246104271060*" },
                { divider: true },
                { text: "Kirkkonummi", value: "92461042257*" },
                { text: "Kirkkonummi työmatka", value: "9246104225711*" },
                { text: "Kirkkonummi asiointi", value: "9246104225721*" },
                { divider: true },
                { text: "Kauniainen", value: "92461042235*" },
                { text: "Kauniainen työmatka", value: "9246104223511*" },
                { text: "Kauniainen asiointi", value: "9246104223521*" }
            ]
        };
    },
    watch: {
        searchParams(val) {
            this.updatedSearchParams = this.formatSearchParams(this.searchParams);
        }
    },
    methods: {
        formatSearchParams(searchParams) {
            let params = {};
            if (!searchParams) return params;
            params.validationStates = this.parseQueryParamsToIntList(searchParams.validationStates);
            params.salesInvoiceStates = this.parseQueryParamsToIntList(searchParams.salesInvoiceStates);
            params.purchaseInvoiceStates = this.parseQueryParamsToIntList(searchParams.purchaseInvoiceStates);
            params.paymentMethods = this.parseQueryParamsToIntList(searchParams.paymentMethods);
            params.eventTypes = this.parseQueryParamsToIntList(searchParams.eventTypes);
            if (searchParams.validationFailReasonCodes) {
                params.validationFailReasonCodes = _.cloneDeep(searchParams.validationFailReasonCodes);
            }
            if (searchParams.salesInvoiceNumbers) {
                params.salesInvoiceNumbers = _.cloneDeep(searchParams.salesInvoiceNumbers);
            }
            if (searchParams.purchaseInvoiceNumbers) {
                params.purchaseInvoiceNumbers = _.cloneDeep(searchParams.purchaseInvoiceNumbers);
            }
            if (searchParams.carNumber) {
                params.carNumber = _.cloneDeep(searchParams.carNumber);
            }
            if (searchParams.cardNumber) {
                params.cardNumber = _.cloneDeep(searchParams.cardNumber);
            }
            if (searchParams.serviceNumber) {
                params.serviceNumber = _.cloneDeep(searchParams.serviceNumber);
            }
            if (searchParams.modifiedFrom) {
                params.modifiedFrom = _.cloneDeep(searchParams.modifiedFrom);
            }
            if (searchParams.modifiedTo) {
                params.modifiedTo = _.cloneDeep(searchParams.modifiedTo);
            }
            if (searchParams.tripStartedFrom) {
                params.tripStartedFrom = _.cloneDeep(searchParams.tripStartedFrom);
            }
            if (searchParams.tripStartedTo) {
                params.tripStartedTo = _.cloneDeep(searchParams.tripStartedTo);
            }
            if (searchParams.tripEndedFrom) {
                params.tripEndedFrom = _.cloneDeep(searchParams.tripEndedFrom);
            }
            if (searchParams.tripEndedTo) {
                params.tripEndedTo = _.cloneDeep(searchParams.tripEndedTo);
            }
            if (searchParams.costCenter) {
                params.costCenter = _.cloneDeep(searchParams.costCenter);
            }
            if (searchParams.costFrom) {
                params.costFrom = _.cloneDeep(searchParams.costFrom);
            }
            if (searchParams.costTo) {
                params.costTo = _.cloneDeep(searchParams.costTo);
            }
            if (searchParams.reimbursementFrom) {
                params.reimbursementFrom = _.cloneDeep(searchParams.reimbursementFrom);
            }
            if (searchParams.reimbursementTo) {
                params.reimbursementTo = _.cloneDeep(searchParams.reimbursementTo);
            }
            if (searchParams.deductibleFrom) {
                params.deductibleFrom = _.cloneDeep(searchParams.deductibleFrom);
            }
            if (searchParams.deductibleTo) {
                params.deductibleTo = _.cloneDeep(searchParams.deductibleTo);
            }
            if (searchParams.removed) {
                params.removed = _.cloneDeep(searchParams.removed);
            }
            if (searchParams.missingTrip) {
                params.missingTrip = _.cloneDeep(searchParams.missingTrip);
            }
            if (searchParams.missingCharge) {
                params.missingCharge = _.cloneDeep(searchParams.missingCharge);
            }
            return params;
        },
        setFailedValidations() {
            this.updatedSearchParams.validationStates = this.validationStatusFailTypes;
        },
        getSearchParameters() {
            if (this.updatedSearchParams.validationStates.length !== 1 &&
          !this.updatedSearchParams.validationStates.includes(this.validationStatusTypes.ExternalValidationFailed)) {
                delete this.updatedSearchParams.validationFailReasonCodes;
            }
            if (this.updatedSearchParams.validationStates.length !== 1 &&
          !this.updatedSearchParams.validationStates.includes(this.validationStatusTypes)) {
                delete this.updatedSearchParams.missingTrip;
                delete this.updatedSearchParams.missingCharge;
            }
            return this.updatedSearchParams;
        },
        parseQueryParamsToIntList(params) {
            if (params) {
                let formatted;
                if (Array.isArray(params)) {
                    formatted = _.cloneDeep(params);
                } else {
                    formatted = [_.cloneDeep(params)];
                }
                formatted = formatted.map(x => parseInt(x, 10));
                return formatted;
            } else {
                return [];
            }
        }
    }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}
</style>
