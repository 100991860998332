<template>
  <v-select
        v-model='paymentType'
        :items="items"
        :input="$emit('paymentUpdated', selectedPayment)"
        label="Payment Type"
        solo
    ></v-select>
</template>

<script>
export default {
    name: "PaymentTypeSelector",
    data: () => ({
        paymentType: null,
        items: ["CreditCard", "DebitCard", "DiscountCode", "Account", "MobilePay", "AtTaxi"]
    }),
    computed: {
        selectedPayment() {
            return this.paymentType;
        }
    }
};
</script>

<style>

</style>
