var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "elevation-1 mb-1 pb-2" },
        [
          _c(
            "v-card-title",
            [
              _vm._v(" " + _vm._s(_vm.$t("invoicing.transportEvents")) + " "),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3",
                  attrs: { small: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openTransportEventDialog(null)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" " + _vm._s(_vm.$t("common.addNew")) + " ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.searchDialog = !_vm.searchDialog
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("invoicing.changeSearchCriteria")) + " "
                  )
                ]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.columnDialog = !_vm.columnDialog
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("invoicing.toggleColumns")) + " ")]
              ),
              _c("v-col", { attrs: { cols: "2" } }),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "pt-0 mt-0 mr-2",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: this.$t("common.filter"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.loading,
            "transition-group": "scale-transition",
            type: "table"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.showableHeaders,
              items: _vm.transportEvents,
              "item-key": "Id",
              "item-class": _vm.transportEventRowClass,
              options: _vm.options,
              searchTotalCount: _vm.searchTotalCount,
              "multi-sort": _vm.multisortEnabled,
              search: _vm.filter,
              loading: _vm.loadingPage,
              "calculate-widths": "",
              "footer-props": {
                pagination: _vm.pagination,
                itemsPerPageOptions: _vm.itemsPerPageOptions
              }
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              },
              "click:row": _vm.openTransportEventDialog,
              "update:page": _vm.loadMoreTransportEvents
            },
            scopedSlots: _vm._u([
              {
                key: "item.eventType",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm.eventTypeColor(item)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " + _vm._s("mdi-square-rounded") + " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                _vm.transportEventTypeToTranslationKey(item)
                              )
                            )
                          )
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "header.validationStatus",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(" " + _vm._s("mdi-car-clock") + " ")
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(header.text))])]
                    )
                  ]
                }
              },
              {
                key: "header.salesInvoiceStatus",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(
                                      " " +
                                        _vm._s("mdi-file-clock-outline") +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(header.text))])]
                    )
                  ]
                }
              },
              {
                key: "header.purchaseInvoiceStatus",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(
                                      " " +
                                        _vm._s("mdi-archive-clock-outline") +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(header.text))])]
                    )
                  ]
                }
              },
              {
                key: "header.fare.paymentMethod",
                fn: function(ref) {
                  var header = ref.header
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(
                                      " " +
                                        _vm._s("mdi-hand-coin-outline") +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [_c("span", [_vm._v(_vm._s(header.text))])]
                    )
                  ]
                }
              },
              {
                key: "item.fare.paymentMethod",
                fn: function(ref) {
                  var value = ref.value
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("paymentMethodToIcon")(value)
                                      )
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.paymentMethodToTranslationKey(value))
                            )
                          )
                        ]),
                        value === _vm.paymentMethods.Invoice &&
                        item.customer.costCentreId
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                "Kustannuspaikka: " +
                                  _vm._s(item.customer.costCentreId)
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.trip.started",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.trip.ended",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.modified",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.sentToExternal",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.confirmationFromExternal",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.salesInvoiceSent",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.purchaseInvoiceSent",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.cost",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.reimbursement",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.reimbursementVat",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.deductible",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.deductibleVat",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.waitingCost",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.waitingVat",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.discountAmount",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.distance",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDistanceToKm")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.duration",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatTimespan")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.slowDrive",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatTimespan")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.fare.waitingTime",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatTimespan")(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.comments",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "span",
                                    _vm._g(
                                      _vm._b(
                                        { staticClass: "line-limit-3" },
                                        "span",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [_vm._v(_vm._s(value))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", { staticClass: "comments-tooltip" }, [
                          _vm._v(_vm._s(value))
                        ])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.validationStatus",
                fn: function(ref) {
                  var value = ref.value
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f(
                                            "validationStatusToColor"
                                          )(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("validationStatusToIcon")(
                                            value
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("validationStatusToText")(value))
                          )
                        ]),
                        item.validationFailReason
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("invoicing.validationFailReason", {
                                    failReason: item.validationFailReason
                                  })
                                ) + " "
                              )
                            ])
                          : _vm._e(),
                        _c("br"),
                        item.sentToExternal
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("invoicing.sentToExternalValidation")
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      item.sentToExternal
                                    )
                                  )
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.salesInvoiceStatus",
                fn: function(ref) {
                  var value = ref.value
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f(
                                            "salesInvoiceStatusToColor"
                                          )(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("salesInvoiceStatusToIcon")(
                                            value
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("salesInvoiceStatusToText")(value))
                          )
                        ]),
                        item.salesInvoiceNumber
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("invoicing.salesInvoiceNumber", {
                                    invoiceNumber: item.salesInvoiceNumber
                                  })
                                ) + " "
                              )
                            ])
                          : _vm._e(),
                        item.salesInvoiceSent
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("invoicing.salesInvoiceSent")) +
                                  ": " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      item.salesInvoiceSent
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.purchaseInvoiceStatus",
                fn: function(ref) {
                  var value = ref.value
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-icon",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm._f(
                                            "purchaseInvoiceStatusToColor"
                                          )(value)
                                        }
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("purchaseInvoiceStatusToIcon")(
                                            value
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("purchaseInvoiceStatusToText")(value))
                          )
                        ]),
                        item.purchaseInvoiceNumber
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                _vm._s(
                                  _vm.$t("invoicing.purchaseInvoiceNumber", {
                                    invoiceNumber: item.purchaseInvoiceNumber
                                  })
                                ) + " "
                              )
                            ])
                          : _vm._e(),
                        item.purchaseInvoiceSent
                          ? _c("span", [
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("invoicing.purchaseInvoiceSent")
                                  ) +
                                  ": " +
                                  _vm._s(
                                    _vm._f("formatDateTime")(
                                      item.purchaseInvoiceSent
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                }
              },
              {
                key: "footer",
                fn: function() {
                  return [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-center",
                        staticStyle: { position: "absolute" },
                        attrs: { cols: "12" }
                      },
                      [
                        _c(
                          "v-card-text",
                          { staticStyle: { "padding-top": "5px" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("invoicing.totalReimbursementSum")
                                ) +
                                ": " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.totalReimbursementSum
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "item.fare.extras",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c("span", _vm._g({}, on), [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatCurrency")(
                                            !value
                                              ? 0
                                              : value.reduce(
                                                  function(a, b) {
                                                    return {
                                                      price: a.price + b.price
                                                    }
                                                  },
                                                  { price: 0.0 }
                                                ).price
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c("ul", { staticClass: "extras-list" }, [
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.preBookingExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.PrebookFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.goodsExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.GoodsFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.airportExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.AirportFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.serviceExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.ServiceFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(_vm._s(_vm.$t("invoicing.aidExtra")) + ": "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type === _vm.feeTypes.AidFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.handicapExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.HandicapFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.stretcherExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.StretcherFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.approachExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                              _vm.feeTypes.ApproachFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ]),
                          _c("li", [
                            _vm._v(
                              _vm._s(_vm.$t("invoicing.otherExtra")) + ": "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    !value
                                      ? 0
                                      : value
                                          .filter(function(extra) {
                                            return (
                                              extra.type ===
                                                _vm.feeTypes.Unknown ||
                                              extra.type ===
                                                _vm.feeTypes.OtherFee
                                            )
                                          })
                                          .reduce(
                                            function(a, b) {
                                              return {
                                                price: a.price + b.price
                                              }
                                            },
                                            { price: 0.0 }
                                          ).price
                                  )
                                )
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          }),
          _vm.searchTotalCount > 0 && _vm.searchTotalCount <= 10000
            ? _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        color: "primary lighten-1",
                        loading: _vm.loadingCSV
                      },
                      on: { click: _vm.downloadTransportEvents }
                    },
                    [
                      _c("v-icon", { staticClass: "mr-2" }, [
                        _vm._v(" mdi-download ")
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("invoicing.downloadCSV")) + " "
                      )
                    ],
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("TransportEventSearchDialog", {
        attrs: { show: _vm.searchDialog, params: _vm.searchParams },
        on: {
          search: function($event) {
            return _vm.searchTransportEvents($event, true)
          },
          close: function($event) {
            _vm.searchDialog = false
          }
        }
      }),
      _c("TransportEventHeadersDialog", {
        attrs: { isOpen: _vm.columnDialog },
        on: {
          close: function($event) {
            _vm.columnDialog = false
          }
        }
      }),
      _c("TransportEventDialog", {
        attrs: {
          isOpen: _vm.transportEventDialog,
          value: _vm.selectedTransportEvent
        },
        on: {
          save: _vm.transportEventSaved,
          changed: _vm.transportEventChanged,
          delete: _vm.transportEventDeleted,
          close: _vm.closeTransportEventDialog
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }