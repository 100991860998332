var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1400" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _vm.fleet
        ? _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { short: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.close }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [
                            _c("span", [
                              _vm._v("Fleet " + _vm._s(_vm.fleet.id))
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "fixed-tabs": "",
                                "background-color": "indigo",
                                dark: ""
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c("v-tab", { attrs: { href: "#tab-details" } }, [
                                _vm._v(" Details ")
                              ]),
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-attributes" } },
                                [_vm._v(" Attributes ")]
                              ),
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-properties" } },
                                [_vm._v(" Properties ")]
                              ),
                              _c("v-tab", { attrs: { href: "#tab-tariffs" } }, [
                                _vm._v(" Tariffs ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: 1, attrs: { value: "tab-details" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Fleet Info")
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "FleetBasicInfoForm",
                                            {
                                              attrs: {
                                                fleet: _vm.fleet,
                                                editable: true
                                              },
                                              on: { save: _vm.saveFleetInfo }
                                            },
                                            [_vm._v("> ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 2, attrs: { value: "tab-attributes" } },
                                [
                                  _c("FleetAttributesForm", {
                                    attrs: { fleet: _vm.fleet },
                                    on: {
                                      addNewAttribute: _vm.addNewAttribute,
                                      saveAttribute: _vm.saveAttribute,
                                      deleteAttribute: _vm.deleteAttribute
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 3, attrs: { value: "tab-properties" } },
                                [
                                  _c("FleetPropertiesForm", {
                                    attrs: { fleet: _vm.fleet },
                                    on: {
                                      addNewProperty: _vm.addNewProperty,
                                      saveProperty: _vm.saveProperty,
                                      deleteProperty: _vm.deleteProperty
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 4, attrs: { value: "tab-tariffs" } },
                                [
                                  _c("FleetTariffsForm", {
                                    attrs: { fleet: _vm.fleet },
                                    on: {
                                      addNewTariffImport:
                                        _vm.addNewTariffImport,
                                      saveTariffImport: _vm.saveTariffImport,
                                      saveTariffId: _vm.saveTariffId,
                                      deleteTariffImport: _vm.deleteTariffImport
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }