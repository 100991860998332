<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
        <v-card-title>
          <span class="headline">{{ header }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
              <v-text-field :error-messages="nameErrors" :disabled="validated" outlined required v-model.lazy="$v.name.$model" :label="$t('userManagement.dialog.name')"></v-text-field>
              <v-text-field :disabled="validated" outlined required v-model="$v.contact.$model" placeholder="Matti Meikäläinen" :label="$t('userManagement.dialog.contact')"></v-text-field>
              <v-text-field outlined v-model="description" placeholder="Vapaa kuvaus" :label="$t('userManagement.dialog.description')"></v-text-field>
              <v-text-field outlined v-model="billingInfo" placeholder="OVT 12345678" :label="$t('userManagement.dialog.billingInfo')"></v-text-field>
              <v-text-field outlined v-model="companyAddress" placeholder="Yrityksenkatu 3 C" :label="$t('userManagement.dialog.address')"></v-text-field>
              <v-text-field :error-messages="phoneErrors" :disabled="validated" outlined required v-model="$v.phoneNumber.$model" placeholder="358501234567" :label="$t('userManagement.dialog.phone')"></v-text-field>
              <v-text-field :error-messages="emailErrors" :disabled="validated" outlined required v-model="$v.email.$model" placeholder="matti@gmail.com" :label="$t('userManagement.dialog.email')"></v-text-field>
              <v-text-field :error-messages="businessIdErrors" :disabled="validated" outlined required v-model="$v.businessIdentityCode.$model" placeholder="1234567-8" :label="$t('userManagement.dialog.businessId')"></v-text-field>
              <v-text-field readonly outlined v-model="operator" :label="$t('userManagement.dialog.operator')"></v-text-field>
              <v-text-field :disabled="validated" outlined required v-model="$v.externalId.$model" placeholder="50012345" :label="$t('userManagement.dialog.identifier')"></v-text-field>
              <v-select
                v-model="carAttributes"
                :items="carAttributesList"
                item-text="name"
                item-value="id"
                return-object
                :label="$t('userManagement.dialog.attributeSelectLabel')"
                multiple
                chips
              ></v-select>
              <v-text-field
              v-for="data of additionalData"
              :key=data.key
              :label=data.key
              v-model="data.value"
              clearable
              clear-icon="mdi-delete"
              @click:clear="additionalData.splice(additionalData.indexOf(data), 1)"
              outlined
              >{{ data.value }}</v-text-field>
              <keyvalue-input v-on:keyValueAdded="addKeyValue"></keyvalue-input>
              <v-switch
                  v-model="useStaticAddresses"
                  :label="$t('userManagement.dialog.staticAddresses')"
                ></v-switch>
              <v-spacer></v-spacer>
              <v-btn :disabled="this.$v.$invalid || this.validated" color="success" class="mr-4" @click="validate">{{$t('userManagement.dialog.validate')}}</v-btn>
              <v-btn color="red" :disabled="!this.validated" text @click="cancel">{{$t('common.cancel')}}</v-btn>
              <v-btn color="primary" v-if="validated" class="float-right" @click="create">{{$t('common.create')}}</v-btn>
          </v-form>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import KeyValueInput from "../Inputs/KeyValueInput";

export default {
    components: {
        "keyvalue-input": KeyValueInput
    },
    props: {
        value: Boolean,
        header: String,
        customer: Object
    },
    mixins: [validationMixin],
    validations: {
        name: {
            required,
            minLength: minLength(4)
        },
        contact: { required },
        externalId: { required },
        email: { required, email },
        businessIdentityCode: { required,
            validBusinessId(businessId) {
                return /^[0-9]{7}-[0-9]{1}$/.test(businessId);
            } },
        phoneNumber: {
            required,
            validPhone(phone) {
                return /^(358)\d{5,}/.test(phone);
            }
        }
    },
    data: () => ({
        validated: false,
        carAttributesList: [
            // {"value": 1504, "name": "Pet friendly"},
            // {"value": 1600, "name": "Test vehicle"},
            { "id": 1601, "name": "Combi" }, // Station wagon
            { "id": 1619, "name": "Bus" },
            { "id": 9999, "name": "Parcel" },
            { "id": 1618, "name": "Sedan" },
            { "id": 1607, "name": "Premium" },
            // {"value": 1612, "name": "Lowrider"},
            { "id": 1615, "name": "Lift" }, // Wheelchair lift
            { "id": 1614, "name": "Ramp" }, // Paratransit
            // {"value": 1616, "name": "Stairclimber"},
            { "id": 1613, "name": "Stretcher" }
            // {"value": 1621, "name": "Non smoking"},
            // {"value": 1627, "name": "Electric wheelchair"},
            // {"value": 1628, "name": "Front seat"},
            // {"value": 1632, "name": "Multiple wheelchairs"},
            // {"value": 1690, "name": "Alcometer"}
        ],
        name: "",
        description: "",
        billingInfo: "",
        carAttributes: [],
        companyAddress: "",
        email: "",
        phoneNumber: "",
        businessIdentityCode: "",
        contact: "",
        externalId: "",
        additionalData: [],
        useStaticAddresses: false
    }),
    methods: {
        addKeyValue(value) {
            if (this.additionalData.find(k => k.key.toLowerCase() === value.key.toLowerCase())) {
                alert("This key was already added.");
                return;
            }
            this.additionalData.push(value);
        },
        cancel() {
            this.$v.$reset();
            this.validated = false;
        },
        validate() {
            this.validated = true;
        },
        create() {
            // Map additionaldata as dictionary.
            let _addData = {};

            for (let data of this.additionalData) {
                _addData[data.key] = data.value;
            }

            this.$emit("created", {
                name: this.name,
                businessIdentityCode: this.businessIdentityCode,
                companyAddress: this.companyAddress,
                email: this.email,
                billingInfo: this.billingInfo,
                description: this.description,
                phoneNumber: this.phoneNumber,
                contact: this.contact,
                externalId: this.externalId,
                customerId: this.customer.id,
                additionalData: _addData,
                useStaticAddresses: this.useStaticAddresses,
                defaultAttributes: this.carAttributes
            });
            this.validated = false;
        }
    },
    created() {
        if (!this.customer) { return; }
        this.name = this.customer.name;
        this.description = this.customer.description;
        this.businessIdentityCode = this.customer.businessIdentityCode;
        this.email = this.customer.email;
        this.phoneNumber = this.customer.phoneNumber;
        this.externalId = this.customer.externalId;
        this.contact = this.customer.contact;
        this.useStaticAddresses = this.customer.useStaticAddresses;
        this.carAttributes = this.customer.defaultCarAttributes;

        if (!this.customer.additionalData) {
            return;
        }

        for (let key of Object.keys(this.customer.additionalData)) {
            this.additionalData.push({
                key: key,
                value: this.customer.additionalData[key]
            });
        }
    },
    computed: {
        operator() {
            return this.$store.state.user.operator;
        },
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push("Name is required.");
            !this.$v.name.minLength && errors.push("Name must be atleast 4 characters long.");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push("Must be valid e-mail");
            !this.$v.email.required && errors.push("E-mail is required");
            return errors;
        },
        phoneErrors() {
            const errors = [];
            if (!this.$v.phoneNumber.$dirty) return errors;
            !this.$v.phoneNumber.validPhone && errors.push("Must be valid phone number");
            !this.$v.phoneNumber.required && errors.push("Phone number is required");
            return errors;
        },
        businessIdErrors() {
            const errors = [];
            if (!this.$v.businessIdentityCode.$dirty) return errors;
            !this.$v.businessIdentityCode.validBusinessId && errors.push("Must be valid businessId");
            !this.$v.businessIdentityCode.required && errors.push("BusinessId is required");
            return errors;
        }
    }
};
</script>

<style>

</style>
