export const AttributeType = {
    Unknown: 0,
    Other: 1,
    Capacity: 2,
    Extra: 3,
    Wheelchair: 4,
    Animal: 5,
    Parcel: 6,
    Male: 7,
    Female: 8,
    Assistance: 9,
    Companion: 10,
    Stretcher: 11,
    StairClimber: 12,
    WheelchairLift: 13,
    Bus: 14,
    Sedan: 15,
    StationWagon: 16,
    Paratransit: 17,
    LowRider: 18,
    PetFriendly: 19,
    NonSmoking: 20,
    FrontSeat: 21,
    MultipleWheelchairs: 22,
    Alcometer: 23,
    ElectricWheelchair: 24,
    ExtraLoadTime: 25,
    ExtraUnloadTime: 26,
    Ambulatory: 27
};

export function AttributeTypeToString(attributeType) {
    switch (attributeType) {
    case AttributeType.Unknown:
        return "-";
    case AttributeType.Other:
        return "Other";
    case AttributeType.Capacity:
        return "Capacity";
    case AttributeType.Extra:
        return "Extra";
    case AttributeType.Wheelchair:
        return "Wheelchair";
    case AttributeType.Animal:
        return "Animal";
    case AttributeType.Parcel:
        return "Parcel";
    case AttributeType.Male:
        return "Male";
    case AttributeType.Female:
        return "Female";
    case AttributeType.Assistance:
        return "Assistance";
    case AttributeType.Companion:
        return "Companion";
    case AttributeType.Stretcher:
        return "Stretcher";
    case AttributeType.StairClimber:
        return "StairClimber";
    case AttributeType.WheelchairLift:
        return "WheelchairLift";
    case AttributeType.Bus:
        return "Bus";
    case AttributeType.Sedan:
        return "Sedan";
    case AttributeType.StationWagon:
        return "StationWagon";
    case AttributeType.Paratransit:
        return "Paratransit";
    case AttributeType.LowRider:
        return "LowRider";
    case AttributeType.PetFriendly:
        return "PetFriendly";
    case AttributeType.NonSmoking:
        return "NonSmoking";
    case AttributeType.FrontSeat:
        return "FrontSeat";
    case AttributeType.MultipleWheelchairs:
        return "MultipleWheelchairs";
    case AttributeType.Alcometer:
        return "Alcometer";
    case AttributeType.ElectricWheelchair:
        return "ElectricWheelchair";
    case AttributeType.ExtraLoadTime:
        return "ExtraLoadTime";
    case AttributeType.ExtraUnloadTime:
        return "ExtraUnloadTime";
    case AttributeType.Ambulatory:
        return "Ambulatory";
    }
};
