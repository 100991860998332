import { RepositoryFactory } from "@/data/repositories/repositoryFactory";

// initial state
const state = {
    missingVerifoneEvents: []
};

// getters
const getters = {
};

// actions
const actions = {
    async getAllMissingVerifoneEvents({ commit }) {
        await RepositoryFactory.get("invoicing").getAllMissingVerifoneEvents()
            .then(response => {
                commit("SET_MISSING_VERIFONE_EVENTS", response.data);
            });
    }
};

// mutations
const mutations = {
    SET_MISSING_VERIFONE_EVENTS(state, events) {
        state.missingVerifoneEvents = events;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
