var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width":
          this.openTransportEvent.eventType === _vm.transportEventType.Kela
            ? "800"
            : "1200"
      },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-row",
            [
              _c("v-card-title", { staticClass: "ml-5" }, [
                _vm._v(
                  " Etsi yhdistettävä " +
                    _vm._s(
                      this.openTransportEvent.eventType ===
                        _vm.transportEventType.Kela ||
                        this.openTransportEvent.eventType ===
                          _vm.transportEventType.Receipt
                        ? " tilaus"
                        : " kuitti"
                    ) +
                    " "
                )
              ]),
              this.openTransportEvent.eventType !== _vm.transportEventType.Kela
                ? _c("v-checkbox", {
                    attrs: { label: "Tyhjä tilaustunnus" },
                    model: {
                      value: _vm.emptyOrderId,
                      callback: function($$v) {
                        _vm.emptyOrderId = $$v
                      },
                      expression: "emptyOrderId"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.isCombinedTransportEventPreviewAvailable()
            ? _c("v-card-subtitle", [
                _vm._v(
                  " Uudet tiedot - Tilaustunnus: " +
                    _vm._s(this.eventPreview.orderId) +
                    ", Summa: " +
                    _vm._s(
                      _vm._f("formatCurrency")(this.eventPreview.fare.cost)
                    ) +
                    ", Maksutapa: " +
                    _vm._s(
                      _vm.$t(
                        _vm.paymentMethodToTranslationKey(
                          this.eventPreview.fare.paymentMethod
                        )
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mb-1",
                        attrs: {
                          label: "Tilaustunnus",
                          hint: "Anna tilaustunnus",
                          disabled: _vm.emptyOrderId,
                          type: _vm.typeCheck
                        },
                        scopedSlots: _vm._u(
                          [
                            this.openTransportEvent.eventType ===
                            _vm.transportEventType.Kela
                              ? {
                                  key: "prepend",
                                  fn: function() {
                                    return [
                                      _c("v-select", {
                                        staticStyle: {
                                          width: "85px",
                                          "margin-top": "2px"
                                        },
                                        attrs: {
                                          items: _vm.kelaOrderPrefixes,
                                          label: "Etuliite",
                                          "item-text": "name",
                                          "item-value": "value",
                                          placeholder: "Tyhjä",
                                          dense: ""
                                        },
                                        model: {
                                          value: _vm.kelaOrderPrefix,
                                          callback: function($$v) {
                                            _vm.kelaOrderPrefix = $$v
                                          },
                                          expression: "kelaOrderPrefix"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.input,
                          callback: function($$v) {
                            _vm.input = $$v
                          },
                          expression: "input"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "1" } },
                    [
                      this.openTransportEvent.eventType !==
                      _vm.transportEventType.Kela
                        ? _c("v-text-field", {
                            attrs: {
                              label: "Senttierotus",
                              hint: "+/- erotus senteissä",
                              maxlength: "4",
                              type: "number"
                            },
                            model: {
                              value: _vm.centDiff,
                              callback: function($$v) {
                                _vm.centDiff = $$v
                              },
                              expression: "centDiff"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  headers: this.headersToUse,
                  items: _vm.transportEvents,
                  "single-select": "",
                  "show-select": "",
                  loading: _vm.loading,
                  "items-per-page": 100,
                  "hide-default-footer": "",
                  dense: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.trip.started",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.trip.ended",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDateTime")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.fare.paymentMethod",
                    fn: function(ref) {
                      var value = ref.value
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c("v-icon", _vm._g({}, on), [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("paymentMethodToIcon")(value)
                                          )
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    _vm.paymentMethodToTranslationKey(value)
                                  )
                                )
                              )
                            ]),
                            value === _vm.paymentMethods.Invoice &&
                            item.customer.costCentreId
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(
                                    "Kustannuspaikka: " +
                                      _vm._s(item.customer.costCentreId)
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.fare.cost",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatCurrency")(value)))
                        ])
                      ]
                    }
                  },
                  {
                    key: "item.orderId",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._f("highlight")(
                              value,
                              _vm.searchValue
                            )
                          }
                        })
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedTransportEvent,
                  callback: function($$v) {
                    _vm.selectedTransportEvent = $$v
                  },
                  expression: "selectedTransportEvent"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.isDisabled,
                    loading: _vm.combining,
                    color: "primary"
                  },
                  nativeOn: {
                    click: function($event) {
                      return _vm.combineTransportEvents($event)
                    }
                  }
                },
                [_vm._v(" Yhdistä ")]
              ),
              _c(
                "v-btn",
                {
                  nativeOn: {
                    click: function($event) {
                      return _vm.emitClose($event)
                    }
                  }
                },
                [_vm._v(" Peruuta ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }