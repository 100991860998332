var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-5" },
    [
      _c(
        "v-container",
        { staticClass: "mx-2", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("b", [_vm._v("Price catalog:")]),
                  _c("br"),
                  _c("b", [_vm._v(_vm._s(_vm.priceCatalog.name))]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-2",
                      attrs: { color: "primary", dense: "" },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.openEditDialog($event)
                        }
                      }
                    },
                    [_vm._v("mdi-pencil")]
                  )
                ],
                1
              ),
              _c("v-col", [
                _vm._v(" Valid through:"),
                _c("br"),
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("formatValidThrough")(
                        _vm.priceCatalog.validThrough
                      )
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                _vm._v("OperatorId:"),
                _c("br"),
                _vm._v(_vm._s(_vm.priceCatalog.operatorId))
              ]),
              _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                _vm._v("CustomerId:"),
                _c("br"),
                _vm._v(_vm._s(_vm.priceCatalog.customerId))
              ]),
              _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                _vm._v("VendorId:"),
                _c("br"),
                _vm._v(_vm._s(_vm.priceCatalog.vendorId))
              ]),
              _c("v-col", { attrs: { cols: "6", sm: "3" } }, [
                _vm._v("ProductId:"),
                _c("br"),
                _vm._v(_vm._s(_vm.priceCatalog.productId))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800" },
          model: {
            value: _vm.editDialog,
            callback: function($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _c("span", { staticClass: "headline" }, [
                  _vm._v("Edit price catalog")
                ])
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rules: [
                                    function(v) {
                                      return !!v || "Name is required"
                                    },
                                    function(v) {
                                      return (
                                        !v ||
                                        v.length < 100 ||
                                        "Name can not be longer than 50 characters"
                                      )
                                    }
                                  ],
                                  counter: "100",
                                  label: "Name",
                                  required: ""
                                },
                                model: {
                                  value: _vm.newPriceCatalogInfo.name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalogInfo,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "newPriceCatalogInfo.name"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                attrs: { label: "OperatorId" },
                                model: {
                                  value: _vm.newPriceCatalogInfo.operatorId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalogInfo,
                                      "operatorId",
                                      $$v
                                    )
                                  },
                                  expression: "newPriceCatalogInfo.operatorId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                attrs: { label: "CustomerId" },
                                model: {
                                  value: _vm.newPriceCatalogInfo.customerId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalogInfo,
                                      "customerId",
                                      $$v
                                    )
                                  },
                                  expression: "newPriceCatalogInfo.customerId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                attrs: { label: "VendorId" },
                                model: {
                                  value: _vm.newPriceCatalogInfo.vendorId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalogInfo,
                                      "vendorId",
                                      $$v
                                    )
                                  },
                                  expression: "newPriceCatalogInfo.vendorId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-text-field", {
                                attrs: { label: "ProductId" },
                                model: {
                                  value: _vm.newPriceCatalogInfo.productId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.newPriceCatalogInfo,
                                      "productId",
                                      $$v
                                    )
                                  },
                                  expression: "newPriceCatalogInfo.productId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [_c("v-subheader", [_vm._v("Valid through")])],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pl-0" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Start time",
                                      type: "datetime-local",
                                      max: "2099-12-31T00:00",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v || "Start time cannot be empty"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value:
                                        _vm.newPriceCatalogInfo.validThrough
                                          .startTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.newPriceCatalogInfo.validThrough,
                                          "startTime",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "newPriceCatalogInfo.validThrough.startTime"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { staticClass: "pl-0" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "End time",
                                      type: "datetime-local",
                                      max: "2099-12-31T00:00",
                                      rules: [
                                        function(v) {
                                          return (
                                            !!v || "End time cannot be empty"
                                          )
                                        }
                                      ],
                                      required: ""
                                    },
                                    model: {
                                      value:
                                        _vm.newPriceCatalogInfo.validThrough
                                          .endTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.newPriceCatalogInfo.validThrough,
                                          "endTime",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "newPriceCatalogInfo.validThrough.endTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "pb-4" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        loading: _vm.isUpdating,
                        color: "primary"
                      },
                      on: { click: _vm.saveCatalogInfo }
                    },
                    [_vm._v(" Save changes ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: _vm.isUpdating },
                      on: { click: _vm.closeDialog }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }