var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mapbox-map",
    {
      attrs: {
        "access-token":
          "pk.eyJ1IjoidHNhYXJpIiwiYSI6ImNraWltenkwNzBheGczNHFwZXEyeXdndXMifQ.c0ho56I2hpOVxTXgg2a6hg",
        "map-style": "mapbox://styles/mapbox/streets-v11",
        center: _vm.pointToMapLocation(_vm.center),
        zoom: _vm.zoom,
        doubleClickZoom: _vm.doubleClickZoom
      },
      on: { dblclick: _vm.onDoubleClick }
    },
    [
      _vm._l(_vm.vehicles, function(v, index) {
        return _c(
          "mapbox-marker",
          {
            key: "v" + index,
            attrs: { "lng-lat": _vm.vehicleToMapLocation(v) }
          },
          [
            _c("v-icon", { attrs: { color: _vm.getVehicleStatusColor(v) } }, [
              _vm._v("mdi-taxi")
            ])
          ],
          1
        )
      }),
      _vm._l(_vm.polygons, function(polygon) {
        return _vm._l(polygon.points, function(p, index) {
          return _c(
            "mapbox-marker",
            {
              key: "p" + index,
              attrs: { "lng-lat": _vm.pointToLonLatArray(p) }
            },
            [
              _c("v-icon", { attrs: { color: _vm.blue } }, [
                _vm._v("mdi-map-marker")
              ])
            ],
            1
          )
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }