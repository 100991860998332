<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
  <v-card>
    <v-toolbar short dark color="primary">
      <v-btn icon dark @click="cancel">
          <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
          Create Fleet
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-row class="mt-3">
          <v-col cols="6">
            <v-select
              :items="operators"
              v-model="newFleet.companyId"
              :rules="[ requiredRule ]"
              label="Company Id"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="newFleet.fleetId"
              :rules="[ requiredRule ]"
              label="Fleet Id"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="newFleet.name"
              :rules="[ requiredRule ]"
              label="Name"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
      class="ml-2"
      @click="create"
      >
        Create
      </v-btn>
      <v-btn
        @click="cancel"
        class="ml-2">
        {{$t("common.cancel")}}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import moment from "moment";

export default {
    mixins: [ InputRuleMixin ],
    props: {
        isOpen: Boolean
    },
    data() {
        return {
            newFleet: {}
        };
    },
    computed: {
        operators() {
            return this.$store.state.user.operators;
        }
    },
    methods: {
        create: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", {
                companyId: this.newFleet.companyId,
                fleetId: this.newFleet.fleetId,
                name: this.newFleet.name
            });
        },
        cancel() {
            this.newFleet = {};
            this.$emit("close");
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
