import { gatewayService } from "./repository";
import qs from "qs";

const resource = "v1";

export default {
    async getVehicleDispatchStatus(vehicleId, integrationSource) {
        return gatewayService.get(`${resource}/vehicles/${vehicleId}/dispatchstatus`, {
            params: {
                integrationSource
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    },
    async getVehicleCache(vehicleId) {
        return gatewayService.get(`${resource}/cache/GetVehicleDataFromCache/${vehicleId}`);
    },
    async invalidateVehicleCache(vehicleId, integrationSource) {
        return gatewayService.post(`${resource}/cache/invalidateVehicleByExternalIdOrVehicleNumber/${vehicleId}`, {
            params: {
                integrationSource
            },
            paramsSerializer: function(params) {
                return qs.stringify(params, { indices: false });
            }
        });
    }
};
