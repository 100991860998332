import { RepositoryFactory } from "@/data/repositories/repositoryFactory";

// initial state
const state = {
    invoiceRules: []
};

// getters
const getters = {};

// actions
const actions = {
    async getInvoiceRules({ commit }) {
        await RepositoryFactory.get("invoiceRule").getInvoiceRules()
            .then((response) => {
                commit("SET_INVOICE_RULES", response.data.item);
            });
    }
};

// mutations
const mutations = {
    SET_INVOICE_RULES(state, invoiceRules) {
        state.invoiceRules = invoiceRules;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
