import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    failReasons: null,
    salesInvoices: null,
    tripStatusesByDay: null,
    tripStatusesByTimeRange: null,
    likumaInvoiceCreationTimestamp: null,
    emvInvoiceCreationTimestamp: null,
    taksiniInvoiceCreationTimestamp: null,
    likumaReadyForPurchaseInvoicingSummary: null,
    emvReadyForPurchaseInvoicingSummary: null,
    taksiniReadyForPurchaseInvoicingSummary: null,
    createdPurchaseInvoices: []
};

// getters
const getters = {
};

// actions
const actions = {
    async createLikumaPurchaseInvoices({ commit }) {
        await RepositoryFactory.get("invoicing").createLikumaPurchaseInvoices()
            .then(() => {
                commit("SET_LIKUMA_INVOICE_CREATION_TIMESTAMP", moment());
            });
    },
    async createEmvPurchaseInvoices({ commit }) {
        await RepositoryFactory.get("invoicing").createEmvPurchaseInvoices()
            .then(() => {
                commit("SET_EMV_INVOICE_CREATION_TIMESTAMP", moment());
            });
    },
    async createTaksiniPurchaseInvoices({ commit }) {
        await RepositoryFactory.get("invoicing").createTaksiniPurchaseInvoices()
            .then(() => {
                commit("SET_TAKSINI_INVOICE_CREATION_TIMESTAMP", moment());
            });
    },
    async getFailReasons({ commit }) {
        await RepositoryFactory.get("invoicing").getFailReasons()
            .then((response) => {
                commit("SET_FAIL_REASONS", response.data);
            });
    },
    async getSalesInvoices({ commit }) {
        await RepositoryFactory.get("invoicing").getSalesInvoices()
            .then((response) => {
                commit("SET_SALES_INVOICES", response.data);
            });
    },
    async getPurchaseInvoices({ commit }) {
        await RepositoryFactory.get("invoicing").getPurchaseInvoices()
            .then((response) => {
                commit("SET_PURCHASE_INVOICES", response.data);
            });
    },
    async getTripStatusesByDay({ commit }, { start, end }) {
        await RepositoryFactory.get("invoicing").getTripStatusesByDay(start, end)
            .then((response) => {
                commit("SET_TRIP_STATUSES_BY_DAY", response.data);
            });
    },
    async getTripStatusesByTimeRange({ commit }, { start, end }) {
        await RepositoryFactory.get("invoicing").getTripStatusesByTimeRange(start, end)
            .then((response) => {
                commit("SET_TRIP_STATUSES_BY_TIME_RANGE", response.data);
            });
    },
    async getLikumaReadyForPurchaseInvoicing({ commit }) {
        await RepositoryFactory.get("invoicing").getLikumaReadyForPurchaseInvoicing()
            .then((response) => {
                commit("SET_LIKUMA_READY_FOR_PURCHASE_INVOICING", response.data);
            });
    },
    async getEmvReadyForPurchaseInvoicing({ commit }) {
        await RepositoryFactory.get("invoicing").getEmvReadyForPurchaseInvoicing()
            .then((response) => {
                commit("SET_EMV_READY_FOR_PURCHASE_INVOICING", response.data);
            });
    },
    async getTaksiniReadyForPurchaseInvoicing({ commit }) {
        await RepositoryFactory.get("invoicing").getTaksiniReadyForPurchaseInvoicing()
            .then((response) => {
                commit("SET_TAKSINI_READY_FOR_PURCHASE_INVOICING", response.data);
            });
    },
    async getCreatedPurchaseInvoices({ commit }) {
        await RepositoryFactory.get("invoicing").getCreatedPurchaseInvoices()
            .then((response) => {
                commit("SET_CREATED_PURCHASE_INVOICES", response.data);
            });
    }
};

// mutations
const mutations = {
    SET_LIKUMA_INVOICE_CREATION_TIMESTAMP(state, timestamp) {
        state.likumaInvoiceCreationTimestamp = timestamp;
    },
    SET_EMV_INVOICE_CREATION_TIMESTAMP(state, timestamp) {
        state.emvInvoiceCreationTimestamp = timestamp;
    },
    SET_TAKSINI_INVOICE_CREATION_TIMESTAMP(state, timestamp) {
        state.taksiniInvoiceCreationTimestamp = timestamp;
    },
    SET_FAIL_REASONS(state, failReasons) {
        state.failReasons = failReasons;
    },
    SET_SALES_INVOICES(state, salesInvoices) {
        state.salesInvoices = salesInvoices;
    },
    SET_PURCHASE_INVOICES(state, purchaseInvoices) {
        state.purchaseInvoices = purchaseInvoices;
    },
    SET_TRIP_STATUSES_BY_DAY(state, tripStatusesByDay) {
        state.tripStatusesByDay = tripStatusesByDay;
    },
    SET_TRIP_STATUSES_BY_TIME_RANGE(state, tripStatusesByTimeRange) {
        state.tripStatusesByTimeRange = tripStatusesByTimeRange;
    },
    SET_LIKUMA_READY_FOR_PURCHASE_INVOICING(state, likumaReadyForPurchaseInvoicing) {
        state.likumaReadyForPurchaseInvoicingSummary = likumaReadyForPurchaseInvoicing;
    },
    SET_EMV_READY_FOR_PURCHASE_INVOICING(state, emvReadyForPurchaseInvoicing) {
        state.emvReadyForPurchaseInvoicingSummary = emvReadyForPurchaseInvoicing;
    },
    SET_TAKSINI_READY_FOR_PURCHASE_INVOICING(state, taksiniReadyForPurchaseInvoicing) {
        state.taksiniReadyForPurchaseInvoicingSummary = taksiniReadyForPurchaseInvoicing;
    },
    SET_CREATED_PURCHASE_INVOICES(state, createdPurchaseInvoices) {
        state.createdPurchaseInvoices = createdPurchaseInvoices;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
