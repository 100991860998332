<template>
  <v-dialog v-model="show" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="headline">Add new Favorite Address</span>
            </v-card-title>
            <v-card-text>
                <v-form>
                   <v-radio-group v-model="addressType">
                        <template v-slot:label>
                            <h3>Address type:</h3>
                        </template>
                        <v-radio value="pick" color="success">
                            <template v-slot:label>
                            <div><strong class="success--text">Pick</strong></div>
                            </template>
                        </v-radio>
                        <v-radio value="drop" color="success">
                            <template v-slot:label>
                            <div><strong class="success--text">Drop</strong></div>
                            </template>
                        </v-radio>
                        <v-radio value="checkpoint" color="success">
                            <template v-slot:label>
                            <div><strong class="success--text">Checkpoint</strong></div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-text-field outlined v-model="street" placeholder="Mannerheimintie" label="Street"></v-text-field>
                    <v-text-field outlined v-model="streetNumber" placeholder="96" label="StreetNumber"></v-text-field>
                    <v-text-field outlined v-model="door" placeholder="D15" label="Door"></v-text-field>
                    <v-text-field outlined v-model="city" placeholder="Vantaa" label="City"></v-text-field>
                    <v-text-field outlined v-model="zipcode" placeholder="01630" label="Zipcode"></v-text-field>
                    <v-text-field outlined v-model="country" placeholder="Finland" label="Country"></v-text-field>
                    <v-text-field outlined v-model="alias" placeholder="Terveyskeskus" label="Alias"></v-text-field>
                    <v-text-field outlined v-model="driverNotes" placeholder="Aja portista sisään" label="DriverNotes"></v-text-field>
                </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" v-if="!editFavoriteAddress" class="float-right" @click="create">{{$t('common.create')}}</v-btn>
              <v-btn color="primary" v-if="editFavoriteAddress" class="float-right" @click="update">{{$t('common.update')}}</v-btn>
              <v-btn color="error" v-if="favoriteAddressId" @click="deleteFavoriteAddress">{{$t('common.delete')}}</v-btn>
              <v-btn color="red" text @click="cancel">Cancel</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        value: Boolean,
        editFavoriteAddress: Boolean,
        favoriteAddress: Object
    },
    data: () => ({
        validated: false,
        favoriteAddressId: "",
        addressType: "pick",
        street: "",
        streetNumber: "",
        city: "",
        zipcode: "",
        country: "",
        alias: "",
        driverNotes: "",
        door: ""
    }),
    methods: {
        cancel() {
            this.addressType = "pick";
            this.street = "";
            this.streetNumber = "";
            this.city = "";
            this.zipcode = "";
            this.country = "";
            this.alias = "";
            this.driverNotes = "";
            this.door = "";
        },
        deleteFavoriteAddress() {
            confirm("Are you sure you want to delete this address?") && this.$emit("deleted", this.favoriteAddressId);
        },
        create() {
            this.$emit("created", {
                addressType: this.addressType,
                street: this.street,
                streetNumber: this.streetNumber,
                city: this.city,
                zipcode: this.zipcode,
                country: this.country,
                alias: this.alias,
                driverNotes: this.driverNotes,
                door: this.door
            });
            this.cancel();
        },
        update() {
            this.$emit("updated", {
                favoriteAddressId: this.favoriteAddressId,
                addressType: this.addressType,
                street: this.street,
                streetNumber: this.streetNumber,
                city: this.city,
                zipcode: this.zipcode,
                country: this.country,
                alias: this.alias,
                driverNotes: this.driverNotes,
                door: this.door
            });
            this.cancel();
        }
    },
    watch: {
        favoriteAddress(selectedFavoriteAddress) {
            this.favoriteAddressId = selectedFavoriteAddress.id;
            this.addressType = selectedFavoriteAddress.addressType;
            this.street = selectedFavoriteAddress.street;
            this.streetNumber = selectedFavoriteAddress.streetNumber;
            this.city = selectedFavoriteAddress.city;
            this.zipcode = selectedFavoriteAddress.zipcode;
            this.country = selectedFavoriteAddress.country;
            this.alias = selectedFavoriteAddress.alias;
            this.driverNotes = selectedFavoriteAddress.driverNotes;
            this.door = selectedFavoriteAddress.door;
        }
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                // !value && this.cancel();
                this.$emit("input", value);
            }
        }
    }
};
</script>

<style>

</style>
