<template>
    <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
                <span class="headline">
                    {{$t(isNewFare ? "invoicing.addNewFare" : "invoicing.editFare")}}
                </span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col class="pl-0">
                                <v-select
                                v-model="selectedFareType"
                                :items="allowedFareTypes"
                                :rules="[v => !!v || 'Fare type is required']"
                                :label="this.$t('invoicing.fareType')"
                                required
                                >
                                <template slot="selection" slot-scope="{ item }">
                                    <span>
                                        {{ item | fareTypeToText }}
                                    </span>
                                </template>
                                <template slot="item" slot-scope="{ item }">
                                    <span>
                                        {{ item | fareTypeToText }}
                                    </span>
                                </template>
                                </v-select>
                            </v-col>
                            <v-col class="pl-0">
                                <v-select
                                    v-model="selectedFareClass"
                                    :items="fareClasses"
                                    :rules="[v => !!v || 'Fare class is required']"
                                    :label="this.$t('invoicing.fareClass')"
                                    required
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="fare.kilometerPrice"
                                    :label="this.$t('invoicing.kilometerPrice')"
                                    type="number"
                                    :rules="[
                                        v => !!v || 'Kilometer price cannot be empty',
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Price is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Price cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    @change="updateFareMetersValue"
                                    :value="fare.meters | formatDistanceToKm"
                                    :label="this.$t('invoicing.kilometers')"
                                    :rules="[
                                        v => !!v || 'Kilometers cannot be empty',
                                        decimalRule
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="fare.time"
                                    :label="this.$t('invoicing.time')"
                                    type="number"
                                    counter=5
                                ></v-text-field>
                            </v-col>
                            <v-col class="pl-0">
                                <v-text-field
                                    v-model="fare.price"
                                    :label="this.$t('invoicing.price')"
                                    type="number"
                                    :rules="[
                                        v => !!v || 'Price cannot be empty',
                                        v => /^\d+((.|,)\d+)?$/.test(v) || 'Price is not valid decimal value',
                                        v => !v || v.toString().replace('.', '').replace(',', '').length <= 8 || 'Price cannot be longer than 8 characters without decimal point'
                                    ]"
                                    counter=9
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn v-if="isNewFare"
                    :disabled="!valid"
                    @click="createFare"
                    color="success">
                    {{$t("invoicing.addFare")}}
                </v-btn>
                <v-btn v-else
                    :disabled="!valid"
                    @click="saveFare"
                    color="primary">
                    {{$t("common.saveChanges")}}
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.close")}}
                  </v-btn>
				<v-spacer></v-spacer>
			</v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { FareTypeMixin } from "../../../models/FareTypes";
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import { FeeType } from "../../../models/FeeTypes";
import _ from "lodash";

export default {
    mixins: [FareTypeMixin, InputRuleMixin],
    props: {
        originalFare: {
            type: Object,
            default: () => {}
        },
        allowedFareTypes: Array,
        isNewFare: {
            type: Boolean,
            default: true
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fare: { type: FeeType.TariffFee },
            valid: true,
            selectedFareType: this.originalFare.fareType,
            selectedFareClass: this.originalFare.tariffCategory,
            fareClasses: [
                { value: "K", text: "Kassa-ajo" },
                { value: "1", text: "1-4 henkilöä" },
                { value: "2", text: "Yli 4 henkilöä" }
            ]
        };
    },
    watch: {
        originalFare: function(newVal, oldVal) {
            this.fare = _.cloneDeep(this.originalFare);
            this.fare.type = FeeType.TariffFee;
            this.selectedFareType = this.fare.fareType;
            this.selectedFareClass = this.fare.tariffCategory;
        },
        isOpen: function(val, oldVal) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        selectedFareType: function(val, oldVal) {
            this.fare.fareType = val;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        selectedFareClass: function(val, oldVal) {
            this.fare.tariffCategory = val;
        }
    },
    methods: {
        saveFare() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", this.fare);
        },
        createFare() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("create", this.fare);
        },
        updateFareMetersValue(updateFareMetersEvent) {
            if (updateFareMetersEvent.indexOf(",") > -1 || updateFareMetersEvent.indexOf(".") > -1) {
                updateFareMetersEvent = Number(updateFareMetersEvent.replace(",", "."));
            } else {
                updateFareMetersEvent = Number(updateFareMetersEvent);
            }
            this.fare.meters = Math.round(updateFareMetersEvent * 1000);
        }
    }
};
</script>

<style scoped>
</style>
