<template>
  <v-container fluid>
    <v-row justify="end">
      <v-col>
        <h1>{{ $t("invoicing.purchasing.title") }}</h1>
      </v-col>
      <v-col cols="auto">
        <v-btn class="ma-2" color="primary" @click="navigate()">
          {{ $t("invoicing.transportEvents") }}
        </v-btn>
      </v-col>
    </v-row>
    <ReadyForPurchaseInvoicingTable v-if="!byFilter"></ReadyForPurchaseInvoicingTable>
    <CreatedPurchaseInvoicesTable v-if="!byFilter"></CreatedPurchaseInvoicesTable>
    <ReadyForPurchaseInvoicingByFilter v-if="byFilter"></ReadyForPurchaseInvoicingByFilter>
  </v-container>
</template>

<script>
import ReadyForPurchaseInvoicingTable from "../components/Invoicing/ReadyForPurchaseInvoicingTable";
import CreatedPurchaseInvoicesTable from "../components/Invoicing/CreatedPurchaseInvoicesTable";
// TODO: delete above import when every customer uses by filter
import ReadyForPurchaseInvoicingByFilter from "../components/Invoicing/ReadyForPurchaseInvoicingByFilter";

export default {
    components: { ReadyForPurchaseInvoicingTable, CreatedPurchaseInvoicesTable, ReadyForPurchaseInvoicingByFilter },
    methods: {
        navigate() {
            this.$router.push({ name: "Invoicing" });
        }
    },
    computed: {
        byFilter() {
            // TODO: delete when every customer uses by filter
            if (this.$store.state.user.tenant === "kaista") {
                return true;
            }
            return false;
        }
    }
};
</script>

<style scoped>
h1 {
  margin-bottom: 1rem;
}
</style>
