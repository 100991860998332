<template>
  <v-data-table
    calculate-widths
    :headers="headers"
    :items="vehicles"
    item-key="vehicleId"
    :loading="loading"
    :server-items-length="searchTotalCount"
    :options.sync="options"
    :hide-default-footer="true"
    @click:row="selectVehicle"
  >
    <template v-slot:item.vehicleId="{ value }">
      <span> {{ value }}</span>
    </template>
    <template v-slot:item.companyId="{ value }">
      <span> {{ value }}</span>
    </template>
    <template v-slot:item.fleetId="{ value }">
      <span> {{ value }}</span>
    </template>
    <template v-slot:item.state="{ value }">
      <span> {{ dispatchStateToString(value) }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { DispatchStateToString } from "../../models/DispatchState";

export default {
    props: {
        vehicles: Array,
        loading: Boolean
    },
    data() {
        return {
            headers: [
                { text: "Id", value: "vehicleId", show: true },
                { text: "Company", value: "companyId", show: true },
                { text: "Fleet", value: "fleetId", show: true },
                { text: "Dispatch", value: "state", show: true }
            ],
            options: {}
        };
    },
    computed: {
        searchTotalCount() {
            return this.vehicles?.length ?? 0;
        }
    },
    methods: {
        dispatchStateToString(dispatchState) {
            return DispatchStateToString(dispatchState);
        },
        selectVehicle(vehicle) {
            this.$emit("vehicleSelected", vehicle);
        }
    }
};
</script>
