<template>
  <v-data-table
        calculate-widths
        :headers="showableHeaders"
        :items="offers"
        item-key="id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="filter"
        :loading="loadingPage"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
      >
        <template v-slot:item.id="{ value }">
          <span> {{ value }}</span>
        </template>
      </v-data-table>
</template>
<script>

export default {
    props: {
        offers: {
            type: Array,
            default: function() {
                return [];
            }
        },
        isOpen: Boolean
    },
    watch: {
        offers(val) {
            if (!val) {
                this.offers = null;
            } else {
                this.offers = val;
            }
        }
    },
    data() {
        return {
            filter: "",
            columnDialog: false,
            searchDialog: false,
            headers: [
                { text: "Id", value: "id", show: true }
            ],
            sortBy: "id",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            searchParams: {
                id: ""
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        searchTotalCount() {
            return this.offers.length;
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    }
};
</script>

<style scoped>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
</style>
