export const TariffImportOrigin = {
    Unknown: 0,
    Company: 1,
    Fleet: 2,
    Vehicle: 4
};

export function TariffImportOriginToString(tariffImportOrigin) {
    switch (tariffImportOrigin) {
    case TariffImportOrigin.Unknown:
        return "-";
    case TariffImportOrigin.Company:
        return "Company";
    case TariffImportOrigin.Fleet:
        return "Fleet";
    case TariffImportOrigin.Vehicle:
        return "Vehicle";
    }
};
