<template>
  <TariffsTable
    :tariffImports="editableFleet.tariffImports"
    :tariffId="editableFleet.tariffId"
    :editableTariffImportOrigin="editableTariffImportOrigin"
    @cancel="isEditFormOpen=false"
    @addNewTariffImport="addNewTariffImport"
    @saveTariffImport="saveTariffImport"
    @saveTariffId="saveTariffId"
    @deleteTariffImport="deleteTariffImport">
  </TariffsTable>
</template>

<script>
import TariffsTable from "../../Shared/TariffsTable";
import { TariffImportOrigin } from "../../../models/TariffImportOrigin";
import _ from "lodash";

export default {
    components: { TariffsTable },
    props: {
        fleet: {
            type: Object,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            editableFleet: _.cloneDeep(this.fleet),
            editableTariffImportOrigin: TariffImportOrigin.Fleet,
            selectedTariffImport: null,
            isEditFormOpen: false
        };
    },
    watch: {
        fleet(val) {
            this.editableFleet = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        addNewTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("addNewTariffImport", tariffImport, eventTargetId);
        },
        saveTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("saveTariffImport", tariffImport, eventTargetId);
        },
        saveTariffId: function(tariffId, eventTargetId) {
            this.$emit("saveTariffId", tariffId, eventTargetId);
        },
        deleteTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("deleteTariffImport", tariffImport, eventTargetId);
        }
    }
};
</script>
