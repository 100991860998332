<template>
  <v-form ref="form">
    <v-row class="mt-3">
      <v-col cols="4">
          <v-text-field
              v-model="editableFleet.id"
              label="Fleet Id"
              :disabled="true"
          ></v-text-field>
      </v-col>
      <v-col cols="4">
          <v-text-field
              v-model="editableFleet.name"
              label="Name"
          ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-select
          :items="fleetStatuses"
          v-model="editableFleet.status"
          label="Status"
          :disabled="true"
        >
          <template v-slot:selection="{ item }">
            {{ fleetStatusToString(item) }}
          </template>
          <template v-slot:item="{ item }">
            {{ fleetStatusToString(item) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="mt-3" v-if="editable">
      <v-col cols="12">
        <v-btn
          class="mr-4"
          @click="save"
          :disabled="!dirty"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { FleetStatus, FleetStatusToString } from "../../../models/FleetStatus";
import moment from "moment";
import _ from "lodash";

export default {
    props: {
        fleet: {
            type: Object,
            default: function() {
                return null;
            }
        },
        editable: Boolean
    },
    data() {
        const editableFleet = _.cloneDeep(this.fleet);
        editableFleet.contactInformationForm = editableFleet.contactInformationForm ?? {};
        const editableIntegrationSources = [];
        for (var i = 0; i < 12; i++) {
            const flag = editableFleet.integrationSources & 1 << i;
            if (flag > 0) {
                editableIntegrationSources.push(flag);
            }
        }

        return {
            editableFleet,
            dirty: false,
            fleetStatuses: Object.values(FleetStatus)
        };
    },
    watch: {
        fleet(val) {
            this.editableFleet = (!val) ? {} : _.cloneDeep(val);
        },
        editableFleet:
    {
        handler() {
            this.dirty = true;
        },
        deep: true
    }
    },
    methods: {
        save: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", {
            });
        },
        fleetStatusToString(status) {
            return FleetStatusToString(status);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
