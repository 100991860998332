<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Calculate route</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="route.from.latitude" label="From" placeholder="Lat" type="number"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="route.from.longitude" label="" placeholder="Long" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
              <v-text-field v-model="route.to.latitude" label="To" placeholder="Lat" type="number"></v-text-field>
              </v-col>
              <v-col cols="6">
              <v-text-field v-model="route.to.longitude" label="" placeholder="Long" type="number"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="route.requestedTime" label="Time" type="datetime-local"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select class="ma-2"
                  v-model="route.requestedTimeType"
                  :items="requestedTimeTypes"
                  label="Type of time"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="getRoute" :loading="isUpdating" color="success">Calculate</v-btn>
        <v-btn @click="close" :loading="isUpdating">Close</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

const mapToRouteRequest = (route) => {
    return {
        Locations: [{
            Point: {
                Latitude: route.from.latitude,
                Longitude: route.from.longitude
            }
        },
        {
            Point: {
                Latitude: route.to.latitude,
                Longitude: route.to.longitude
            }
        }
        ],
        RequestedTime: route.requestedTime,
        RequestedTimeType: route.requestedTimeType
    };
};

export default {
    props: {
        isUpdating: {
            type: Boolean,
            default: false
        },
        isOpen: {
            type: Boolean,
            default: false
        } },
    data() {
        return {
            requestedTimeTypes: [
                { text: "Departure", value: 1 },
                { text: "Arrival", value: 2 }],
            route: {
                from: {
                    latitude: "60.326692",
                    longitude: "24.840489"
                },
                to: {
                    latitude: "60.282361",
                    longitude: "24.856797"
                },
                requestedTime: moment().format("YYYY-MM-DDTHH:mm"),
                requestedTimeType: 1
            }
        };
    },
    methods: {
        getRoute() {
            this.$emit("getRoute", mapToRouteRequest(this.route));
            this.$emit("close");
        },
        close() {
            this.$emit("close");
        }
    }
};
</script>

<style>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}
</style>
