export const AttributeSourceType = {
    Unknown: 0,
    Vehicle: 1,
    Driver: 2,
    Fleet: 4,
    Booking: 8,
    Rider: 16,
    Customer: 32,
    Company: 64,
    Device: 128
};

export function AttributeSourceTypeToString(attributeSourceType) {
    switch (attributeSourceType) {
    case AttributeSourceType.Unknown:
        return "-";
    case AttributeSourceType.Vehicle:
        return "Vehicle";
    case AttributeSourceType.Driver:
        return "Driver";
    case AttributeSourceType.Fleet:
        return "Fleet";
    case AttributeSourceType.Booking:
        return "Booking";
    case AttributeSourceType.Rider:
        return "Rider";
    case AttributeSourceType.Customer:
        return "Customer";
    case AttributeSourceType.Company:
        return "Company";
    case AttributeSourceType.Device:
        return "Device";
    }
};
