var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "elevation-1 mb-1 pb-2" },
        [
          _c("v-card-title", [_vm._v(" Postal Code Vehicles ")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.searchDialog = true
                    }
                  }
                },
                [_vm._v(" Search ")]
              ),
              _c("v-spacer"),
              _c("PostalCodeVehiclesSearchDialog", {
                attrs: {
                  show: _vm.searchDialog,
                  searchParams: _vm.searchParams
                },
                on: {
                  search: function($event) {
                    return _vm.searchAreaVehicles($event, false)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("DispatchVehiclesTable", {
        attrs: { loading: _vm.loading, vehicles: _vm.vehicles },
        on: {
          close: function($event) {
            _vm.searchDialog = false
          },
          vehicleSelected: _vm.vehicleSelected
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }