var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c("v-col", [_c("h1", [_vm._v(_vm._s(_vm.$t("invoicing.title")))])]),
          _vm.filterByTenant
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "blue-grey white--text" },
                      on: {
                        click: function($event) {
                          return _vm.navigate("SalesInvoicing")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("invoicing.sales.title")) + " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-archive-outline")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "blue-grey white--text" },
                      on: {
                        click: function($event) {
                          return _vm.navigate("PurchaseInvoicing")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("invoicing.purchasing.title")) + " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-archive-outline")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.ltxSpecificNavItems
            ? _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "light-blue white--text" },
                      on: {
                        click: function($event) {
                          return _vm.navigate("MissingVerifoneEvents")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("invoicing.verifone.title")) + " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-credit-card-search-outline")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: { color: "pink white--text" },
                      on: {
                        click: function($event) {
                          return _vm.navigate("PaytrailTransactions")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("invoicing.paytrail.title")) + " "
                      ),
                      _c("v-icon", { attrs: { right: "" } }, [
                        _vm._v("mdi-credit-card-search")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("TransportEventTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }