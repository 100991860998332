export const InvoiceCreationType = {
    Unknown: 0,
    Kela: 1,
    Likuma: 2,
    LikumaProvisionBill: 3,
    DebitCredit: 4,
    KelaProvisionBill: 5,
    FCG: 6,
    Taksini: 7,
    CostCenter: 8,
    Purchase: 9
};

export const InvoiceCreationTypeMixin = {
    data() {
        return {
            invoiceCreationTypes: InvoiceCreationType
        };
    },
    filters: {
        invoiceCreationTypeToString(invoiceCreationType) {
            switch (invoiceCreationType) {
            case InvoiceCreationType.Unknown:
                return "-";
            case InvoiceCreationType.Kela:
                return "Kela";
            case InvoiceCreationType.Likuma:
            case InvoiceCreationType.Purchase:
                return "Likuma";
            case InvoiceCreationType.LikumaProvisionBill:
                return "Likuma Provision Bill";
            case InvoiceCreationType.DebitCredit:
                return "EMV";
            case InvoiceCreationType.KelaProvisionBill:
                return "Kela Provision Bill";
            case InvoiceCreationType.FCG:
                return "FCG";
            case InvoiceCreationType.Taksini:
                return "Taksini";
            }
        }
    }
};
