var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.transportEvent
    ? _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.tab,
                callback: function($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _vm._l(_vm.tabs, function(item, i) {
                return _c("v-tab", { key: i }, [
                  i !== 1 || (i === 1 && _vm.cancellableEvent)
                    ? _c("div", [_vm._v(" " + _vm._s(item) + " ")])
                    : _vm._e()
                ])
              }),
              _c(
                "v-tab-item",
                { key: "0" },
                [
                  this.transportEvent.salesInvoiceStatus !== 1
                    ? _c(
                        "v-row",
                        [
                          _vm.transportEvent.isCompensated
                            ? _c("v-row", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("invoicing.eventHasBeenCorrected")
                                    ) +
                                    " "
                                )
                              ])
                            : _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: { click: _vm.setToBeCorrected }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "invoicing.makeCorrections"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("v-col", { attrs: { cols: "auto" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "invoicing.makeCorrectionsInfo"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-subheader", { staticClass: "mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoicing.transportEventTypes.likuma.likumaTripInfo"
                        )
                      )
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Booking id",
                              disabled: _vm.readOnly,
                              rules: _vm.likumaOrderIdRules,
                              counter: _vm.likumaOrderIdLength,
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.orderId,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent, "orderId", $$v)
                              },
                              expression: "transportEvent.orderId"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip id",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(10)
                              ],
                              counter: "10",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.likumaTripId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent,
                                  "likumaTripId",
                                  $$v
                                )
                              },
                              expression: "transportEvent.likumaTripId"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Card number",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(20)],
                              counter: "20",
                              required: ""
                            },
                            model: {
                              value: _vm.likumaCardNumber,
                              callback: function($$v) {
                                _vm.likumaCardNumber = $$v
                              },
                              expression: "likumaCardNumber"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Certificate id",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(6)
                              ],
                              counter: "6",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.likumaCertificateId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent,
                                  "likumaCertificateId",
                                  $$v
                                )
                              },
                              expression: "transportEvent.likumaCertificateId"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              label: "Decision id",
                              disabled: _vm.readOnly,
                              items: _vm.decisionIds,
                              rules: [_vm.requiredRule],
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.trip.welfareTripType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "welfareTripType",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.welfareTripType"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              label: "Trip type",
                              disabled: _vm.readOnly,
                              items: _vm.tripTypes,
                              rules: [_vm.requiredRule],
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.trip.tripType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "tripType",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.tripType"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip start timestamp",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.dateTimeRule],
                              type: _vm.tripStartedTextFieldType,
                              placeholder: "yyyy-MM-dd HH:mm",
                              max: "2099-12-31T00:00",
                              required: ""
                            },
                            on: {
                              dblclick: _vm.dblClickTripStartedTypeChange,
                              focus: function($event) {
                                return $event.target.select()
                              }
                            },
                            model: {
                              value: _vm.transportEvent.trip.started,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "started",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.started"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip end timestamp",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.dateTimeRule],
                              type: _vm.tripEndedTextFieldType,
                              placeholder: "yyyy-MM-dd HH:mm",
                              max: "2099-12-31T00:00",
                              required: ""
                            },
                            on: {
                              dblclick: _vm.dblClickTripEndedTypeChange,
                              focus: function($event) {
                                return $event.target.select()
                              }
                            },
                            model: {
                              value: _vm.transportEvent.trip.ended,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent.trip, "ended", $$v)
                              },
                              expression: "transportEvent.trip.ended"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip time",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(3)
                              ],
                              counter: "3",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.duration,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "duration",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.duration"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Trip distance (m)",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(6)
                              ],
                              counter: "6",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.distance,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "distance",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.distance"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Waiting time",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(4)
                              ],
                              counter: "4",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.waitingTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "waitingTime",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.waitingTime"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Vehicle id",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(8)],
                              counter: "8",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.trip.carNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "carNumber",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.carNumber"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Meter id",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(14)],
                              counter: "14",
                              required: ""
                            },
                            model: {
                              value:
                                _vm.transportEvent.fare.taximeterSerialNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "taximeterSerialNumber",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.fare.taximeterSerialNumber"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Service number",
                              disabled: _vm.readOnly,
                              rules: [_vm.requiredRule, _vm.maxLengthRule(12)],
                              counter: "14"
                            },
                            model: {
                              value: _vm.transportEvent.fare.paymentTerminalId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "paymentTerminalId",
                                  $$v
                                )
                              },
                              expression:
                                "transportEvent.fare.paymentTerminalId"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Driver id",
                              disabled: _vm.readOnly,
                              rules: [_vm.maxLengthRule(12)],
                              counter: "12"
                            },
                            model: {
                              value: _vm.transportEvent.trip.driverId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "driverId",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.driverId"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              label: "Car pool",
                              disabled: _vm.readOnly,
                              items: _vm.carPoolValues
                            },
                            model: {
                              value: _vm.transportEvent.trip.carPool,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "carPool",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.carPool"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Start address",
                              disabled: _vm.readOnly,
                              rules: [_vm.maxLengthRule(100)],
                              counter: "100"
                            },
                            model: {
                              value: _vm.transportEvent.trip.startingPoint,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "startingPoint",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.startingPoint"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "End address",
                              disabled: _vm.readOnly,
                              rules: [_vm.maxLengthRule(100)],
                              counter: "100"
                            },
                            model: {
                              value: _vm.transportEvent.trip.destination,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "destination",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.destination"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-subheader", { staticClass: "mt-3" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "invoicing.transportEventTypes.likuma.likumaCharge"
                        )
                      )
                    )
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Receipt number",
                              disabled: _vm.readOnly,
                              rules: [
                                _vm.requiredRule,
                                _vm.digitRule,
                                _vm.maxLengthRule(8)
                              ],
                              counter: "8",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.receiptNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "receiptNumber",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.receiptNumber"
                            }
                          }),
                          _c("v-select", {
                            attrs: {
                              items: [0.0, 10.0, 24.0, 25.5],
                              rules: [_vm.requiredRule],
                              label: "Vat percent",
                              disabled: _vm.readOnly,
                              required: ""
                            },
                            on: { input: _vm.calculateSums },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "selection",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(" " + _vm._s(item) + " % ")]
                                  }
                                },
                                {
                                  key: "item",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [_vm._v(" " + _vm._s(item) + " % ")]
                                  }
                                }
                              ],
                              null,
                              false,
                              3334045436
                            ),
                            model: {
                              value: _vm.transportEvent.fare.vatPercent,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "vatPercent",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.vatPercent"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total amount",
                              value: _vm._f("formatPrice")(
                                _vm.transportEvent.fare.cost
                              ),
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.requiredRule,
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9",
                              required: ""
                            },
                            on: { change: _vm.updateFareCostValue },
                            model: {
                              value: _vm.transportEvent.fare.cost,
                              callback: function($$v) {
                                _vm.$set(_vm.transportEvent.fare, "cost", $$v)
                              },
                              expression: "transportEvent.fare.cost"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Discount amount",
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9"
                            },
                            model: {
                              value: _vm.transportEvent.fare.discountAmount,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "discountAmount",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.discountAmount"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total charge",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.requiredRule,
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9",
                              required: ""
                            },
                            model: {
                              value: _vm.transportEvent.fare.reimbursement,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "reimbursement",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.reimbursement"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total charge vat",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(8)
                              ],
                              counter: "9"
                            },
                            model: {
                              value: _vm.transportEvent.fare.reimbursementVat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "reimbursementVat",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.reimbursementVat"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Total deductible",
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.requiredRule,
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7",
                              required: ""
                            },
                            on: { input: _vm.calculateSums },
                            model: {
                              value: _vm.transportEvent.fare.deductible,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "deductible",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.deductible"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Excess vat",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7"
                            },
                            model: {
                              value: _vm.transportEvent.fare.deductibleVat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "deductibleVat",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.deductibleVat"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Total waiting",
                              disabled: _vm.readOnly,
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7"
                            },
                            on: { input: _vm.calculateSums },
                            model: {
                              value: _vm.transportEvent.fare.waitingCost,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "waitingCost",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.waitingCost"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "Waiting vat",
                              disabled: "",
                              type: "number",
                              rules: [
                                _vm.decimalRule,
                                _vm.maxLengthWithoutDecimalSeparatorRule(6)
                              ],
                              counter: "7"
                            },
                            model: {
                              value: _vm.transportEvent.fare.waitingVat,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "waitingVat",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.waitingVat"
                            }
                          }),
                          _c("v-text-field", {
                            attrs: {
                              label: "High tariff",
                              disabled: _vm.readOnly
                            },
                            model: {
                              value: _vm.transportEvent.fare.highTariff,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.fare,
                                  "highTariff",
                                  $$v
                                )
                              },
                              expression: "transportEvent.fare.highTariff"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-subheader", [
                    _vm._v(_vm._s(_vm.$t("invoicing.extraCharges")) + " (0-5)")
                  ]),
                  _c("FeeChipGroup", {
                    ref: "feeChipGroup",
                    attrs: {
                      fees: _vm.transportEvent.fare.extras,
                      allowedFeeTypes: _vm.extraChargeTypes,
                      maxAmount: 5,
                      readOnly: _vm.readOnly,
                      useLikumaTexts: true
                    },
                    on: { update: _vm.updateFees }
                  }),
                  _c(
                    "v-row",
                    [
                      _c("v-textarea", {
                        staticClass: "mt-3 mx-2",
                        attrs: {
                          label: "Comments (optional)",
                          outlined: "",
                          rows: "3"
                        },
                        model: {
                          value: _vm.transportEvent.comments,
                          callback: function($$v) {
                            _vm.$set(_vm.transportEvent, "comments", $$v)
                          },
                          expression: "transportEvent.comments"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-textarea", {
                        staticClass: "mt-3 mx-2",
                        attrs: {
                          label: "Info for purchase invoice (optional)",
                          disabled: _vm.readOnly,
                          outlined: "",
                          rows: "3"
                        },
                        model: {
                          value: _vm.transportEvent.purchaseInvoiceMessage,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.transportEvent,
                              "purchaseInvoiceMessage",
                              $$v
                            )
                          },
                          expression: "transportEvent.purchaseInvoiceMessage"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                loading: _vm.commentUpdating
                              },
                              on: { click: _vm.updateComment }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("invoicing.updateComments")) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: {
                      label: "Set to be validated",
                      disabled: _vm.readOnly,
                      hint:
                        "Event cannot be modified during the validation process",
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.toBeValidated,
                      callback: function($$v) {
                        _vm.toBeValidated = $$v
                      },
                      expression: "toBeValidated"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-tab-item",
                { key: "1" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-6" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "8", md: "4" } },
                        [
                          _c("v-subheader", { staticClass: "mt-3" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "invoicing.transportEventTypes.likuma.likumaCancel"
                                )
                              )
                            )
                          ]),
                          _c("v-text-field", {
                            staticClass: "mx-2",
                            attrs: {
                              label: "Likuma Certificate ID",
                              readonly: ""
                            },
                            model: {
                              value: _vm.transportEvent.likumaCertificateId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent,
                                  "likumaCertificateId",
                                  $$v
                                )
                              },
                              expression: "transportEvent.likumaCertificateId"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "mx-2",
                            attrs: { label: "Likuma Trip ID", readonly: "" },
                            model: {
                              value: _vm.transportEvent.likumaTripId,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent,
                                  "likumaTripId",
                                  $$v
                                )
                              },
                              expression: "transportEvent.likumaTripId"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "mx-2",
                            attrs: { label: "Likuma Trip Type", readonly: "" },
                            model: {
                              value: _vm.transportEvent.trip.tripType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.transportEvent.trip,
                                  "tripType",
                                  $$v
                                )
                              },
                              expression: "transportEvent.trip.tripType"
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                depressed: "",
                                color: "warning",
                                disabled: !_vm.cancellableEvent
                              },
                              on: {
                                click: function($event) {
                                  return _vm.cancelLikumaTrip(
                                    _vm.transportEvent
                                  )
                                }
                              }
                            },
                            [_vm._v(" Peruuta matka ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "v-snackbar",
            {
              staticStyle: { "white-space": "pre" },
              attrs: { vertical: "", color: "error" },
              model: {
                value: _vm.errorSnackbar,
                callback: function($$v) {
                  _vm.errorSnackbar = $$v
                },
                expression: "errorSnackbar"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.errorText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.errorSnackbar = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }