var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { display: "flex", "justify-content": "center" },
      attrs: { id: "paytrailTransactions" }
    },
    [
      _c(
        "v-card",
        { staticClass: "mt-1", attrs: { width: 1080 } },
        [
          _c(
            "div",
            { staticClass: "elevation-1 mb-1 pb-2" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("invoicing.paytrail.title")) +
                              " "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-archive-search-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { style: { maxWidth: "50%" } },
                    [
                      _vm.reportTotalAmount > 0
                        ? _c("v-card-text", { staticClass: "pb-0" }, [
                            _vm._v(
                              " Raportin summa (saatu raha): " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.reportTotalAmount
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.reportTransactionAndProvisionFees != 0
                        ? _c("v-card-text", { staticClass: "pt-0" }, [
                            _vm._v(
                              " Transaktiomaksut ja provisio: " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.reportTransactionAndProvisionFees
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.reportUnsettledAmount > 0
                        ? _c("v-card-text", { staticClass: "pb-0" }, [
                            _vm._v(
                              " Tilittämätön summa: " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.reportUnsettledAmount
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e(),
                      _vm.reportUnrefundedAmount < 0
                        ? _c("v-card-text", { staticClass: "pt-0" }, [
                            _vm._v(
                              " Hyvittämättä jäljellä: " +
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    _vm.reportUnrefundedAmount
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { style: { maxWidth: "50%" } },
                    [
                      _vm.reportDate
                        ? _c("v-card-text", { staticClass: "pb-0" }, [
                            _vm._v(
                              " Raportin pvm: " + _vm._s(_vm.reportDate) + " "
                            )
                          ])
                        : _vm._e(),
                      _vm.reportTransactionAndProvisionFees != 0
                        ? _c("v-card-text", { staticClass: "pt-0" }, [
                            _vm._v(
                              " Tapahtumia ajalla: " +
                                _vm._s(_vm.reportMinDate) +
                                " - " +
                                _vm._s(_vm.reportMaxDate) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex align-center",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("v-select", {
                            staticClass: "mt-2 mr-",
                            style: { maxWidth: "150px" },
                            attrs: {
                              items: _vm.settlementIds,
                              label: this.$t("invoicing.paytrail.settlementId")
                            },
                            model: {
                              value: _vm.selectedSettlementId,
                              callback: function($$v) {
                                _vm.selectedSettlementId = $$v
                              },
                              expression: "selectedSettlementId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("CreateEventsFromPaytrailTransactionsDialog", {
            ref: "createPaytrailEventsDialog",
            on: {
              refreshTableData: function($event) {
                return _vm.refreshData()
              },
              closed: function($event) {
                _vm.createPaytrailEventsDialog = false
              }
            },
            model: {
              value: _vm.createPaytrailEventsDialog,
              callback: function($$v) {
                _vm.createPaytrailEventsDialog = $$v
              },
              expression: "createPaytrailEventsDialog"
            }
          }),
          _c(
            "v-skeleton-loader",
            {
              attrs: {
                loading: _vm.loading,
                "transition-group": "scale-transition",
                type: "table",
                name: "paytrailTransactionsTable"
              }
            },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.transactionsRows,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "show-select": "",
                  "item-key": "checkoutReference",
                  loading: _vm.loadingPage,
                  "footer-props": _vm.footerProps,
                  "items-per-page": _vm.itemsPerPage
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.createdAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDateTime")(item.createdAt)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.amount",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatCurrency")(item.amount)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-1",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.searchTransportEvent(
                                                    item.createdAt,
                                                    item.amount
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_c("v-icon", [_vm._v("mdi-magnify")])],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("invoicing.paytrail.search"))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-1",
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.settleEvent(
                                                    _vm.selectedSettlementId,
                                                    item.archiveNumber,
                                                    item.checkoutReference
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-archive-cog-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("invoicing.paytrail.settle"))
                              )
                            ])
                          ]
                        ),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setAsHandled(
                                                    _vm.selectedSettlementId,
                                                    item.checkoutReference
                                                  )
                                                }
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-format-list-checks")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("invoicing.paytrail.handle"))
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedPaytrailTransactions,
                  callback: function($$v) {
                    _vm.selectedPaytrailTransactions = $$v
                  },
                  expression: "selectedPaytrailTransactions"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        disabled: _vm.selectedPaytrailTransactions.length < 1,
                        color: "success"
                      },
                      on: { click: _vm.showCreateEventsDialog }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("invoicing.paytrail.createEvents")) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.selectedPaytrailTransactions.length > 0
                ? _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "text-md-body-1 font-weight-bold" },
                      [
                        _vm._v(
                          "Valittujen tapahtumien summa: " +
                            _vm._s(
                              _vm._f("formatCurrency")(_vm.selectedTotalSum)
                            ) +
                            " "
                        ),
                        _vm.selectedPaytrailTransactions.some(function(e) {
                          return e.amount < 0
                        })
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-md-body-1 font-weight-bold red--text"
                              },
                              [
                                _vm._v(
                                  " Huom! Valittuihin tapahtumiin sisältyy palautuksia. "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbarColor },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }