var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-expansion-panel",
    [
      _c(
        "v-expansion-panel-header",
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c("b", [_vm._v(_vm._s(_vm.price.unitPriceInfo.name))])
              ]),
              _c("v-spacer"),
              _c("v-col", { attrs: { sm: "2" } }, [
                _vm._v("Vendor"),
                _c("br"),
                _vm._v(
                  _vm._s(
                    _vm._f("formatPrice")(
                      _vm.price.unitPriceInfo.vendorUnitPrice
                    )
                  )
                )
              ]),
              _c("v-col", { attrs: { sm: "2" } }, [
                _vm._v("Customer"),
                _c("br"),
                _vm._v(
                  _vm._s(
                    _vm._f("formatPrice")(
                      _vm.price.unitPriceInfo.customerUnitPrice
                    )
                  )
                )
              ]),
              _c("v-col", { attrs: { sm: "2" } }, [
                _vm._v("VAT"),
                _c("br"),
                _vm._v(_vm._s(_vm.price.unitPriceInfo.vatPercent) + " %")
              ]),
              _c(
                "v-col",
                { attrs: { sm: "2" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mr-2", attrs: { justify: "end" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("modify", _vm.price)
                            }
                          }
                        },
                        [_vm._v("mdi-pencil")]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticClass: "ml-2",
                          attrs: { color: "error" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.$emit("delete", _vm.price)
                            }
                          }
                        },
                        [_vm._v("delete")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-expansion-panel-content",
        [
          _vm.price.rules.some(function(r) {
            return r.ruleType === "Default"
          })
            ? _c("div", [
                _c("span", { staticStyle: { "font-size": "0.9375rem" } }, [
                  _vm._v(
                    "This is default price. Default price is used when rules of any other price are not fulfilled."
                  )
                ])
              ])
            : _c("RuleChipGroup", {
                ref: "ruleChipGroup",
                attrs: {
                  rules: _vm.price.rules.filter(function(r) {
                    return r.ruleType !== "Default"
                  }),
                  allowedRuleTypes: _vm.allowedRuleTypes,
                  isUpdating: _vm.isUpdating
                },
                on: { update: _vm.updateRules }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }