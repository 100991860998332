<template>
    <v-row class="cols-4">
        <v-col>
            <v-card class="mb-2">
                <v-card-title>{{ invoicingBatchesTitle }}</v-card-title>
                <v-data-table :sortBy="headers.created" :sort-desc="true" :headers="headers"
                    :items="batchRows" :items-per-page="-1" class="elevation-1" disable-filtering disable-pagination
                    disable-sort hide-default-footer>
                    <template v-slot:item.created="{ value }">
                        <span>{{ value | formatDateTime }}</span>
                    </template>
                    <template v-slot:item.batchStatus="{ value }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" :color="value | invoicingBatchStatusToColor">
                                    {{ value | invoicingBatchStatusToIcon }}</v-icon>
                            </template>
                            <span>{{ value | invoicingBatchStatusToString }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.totalSum="{ value }">
                        <span>{{ value | formatCurrency }}</span>
                    </template>
                    <template v-slot:item.createdSum="{ value }">
                        <span>{{ value | formatCurrency }}</span>
                    </template>
                    <template v-slot:item.verifiedSum="{ value }">
                        <span>{{ value | formatCurrency }}</span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :disabled="item.batchStatus !== invoicingBatchStatuses.InvoiceCreationCompleted &&
                                                  item.batchStatus !== invoicingBatchStatuses.InvoiceVerificationInProgress"
                                    fab small v-bind="attrs" v-on="on" class="mx-2" @click="verifyBatch(item)">
                                    <v-icon>mdi-file-sync-outline</v-icon>
                                </v-btn>
                            </template>
                        <span> {{ $t("invoicing.batches.verify") }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn fab small v-bind="attrs" v-on="on" class="mx-2" @click="showLogMessages(item)">
                                    <v-icon> mdi-message-text-outline </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("invoicing.batches.showLogMessages") }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn fab :disabled="item.batchStatus !== invoicingBatchStatuses.Completed ||
                                                      item.batchStatus !== invoicingBatchStatuses.Unknown" small v-bind="attrs" v-on="on"
                                    class="mx-2" @click="cancelBatchInvoices(item)">
                                    <v-icon> mdi-file-undo-outline </v-icon>
                                </v-btn>
                            </template>
                            <span> {{ $t("invoicing.batches.cancelBatchInvoices") }} </span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { InvoicingBatchStatusMixin } from "@/models/InvoicingBatchStatus.js";
export default {
    name: "ReadyForInvoicingByFilter",
    mixins: [InvoicingBatchStatusMixin],
    props: {
        id: {
            type: String,
            required: true
        },
        batchRows: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: [
                { text: this.$t("invoicing.batches.created"), value: "created" },
                { text: this.$t("invoicing.batches.status"), value: "batchStatus" },
                { text: this.$t("invoicing.batches.invoicesExpected"), value: "invoicesExpectedCount" },
                { text: this.$t("invoicing.batches.invoicesCreated"), value: "invoicesCreatedCount" },
                { text: this.$t("invoicing.batches.invoicesVerified"), value: "invoicesVerifiedCount" },
                { text: this.$t("invoicing.batches.totalSumEvents"), value: "totalSum" },
                { text: this.$t("invoicing.batches.totalSumCreated"), value: "createdSum" },
                { text: this.$t("invoicing.batches.totalSumVerified"), value: "verifiedSum" },
                { text: this.$t("invoicing.batches.actions"), value: "actions" }
            ]
        };
    },
    computed: {
        invoicingBatchesTitle() {
            if (this.id === "ReadyForPurchaseInvoicingByFilter") {
                return this.$t("invoicing.batches.purchaseTitle");
            } else if (this.id === "ReadyForSalesInvoicingByFilter") {
                return this.$t("invoicing.batches.salesTitle");
            }

            return "Ready for invoicing";
        }
    },
    methods: {
        verifyBatch(batch) {
            this.$emit("verify-batch", batch);
        },
        searchEvents(filterId) {
            this.$emit("search-events", filterId);
        },
        showLogMessages(item) {
            this.$emit("show-log-messages", item.logMessages);
        }

    }
};
</script>
