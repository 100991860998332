var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "500" },
      on: { "click:outside": _vm.closeDialog },
      model: {
        value: _vm.localVisible,
        callback: function($$v) {
          _vm.localVisible = $$v
        },
        expression: "localVisible"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v("Log Messages")
          ]),
          _c(
            "v-card-text",
            _vm._l(_vm.messages, function(message, index) {
              return _c("div", { key: index }, [
                _vm._v(" " + _vm._s(message) + " ")
              ])
            }),
            0
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.closeDialog }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }