<template>
    <v-chip-group column dark>
        <template v-for="(waitFee, n) in waitFees">
            <v-tooltip :key="n" top>
                <template v-slot:activator="{ on }">
                    <v-chip
                        :key="n"
                        @click.stop="openWaitFeeDialog(waitFee, n)"
                        close
                        @click:close="deleteWaitFee(n)"
                        :disabled="readOnly"
                        close-icon="mdi-delete"
                        color="primary"
                        v-on="on"
                    >
                       <span v-if="verbose">{{ waitFee.tariffCategory }}: {{ waitFee.hourPrice }} €/h, {{ waitFee.minutes }} min,  {{waitFee.price}} €</span>
                       <span v-else>{{ waitFee.tariffCategory }}: {{ waitFee.price }}</span>
                    </v-chip>
                </template>
                <span>{{ waitFee | waitFeeToToolTipText }}</span>
            </v-tooltip>
        </template>
        <v-chip
            v-if="!readOnly && (waitFees.length < maxAmount)"
            color="success"
            @click.stop="openNewWaitFeeDialog"
        >
            {{$t("invoicing.addNewWaitFee")}}
            <v-icon right>mdi-plus</v-icon>
        </v-chip>
        <WaitFeeDialog
            :isOpen="waitFeeDialog"
            :originalWaitFee="selectedWaitFee"
            :isNewWaitFee="newWaitFee"
            :allowedWaitFeeTypes="allowedWaitFeeTypes"
            @create="addWaitFee"
            @save="modifyWaitFee"
            @close="closeWaitFeeDialog"
        ></WaitFeeDialog>
    </v-chip-group>
</template>

<script>
import WaitFeeDialog from "./WaitFeeDialog";
import { WaitFeeTypeMixin } from "../../../models/WaitFeeTypes";
import _ from "lodash";

export default {
    components: {
        WaitFeeDialog
    },
    mixins: [WaitFeeTypeMixin],
    props: {
        waitFees: {
            type: Array,
            default: () => []
        },
        allowedWaitFeeTypes: Array,
        maxAmount: Number,
        readOnly: Boolean,
        verbose: Boolean
    },
    data() {
        return {
            waitFeeDialog: false,
            selectedWaitFee: {},
            selectedWaitFeeIndex: -1,
            newWaitFee: true
        };
    },
    methods: {
        openNewWaitFeeDialog() {
            this.selectedWaitFee = {};
            this.newWaitFee = true;
            this.waitFeeDialog = true;
        },
        openWaitFeeDialog(waitFee, index) {
            this.selectedWaitFee = waitFee;
            this.selectedWaitFeeIndex = index;
            this.newWaitFee = false;
            this.waitFeeDialog = true;
        },
        closeWaitFeeDialog() {
            this.selectedWaitFee = {};
            this.waitFeeDialog = false;
        },
        addWaitFee(waitFee) {
            let newWaitFees = _.cloneDeep(this.waitFees);
            newWaitFees.push(waitFee);
            this.$emit("update", newWaitFees);
        },
        deleteWaitFee(index) {
            let newWaitFees = _.cloneDeep(this.waitFees);
            newWaitFees.splice(index, 1);
            this.$emit("update", newWaitFees);
        },
        modifyWaitFee(waitFee) {
            let newWaitFees = _.cloneDeep(this.waitFees);
            this.$set(newWaitFees, this.selectedWaitFeeIndex, waitFee);
            this.$emit("update", newWaitFees);
        }
    }
};
</script>

<style>

</style>
