<template>
  <v-container fluid>
      <v-select
        v-model='dayTypes'
        :items="dayTypeItems"
        :input="$emit('dayTypeTimeRangeUpdated', dayTypeTimeRangeObject)"
        label="DayTypes"
        multiple
        chips
        deletable-chips
        hint="Pick day types"
        persistent-hint
    ></v-select>
    <template v-if="dayTypes.length > 0">
        <TimeRange v-on:timeRangeUpdated="timeRangeUpdated"></TimeRange>
    </template>
  </v-container>
</template>

<script>
import TimeRange from "../TimeRange/TimeRange";

export default {
    components: {
        TimeRange
    },
    data: () => ({
        dayTypes: ["Public holiday", "Sunday"],
        dayTypeItems: ["Working day", "Saturday", "Sunday", "Public holiday", "Workingday Eve"],
        startTime: "",
        endTime: ""
    }),
    methods: {
        timeRangeUpdated(timeRangeObj) {
            this.startTime = timeRangeObj.startTime;
            this.endTime = timeRangeObj.endTime;
        }
    },
    computed: {
        dayTypeTimeRangeObject() {
            return {
                dayTypes: this.dayTypes.join(","),
                startTime: this.startTime,
                endTime: this.endTime
            };
        }
    }
};
</script>

<style>

</style>
