<template>
  <v-form v-model="searchParamsValid" class="mx-3 mt-2">
    <v-row>
      <v-col cols=12 md=6 class="pl-0 py-0">
        <v-autocomplete
          v-model="updatedSearchParams.orderId"
          :items="regions"
          label="Alue">
        </v-autocomplete>
      </v-col>
    </v-row>
     <v-row align="center" class="mt-3">
      <v-col cols=12 sm=7 class="pl-0 py-0">
        <v-select
          v-model="updatedSearchParams.validationStates"
          :items="Object.values(validationStatusTypes)"
          multiple
          clearable
        >
        <template v-slot:label>
          <v-icon style="margin-top: -1px">
            {{ "mdi-car-clock"  }}
          </v-icon>
          <span> {{ $t('invoicing.validationStatusLabel') }} </span>
        </template>
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip v-if="index === 0 || index === 1">
            {{ item | validationStatusToText }}
            </v-chip>
            <span
            v-if="index === 2"
            class="grey--text caption"
            >(+{{ updatedSearchParams.validationStates.length - 2 }} others)</span>
          </template>
          <template v-slot:item="{item, attrs, on}">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action>
                  <v-icon v-on="on">{{ item | validationStatusToIcon }}</v-icon>
                </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    {{ item | validationStatusToText }}
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                    :input-value="active"
              ></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols=12 md=3 class="pl-0 py-0">
        <v-btn
          @click="setFailedValidations"
          outlined
          small
          class="pt-0"
        >
          {{$t("invoicing.searchForFailed")}}
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <v-select
          v-model="updatedSearchParams.salesInvoiceStates"
          :items="Object.values(kelaSalesInvoiceStates)"
          :disabled="!!updatedSearchParams.salesInvoiceNumbers"
          multiple
          clearable
        >
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip v-if="index === 0 || index === 1">
            {{ item | salesInvoiceStatusToText }}
            </v-chip>
            <span
            v-if="index === 2"
            class="grey--text caption"
            >(+{{ updatedSearchParams.salesInvoiceStates.length - 2 }} others)</span>
          </template>
          <template v-slot:label>
            <v-icon style="margin-top: -2px">
              {{ "mdi-file-clock-outline"  }}
            </v-icon>
            <span> Myyntilaskun tila </span>
          </template>
          <template v-slot:item="{item, attrs, on}">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action>
                  <v-icon v-on="on">{{ item | salesInvoiceStatusToIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      {{ item | salesInvoiceStatusToText }}
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                      :input-value="active"
                ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols=12 sm=1 class="py-0">
        <v-subheader>TAI</v-subheader>
      </v-col>
      <v-col cols=12 sm=5 class="pl-0 py-0">
        <v-text-field
          v-model="updatedSearchParams.salesInvoiceNumbers"
          label="Myyntilaskun numero"
          @change="updatedSearchParams.salesInvoiceStates = []"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 sm=6 class="pl-0 py-0">
        <v-select
          v-model="updatedSearchParams.purchaseInvoiceStates"
          :items="Object.values(kelaPurchaseInvoiceStates)"
          :disabled="!!updatedSearchParams.purchaseInvoiceNumbers"
          multiple
          clearable
        >
          <template v-slot:label>
            <v-icon style="margin-top: -2px">
              {{ "mdi-archive-clock-outline"  }}
            </v-icon>
            <span> Ostolaskun tila </span>
          </template>
          <template slot="selection" slot-scope="{ item, index }">
            <v-chip v-if="index === 0 || index === 1">
            {{ item | purchaseInvoiceStatusToText }}
            </v-chip>
            <span
            v-if="index === 2"
            class="grey--text caption"
            >(+{{ updatedSearchParams.purchaseInvoiceStates.length - 2 }} others)</span>
          </template>
          <template v-slot:item="{item, attrs, on}">
              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                <v-list-item-action>
                  <v-icon v-on="on">{{ item | purchaseInvoiceStatusToIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters align="center">
                      {{ item | purchaseInvoiceStatusToText }}
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                      :input-value="active"
                ></v-checkbox>
                </v-list-item-action>
              </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols=12 sm=1 class="py-0">
        <v-subheader>TAI</v-subheader>
      </v-col>
      <v-col cols=12 sm=5 class="pl-0 py-0">
        <v-text-field
          v-model="updatedSearchParams.purchaseInvoiceNumbers"
          label="Ostolaskun numero"
          @change="updatedSearchParams.purchaseInvoiceStates = []"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.serviceNumber"
          label="Palvelutunnus"
        ></v-text-field>
      </v-col>
      <!--
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.serviceProviderVatId"
          label="Y-tunnus"
        ></v-text-field>
      </v-col>
      -->
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.carNumber"
          label="Autonumero"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=4>
        <v-text-field
          v-model="updatedSearchParams.SSN"
          label="Henkilötunnus"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=3 class="pl-0 py-0">Matka alkanut</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedFrom"
          label="Matkan alku alkaen"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripStartedTo"
          label="Matkan alku päättyen"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=3 class="pl-0 py-0">Matka päättynyt</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedFrom"
          label="Matkan päätös alkaen"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.tripEndedTo"
          label="Matkan päätös päättyen"
          type="datetime-local"
          max="2099-12-31T00:00"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=3 class="pl-0 py-0">Tapahtumaa muokattu</v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
          v-model="updatedSearchParams.modifiedFrom"
          label="Muokattu alkaen"
          type="datetime-local"
          max="2099-12-31T00:00"
          useCurrent=true
        ></v-text-field>
      </v-col>
      <v-col class="pl-0 py-0" cols=12 sm=6 md=3>
        <v-text-field
        v-model="updatedSearchParams.modifiedTo"
        label="Muokattu päättyen"
        type="datetime-local"
        max="2099-12-31T00:00"
        useCurrent="true"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0 mb-2">Kustannus</v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.costFrom"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col sm=auto class="hidden-xs-only py-0 pb-3" style="font-size: 1.5em;">
        -
      </v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.costTo"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols=12 sm=2 class="py-0">
        <v-btn text @click="updatedSearchParams.costFrom = null; updatedSearchParams.costTo = null;">{{$t('common.clear')}}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0 mb-2">Korvaus</v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.reimbursementFrom"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col sm=auto class="hidden-xs-only py-0 pb-3" style="font-size: 1.5em;">
        -
      </v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.reimbursementTo"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols=12 sm=2 class="py-0">
        <v-btn text @click="updatedSearchParams.reimbursementFrom = null; updatedSearchParams.reimbursementTo = null;">{{$t('common.clear')}}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row align="center">
      <v-col cols=12 md=2 class="pl-0 py-0 mb-2">Omavastuu</v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.deductibleFrom"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col sm=auto class="hidden-xs-only py-0 pb-3" style="font-size: 1.5em;">
        -
      </v-col>
      <v-col cols=6 sm=auto class="py-0">
        <v-text-field
          v-model="updatedSearchParams.deductibleTo"
          single-line
          type="number"
          placeholder="-"
          variant="outlined"
          class="pt-0 centered-input"
          style="width: 70px"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols=12 sm=2 class="py-0">
        <v-btn text @click="updatedSearchParams.deductibleFrom = null; updatedSearchParams.deductibleTo = null;">{{$t('common.clear')}}</v-btn>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-switch
        v-model="updatedSearchParams.removed"
        label="Etsi myös poistettuja tapahtumia"
      ></v-switch>
    </v-row>
    </v-form>
</template>

<script>
import _ from "lodash";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TranportEventValidationErrorMixin } from "@/models/ValidationErrors";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import { KelaRegions } from "@/models/KelaRegions";

export default {
    props: {
        searchParams: Object
    },
    mixins: [ TransportEventStatusMixin, TranportEventValidationErrorMixin, TransportEventInvoicingMixin ],
    data() {
        return {
            updatedSearchParams: this.formatSearchParams(this.searchParams),
            searchParamsValid: true,
            regions: this.toRegionSearchFormat(KelaRegions)
        };
    },
    watch: {
        searchParams(val) {
            this.updatedSearchParams = this.formatSearchParams(this.searchParams);
        }
    },
    methods: {
        formatSearchParams(searchParams) {
            let params = {};
            if (!searchParams) return params;
            params.validationStates = this.parseQueryParamsToIntList(searchParams.validationStates);
            params.salesInvoiceStates = this.parseQueryParamsToIntList(searchParams.salesInvoiceStates);
            params.purchaseInvoiceStates = this.parseQueryParamsToIntList(searchParams.purchaseInvoiceStates);
            if (searchParams.validationFailReasonCodes) {
                params.validationFailReasonCodes = _.cloneDeep(searchParams.validationFailReasonCodes);
            }
            if (searchParams.salesInvoiceStates) {
                params.salesInvoiceStates = _.cloneDeep(searchParams.salesInvoiceStates);
            } else {
                params.salesInvoiceStates = [];
            }
            if (searchParams.salesInvoiceNumbers) {
                params.salesInvoiceNumbers = _.cloneDeep(searchParams.salesInvoiceNumbers);
            }
            if (searchParams.purchaseInvoiceStates) {
                params.purchaseInvoiceStates = _.cloneDeep(searchParams.purchaseInvoiceStates);
            } else {
                params.purchaseInvoiceStates = [];
            }
            if (searchParams.purchaseInvoiceNumbers) {
                params.purchaseInvoiceNumbers = _.cloneDeep(searchParams.purchaseInvoiceNumbers);
            }
            if (searchParams.carNumber) {
                params.carNumber = _.cloneDeep(searchParams.carNumber);
            }
            if (searchParams.cardNumber) {
                params.cardNumber = _.cloneDeep(searchParams.cardNumber);
            }
            if (searchParams.taximeterSerialNumber) {
                params.taximeterSerialNumber = _.cloneDeep(searchParams.taximeterSerialNumber);
            }
            if (searchParams.serviceNumber) {
                params.serviceNumber = _.cloneDeep(searchParams.serviceNumber);
            }
            if (searchParams.SSN) {
                params.SSN = _.cloneDeep(searchParams.SSN);
            }
            if (searchParams.modifiedFrom) {
                params.modifiedFrom = _.cloneDeep(searchParams.modifiedFrom);
            }
            if (searchParams.modifiedTo) {
                params.modifiedTo = _.cloneDeep(searchParams.modifiedTo);
            }
            if (searchParams.tripStartedFrom) {
                params.tripStartedFrom = _.cloneDeep(searchParams.tripStartedFrom);
            }
            if (searchParams.tripStartedTo) {
                params.tripStartedTo = _.cloneDeep(searchParams.tripStartedTo);
            }
            if (searchParams.tripEndedFrom) {
                params.tripEndedFrom = _.cloneDeep(searchParams.tripEndedFrom);
            }
            if (searchParams.tripEndedTo) {
                params.tripEndedTo = _.cloneDeep(searchParams.tripEndedTo);
            }
            return params;
        },
        setFailedValidations() {
            this.updatedSearchParams.validationStates = this.validationStatusFailTypes;
        },
        getSearchParameters() {
            if (!this.updatedSearchParams.validationStates.includes(this.validationStatusTypes.ExternalValidationFailed)) {
                delete this.updatedSearchParams.validationFailReasonCodes;
            }
            return this.updatedSearchParams;
        },
        toRegionSearchFormat(regions) {
            let searchFormatted = regions;
            searchFormatted.forEach(function(region, index, array) {
                array[index].value = region.value + "*";
            });
            return searchFormatted;
        },
        parseQueryParamsToIntList(params) {
            if (params) {
                let formatted;
                if (Array.isArray(params)) {
                    formatted = _.cloneDeep(params);
                } else {
                    formatted = [_.cloneDeep(params)];
                }
                formatted = formatted.map(x => parseInt(x, 10));
                return formatted;
            } else {
                return [];
            }
        }
    }
};
</script>

<style>

</style>
