var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { staticClass: "pr-2", attrs: { cols: "auto" } },
        [
          _c("v-text-field", {
            attrs: { "pr-1": "", label: _vm.$t("keyValueInput.key") },
            model: {
              value: _vm.key,
              callback: function($$v) {
                _vm.key = $$v
              },
              expression: "key"
            }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "auto" } },
        [
          _c("v-text-field", {
            attrs: { label: _vm.$t("keyValueInput.value") },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        ],
        1
      ),
      !_vm.isEmpty
        ? _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { fab: "", dark: "", color: "indigo" },
                  on: { click: _vm.createKeyValuePair }
                },
                [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("mdi-check")])],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }