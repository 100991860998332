var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-col",
        { attrs: { cols: "2" } },
        [_c("v-btn", { on: { click: _vm.getLogs } }, [_vm._v("Get")])],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "calculate-widths": "",
          headers: _vm.showableHeaders,
          items: _vm.logs,
          "item-key": "id",
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          search: _vm.filter,
          loading: _vm.loadingPage,
          "footer-props": {
            pagination: _vm.pagination,
            itemsPerPageOptions: _vm.itemsPerPageOptions
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.timestamp",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("span", [_vm._v(" " + _vm._s(_vm.formatDateTime(value)))])
              ]
            }
          },
          {
            key: "item.level",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.message",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }