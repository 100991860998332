import Vue from "vue";
import Vuetify from "vuetify/lib";
import en from "vuetify/es5/locale/en";
import fi from "@/assets/translations/vuetify-fi";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { en, fi }
    },
    icons: {
        iconfont: "mdi"
    },
    theme: {
        light: true,
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: "#2882B9",
                secondary: "#F07D00"
            }
        }
    }
});
