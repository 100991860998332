export const TaxiMeterState = {
    Unknown: 0,
    Off: 1,
    Private: 2,
    Service: 3,
    Bus: 4,
    Contract: 5,
    Free: 6,
    Occupied: 7,
    Cash: 8
};

export function TaxiMeterStateToString(taxiMeterState) {
    switch (taxiMeterState) {
    case TaxiMeterState.Unknown:
        return "-";
    case TaxiMeterState.Off:
        return "Off";
    case TaxiMeterState.Private:
        return "Private";
    case TaxiMeterState.Service:
        return "Service";
    case TaxiMeterState.Bus:
        return "Bus";
    case TaxiMeterState.Contract:
        return "Contract";
    case TaxiMeterState.Free:
        return "Free";
    case TaxiMeterState.Occupied:
        return "Occupied";
    case TaxiMeterState.Cash:
        return "Cash";
    }
};
