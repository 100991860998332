var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Company Id", disabled: true },
                model: {
                  value: _vm.editableCompany.id,
                  callback: function($$v) {
                    _vm.$set(_vm.editableCompany, "id", $$v)
                  },
                  expression: "editableCompany.id"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Name" },
                model: {
                  value: _vm.editableCompany.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editableCompany, "name", $$v)
                  },
                  expression: "editableCompany.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.companyStatuses,
                  label: "Status",
                  disabled: true
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.companyStatusToString(item)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.companyStatusToString(item)) + " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.editableCompany.status,
                  callback: function($$v) {
                    _vm.$set(_vm.editableCompany, "status", $$v)
                  },
                  expression: "editableCompany.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.editable
        ? _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { disabled: !_vm.dirty },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }