<template>
  <v-select
    :value="updatedSearchParams"
    :items="Object.values(validationStatusTypes)"
    @input="$emit('update:updatedSearchParams', $event)"
    multiple
    clearable
  >
    <template v-slot:label>
      <v-icon style="margin-top: -1px">
        {{ "mdi-car-clock" }}
      </v-icon>
      <span> {{ $t("invoicing.validationStatusLabel") }} </span>
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0 || index === 1">
        {{ item | validationStatusToText }}
      </v-chip>
      <span v-if="index === 2" class="grey--text caption"
        >(+{{ updatedSearchParams.validationStates.length - 2 }} others)</span
      >
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-icon v-on="on">{{ item | validationStatusToIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ item | validationStatusToText }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
export default {
    props: {
        updatedSearchParams: Array
    },
    emits: ["update:updatedSearchParams"],
    mixins: [TransportEventStatusMixin]
};
</script>
