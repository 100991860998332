export const TripStatus = {
    Unknown: 0,
    New: 1,
    LookingForVehicle: 2,
    VehicleSelected: 4,
    SentToVehicle: 8,
    Ongoing: 16,
    Done: 32,
    Cancelled: 64,
    VehicleNotFound: 128
};

export function TripStatusToString(tripStatus) {
    switch (tripStatus) {
    case TripStatus.Unknown:
        return "-";
    case TripStatus.New:
        return "New";
    case TripStatus.LookingForVehicle:
        return "Looking for vehicle";
    case TripStatus.VehicleSelected:
        return "Vehicle selected";
    case TripStatus.SentToVehicle:
        return "Sent to vehicle";
    case TripStatus.Ongoing:
        return "Ongoing";
    case TripStatus.Done:
        return "Done";
    case TripStatus.Cancelled:
        return "Cancelled";
    case TripStatus.VehicleNotFound:
        return "Vehicle not found";
    }
};
