var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "end" } },
        [
          _c("v-col", [
            _c("h1", [_vm._v(_vm._s(_vm.$t("invoicing.paytrail.title")))])
          ]),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.navigate()
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("invoicing.transportEvents")) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PaytrailTransactionsTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }