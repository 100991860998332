var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("v-data-table", {
            attrs: {
              "calculate-widths": "",
              headers: _vm.showableHeaders,
              items: _vm.editableProperties,
              "item-key": "key",
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              search: _vm.filter,
              "items-per-page": 10
            },
            scopedSlots: _vm._u([
              {
                key: "item.origin",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.propertyOriginToString(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.key",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.value",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: !_vm.isEditable(item) },
                            on: {
                              click: function($event) {
                                return _vm.editProperty(item)
                              }
                            }
                          },
                          [_vm._v(" Edit ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: !_vm.isEditable(item) },
                            on: {
                              click: function($event) {
                                return _vm.deleteProperty(item)
                              }
                            }
                          },
                          [_vm._v(" Delete ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "pb-4" },
        [
          _c("v-spacer"),
          _vm.canCreate
            ? _c("v-btn", { on: { click: _vm.createProperty } }, [
                _vm._v("Create")
              ])
            : _vm._e(),
          _c("v-spacer")
        ],
        1
      ),
      _c("PropertyForm", {
        attrs: {
          isOpen: _vm.isEditFormOpen,
          property: _vm.selectedProperty,
          canAddNew: true,
          propertyOrigin: _vm.editablePropertyOrigin
        },
        on: {
          cancel: _vm.closeEditForm,
          addNewProperty: _vm.addNewProperty,
          saveProperty: _vm.saveProperty,
          deleteProperty: _vm.deleteProperty
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }