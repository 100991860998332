export default {
    inject: ["$triggerEvent"],
    data() {
        return {
            eventTargetId: self.crypto.randomUUID()
        };
    },
    computed: {
        triggerEvent() {
            return this.$triggerEvent();
        }
    },
    watch: {
        triggerEvent: {
            handler(val) {
                if (val.eventTargetId === this.eventTargetId) {
                    this.$emit("newEventTriggered", val);
                }
            },
            deep: true
        }
    }
};
