<template>
    <div>
        <v-dialog v-model="showDialog" max-width="800" @input="onDialogInput">
            <v-card>
                <v-toolbar short dark color="primary">
                    <v-btn icon dark @click="cancel">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        {{ $t("invoicing.invoicingOptions") }}
                    </v-toolbar-title>
                    <template v-slot:extension>
                        <v-tabs v-model="modelTab">
                            <v-tab v-for="option in localInvoiceOptions" :key="option.id" :href="'#' + option.id">
                                {{ option.optionsName }}
                            </v-tab>
                            <v-tab @click="createNewInvoiceOption">+ {{ $t("common.addNew") }}</v-tab>
                        </v-tabs>
                    </template>
                </v-toolbar>
                <v-card-text style="color: black; margin-top: 16px;">
                    <v-tabs-items v-model="modelTab">
                        <v-tab-item v-for="option in localInvoiceOptions" :key="option.id" :value="option.id">
                            <InvoiceOptionsForm ref="invoiceOptionsForm" :invoiceOptions="option"
                                @submit="submitForm" />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn @click="triggerFormSubmit" color="primary">
                        {{ $t("common.save") }}
                    </v-btn>
                    <v-btn @click="$emit('cancel')" class="ml-4">
                        {{ $t("common.cancel") }}
                    </v-btn>
                    <v-btn @click="confirmDeleteOption(modelTab)" class="ml-4" color="error">
                        {{ $t("common.delete") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <NewInvoiceOptionDialog :show="showNewOptionDialog" @update:show="showNewOptionDialog = $event"
            :defaultInvoicingOptions="defaultInvoicingOptions" @create="handleCreateNewOptions"
            @cancel="handleCancelNewOptionDialog" />

        <!-- Confirmation dialog for deletion -->
        <v-dialog v-model="showConfirmDialog" max-width="500">
            <v-card>
                <v-card-title class="headline">{{ $t("common.delete") }}</v-card-title>
                <v-card-text>{{ $t("invoicing.options.confirmDelete") }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="cancelDelete">{{ $t("common.cancel") }}</v-btn>
                    <v-btn color="error" @click="deleteOption">{{ $t("common.delete") }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import NewInvoiceOptionDialog from "./NewInvoiceOptionsDialog.vue";
import InvoiceOptionsForm from "../InvoiceOptionsForm.vue";
import { RepositoryFactory } from "../../../data/repositories/repositoryFactory";
import _ from "lodash";

export default {
    components: { InvoiceOptionsForm, NewInvoiceOptionDialog },
    props: {
        show: { type: Boolean, required: true },
        invoiceOptions: { type: Array, required: true }
    },
    data() {
        return {
            showDialog: this.show,
            modelTab: null,
            localInvoiceOptions: _.cloneDeep(this.invoiceOptions),
            defaultInvoicingOptions: [],
            showNewOptionDialog: false,
            showConfirmDialog: false, // Hyväksyntädialogin tila
            optionToDelete: null // Poistettava optio
        };
    },
    watch: {
        show(newVal) {
            this.showDialog = newVal;
            if (newVal) {
                this.localInvoiceOptions = _.cloneDeep(this.invoiceOptions);
                if (this.localInvoiceOptions.length > 0) {
                    this.modelTab = this.localInvoiceOptions[0].id;
                }
            }
        },
        invoiceOptions: {
            handler(newVal) {
                this.localInvoiceOptions = _.cloneDeep(newVal);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        onDialogInput(value) {
            if (value === false) {
                this.$emit("cancel");
            }
        },
        triggerFormSubmit() {
            const formRef = this.$refs.invoiceOptionsForm.find(form => form.invoiceOptions.id === this.modelTab);
            if (formRef) {
                formRef.submitForm();
            } else {
                // console.error("No form found for the selected tab");
            }
        },
        submitForm(updatedOptions) {
            const selectedOption = this.localInvoiceOptions.find(option => option.id === this.modelTab);
            if (selectedOption) {
                this.$emit("save", { options: updatedOptions });
            } else {
                // console.error("No selected option found");
            }
        },
        createNewInvoiceOption() {
            this.showNewOptionDialog = true;
            this.showDialog = false;
        },
        async handleCreateNewOptions(newOption) {
            this.$emit("create", newOption);
        },
        cancel() {
            this.$emit("cancel");
        },
        confirmDeleteOption(optionId) {
            this.optionToDelete = optionId;
            this.showConfirmDialog = true;
        },
        cancelDelete() {
            this.optionToDelete = null;
            this.showConfirmDialog = false;
        },
        deleteOption() {
            // console.log("Delete option with id: ", this.optionToDelete);
            this.$emit("delete", this.optionToDelete);
            this.showConfirmDialog = false;
            this.optionToDelete = null;
        },
        handleCancelNewOptionDialog() {
            this.showNewOptionDialog = false;
            this.$emit("cancel");
        },
        async getAllDefaultInvoicingOptions() {
            RepositoryFactory.get("invoicing").getAllDefaultInvoicingOptions()
                .then(response => {
                    this.defaultInvoicingOptions = response.data;
                });
        }
    },
    mounted() {
        this.getAllDefaultInvoicingOptions();
    }
};
</script>
