export const FeeType = {
    Unknown: 0,
    BaseFee: 1,
    KilometerFee: 2,
    MinuteFee: 3,
    WaitFee: 4,
    FixedFee: 5,
    PrebookFee: 6,
    GoodsFee: 7,
    AirportFee: 8,
    ServiceFee: 9, // Palvelulisämaksu
    AidFee: 10, // Avustuslisämaksu
    HandicapFee: 11, // Invalisämaksu
    OtherFee: 12, // For Kela
    TariffFee: 13,
    StretcherFee: 14, // Paarilisä
    ApproachFee: 15 // Luokseajo
};

export const FeeTypeMitax = {
    T: "T",
    E: "E",
    A: "A",
    P: "P",
    K: "K",
    L: "L",
    M: "M"
};

export const FeeTypeMixin = {
    data() {
        return {
            feeTypes: FeeType
        };
    },
    methods: {
        mitaxFeeTypeToFeeType: function(mitaxFeeType) {
            switch (mitaxFeeType) {
            case FeeTypeMitax.T:
                return FeeType.GoodsFee;
            case FeeTypeMitax.E:
                return FeeType.PrebookFee;
            case FeeTypeMitax.A:
                return [FeeType.ServiceFee, FeeType.HandicapFee];
            case FeeTypeMitax.K:
                return FeeType.AidFee;
            case FeeTypeMitax.L:
                return FeeType.AirportFee;
            case FeeTypeMitax.M:
            default:
                return FeeType.OtherFee;
            }
        },
        feeTypeToTranslationKey: function(feeType) {
            switch (feeType) {
            case FeeType.BaseFee:
                return "baseExtra";
            case FeeType.KilometerFee:
                return "kilometerExtra";
            case FeeType.MinuteFee:
                return "minuteExtra";
            case FeeType.WaitFee:
                return "waitExtra";
            case FeeType.FixedFee:
                return "fixedExtra";
            case FeeType.PrebookFee:
                return "preBookingExtra";
            case FeeType.GoodsFee:
                return "goodsExtra";
            case FeeType.AirportFee:
                return "airportExtra";
            case FeeType.ServiceFee:
                return "serviceExtra";
            case FeeType.AidFee:
                return "aidExtra";
            case FeeType.HandicapFee:
                return "handicapExtra";
            case FeeType.StretcherFee:
                return "stretcherExtra";
            case FeeType.ApproachFee:
                return "approachExtra";
            case FeeType.OtherFee:
            default:
                return "otherExtra";
            }
        }
    },
    filters: {
        feeTypeToText: function(value) {
            switch (value) {
            case FeeType.Unknown:
                return "Unknown";
            case FeeType.BaseFee:
                return "Base fee";
            case FeeType.KilometerFee:
                return "Kilometer fee";
            case FeeType.MinuteFee:
                return "Minute fee";
            case FeeType.WaitFee:
                return "Wait fee";
            case FeeType.FixedFee:
                return "Fixed fee";
            case FeeType.PrebookFee:
            case FeeTypeMitax.E:
                return "Prebook fee";
            case FeeType.GoodsFee:
            case FeeTypeMitax.T:
                return "Goods fee";
            case FeeType.AirportFee:
            case FeeTypeMitax.L:
                return "Airport fee";
            case FeeType.ServiceFee: // Mitax A
                return "Service fee";
            case FeeType.AidFee:
            case FeeTypeMitax.K:
                return "Aid fee";
            case FeeType.HandicapFee: // Mitax A
                return "Handicap fee";
            case FeeType.StretcherFee:
            case FeeTypeMitax.P:
                return "Strecher fee";
            case FeeType.ApproachFee:
                return "Approach Fee";
            case FeeType.OtherFee:
            case FeeTypeMitax.M:
                return "Other fee";
            default:
                return "Unknown";
            }
        },
        feeTypeToLikumaText: function(value) {
            switch (value) {
            case FeeType.AirportFee:
                return "L - Airport extra";
            case FeeType.ServiceFee:
                return "S - Service extra";
            case FeeType.AidFee:
                return "P - Stair help extra";
            case FeeType.HandicapFee:
                return "I - Handicap extra";
            case FeeType.StretcherFee:
                return "H - Stretcher extra";
            case FeeType.ApproachFee:
                return "N - Approach extra";
            default:
                return "Not valid type";
            }
        },
        feeTypeToKelaText: function(value) {
            switch (value) {
            case FeeType.ServiceFee:
                return "A - Avustamislisä";
            case FeeType.AidFee:
                return "P - Paarilisä tai porraslisä";
            case FeeType.OtherFee:
                return "M - Muu lisä";
            default:
                return "Lisän tyyppi ei kelpaa";
            }
        },
        feeToToolTipText: function(value) {
            let vat = !value.vat ? "" : ", Vat: " + value.vat;
            return "Sum: " + value.price + vat;
        }
    }
};
