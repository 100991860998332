<template>
  <v-card>
    <v-card-text>
      <v-data-table
          calculate-widths
          :headers="showableHeaders"
          :items="editableProperties"
          item-key="key"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :search="filter"
          :items-per-page="10"
        >
          <template v-slot:item.origin="{ value }">
            <span> {{ propertyOriginToString(value) }}</span>
          </template>
          <template v-slot:item.key="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.value="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <span>
              <v-btn
                @click="editProperty(item)"
                :disabled="!isEditable(item)"
              >
                Edit
              </v-btn>
            </span>
            <span>
              <v-btn
                @click="deleteProperty(item)"
                :disabled="!isEditable(item)"
              >
                Delete
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer></v-spacer>
        <v-btn v-if="canCreate" @click="createProperty">Create</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <PropertyForm
      :isOpen="isEditFormOpen"
      :property="selectedProperty"
      :canAddNew="true"
      :propertyOrigin="editablePropertyOrigin"
      @cancel="closeEditForm"
      @addNewProperty="addNewProperty"
      @saveProperty="saveProperty"
      @deleteProperty="deleteProperty">
    </PropertyForm>
  </v-card>
</template>

<script>
import PropertyForm from "./Forms/PropertyForm";
import { PropertyOrigin, PropertyOriginToString } from "../../models/PropertyOrigin";
import _ from "lodash";

export default {
    components: { PropertyForm },
    props: {
        properties: {
            type: Array,
            default: function() {
                return null;
            }
        },
        editablePropertyOrigin: PropertyOrigin,
        result: {
            type: Object,
            default: function() {
                return null;
            }
        },
        canEdit: {
            type: Boolean,
            default: function() {
                return true;
            }
        },
        canCreate: {
            type: Boolean,
            default: function() {
                return true;
            }
        }
    },
    data() {
        return {
            filter: "",
            sortBy: "key",
            sortDesc: true,
            headers: [
                { text: "Origin", value: "origin", show: true },
                { text: "Key", value: "key", show: true },
                { text: "Value", value: "value", show: true },
                { text: "Actions", value: "actions", show: this.canEdit }
            ],
            editableProperties: _.cloneDeep(this.properties),
            selectedProperty: null,
            isEditFormOpen: false,
            triggerableEvent: null
        };
    },
    computed: {
        showableHeaders() {
            return this.headers.filter(h => h.show);
        }
    },
    watch: {
        properties(val) {
            this.editableProperties = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        propertyOriginToString(propertyOrigin) {
            return PropertyOriginToString(propertyOrigin);
        },
        createProperty() {
            this.selectedProperty = null;
            this.isEditFormOpen = true;
        },
        editProperty(property) {
            this.selectedProperty = property;
            this.isEditFormOpen = true;
        },
        addNewProperty: function(property, eventTargetId) {
            this.$emit("addNewProperty", property, eventTargetId);
        },
        saveProperty: function(property, eventTargetId) {
            this.$emit("saveProperty", property, eventTargetId);
        },
        deleteProperty: function(property, eventTargetId) {
            this.$emit("deleteProperty", property, eventTargetId);
        },
        isEditable(property) {
            return property.origin === this.editablePropertyOrigin;
        },
        closeEditForm() {
            this.isEditFormOpen = false;
            this.selectedProperty = null;
        }
    }
};
</script>
