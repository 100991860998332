var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      value: _vm.updatedSearchParams,
      items: Object.values(_vm.validationStatusTypes),
      multiple: "",
      clearable: ""
    },
    on: {
      input: function($event) {
        return _vm.$emit("update:updatedSearchParams", $event)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [
            _c("v-icon", { staticStyle: { "margin-top": "-1px" } }, [
              _vm._v(" " + _vm._s("mdi-car-clock") + " ")
            ]),
            _c("span", [
              _vm._v(
                " " + _vm._s(_vm.$t("invoicing.validationStatusLabel")) + " "
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "selection",
        fn: function(ref) {
          var item = ref.item
          var index = ref.index
          return [
            index === 0 || index === 1
              ? _c("v-chip", [
                  _vm._v(
                    " " + _vm._s(_vm._f("validationStatusToText")(item)) + " "
                  )
                ])
              : _vm._e(),
            index === 2
              ? _c("span", { staticClass: "grey--text caption" }, [
                  _vm._v(
                    "(+" +
                      _vm._s(
                        _vm.updatedSearchParams.validationStates.length - 2
                      ) +
                      " others)"
                  )
                ])
              : _vm._e()
          ]
        }
      },
      {
        key: "item",
        fn: function(ref) {
          var item = ref.item
          var attrs = ref.attrs
          var on = ref.on
          return [
            _c(
              "v-list-item",
              _vm._g(
                _vm._b(
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var active = ref.active
                            return [
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-icon", _vm._g({}, on), [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("validationStatusToIcon")(item)
                                      )
                                    )
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c(
                                    "v-list-item-title",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f(
                                                  "validationStatusToText"
                                                )(item)
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  _c("v-checkbox", {
                                    attrs: { "input-value": active }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  "v-list-item",
                  attrs,
                  false
                ),
                on
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }