var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1400" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _vm.trip
        ? _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { short: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.close }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _c("v-toolbar-title", [
                            _vm._v(" Trip - " + _vm._s(_vm.trip.id) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "fixed-tabs": "",
                                "background-color": "indigo",
                                dark: ""
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c("v-tab", { attrs: { href: "#tab-details" } }, [
                                _vm._v(" Details ")
                              ]),
                              _c("v-tab", { attrs: { href: "#tab-route" } }, [
                                _vm._v(" Route ")
                              ]),
                              _c("v-tab", { attrs: { href: "#tab-offers" } }, [
                                _vm._v(" Offers ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: 1, attrs: { value: "tab-details" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [_vm._v("Trip Info")]),
                                      _c("v-card-text", [_vm._v(" TODO ")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 2, attrs: { value: "tab-route" } },
                                [
                                  _c("MapBox", {
                                    attrs: {
                                      routes: [
                                        _vm.trip.actualRoute,
                                        _vm.trip.plannedRoute
                                      ]
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 3, attrs: { value: "tab-offers" } },
                                [
                                  _c("OffersTable", {
                                    attrs: { offers: _vm.offers }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }