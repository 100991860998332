var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1000" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _vm.show
        ? _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { short: "", dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: { click: _vm.cancel }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [
                    _vm._v(" " + _vm._s(_vm.$t("common.search")) + " ")
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "mx-3 mt-2",
                      model: {
                        value: _vm.searchParamsValid,
                        callback: function($$v) {
                          _vm.searchParamsValid = $$v
                        },
                        expression: "searchParamsValid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mt-3", attrs: { align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: { items: _vm.operators, label: "Id" },
                                model: {
                                  value: _vm.editableSearchParams.companyId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSearchParams,
                                      "companyId",
                                      $$v
                                    )
                                  },
                                  expression: "editableSearchParams.companyId"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.tripStatuses,
                                  label: "Trip Status"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tripStatusToString(item)
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    },
                                    {
                                      key: "item",
                                      fn: function(ref) {
                                        var item = ref.item
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.tripStatusToString(item)
                                              ) +
                                              " "
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  419785084
                                ),
                                model: {
                                  value: _vm.editableSearchParams.tripStatus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.editableSearchParams,
                                      "tripStatus",
                                      $$v
                                    )
                                  },
                                  expression: "editableSearchParams.tripStatus"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { color: "primary" }, on: { click: _vm.search } },
                    [_vm._v(" " + _vm._s(_vm.$t("common.search")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    { staticClass: "ml-2", on: { click: _vm.cancel } },
                    [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }