<template>
  <div class="mb-1 pb-2">
    <h1>
      Dispatching
    </h1>
    <v-container fluid fill-height>
      <v-row>
        <v-col>
          <v-tabs
              v-model="tab"
              fixed-tabs
              background-color="indigo"
              dark
          >
            <v-tab href="#tab-areas">
              Areas
            </v-tab>
            <v-tab href="#tab-postalcodes">
              Postal Codes
            </v-tab>
            <v-tab href="#tab-geo">
              Geo
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab">
            <v-tab-item
              :key="1"
              :value="'tab-areas'">
              <v-card>
                <v-card-text>
                  <AreaVehiclesTable
                    @vehicleSelected="selectVehicle"
                  >
                  </AreaVehiclesTable>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              :value="'tab-postalcodes'">
              <v-card>
                <v-card-text>
                  <PostalCodeVehiclesTable
                    @vehicleSelected="selectVehicle"
                  >
                  </PostalCodeVehiclesTable>
                </v-card-text>
              </v-card>
            </v-tab-item>
             <v-tab-item
              :key="3"
              :value="'tab-geo'">
              <v-card>
                <v-card-text>
                  <GeoVehiclesTable
                    @vehicleSelected="selectVehicle"
                  >
                  </GeoVehiclesTable>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col style="height: 600px; width: 600px">
          <MapBox
            :vehicles="vehicles"
            :center="center"
          ></MapBox>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
    </div>
</template>

<script>
import AreaVehiclesTable from "./AreaVehiclesTable";
import PostalCodeVehiclesTable from "./PostalCodeVehiclesTable";
import GeoVehiclesTable from "./GeoVehiclesTable";
import MapBox from "../Geo/MapBox";

export default {
    components: {
        AreaVehiclesTable,
        PostalCodeVehiclesTable,
        GeoVehiclesTable,
        MapBox
    },
    data() {
        return {
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            tab: null,
            vehicle: null,
            defaultCenter: { latitude: 60.326692, longitude: 24.840489 }
        };
    },
    computed: {
        vehicles() {
            if (this.vehicle) {
                return [this.vehicle];
            }
            return [];
        },
        center() {
            if (this.vehicle) {
                return this.vehicle.location.point;
            }
            return this.defaultCenter;
        }
    },
    methods: {
        selectVehicle: function(vehicle) {
            this.vehicle = vehicle;
        }
    }
};
</script>
