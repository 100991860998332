<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
        <v-card-title>
          <span class="headline">{{$t('customerDetails.codeDialog.createNewRegistrationCode')}}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
              <template v-if="!codeCreated">
                <v-date-picker class="pm-10" full-width required v-model="dates" range>
                  <v-btn text color="primary" @click="dates = []">{{$t('common.clear')}}</v-btn>
                  <v-spacer></v-spacer>
                  <h3 class="primary--text">{{ validThroughText }}</h3>
                </v-date-picker>
                <v-text-field class="pt-10" :disabled="generateCode" outlined v-model="code" placeholder="TESTCODE" :label="$t('customerDetails.codeDialog.code')"></v-text-field>
                <v-switch @change="generateCodeChange" v-model="generateCode" :label="$t('customerDetails.codeDialog.generateCode')"></v-switch>
                <v-radio-group
                  v-model="codeType"
                  row
                >
                  <v-radio
                    :label="$t('customerDetails.codeDialog.multipleUse')"
                    value="multipleUse"
                  ></v-radio>
                  <v-radio
                    :label="$t('customerDetails.codeDialog.singleUse')"
                    value="singleUse"
                  ></v-radio>
                </v-radio-group>
                <v-btn color="primary" :disabled="codeCreated" @click="create">{{$t('common.create')}}</v-btn>
              </template>
              <template v-else>
                <h2>{{ createdCode }}</h2>
                <p>{{$t('customerDetails.codeDialog.pleaseCopy')}}</p>
                <v-btn color="primary" :disabled="!codeCreated" @click="close">{{$t('common.close')}}</v-btn>
              </template>
          </v-form>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";
const UserManagementRepository = RepositoryFactory.get("userManagement");
const generatedTemplate = "<**GENERATED**>";
export default {
    props: {
        value: Boolean,
        customerId: String,
        operatorId: String
    },
    data: () => ({
        generateCode: false,
        code: "",
        dates: [],
        codeInMemory: "",
        codeCreated: false,
        createdCode: "",
        codeType: "multipleUse"
    }),
    methods: {
        generateCodeChange() {
            if (this.generateCode) {
                this.codeInMemory = this.code.toString();
                this.code = generatedTemplate;
                return;
            }
            this.code = this.codeInMemory;
        },
        async create() {
            let _code = this.code;

            if (this.code === generatedTemplate) {
                _code = null;
            }

            var minDate = this.dates.reduce(function(a, b) { return a < b ? a : b; });
            var maxDate = this.dates.reduce(function(a, b) { return a > b ? a : b; });

            const codeObj = {
                code: _code,
                validFor: {
                    start: minDate,
                    end: maxDate
                },
                customerId: this.customerId,
                type: this.codeType
            };

            await UserManagementRepository.createRegistrationCode(codeObj, this.operatorId);

            this.createdCode = _code;
            this.codeCreated = true;
        },
        async close() {
            this.show = false;
            this.generateCode = false;
            this.code = "";
            this.codeInMemory = "";
            this.codeCreated = false;
            this.createdCode = "";
        }
    },
    created() {
        this.dates.push(moment().format("YYYY-MM-DD"));
        this.dates.push(moment().add(1, "years").format("YYYY-MM-DD"));
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            }
        },
        validThroughText() {
            const formattedDates = this.dates.map(e => moment(e).format("DD.MM.YYYY"));
            return formattedDates.join(" -> ");
        }
    }
};
</script>

<style>

</style>
