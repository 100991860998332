var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      _vm.isNewFare
                        ? "invoicing.addNewFare"
                        : "invoicing.editFare"
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.allowedFareTypes,
                                  rules: [
                                    function(v) {
                                      return !!v || "Fare type is required"
                                    }
                                  ],
                                  label: this.$t("invoicing.fareType"),
                                  required: ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("fareTypeToText")(item)
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("fareTypeToText")(item)
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.selectedFareType,
                                  callback: function($$v) {
                                    _vm.selectedFareType = $$v
                                  },
                                  expression: "selectedFareType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.fareClasses,
                                  rules: [
                                    function(v) {
                                      return !!v || "Fare class is required"
                                    }
                                  ],
                                  label: this.$t("invoicing.fareClass"),
                                  required: ""
                                },
                                model: {
                                  value: _vm.selectedFareClass,
                                  callback: function($$v) {
                                    _vm.selectedFareClass = $$v
                                  },
                                  expression: "selectedFareClass"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.kilometerPrice"),
                                  type: "number",
                                  rules: [
                                    function(v) {
                                      return (
                                        !!v || "Kilometer price cannot be empty"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        /^\d+((.|,)\d+)?$/.test(v) ||
                                        "Price is not valid decimal value"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        !v ||
                                        v
                                          .toString()
                                          .replace(".", "")
                                          .replace(",", "").length <= 8 ||
                                        "Price cannot be longer than 8 characters without decimal point"
                                      )
                                    }
                                  ],
                                  counter: "9",
                                  required: ""
                                },
                                model: {
                                  value: _vm.fare.kilometerPrice,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fare, "kilometerPrice", $$v)
                                  },
                                  expression: "fare.kilometerPrice"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm._f("formatDistanceToKm")(
                                    _vm.fare.meters
                                  ),
                                  label: this.$t("invoicing.kilometers"),
                                  rules: [
                                    function(v) {
                                      return !!v || "Kilometers cannot be empty"
                                    },
                                    _vm.decimalRule
                                  ],
                                  counter: "9",
                                  required: ""
                                },
                                on: { change: _vm.updateFareMetersValue }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.time"),
                                  type: "number",
                                  counter: "5"
                                },
                                model: {
                                  value: _vm.fare.time,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fare, "time", $$v)
                                  },
                                  expression: "fare.time"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: this.$t("invoicing.price"),
                                  type: "number",
                                  rules: [
                                    function(v) {
                                      return !!v || "Price cannot be empty"
                                    },
                                    function(v) {
                                      return (
                                        /^\d+((.|,)\d+)?$/.test(v) ||
                                        "Price is not valid decimal value"
                                      )
                                    },
                                    function(v) {
                                      return (
                                        !v ||
                                        v
                                          .toString()
                                          .replace(".", "")
                                          .replace(",", "").length <= 8 ||
                                        "Price cannot be longer than 8 characters without decimal point"
                                      )
                                    }
                                  ],
                                  counter: "9",
                                  required: ""
                                },
                                model: {
                                  value: _vm.fare.price,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fare, "price", $$v)
                                  },
                                  expression: "fare.price"
                                }
                              })
                            ],
                            1
                          ),
                          _c("v-col")
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNewFare
                ? _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "success" },
                      on: { click: _vm.createFare }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("invoicing.addFare")) + " ")]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.valid, color: "primary" },
                      on: { click: _vm.saveFare }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("common.saveChanges")) + " ")]
                  ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }