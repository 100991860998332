<template>
 <v-dialog
    v-model="show"
    max-width="1000">
    <v-card
      v-if="show">
        <v-toolbar short dark color="primary">
          <v-btn icon dark @click="cancel">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
              {{$t("common.search")}}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="searchParamsValid" class="mx-3 mt-2">
            <v-row align="center" class="mt-3">
              <v-col cols=12 md=6>
                <v-select
                  :items="operators"
                  v-model="editableSearchParams.companyId"
                  label="Company"
                ></v-select>
              </v-col>
              <v-col cols=12 md=6>
                <v-text-field
                    v-model="editableSearchParams.fleetId"
                    label="Fleet"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-3">
              <v-col cols=12 md=4>
                <v-text-field
                    v-model="editableSearchParams.vehicleId"
                    label="Vehicle Id"
                ></v-text-field>
              </v-col>
              <v-col cols=12 md=4>
                <v-text-field
                    v-model="editableSearchParams.vehicleNumber"
                    label="Vehicle Number"
                ></v-text-field>
              </v-col>
              <v-col cols=12 md=4>
                <v-text-field
                    v-model="editableSearchParams.registrationPlateNumber"
                    label="Registration Plate Number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" class="mt-3">
              <v-col cols=12 md=4>
                <v-select
                  :items="vehicleStatuses"
                  v-model="editableSearchParams.vehicleStatus"
                  label="Vehicle Status"
                >
                  <template v-slot:selection="{ item }">
                    {{ vehicleStatusToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ vehicleStatusToString(item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols=12 md=4>
                <v-select
                  :items="vehicleTwinTypes"
                  v-model="editableSearchParams.twinType"
                  label="Twin Type"
                >
                  <template v-slot:selection="{ item }">
                    {{ vehicleTwinTypeToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ vehicleTwinTypeToString(item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols=12 md=4>
                <v-select
                  :items="integrationSources"
                  v-model="editableSearchParams.integrationSource"
                  label="Integration Source"
                >
                  <template v-slot:selection="{ item }">
                    {{ integrationSourceToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ integrationSourceToString(item) }}
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="search">
              {{$t("common.search")}}
            </v-btn>
            <v-btn
              @click="cancel"
              class="ml-2">
              {{$t("common.cancel")}}
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TaxiMeterState, TaxiMeterStateToString } from "../../../models/TaxiMeterState";
import { DispatchState, DispatchStateToString } from "../../../models/DispatchState";
import { IntegrationSource, IntegrationSourceToString } from "../../../models/IntegrationSource";
import { VehicleStatus, VehicleStatusToString } from "../../../models/VehicleStatus";
import { VehicleTwinType, VehicleTwinTypeToString } from "../../../models/VehicleTwinType";
import _ from "lodash";

export default {
    props: {
        show: Boolean,
        searchParams: Object
    },
    data() {
        return {
            editableSearchParams: _.cloneDeep(this.searchParams),
            searchParamsValid: true,
            dispatchStates: Object.values(DispatchState),
            taxiMeterStates: Object.values(TaxiMeterState),
            integrationSources: Object.values(IntegrationSource),
            vehicleStatuses: Object.values(VehicleStatus),
            vehicleTwinTypes: Object.values(VehicleTwinType)
        };
    },
    watch: {
        searchParams(val) {
            if (!val) {
                this.editableSearchParams = null;
            } else {
                this.editableSearchParams = _.cloneDeep(val);
            }
        }
    },
    computed: {
        operators() {
            return _.filter(this.$store.state.user.operators, function(o) { return o !== "all"; });
        }
    },
    methods: {
        search() {
            for (const key in this.editableSearchParams) {
                if (this.editableSearchParams.hasOwnProperty(key)) {
                    const element = this.editableSearchParams[key];
                    if (typeof element === "string") {
                        this.editableSearchParams[key] = element.trim();
                    }
                }
            }
            let params = _.cloneDeep(this.editableSearchParams);
            this.$emit("search", params);
        },
        cancel() {
            this.searchParams = this.searchParams;
            this.$emit("close");
        },
        taxiMeterStateToString(taxiMeterState) {
            return TaxiMeterStateToString(taxiMeterState);
        },
        dispatchStateToString(dispatchState) {
            return DispatchStateToString(dispatchState);
        },
        integrationSourceToString(integrationSource) {
            return IntegrationSourceToString(integrationSource);
        },
        vehicleStatusToString(vehicleStatus) {
            return VehicleStatusToString(vehicleStatus);
        },
        vehicleTwinTypeToString(vehicleStatus) {
            return VehicleTwinTypeToString(vehicleStatus);
        }
    }
};
</script>
