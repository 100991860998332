<template>
    <v-list-item two-line>
        <v-list-item-avatar>
            <v-icon large>person</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-title>{{ username }}</v-list-item-title>
            <v-list-item-subtitle>Kirjautunut</v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: {
        imageSrc: String,
        username: String
    }
};
</script>

<style scoped>

</style>
