<template>
  <v-select
    :value="updatedSearchParams"
    :items="Object.values(purchaseInvoiceStates)"
    :disabled="disabled"
    @input="$emit('update:updatedSearchParams', $event)"
    multiple
    clearable
  >
    <template v-slot:label>
      <v-icon style="margin-top: -2px">
        {{ "mdi-archive-clock-outline" }}
      </v-icon>
      <span> Purchase Invoice Status </span>
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip v-if="index === 0 || index === 1">
        {{ item | purchaseInvoiceStatusToText }}
      </v-chip>
      <span v-if="index === 2" class="grey--text caption"
        >+{{ updatedSearchParams.length - 2 }}</span
      >
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action>
          <v-icon v-on="on">{{ item | purchaseInvoiceStatusToIcon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ item | purchaseInvoiceStatusToText }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
export default {
    props: {
        updatedSearchParams: Array,
        disabled: Boolean
    },
    emits: [ "update:updatedSearchParams" ],
    mixins: [ TransportEventInvoicingMixin ]
};
</script>
