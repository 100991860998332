var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PropertiesTable", {
    attrs: {
      properties: _vm.editableDriver.properties,
      editablePropertyOrigin: _vm.editablePropertyOrigin
    },
    on: {
      cancel: function($event) {
        _vm.isEditFormOpen = false
      },
      addNewProperty: _vm.addNewProperty,
      saveProperty: _vm.saveProperty,
      deleteProperty: _vm.deleteProperty
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }