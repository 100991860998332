<template>
  <v-form ref="form">
    <v-row class="mt-3">
      <v-col cols="6">
          <v-text-field
              v-model="editableVehicle.id"
              :label="$t('vehicles.dialogs.vehicleId')"
              counter=12
              :disabled="true"
          ></v-text-field>
      </v-col>
      <v-col cols="6">
          <v-text-field
              v-model="editableVehicle.vehicleNumber"
              :label="$t('vehicles.dialogs.vehicleNumber')"
              :disabled="!editable"
          ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="6">
          <v-text-field
              v-model="editableVehicle.registrationPlateNumber"
              :label="$t('vehicles.dialogs.registrationPlateNumber')"
              :disabled="!editable"
          ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          :items="vehicleStatuses"
          v-model="editableVehicle.status"
          :label="$t('vehicles.dialogs.status')"
          :disabled="true"
        >
          <template v-slot:selection="{ item }">
            {{ vehicleStatusToString(item) }}
          </template>
          <template v-slot:item="{ item }">
            {{ vehicleStatusToString(item) }}
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="6">
        <v-select
          :items="vehicleTwinTypes"
          v-model="editableVehicle.twinType"
          :label="$t('vehicles.dialogs.twinType')"
          :disabled="!editable"
        >
          <template v-slot:selection="{ item }">
            {{ vehicleTwinTypeToString(item) }}
          </template>
          <template v-slot:item="{ item }">
            {{ vehicleTwinTypeToString(item) }}
          </template>
        </v-select>
      </v-col>
      <v-col cols="6">
        <IntegrationSourcesField
              :editableIntegrationSources="editableIntegrationSources"
              :editable="editable"
              @update:editableIntegrationSources="newValue => editableIntegrationSources = newValue">
        </IntegrationSourcesField>
      </v-col>
    </v-row>
    <v-row class="mt-3" v-if="editable">
      <v-col cols="12">
        <v-btn
          class="mr-4"
          @click="save"
          :disabled="!dirty"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { VehicleStatus, VehicleStatusToString } from "../../../models/VehicleStatus";
import { IntegrationSource, IntegrationSourceToString } from "../../../models/IntegrationSource";
import { VehicleTwinType, VehicleTwinTypeToString } from "../../../models/VehicleTwinType";
import IntegrationSourcesField from "./HelperFields/IntegrationSourcesField";
import moment from "moment";
import _ from "lodash";

export default {
    props: {
        vehicle: {
            type: Object,
            default: function() {
                return null;
            }
        },
        editable: Boolean
    },
    data() {
    // const editableVehicle = _.cloneDeep(this.vehicle);
    /* this.editableVehicle.contactInformationForm = this.editableVehicle.contactInformationForm ?? {};
    const editableIntegrationSources = [];
    for (var i = 0; i < 12; i++) {
      const flag = this.editableVehicle.integrationSources & 1 << i;
      if (flag > 0) {
        editableIntegrationSources.push(flag);
      }
    } */
        return {
            editableVehicle: _.cloneDeep(this.vehicle),
            dirty: false,
            integrationSources: Object.values(IntegrationSource),
            vehicleStatuses: Object.values(VehicleStatus),
            vehicleTwinTypes: Object.values(VehicleTwinType)
        };
    },
    components: {
        IntegrationSourcesField
    },
    computed:
  {
      editableIntegrationSources: {
          get() {
              const editableIntegrationSources = [];
              for (var i = 0; i < 12; i++) {
                  const flag = this.editableVehicle.integrationSources & 1 << i;
                  if (flag > 0) {
                      editableIntegrationSources.push(flag);
                  }
              }
              return editableIntegrationSources;
          },
          set(newValue) {
              this.editableVehicle.integrationSources = newValue.reduce((a, b) => a | b, 0);
          }
      }
  },
    watch: {
        vehicle(val) {
            this.editableVehicle = (!val) ? {} : _.cloneDeep(val);
        },
        editableVehicle:
    {
        handler() {
            this.dirty = true;
        },
        deep: true
    }
    },
    methods: {
        save: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("save", {
                vehicleNumber: this.editableVehicle.vehicleNumber,
                registrationPlateNumber: this.editableVehicle.registrationPlateNumber,
                integrationSources: this.editableIntegrationSources.reduce((partialSum, a) => partialSum + a, 0),
                twinType: this.editableVehicle.twinType
            });
        },
        integrationSourceToString(integrationSource) {
            return IntegrationSourceToString(integrationSource);
        },
        vehicleStatusToString(vehicleStatus) {
            return VehicleStatusToString(vehicleStatus);
        },
        vehicleTwinTypeToString(vehicleTwinType) {
            return VehicleTwinTypeToString(vehicleTwinType);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
