import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedDrivers: [],
    fetchedDriver: null,
    searchParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null
};

// getters
const getters = {};

// actions
const actions = {
    // Drivers
    async searchDrivers({ commit }, { searchParams, top = 100, forceUpdate = false }) {
        if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
            return;
        }
        await RepositoryFactory.get("driver").find(searchParams, top)
            .then(response => {
                commit("SET_DRIVERS", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_FETCHED_TIMESTAMP", moment());
            });
    },
    async getDriver({ commit }, id) {
        await RepositoryFactory.get("driver").getDriver(id)
            .then(response => {
                commit("SET_DRIVER", response.data.item);
            });
    },
    async loadMoreDrivers({ commit }, { top = 100 }) {
        await RepositoryFactory.get("driver").find(
            state.searchParams,
            top,
            Math.floor(state.searchedDrivers.length / top) + 1)
            .then(response => {
                commit("ADD_SEARCHED_DRIVERS", response.data.results);
            });
    },
    async createDriver({ commit }, driver) {
        await RepositoryFactory.get("driver").create(driver)
            .then(() => {
                commit("CREATE_DRIVER", driver);
            });
    },
    async deleteDriver({ commit }, id) {
        await RepositoryFactory.get("driver").delete(id)
            .then(() => {
                commit("REMOVE_DRIVER", id);
            });
    },
    async updateDriverContactInformation({ commit }, { driverId, contactInformation }) {
        await RepositoryFactory.get("driver").patchContactInformation(driverId, contactInformation);
    },
    async updateDriverInfo({ commit }, { driverId, driverInfo }) {
        await RepositoryFactory.get("driver").patchDriverInfo(driverId, driverInfo);
    },
    // Attributes
    async addNewAttribute({ commit }, { driverId, attribute }) {
        await RepositoryFactory.get("driver").postNewAttribute(driverId, { attribute });
    },
    async saveAttribute({ commit }, { driverId, attribute }) {
        await RepositoryFactory.get("driver").patchAttribute(driverId, { attribute });
    },
    async deleteAttribute({ commit }, { driverId, attribute }) {
        await RepositoryFactory.get("driver").deleteAttribute(driverId, attribute);
    },
    // Properties
    async addNewProperty({ commit }, { driverId, property }) {
        await RepositoryFactory.get("driver").postNewProperty(driverId, { property });
    },
    async saveProperty({ commit }, { driverId, property }) {
        await RepositoryFactory.get("driver").patchProperty(driverId, { property });
    },
    async deleteProperty({ commit }, { driverId, property }) {
        await RepositoryFactory.get("driver").deleteProperty(driverId, property);
    }
};

// mutations
const mutations = {
    SET_DRIVERS(state, drivers) {
        state.searchedDrivers = drivers;
    },
    SET_DRIVER(state, driver) {
        state.fetchedDriver = driver;
    },
    ADD_SEARCHED_DRIVERS(state, drivers) {
        state.searchedDrivers = state.searchedDrivers.concat(drivers);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    },
    ADD_DRIVER(state, driver) {
        state.searchedDrivers.push(driver);
    },
    UPDATE_DRIVER(state, driver) {
        state.searchedDrivers = [
            ...state.searchedDrivers.map(
                item => item.id !== driver.id ? item : { ...item, ...driver })
        ];
    },
    REMOVE_DRIVER(state, id) {
        let index = state.searchedDrivers.findIndex(te => te.id === id);
        state.searchedDrivers.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
