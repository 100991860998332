var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v(_vm._s(_vm.header))
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.nameErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      label: _vm.$t("userManagement.dialog.name")
                    },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "Matti Meikäläinen",
                      label: _vm.$t("userManagement.dialog.contact")
                    },
                    model: {
                      value: _vm.$v.contact.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.contact, "$model", $$v)
                      },
                      expression: "$v.contact.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Vapaa kuvaus",
                      label: _vm.$t("userManagement.dialog.description")
                    },
                    model: {
                      value: _vm.description,
                      callback: function($$v) {
                        _vm.description = $$v
                      },
                      expression: "description"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "OVT 12345678",
                      label: _vm.$t("userManagement.dialog.billingInfo")
                    },
                    model: {
                      value: _vm.billingInfo,
                      callback: function($$v) {
                        _vm.billingInfo = $$v
                      },
                      expression: "billingInfo"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Yrityksenkatu 3 C",
                      label: _vm.$t("userManagement.dialog.address")
                    },
                    model: {
                      value: _vm.companyAddress,
                      callback: function($$v) {
                        _vm.companyAddress = $$v
                      },
                      expression: "companyAddress"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.phoneErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "358501234567",
                      label: _vm.$t("userManagement.dialog.phone")
                    },
                    model: {
                      value: _vm.$v.phoneNumber.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.phoneNumber, "$model", $$v)
                      },
                      expression: "$v.phoneNumber.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.emailErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "matti@gmail.com",
                      label: _vm.$t("userManagement.dialog.email")
                    },
                    model: {
                      value: _vm.$v.email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.email, "$model", $$v)
                      },
                      expression: "$v.email.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      "error-messages": _vm.businessIdErrors,
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "1234567-8",
                      label: _vm.$t("userManagement.dialog.businessId")
                    },
                    model: {
                      value: _vm.$v.businessIdentityCode.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.businessIdentityCode, "$model", $$v)
                      },
                      expression: "$v.businessIdentityCode.$model"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      readonly: "",
                      outlined: "",
                      label: _vm.$t("userManagement.dialog.operator")
                    },
                    model: {
                      value: _vm.operator,
                      callback: function($$v) {
                        _vm.operator = $$v
                      },
                      expression: "operator"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      disabled: _vm.validated,
                      outlined: "",
                      required: "",
                      placeholder: "50012345",
                      label: _vm.$t("userManagement.dialog.identifier")
                    },
                    model: {
                      value: _vm.$v.externalId.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.externalId, "$model", $$v)
                      },
                      expression: "$v.externalId.$model"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.carAttributesList,
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": "",
                      label: _vm.$t(
                        "userManagement.dialog.attributeSelectLabel"
                      ),
                      multiple: "",
                      chips: ""
                    },
                    model: {
                      value: _vm.carAttributes,
                      callback: function($$v) {
                        _vm.carAttributes = $$v
                      },
                      expression: "carAttributes"
                    }
                  }),
                  _vm._l(_vm.additionalData, function(data) {
                    return _c(
                      "v-text-field",
                      {
                        key: data.key,
                        attrs: {
                          label: data.key,
                          clearable: "",
                          "clear-icon": "mdi-delete",
                          outlined: ""
                        },
                        on: {
                          "click:clear": function($event) {
                            _vm.additionalData.splice(
                              _vm.additionalData.indexOf(data),
                              1
                            )
                          }
                        },
                        model: {
                          value: data.value,
                          callback: function($$v) {
                            _vm.$set(data, "value", $$v)
                          },
                          expression: "data.value"
                        }
                      },
                      [_vm._v(_vm._s(data.value))]
                    )
                  }),
                  _c("keyvalue-input", {
                    on: { keyValueAdded: _vm.addKeyValue }
                  }),
                  _c("v-switch", {
                    attrs: {
                      label: _vm.$t("userManagement.dialog.staticAddresses")
                    },
                    model: {
                      value: _vm.useStaticAddresses,
                      callback: function($$v) {
                        _vm.useStaticAddresses = $$v
                      },
                      expression: "useStaticAddresses"
                    }
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: {
                        disabled: this.$v.$invalid || this.validated,
                        color: "success"
                      },
                      on: { click: _vm.validate }
                    },
                    [_vm._v(_vm._s(_vm.$t("userManagement.dialog.validate")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "red",
                        disabled: !this.validated,
                        text: ""
                      },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.cancel")))]
                  ),
                  _vm.validated
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "float-right",
                          attrs: { color: "primary" },
                          on: { click: _vm.create }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.create")))]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }