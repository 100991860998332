export const AttributeContentType = {
    Unknown: 0,
    String: 1,
    Integer: 2,
    Decimal: 3
};

export function AttributeContentTypeToString(attributeContentType) {
    switch (attributeContentType) {
    case AttributeContentType.Unknown:
        return "-";
    case AttributeContentType.String:
        return "String";
    case AttributeContentType.Integer:
        return "Integer";
    case AttributeContentType.Decimal:
        return "Decimal";
    }
};
