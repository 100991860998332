var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TariffsTable", {
    attrs: {
      tariffImports: _vm.editableCompany.tariffImports,
      tariffId: _vm.editableCompany.tariffId,
      editableTariffImportOrigin: _vm.editableTariffImportOrigin
    },
    on: {
      cancel: function($event) {
        _vm.isEditFormOpen = false
      },
      addNewTariffImport: _vm.addNewTariffImport,
      saveTariffImport: _vm.saveTariffImport,
      saveTariffId: _vm.saveTariffId,
      deleteTariffImport: _vm.deleteTariffImport
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }