var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    attrs: {
      "calculate-widths": "",
      headers: _vm.headers,
      items: _vm.vehicles,
      "item-key": "vehicleId",
      loading: _vm.loading,
      "server-items-length": _vm.searchTotalCount,
      options: _vm.options,
      "hide-default-footer": true
    },
    on: {
      "update:options": function($event) {
        _vm.options = $event
      },
      "click:row": _vm.selectVehicle
    },
    scopedSlots: _vm._u([
      {
        key: "item.vehicleId",
        fn: function(ref) {
          var value = ref.value
          return [_c("span", [_vm._v(" " + _vm._s(value))])]
        }
      },
      {
        key: "item.companyId",
        fn: function(ref) {
          var value = ref.value
          return [_c("span", [_vm._v(" " + _vm._s(value))])]
        }
      },
      {
        key: "item.fleetId",
        fn: function(ref) {
          var value = ref.value
          return [_c("span", [_vm._v(" " + _vm._s(value))])]
        }
      },
      {
        key: "item.state",
        fn: function(ref) {
          var value = ref.value
          return [
            _c("span", [_vm._v(" " + _vm._s(_vm.dispatchStateToString(value)))])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }