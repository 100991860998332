export const municipalityOIDs =
[
    {
        "text": "Tunnistamaton",
        "value": "000"
    },
    {
        "text": "Alahärmä",
        "value": "004"
    },
    {
        "text": "Alajärvi",
        "value": "005"
    },
    {
        "text": "Alastaro",
        "value": "006"
    },
    {
        "text": "Alavieska",
        "value": "009"
    },
    {
        "text": "Alavus",
        "value": "010"
    },
    {
        "text": "Anttola",
        "value": "014"
    },
    {
        "text": "Artjärvi",
        "value": "015"
    },
    {
        "text": "Asikkala",
        "value": "016"
    },
    {
        "text": "Askainen",
        "value": "017"
    },
    {
        "text": "Askola",
        "value": "018"
    },
    {
        "text": "Aura",
        "value": "019"
    },
    {
        "text": "Akaa",
        "value": "020"
    },
    {
        "text": "Brändö",
        "value": "035"
    },
    {
        "text": "Dragsfjärd",
        "value": "040"
    },
    {
        "text": "Eckerö",
        "value": "043"
    },
    {
        "text": "Elimäki",
        "value": "044"
    },
    {
        "text": "Eno",
        "value": "045"
    },
    {
        "text": "Enonkoski",
        "value": "046"
    },
    {
        "text": "Enontekiö",
        "value": "047"
    },
    {
        "text": "Espoo",
        "value": "049"
    },
    {
        "text": "Eura",
        "value": "050"
    },
    {
        "text": "Eurajoki",
        "value": "051"
    },
    {
        "text": "Evijärvi",
        "value": "052"
    },
    {
        "text": "Finström",
        "value": "060"
    },
    {
        "text": "Forssa",
        "value": "061"
    },
    {
        "text": "Föglö",
        "value": "062"
    },
    {
        "text": "Geta",
        "value": "065"
    },
    {
        "text": "Haapajärvi",
        "value": "069"
    },
    {
        "text": "Haapavesi",
        "value": "071"
    },
    {
        "text": "Hailuoto",
        "value": "072"
    },
    {
        "text": "Halikko",
        "value": "073"
    },
    {
        "text": "Halsua",
        "value": "074"
    },
    {
        "text": "Hamina",
        "value": "075"
    },
    {
        "text": "Hammarland",
        "value": "076"
    },
    {
        "text": "Hankasalmi",
        "value": "077"
    },
    {
        "text": "Hanko",
        "value": "078"
    },
    {
        "text": "Harjavalta",
        "value": "079"
    },
    {
        "text": "Hartola",
        "value": "081"
    },
    {
        "text": "Hattula",
        "value": "082"
    },
    {
        "text": "Hauho",
        "value": "083"
    },
    {
        "text": "Haukipudas",
        "value": "084"
    },
    {
        "text": "Haukivuori",
        "value": "085"
    },
    {
        "text": "Hausjärvi",
        "value": "086"
    },
    {
        "text": "Heinola",
        "value": "088"
    },
    {
        "text": "Heinolan mlk",
        "value": "089"
    },
    {
        "text": "Heinävesi",
        "value": "090"
    },
    {
        "text": "Helsinki",
        "value": "091"
    },
    {
        "text": "Vantaa",
        "value": "092"
    },
    {
        "text": "Himanka",
        "value": "095"
    },
    {
        "text": "Hirvensalmi",
        "value": "097"
    },
    {
        "text": "Hollola",
        "value": "098"
    },
    {
        "text": "Honkajoki",
        "value": "099"
    },
    {
        "text": "Houtskari",
        "value": "101"
    },
    {
        "text": "Huittinen",
        "value": "102"
    },
    {
        "text": "Humppila",
        "value": "103"
    },
    {
        "text": "Hyrynsalmi",
        "value": "105"
    },
    {
        "text": "Hyvinkää",
        "value": "106"
    },
    {
        "text": "Hämeenkyrö",
        "value": "108"
    },
    {
        "text": "Hämeenlinna",
        "value": "109"
    },
    {
        "text": "Heinola",
        "value": "111"
    },
    {
        "text": "Ii",
        "value": "139"
    },
    {
        "text": "Iisalmi",
        "value": "140"
    },
    {
        "text": "Iitti",
        "value": "142"
    },
    {
        "text": "Ikaalinen",
        "value": "143"
    },
    {
        "text": "Ilmajoki",
        "value": "145"
    },
    {
        "text": "Ilomantsi",
        "value": "146"
    },
    {
        "text": "Inari",
        "value": "148"
    },
    {
        "text": "Inkoo",
        "value": "149"
    },
    {
        "text": "Iniö",
        "value": "150"
    },
    {
        "text": "Isojoki",
        "value": "151"
    },
    {
        "text": "Isokyrö",
        "value": "152"
    },
    {
        "text": "Imatra",
        "value": "153"
    },
    {
        "text": "Jaala",
        "value": "163"
    },
    {
        "text": "Jalasjärvi",
        "value": "164"
    },
    {
        "text": "Janakkala",
        "value": "165"
    },
    {
        "text": "Joensuu",
        "value": "167"
    },
    {
        "text": "Jokioinen",
        "value": "169"
    },
    {
        "text": "Jomala",
        "value": "170"
    },
    {
        "text": "Joroinen",
        "value": "171"
    },
    {
        "text": "Joutsa",
        "value": "172"
    },
    {
        "text": "Joutseno",
        "value": "173"
    },
    {
        "text": "Juankoski",
        "value": "174"
    },
    {
        "text": "Jurva",
        "value": "175"
    },
    {
        "text": "Juuka",
        "value": "176"
    },
    {
        "text": "Juupajoki",
        "value": "177"
    },
    {
        "text": "Juva",
        "value": "178"
    },
    {
        "text": "Jyväskylä",
        "value": "179"
    },
    {
        "text": "Jyväskylän mlk",
        "value": "180"
    },
    {
        "text": "Jämijärvi",
        "value": "181"
    },
    {
        "text": "Jämsä",
        "value": "182"
    },
    {
        "text": "Jämsänkoski",
        "value": "183"
    },
    {
        "text": "Jäppilä",
        "value": "184"
    },
    {
        "text": "Järvenpää",
        "value": "186"
    },
    {
        "text": "Ei kotikuntaa Suomessa",
        "value": "198"
    },
    {
        "text": "Tuntematon",
        "value": "199"
    },
    {
        "text": "Ulkomaat",
        "value": "200"
    },
    {
        "text": "Kaarina",
        "value": "202"
    },
    {
        "text": "Kaavi",
        "value": "204"
    },
    {
        "text": "Kajaani",
        "value": "205"
    },
    {
        "text": "Kalajoki",
        "value": "208"
    },
    {
        "text": "Kalanti",
        "value": "209"
    },
    {
        "text": "Kalvola",
        "value": "210"
    },
    {
        "text": "Kangasala",
        "value": "211"
    },
    {
        "text": "Kangaslampi",
        "value": "212"
    },
    {
        "text": "Kangasniemi",
        "value": "213"
    },
    {
        "text": "Kankaanpää",
        "value": "214"
    },
    {
        "text": "Kannonkoski",
        "value": "216"
    },
    {
        "text": "Kannus",
        "value": "217"
    },
    {
        "text": "Karijoki",
        "value": "218"
    },
    {
        "text": "Karinainen",
        "value": "219"
    },
    {
        "text": "Karjaa",
        "value": "220"
    },
    {
        "text": "Karjalohja",
        "value": "223"
    },
    {
        "text": "Karkkila",
        "value": "224"
    },
    {
        "text": "Karstula",
        "value": "226"
    },
    {
        "text": "Karttula",
        "value": "227"
    },
    {
        "text": "Karvia",
        "value": "230"
    },
    {
        "text": "Kaskinen",
        "value": "231"
    },
    {
        "text": "Kauhajoki",
        "value": "232"
    },
    {
        "text": "Kauhava",
        "value": "233"
    },
    {
        "text": "Kauniainen",
        "value": "235"
    },
    {
        "text": "Kaustinen",
        "value": "236"
    },
    {
        "text": "Keitele",
        "value": "239"
    },
    {
        "text": "Kemi",
        "value": "240"
    },
    {
        "text": "Keminmaa",
        "value": "241"
    },
    {
        "text": "Kemiö",
        "value": "243"
    },
    {
        "text": "Kempele",
        "value": "244"
    },
    {
        "text": "Kerava",
        "value": "245"
    },
    {
        "text": "Kerimäki",
        "value": "246"
    },
    {
        "text": "Kestilä",
        "value": "247"
    },
    {
        "text": "Kesälahti",
        "value": "248"
    },
    {
        "text": "Keuruu",
        "value": "249"
    },
    {
        "text": "Kihniö",
        "value": "250"
    },
    {
        "text": "Kiihtelysvaara",
        "value": "251"
    },
    {
        "text": "Kiikala",
        "value": "252"
    },
    {
        "text": "Kiikoinen",
        "value": "254"
    },
    {
        "text": "Kiiminki",
        "value": "255"
    },
    {
        "text": "Kinnula",
        "value": "256"
    },
    {
        "text": "Kirkkonummi",
        "value": "257"
    },
    {
        "text": "Kisko",
        "value": "259"
    },
    {
        "text": "Kitee",
        "value": "260"
    },
    {
        "text": "Kittilä",
        "value": "261"
    },
    {
        "text": "Kiukainen",
        "value": "262"
    },
    {
        "text": "Kiuruvesi",
        "value": "263"
    },
    {
        "text": "Kivijärvi",
        "value": "265"
    },
    {
        "text": "Kodisjoki",
        "value": "266"
    },
    {
        "text": "Kokemäki",
        "value": "271"
    },
    {
        "text": "Kokkola",
        "value": "272"
    },
    {
        "text": "Kolari",
        "value": "273"
    },
    {
        "text": "Konginkangas",
        "value": "274"
    },
    {
        "text": "Konnevesi",
        "value": "275"
    },
    {
        "text": "Kontiolahti",
        "value": "276"
    },
    {
        "text": "Korpilahti",
        "value": "277"
    },
    {
        "text": "Korppoo",
        "value": "279"
    },
    {
        "text": "Korsnäs",
        "value": "280"
    },
    {
        "text": "Kortesjärvi",
        "value": "281"
    },
    {
        "text": "Hämeenkoski",
        "value": "283"
    },
    {
        "text": "Koski Tl",
        "value": "284"
    },
    {
        "text": "Kotka",
        "value": "285"
    },
    {
        "text": "Kouvola",
        "value": "286"
    },
    {
        "text": "Kristiinankaupunki",
        "value": "287"
    },
    {
        "text": "Kruunupyy",
        "value": "288"
    },
    {
        "text": "Kuhmalahti",
        "value": "289"
    },
    {
        "text": "Kuhmo",
        "value": "290"
    },
    {
        "text": "Kuhmoinen",
        "value": "291"
    },
    {
        "text": "Kuivaniemi",
        "value": "292"
    },
    {
        "text": "Kullaa",
        "value": "293"
    },
    {
        "text": "Kumlinge",
        "value": "295"
    },
    {
        "text": "Kuopio",
        "value": "297"
    },
    {
        "text": "Kuorevesi",
        "value": "299"
    },
    {
        "text": "Kuortane",
        "value": "300"
    },
    {
        "text": "Kurikka",
        "value": "301"
    },
    {
        "text": "Kuru",
        "value": "303"
    },
    {
        "text": "Kustavi",
        "value": "304"
    },
    {
        "text": "Kuusamo",
        "value": "305"
    },
    {
        "text": "Kuusankoski",
        "value": "306"
    },
    {
        "text": "Kuusjoki",
        "value": "308"
    },
    {
        "text": "Outokumpu",
        "value": "309"
    },
    {
        "text": "Kylmäkoski",
        "value": "310"
    },
    {
        "text": "Kyyjärvi",
        "value": "312"
    },
    {
        "text": "Kälviä",
        "value": "315"
    },
    {
        "text": "Kärkölä",
        "value": "316"
    },
    {
        "text": "Kärsämäki",
        "value": "317"
    },
    {
        "text": "Kökar",
        "value": "318"
    },
    {
        "text": "Köyliö",
        "value": "319"
    },
    {
        "text": "Kemijärvi",
        "value": "320"
    },
    {
        "text": "Kemiönsaari",
        "value": "322"
    },
    {
        "text": "Lahti",
        "value": "398"
    },
    {
        "text": "Laihia",
        "value": "399"
    },
    {
        "text": "Laitila",
        "value": "400"
    },
    {
        "text": "Lammi",
        "value": "401"
    },
    {
        "text": "Lapinlahti",
        "value": "402"
    },
    {
        "text": "Lappajärvi",
        "value": "403"
    },
    {
        "text": "Lappeenranta",
        "value": "405"
    },
    {
        "text": "Lappi",
        "value": "406"
    },
    {
        "text": "Lapinjärvi",
        "value": "407"
    },
    {
        "text": "Lapua",
        "value": "408"
    },
    {
        "text": "Laukaa",
        "value": "410"
    },
    {
        "text": "Lavia",
        "value": "413"
    },
    {
        "text": "Lehtimäki",
        "value": "414"
    },
    {
        "text": "Leivonmäki",
        "value": "415"
    },
    {
        "text": "Lemi",
        "value": "416"
    },
    {
        "text": "Lemland",
        "value": "417"
    },
    {
        "text": "Lempäälä",
        "value": "418"
    },
    {
        "text": "Lemu",
        "value": "419"
    },
    {
        "text": "Leppävirta",
        "value": "420"
    },
    {
        "text": "Lestijärvi",
        "value": "421"
    },
    {
        "text": "Lieksa",
        "value": "422"
    },
    {
        "text": "Lieto",
        "value": "423"
    },
    {
        "text": "Liljendal",
        "value": "424"
    },
    {
        "text": "Liminka",
        "value": "425"
    },
    {
        "text": "Liperi",
        "value": "426"
    },
    {
        "text": "Lohja",
        "value": "427"
    },
    {
        "text": "Lohjan kunta",
        "value": "428"
    },
    {
        "text": "Lohtaja",
        "value": "429"
    },
    {
        "text": "Loimaa",
        "value": "430"
    },
    {
        "text": "Loimaan kunta",
        "value": "431"
    },
    {
        "text": "Loppi",
        "value": "433"
    },
    {
        "text": "Loviisa",
        "value": "434"
    },
    {
        "text": "Luhanka",
        "value": "435"
    },
    {
        "text": "Lumijoki",
        "value": "436"
    },
    {
        "text": "Lumparland",
        "value": "438"
    },
    {
        "text": "Luopioinen",
        "value": "439"
    },
    {
        "text": "Luoto",
        "value": "440"
    },
    {
        "text": "Luumäki",
        "value": "441"
    },
    {
        "text": "Luvia",
        "value": "442"
    },
    {
        "text": "Längelmäki",
        "value": "443"
    },
    {
        "text": "Lohja",
        "value": "444"
    },
    {
        "text": "Parainen",
        "value": "445"
    },
    {
        "text": "Maalahti",
        "value": "475"
    },
    {
        "text": "Maaninka",
        "value": "476"
    },
    {
        "text": "Maarianhamina",
        "value": "478"
    },
    {
        "text": "Maksamaa",
        "value": "479"
    },
    {
        "text": "Marttila",
        "value": "480"
    },
    {
        "text": "Masku",
        "value": "481"
    },
    {
        "text": "Mellilä",
        "value": "482"
    },
    {
        "text": "Merijärvi",
        "value": "483"
    },
    {
        "text": "Merikarvia",
        "value": "484"
    },
    {
        "text": "Merimasku",
        "value": "485"
    },
    {
        "text": "Miehikkälä",
        "value": "489"
    },
    {
        "text": "Mietoinen",
        "value": "490"
    },
    {
        "text": "Mikkeli",
        "value": "491"
    },
    {
        "text": "Mikkelin mlk",
        "value": "492"
    },
    {
        "text": "Mouhijärvi",
        "value": "493"
    },
    {
        "text": "Muhos",
        "value": "494"
    },
    {
        "text": "Multia",
        "value": "495"
    },
    {
        "text": "Muonio",
        "value": "498"
    },
    {
        "text": "Mustasaari",
        "value": "499"
    },
    {
        "text": "Muurame",
        "value": "500"
    },
    {
        "text": "Muurla",
        "value": "501"
    },
    {
        "text": "Mynämäki",
        "value": "503"
    },
    {
        "text": "Myrskylä",
        "value": "504"
    },
    {
        "text": "Mäntsälä",
        "value": "505"
    },
    {
        "text": "Mänttä",
        "value": "506"
    },
    {
        "text": "Mäntyharju",
        "value": "507"
    },
    {
        "text": "Mänttä-Vilppula",
        "value": "508"
    },
    {
        "text": "Naantali",
        "value": "529"
    },
    {
        "text": "Nakkila",
        "value": "531"
    },
    {
        "text": "Nastola",
        "value": "532"
    },
    {
        "text": "Nauvo",
        "value": "533"
    },
    {
        "text": "Nilsiä",
        "value": "534"
    },
    {
        "text": "Nivala",
        "value": "535"
    },
    {
        "text": "Nokia",
        "value": "536"
    },
    {
        "text": "Noormarkku",
        "value": "537"
    },
    {
        "text": "Nousiainen",
        "value": "538"
    },
    {
        "text": "Nummi-Pusula",
        "value": "540"
    },
    {
        "text": "Nurmes",
        "value": "541"
    },
    {
        "text": "Nurmijärvi",
        "value": "543"
    },
    {
        "text": "Nurmo",
        "value": "544"
    },
    {
        "text": "Närpiö",
        "value": "545"
    },
    {
        "text": "Oravainen",
        "value": "559"
    },
    {
        "text": "Orimattila",
        "value": "560"
    },
    {
        "text": "Oripää",
        "value": "561"
    },
    {
        "text": "Orivesi",
        "value": "562"
    },
    {
        "text": "Oulainen",
        "value": "563"
    },
    {
        "text": "Oulu",
        "value": "564"
    },
    {
        "text": "Oulunsalo",
        "value": "567"
    },
    {
        "text": "Parainen",
        "value": "573"
    },
    {
        "text": "Padasjoki",
        "value": "576"
    },
    {
        "text": "Paimio",
        "value": "577"
    },
    {
        "text": "Paltamo",
        "value": "578"
    },
    {
        "text": "Parikkala",
        "value": "580"
    },
    {
        "text": "Parkano",
        "value": "581"
    },
    {
        "text": "Pattijoki",
        "value": "582"
    },
    {
        "text": "Pelkosenniemi",
        "value": "583"
    },
    {
        "text": "Perho",
        "value": "584"
    },
    {
        "text": "Pernaja",
        "value": "585"
    },
    {
        "text": "Perniö",
        "value": "586"
    },
    {
        "text": "Pertteli",
        "value": "587"
    },
    {
        "text": "Pertunmaa",
        "value": "588"
    },
    {
        "text": "Peräseinäjoki",
        "value": "589"
    },
    {
        "text": "Petäjävesi",
        "value": "592"
    },
    {
        "text": "Pieksämäki",
        "value": "593"
    },
    {
        "text": "Pieksämäen mlk",
        "value": "594"
    },
    {
        "text": "Pielavesi",
        "value": "595"
    },
    {
        "text": "Pietarsaari",
        "value": "598"
    },
    {
        "text": "Pedersören kunta",
        "value": "599"
    },
    {
        "text": "Pihtipudas",
        "value": "601"
    },
    {
        "text": "Piikkiö",
        "value": "602"
    },
    {
        "text": "Piippola",
        "value": "603"
    },
    {
        "text": "Pirkkala",
        "value": "604"
    },
    {
        "text": "Pohja",
        "value": "606"
    },
    {
        "text": "Polvijärvi",
        "value": "607"
    },
    {
        "text": "Pomarkku",
        "value": "608"
    },
    {
        "text": "Pori",
        "value": "609"
    },
    {
        "text": "Pornainen",
        "value": "611"
    },
    {
        "text": "Porvoo",
        "value": "612"
    },
    {
        "text": "Porvoon mlk",
        "value": "613"
    },
    {
        "text": "Posio",
        "value": "614"
    },
    {
        "text": "Pudasjärvi",
        "value": "615"
    },
    {
        "text": "Pukkila",
        "value": "616"
    },
    {
        "text": "Pulkkila",
        "value": "617"
    },
    {
        "text": "Punkaharju",
        "value": "618"
    },
    {
        "text": "Punkalaidun",
        "value": "619"
    },
    {
        "text": "Puolanka",
        "value": "620"
    },
    {
        "text": "Puumala",
        "value": "623"
    },
    {
        "text": "Pyhtää",
        "value": "624"
    },
    {
        "text": "Pyhäjoki",
        "value": "625"
    },
    {
        "text": "Pyhäjärvi",
        "value": "626"
    },
    {
        "text": "Pyhäntä",
        "value": "630"
    },
    {
        "text": "Pyhäranta",
        "value": "631"
    },
    {
        "text": "Pyhäselkä",
        "value": "632"
    },
    {
        "text": "Pylkönmäki",
        "value": "633"
    },
    {
        "text": "Pälkäne",
        "value": "635"
    },
    {
        "text": "Pöytyä",
        "value": "636"
    },
    {
        "text": "Porvoo",
        "value": "638"
    },
    {
        "text": "Pieksänmaa",
        "value": "640"
    },
    {
        "text": "Raahe",
        "value": "678"
    },
    {
        "text": "Raisio",
        "value": "680"
    },
    {
        "text": "Rantasalmi",
        "value": "681"
    },
    {
        "text": "Rantsila",
        "value": "682"
    },
    {
        "text": "Ranua",
        "value": "683"
    },
    {
        "text": "Rauma",
        "value": "684"
    },
    {
        "text": "Rauman mlk",
        "value": "685"
    },
    {
        "text": "Rautalampi",
        "value": "686"
    },
    {
        "text": "Rautavaara",
        "value": "687"
    },
    {
        "text": "Rautjärvi",
        "value": "689"
    },
    {
        "text": "Reisjärvi",
        "value": "691"
    },
    {
        "text": "Renko",
        "value": "692"
    },
    {
        "text": "Riihimäki",
        "value": "694"
    },
    {
        "text": "Ristiina",
        "value": "696"
    },
    {
        "text": "Ristijärvi",
        "value": "697"
    },
    {
        "text": "Rovaniemi",
        "value": "698"
    },
    {
        "text": "Rovaniemen mlk",
        "value": "699"
    },
    {
        "text": "Ruokolahti",
        "value": "700"
    },
    {
        "text": "Ruotsinpyhtää",
        "value": "701"
    },
    {
        "text": "Ruovesi",
        "value": "702"
    },
    {
        "text": "Rusko",
        "value": "704"
    },
    {
        "text": "Rymättylä",
        "value": "705"
    },
    {
        "text": "Rääkkylä",
        "value": "707"
    },
    {
        "text": "Ruukki",
        "value": "708"
    },
    {
        "text": "Raasepori",
        "value": "710"
    },
    {
        "text": "Saari",
        "value": "728"
    },
    {
        "text": "Saarijärvi",
        "value": "729"
    },
    {
        "text": "Sahalahti",
        "value": "730"
    },
    {
        "text": "Salla",
        "value": "732"
    },
    {
        "text": "Salo",
        "value": "734"
    },
    {
        "text": "Saltvik",
        "value": "736"
    },
    {
        "text": "Sammatti",
        "value": "737"
    },
    {
        "text": "Sauvo",
        "value": "738"
    },
    {
        "text": "Savitaipale",
        "value": "739"
    },
    {
        "text": "Savonlinna",
        "value": "740"
    },
    {
        "text": "Savonranta",
        "value": "741"
    },
    {
        "text": "Savukoski",
        "value": "742"
    },
    {
        "text": "Seinäjoki",
        "value": "743"
    },
    {
        "text": "Sievi",
        "value": "746"
    },
    {
        "text": "Siikainen",
        "value": "747"
    },
    {
        "text": "Siikajoki",
        "value": "748"
    },
    {
        "text": "Siilinjärvi",
        "value": "749"
    },
    {
        "text": "Simo",
        "value": "751"
    },
    {
        "text": "Simpele",
        "value": "752"
    },
    {
        "text": "Sipoo",
        "value": "753"
    },
    {
        "text": "Anjalankoski",
        "value": "754"
    },
    {
        "text": "Siuntio",
        "value": "755"
    },
    {
        "text": "Sodankylä",
        "value": "758"
    },
    {
        "text": "Soini",
        "value": "759"
    },
    {
        "text": "Somero",
        "value": "761"
    },
    {
        "text": "Sonkajärvi",
        "value": "762"
    },
    {
        "text": "Sotkamo",
        "value": "765"
    },
    {
        "text": "Sottunga",
        "value": "766"
    },
    {
        "text": "Sulkava",
        "value": "768"
    },
    {
        "text": "Sumiainen",
        "value": "770"
    },
    {
        "text": "Sund",
        "value": "771"
    },
    {
        "text": "Suodenniemi",
        "value": "772"
    },
    {
        "text": "Suolahti",
        "value": "774"
    },
    {
        "text": "Suomenniemi",
        "value": "775"
    },
    {
        "text": "Suomusjärvi",
        "value": "776"
    },
    {
        "text": "Suomussalmi",
        "value": "777"
    },
    {
        "text": "Suonenjoki",
        "value": "778"
    },
    {
        "text": "Sysmä",
        "value": "781"
    },
    {
        "text": "Säkylä",
        "value": "783"
    },
    {
        "text": "Särkisalo",
        "value": "784"
    },
    {
        "text": "Vaala",
        "value": "785"
    },
    {
        "text": "Säynätsalo",
        "value": "787"
    },
    {
        "text": "Sastamala",
        "value": "790"
    },
    {
        "text": "Siikalatva",
        "value": "791"
    },
    {
        "text": "Taipalsaari",
        "value": "831"
    },
    {
        "text": "Taivalkoski",
        "value": "832"
    },
    {
        "text": "Taivassalo",
        "value": "833"
    },
    {
        "text": "Tammela",
        "value": "834"
    },
    {
        "text": "Tammisaari",
        "value": "835"
    },
    {
        "text": "Tampere",
        "value": "837"
    },
    {
        "text": "Tarvasjoki",
        "value": "838"
    },
    {
        "text": "Temmes",
        "value": "841"
    },
    {
        "text": "Tenhola",
        "value": "842"
    },
    {
        "text": "Tervo",
        "value": "844"
    },
    {
        "text": "Tervola",
        "value": "845"
    },
    {
        "text": "Teuva",
        "value": "846"
    },
    {
        "text": "Tohmajärvi",
        "value": "848"
    },
    {
        "text": "Toholampi",
        "value": "849"
    },
    {
        "text": "Toivakka",
        "value": "850"
    },
    {
        "text": "Tornio",
        "value": "851"
    },
    {
        "text": "Turku",
        "value": "853"
    },
    {
        "text": "Pello",
        "value": "854"
    },
    {
        "text": "Tuulos",
        "value": "855"
    },
    {
        "text": "Tuupovaara",
        "value": "856"
    },
    {
        "text": "Tuusniemi",
        "value": "857"
    },
    {
        "text": "Tuusula",
        "value": "858"
    },
    {
        "text": "Tyrnävä",
        "value": "859"
    },
    {
        "text": "Töysä",
        "value": "863"
    },
    {
        "text": "Toijala",
        "value": "864"
    },
    {
        "text": "Ullava",
        "value": "885"
    },
    {
        "text": "Ulvila",
        "value": "886"
    },
    {
        "text": "Urjala",
        "value": "887"
    },
    {
        "text": "Utajärvi",
        "value": "889"
    },
    {
        "text": "Utsjoki",
        "value": "890"
    },
    {
        "text": "Uukuniemi",
        "value": "891"
    },
    {
        "text": "Uurainen",
        "value": "892"
    },
    {
        "text": "Uusikaarlepyy",
        "value": "893"
    },
    {
        "text": "Uusikaupunki",
        "value": "895"
    },
    {
        "text": "Vaasa",
        "value": "905"
    },
    {
        "text": "Vahto",
        "value": "906"
    },
    {
        "text": "Valkeakoski",
        "value": "908"
    },
    {
        "text": "Valkeala",
        "value": "909"
    },
    {
        "text": "Valtimo",
        "value": "911"
    },
    {
        "text": "Vammala",
        "value": "912"
    },
    {
        "text": "Vampula",
        "value": "913"
    },
    {
        "text": "Varkaus",
        "value": "915"
    },
    {
        "text": "Varpaisjärvi",
        "value": "916"
    },
    {
        "text": "Vehkalahti",
        "value": "917"
    },
    {
        "text": "Vehmaa",
        "value": "918"
    },
    {
        "text": "Vehmersalmi",
        "value": "919"
    },
    {
        "text": "Velkua",
        "value": "920"
    },
    {
        "text": "Vesanto",
        "value": "921"
    },
    {
        "text": "Vesilahti",
        "value": "922"
    },
    {
        "text": "Västanfjärd",
        "value": "923"
    },
    {
        "text": "Veteli",
        "value": "924"
    },
    {
        "text": "Vieremä",
        "value": "925"
    },
    {
        "text": "Vihanti",
        "value": "926"
    },
    {
        "text": "Vihti",
        "value": "927"
    },
    {
        "text": "Viiala",
        "value": "928"
    },
    {
        "text": "Viitasaari",
        "value": "931"
    },
    {
        "text": "Viljakkala",
        "value": "932"
    },
    {
        "text": "Vilppula",
        "value": "933"
    },
    {
        "text": "Vimpeli",
        "value": "934"
    },
    {
        "text": "Virolahti",
        "value": "935"
    },
    {
        "text": "Virrat",
        "value": "936"
    },
    {
        "text": "Virtasalmi",
        "value": "937"
    },
    {
        "text": "Vuolijoki",
        "value": "940"
    },
    {
        "text": "Vårdö",
        "value": "941"
    },
    {
        "text": "Vähäkyrö",
        "value": "942"
    },
    {
        "text": "Värtsilä",
        "value": "943"
    },
    {
        "text": "Vöyri",
        "value": "944"
    },
    {
        "text": "Vöyri-Maksamaa",
        "value": "945"
    },
    {
        "text": "Vöyri",
        "value": "946"
    },
    {
        "text": "Ylihärmä",
        "value": "971"
    },
    {
        "text": "Yli-Ii",
        "value": "972"
    },
    {
        "text": "Ylikiiminki",
        "value": "973"
    },
    {
        "text": "Ylistaro",
        "value": "975"
    },
    {
        "text": "Ylitornio",
        "value": "976"
    },
    {
        "text": "Ylivieska",
        "value": "977"
    },
    {
        "text": "Ylämaa",
        "value": "978"
    },
    {
        "text": "Yläne",
        "value": "979"
    },
    {
        "text": "Ylöjärvi",
        "value": "980"
    },
    {
        "text": "Ypäjä",
        "value": "981"
    },
    {
        "text": "Äetsä",
        "value": "988"
    },
    {
        "text": "Ähtäri",
        "value": "989"
    },
    {
        "text": "Äänekoski",
        "value": "992"
    }
];
