<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="vehicleDispatchStatus">
        <v-col
        class="pa-0">
          <v-card>
            <v-toolbar short dark color="primary">
                <v-card-title>
                    <span>Vehicle {{vehicleDispatchStatus.id}}</span>
                    <v-btn @click="$emit('close')" class="ml-4">
                    {{$t("common.cancel")}}
                </v-btn>
                </v-card-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text style="height: 600px">
              <MapBox
                :vehicles="vehicles"
                :center="vehicleDispatchStatus.location"
              ></MapBox>
            </v-card-text>
            </v-card>
          </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import MapBox from "../../Geo/MapBox";

export default {
    components: {
        MapBox
    },
    props: {
        vehicleDispatchStatus: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    computed: {
        vehicles() {
            return [this.vehicleDispatchStatusToVehicle(this.vehicleDispatchStatus)];
        }
    },
    methods: {
        close: () => {
            this.$emit("close");
        },
        vehicleDispatchStatusToVehicle: (vehicleDispatchStatus) => {
            return vehicleDispatchStatus;
        }
    }
};
</script>
