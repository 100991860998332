<template>
  <v-container fluid>
        <CompaniesTable></CompaniesTable>
  </v-container>
</template>

<script>
import CompaniesTable from "../components/Companies/CompaniesTable";

export default {
    components: { CompaniesTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
