<template>
  <v-container fluid>
        <h1>Trips</h1>
        <TripsTable></TripsTable>
  </v-container>
</template>

<script>
import TripsTable from "../components/Trips/TripsTable";

export default {
    components: { TripsTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
