var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1400" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { short: "", dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.cancel } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [_vm._v(" Create Vehicle ")])
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { key: _vm.refreshValidation, ref: "form" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.operators,
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "companyId"
                              ),
                              label: "Company Id"
                            },
                            model: {
                              value: _vm.newVehicle.companyId,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "companyId", $$v)
                              },
                              expression: "newVehicle.companyId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "fleetId"
                              ),
                              label: "Fleet Id"
                            },
                            model: {
                              value: _vm.newVehicle.fleetId,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "fleetId", $$v)
                              },
                              expression: "newVehicle.fleetId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "vehicleId"
                              ),
                              label: "Vehicle Id"
                            },
                            model: {
                              value: _vm.newVehicle.vehicleId,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "vehicleId", $$v)
                              },
                              expression: "newVehicle.vehicleId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.vehicleTwinTypes,
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "twinType"
                              ),
                              label: "Twin Type"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.vehicleTwinTypeToString(item)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.vehicleTwinTypeToString(item)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.newVehicle.twinType,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "twinType", $$v)
                              },
                              expression: "newVehicle.twinType"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "vehicleNumber"
                              ),
                              label: "Vehicle Number"
                            },
                            model: {
                              value: _vm.newVehicle.vehicleNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "vehicleNumber", $$v)
                              },
                              expression: "newVehicle.vehicleNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Registration Plate Number" },
                            model: {
                              value: _vm.newVehicle.registrationPlateNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.newVehicle,
                                  "registrationPlateNumber",
                                  $$v
                                )
                              },
                              expression: "newVehicle.registrationPlateNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.emailRule, _vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "email"
                              ),
                              label: "Email"
                            },
                            model: {
                              value: _vm.newVehicle.email,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "email", $$v)
                              },
                              expression: "newVehicle.email"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [_vm.phoneNumberRule, _vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "phoneNumber"
                              ),
                              label: "Phone Number"
                            },
                            model: {
                              value: _vm.newVehicle.phoneNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.newVehicle, "phoneNumber", $$v)
                              },
                              expression: "newVehicle.phoneNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("v-btn", { staticClass: "ml-2", on: { click: _vm.create } }, [
                _vm._v(" Create ")
              ]),
              _c("v-btn", { staticClass: "ml-2", on: { click: _vm.cancel } }, [
                _vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")
              ]),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }