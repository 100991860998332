<template>
  <v-card v-if="operators && operators.length > 0">
     <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
            >
            {{ snackbarText }}
            <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-card-title>
      {{ $t('userManagement.title')}}
      <v-spacer></v-spacer>
      <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="$t('common.filter')"
          single-line
          hide-details
          class="pt-0 mt-0 mr-2"></v-text-field>
    </v-card-title>
    <v-data-table
      :items=customers
      :headers=headers
      :search="filter"
      item-key="id"
      @click:row="openCustomer"
    >
    <template v-slot:top>
        <div class="pl-5">
          <label v-if="operators.length === 1">{{ operators[0] }}</label>
            <v-select
              v-else-if="operators.length > 1"
              :label="$t('userManagement.operator')"
              :items="operators"
              :value="operator"
              @change="changeOperator"
              width="300"
            ></v-select>
        </div>
        <div class="pl-5">
          <v-btn color="primary" @click="showCustomerDialog = true;">{{$t("userManagement.addCustomer")}}</v-btn>
            <CustomerDialog
            v-model="showCustomerDialog"
            :header="$t('userManagement.dialog.createCustomer')"
            :customer=customer
            v-on:created="createCustomer"></CustomerDialog>
        </div>
    </template>
    </v-data-table>
  </v-card>
  <div v-else>
    <h1 style="color: error">{{$t("common.error").toUpperCase()}}</h1>
    <span>{{$t("userManagement.noOperatorsError")}}</span>
  </div>
</template>

<script>
import { RepositoryFactory } from "../data/repositories/repositoryFactory";
import CustomerDialog from "../components/Customers/CustomerDialog";
const UserManagementRepository = RepositoryFactory.get("userManagement");

export default {
    components: { CustomerDialog },
    data: () => ({
        customers: [],
        showCustomerDialog: false,
        customer: {},
        filter: "",
        snackbar: false,
        snackbarText: "",
        snackbarColor: ""
    }),
    methods: {
        openCustomer(customer) {
            this.$router.push({ name: "CustomerDetails", params: { id: customer.id }, query: { operator: this.operator } });
        },
        showNotification(color, text) {
            this.snackbarText = text;
            this.snackbarColor = color;
            this.snackbar = true;
        },
        async createCustomer(customerObj) {
            this.showCustomerDialog = false;
            let { data: createdCustomer } = await UserManagementRepository.createCustomer(customerObj, this.operator);
            await UserManagementRepository.addCustomerVehicleAttributes(customerObj.defaultAttributes, createdCustomer.itemId, this.operator);
            if (!createdCustomer.success) {
                this.showNotification("alert", "Error occured while trying to create new Customer!");
                return;
            }
            this.searchCustomers();
            this.showNotification("success", "Created a new Customer successfully!");
        },
        async changeOperator(operatorId) {
            this.$store.dispatch("user/setOperator", operatorId);
        },
        async searchCustomers() {
            const { data: { results: customers } } = await UserManagementRepository.findCustomers(this.operator);
            this.customers = customers;
        }
    },
    async created() {
        await this.searchCustomers();
    },
    computed: {
        operators() {
            return this.$store.state.user.operators;
        },
        operator() {
            return this.$store.state.user.operator;
        },
        headers() {
            return [
                { text: this.$t("userManagement.headers.name"), value: "name" },
                { text: this.$t("userManagement.headers.description"), value: "description" },
                { text: this.$t("userManagement.headers.externalId"), value: "externalId" },
                { text: this.$t("userManagement.headers.businessId"), value: "businessIdentityCode" }
            ];
        }
    },
    watch: {
        operator(val) {
            this.searchCustomers();
        }
    }
};
</script>

<style scoped>

</style>
