var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1400" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { short: "", dark: "", color: "primary" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t("invoicing.toggleColumnsTitle")) + " "
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", dark: "" },
                  on: { click: _vm.hideAllColumns }
                },
                [_vm._v(" " + _vm._s(_vm.$t("invoicing.hideAllColumns")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", dark: "" },
                  on: { click: _vm.showAllColumns }
                },
                [_vm._v(" " + _vm._s(_vm.$t("invoicing.showAllColumns")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { text: "", dark: "" },
                  on: { click: _vm.resetColumns }
                },
                [_vm._v(" " + _vm._s(_vm.$t("invoicing.resetDefaults")) + " ")]
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-row", { staticClass: "mt-1" }, [
                _vm._v(_vm._s(_vm.$t("invoicing.dragColumnsHint")))
              ]),
              _c(
                "draggable",
                {
                  staticClass: "row",
                  model: {
                    value: _vm.headers,
                    callback: function($$v) {
                      _vm.headers = $$v
                    },
                    expression: "headers"
                  }
                },
                _vm._l(_vm.togglableHeaders, function(header, n) {
                  return _c(
                    "v-col",
                    { key: n, attrs: { cols: "4", md: "2" } },
                    [
                      _c("v-switch", {
                        attrs: { label: header.toggleText || header.text },
                        model: {
                          value: header.show,
                          callback: function($$v) {
                            _vm.$set(header, "show", $$v)
                          },
                          expression: "header.show"
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }