var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FleetMembershipsTable", {
    attrs: { fleetMemberships: _vm.editableVehicle.memberships },
    on: {
      addFleetMembership: _vm.addFleetMembership,
      saveFleetMembership: _vm.saveFleetMembership,
      deleteFleetMembership: _vm.deleteFleetMembership
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }