<template>
  <v-container fluid>
        <BookingsTable></BookingsTable>
  </v-container>
</template>

<script>
import BookingsTable from "../components/Bookings/BookingsTable";

export default {
    components: { BookingsTable }
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}
</style>
