export const PriceRuleType = {
    DayTypeTimeRange: "DayTypeTimeRange",
    CarType: "CarType",
    PassengerCount: "PassengerCount",
    FixedRoute: "FixedRoute",
    PreBooked: "PreBooked",
    Range: "Range",
    NotRule: "NotRule",
    Default: "Default",
    Route: "Route",
    AverageSpeed: "AverageSpeed",
    StairClimber: "StairClimber",
    StairAssistWithoutClimber: "StairAssistWithoutClimber",
    UnobstructedExtraRule: "UnobstructedExtraRule",
    ElevatorExtraRule: "ElevatorExtraRule",
    AssistToDoor: "AssistToDoor"
};

export function PriceRuleTypeToString(priceRuleType) {
    switch (priceRuleType) {
    case PriceRuleType.DayTypeTimeRange:
        return "Day type & time range";
    case PriceRuleType.CarType:
        return "Car type";
    case PriceRuleType.PassengerCount:
        return "Passenger count";
    case PriceRuleType.FixedRoute:
        return "Fixed route";
    case PriceRuleType.PreBooked:
        return "Pre-booked";
    case PriceRuleType.Range:
        return "Range";
    case PriceRuleType.NotRule:
        return "Negate";
    case PriceRuleType.Default:
        return "Default";
    case PriceRuleType.Route:
        return "Route";
    case PriceRuleType.AverageSpeed:
        return "Average speed";
    case PriceRuleType.StairClimber:
        return "Stair climber";
    case PriceRuleType.StairAssistWithoutClimber:
        return "Stair assist without climber";
    case PriceRuleType.UnobstructedExtraRule:
        return "Unobstructed extra rule";
    case PriceRuleType.ElevatorExtraRule:
        return "Elevator extra rule";
    case PriceRuleType.AssistToDoor:
        return "Assist to door";
    }
};
