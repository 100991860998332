<template>
    <v-dialog v-model="isOpen" persistent max-width="800">
        <v-card>
            <v-card-title>
               <span class="headline">
                   {{ isNewRule ? 'Add new rule' : 'Edit rule' }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation>
                    <v-container>
                        <v-row>
                            <v-text-field
                                v-model="rule.name"
                                :rules="[v => !v || v.length <= 30 || 'Name cannot be longer than 30 characters']"
                                counter="30"
                                label="Name"
                            ></v-text-field>
                        </v-row>
                        <v-row>
                            <v-select
                                v-model="selectedRuleType"
                                :items="allowedRuleTypes"
                                :rules="[v => !!v || 'Rule type is required']"
                                label="Rule type"
                                required
                            >
                                <template slot="selection" slot-scope="{ item }">
                                    {{ formatRuleTypeToString(item) }}
                                </template>
                                <template slot="item" slot-scope="{ item }">
                                    {{ formatRuleTypeToString(item) }}
                                </template>
                            </v-select>
                        </v-row>
                        <v-slide-y-transition group hide-on-leave>
                            <template v-if="selectedRuleType === priceRuleTypes.DayTypeTimeRange">
                                <div key=1>
                                    <v-row>
                                        <v-select
                                            v-model="dayTypeTimeRange.dayTypes"
                                            :items="Object.values(dayTypes)"
                                            :rules="[v => !!v || 'Day type is required']"
                                            label="Day type"
                                            required
                                        >
                                            <template slot="selection" slot-scope="{ item }">
                                                {{ dayTypeToString(item) }}
                                            </template>
                                            <template slot="item" slot-scope="{ item }">
                                                {{ dayTypeToString(item) }}
                                            </template>
                                        </v-select>
                                    </v-row>
                                    <v-switch
                                        v-model="isWholeDay"
                                        label="Whole day"
                                    ></v-switch>
                                    <v-row>
                                        <v-col cols=2>
                                            <v-text-field
                                                v-model="dayTypeTimeRange.start"
                                                label="From"
                                                :disabled="isWholeDay"
                                                type="time"
                                                step=1
                                                required
                                                :rules="[v => !!v || 'Start time is required']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols=2>
                                            <v-text-field
                                                v-model="dayTypeTimeRange.end"
                                                label="To"
                                                :disabled="isWholeDay"
                                                type="time"
                                                step=1
                                                required
                                                :rules="[v => !!v || 'End time is required']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                            <template v-else-if="selectedRuleType === priceRuleTypes.CarType">
                                <div key=2>
                                    <v-row>
                                        <v-select
                                            v-model="carType"
                                            :items="Object.values(carTypes)"
                                            :rules="[v => !!v || 'Car type is required']"
                                            label="Car type"
                                            required
                                        >
                                            <template slot="selection" slot-scope="{ item }">
                                                {{ carTypeToString(item) }}
                                            </template>
                                            <template slot="item" slot-scope="{ item }">
                                                {{ carTypeToString(item) }}
                                            </template>
                                        </v-select>
                                    </v-row>
                                </div>
                            </template>
                            <template v-else-if="selectedRuleType === priceRuleTypes.PassengerCount">
                                <div key=3>
                                    <v-row>
                                        <v-col cols="2">
                                            <v-text-field
                                                v-model="passengerCount.min"
                                                label="Min"
                                                prepend-icon="mdi-account"
                                                required
                                                :rules="[v => !!v || 'Minimun amount of passengers is required']"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-text-field
                                                v-model="passengerCount.max"
                                                label="Max"
                                                prepend-icon="mdi-account-multiple"
                                                required
                                                :rules="[v => !!v || 'Maximum amount of passengers is required']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                            <template v-else-if="selectedRuleType === priceRuleTypes.Range">
                                <div key=3>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                v-model="distanceRange.start"
                                                label="Distance range start"
                                                hint="Can be left empty for open ended range"
                                                persistent-hint
                                                prepend-icon="mdi-account"
                                                min=0
                                                step=1
                                                type="number"
                                                :rules="[v => v === '' || !!Number.isInteger(parseFloat(v)) || 'Range has to be in integers']"
                                            ></v-text-field>
                                        </v-col>
                                         <v-col cols="6">
                                            <v-text-field
                                                v-model="distanceRange.end"
                                                label="Distance range end"
                                                hint="Can be left empty for open ended range"
                                                persistent-hint
                                                prepend-icon="mdi-account"
                                                min=0
                                                step=1
                                                type="number"
                                                :rules="[v => v === '' || !!Number.isInteger(parseFloat(v)) || 'Range has to be in integers']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                            <template v-else-if="selectedRuleType === priceRuleTypes.FixedRoute">
                                <div key=4>
                                    <div class="pickup-location-header"><v-icon>place</v-icon> Pickup location</div>
                                    <v-row>
                                        <v-text-field
                                            v-model="pickupLocation.street"
                                            label="Street name"
                                            required
                                            :rules="[v => !!v || 'Street name is required']"
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                            v-model="pickupLocation.streetNumber"
                                            label="Street Number (regex)"
                                            hint="eg. ^[1-9]$|^10$ from 1 to 10 or ^[1-9][0-9]?$|^100$ from 1 to 100"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pl-0" cols=12 sm=3>
                                            <v-text-field
                                                v-model="pickupLocation.zipcode"
                                                label="Zipcode (optional)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="px-0">
                                            <v-text-field
                                                v-model="pickupLocation.city"
                                                label="City"
                                                required
                                                :rules="[v => !!v || 'City is required']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <div class="pickup-location-header"><v-icon>place</v-icon> Drop off location</div>
                                    <v-row>
                                            <v-text-field
                                                v-model="dropoffLocation.street"
                                                label="Street name"
                                                required
                                                :rules="[v => !!v || 'Street name is required']"
                                            ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-text-field
                                            v-model="dropoffLocation.streetNumber"
                                            label="Street Number (regex)"
                                            hint="eg. ^[1-9]$|^10$ from 1 to 10 or ^[1-9][0-9]?$|^100$ from 1 to 100"
                                            persistent-hint
                                            required
                                        ></v-text-field>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pl-0" cols=12 sm=3>
                                            <v-text-field
                                                v-model="dropoffLocation.zipcode"
                                                label="Zipcode (optional)"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col class="px-0">
                                            <v-text-field
                                                v-model="dropoffLocation.city"
                                                label="City"
                                                required
                                                :rules="[v => !!v || 'City is required']"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-checkbox
                                            v-model="bidirectional"
                                            label="Bidirectional"
                                            hint="Trip can start or end at either location"
                                            persistent-hint
                                            required
                                        ></v-checkbox>
                                    </v-row>
                                </div>
                            </template>
                        </v-slide-y-transition>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn v-if="isNewRule"
                    :disabled="!valid"
                    :loading="isUpdating"
                    @click="createRule"
                    color="success">
                    Add rule
                </v-btn>
                <v-btn v-else
                    :disabled="!valid"
                    @click="saveRule"
                    color="primary">
                    Save changes
                </v-btn>
                <v-btn @click="$emit('close')" class="ml-4">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import RuleFormatterMixin from "./RuleFormatterMixin";
import { DayType, DayTypeToString } from "../../models/DayTypes";
import { CarType, CarTypeToString } from "../../models/CarTypes";

export default {
    props: {
        originalRule: {
            type: Object,
            default: () => {}
        },
        allowedRuleTypes: Array,
        isOpen: {
            type: Boolean,
            default: false
        },
        isUpdating: {
            type: Boolean,
            default: false
        },
        isNewRule: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            rule: {},
            dayTypeTimeRange: {},
            carType: null,
            pickupLocation: {},
            dropoffLocation: {},
            bidirectional: false,
            passengerCount: {},
            distanceRange: {},
            valid: true,
            dayTypes: DayType,
            menuStartTime: false,
            menuEndTime: false,
            isWholeDay: false,
            carTypes: CarType,
            selectedRuleType: null
        };
    },
    mixins: [RuleFormatterMixin],
    watch: {
        originalRule: function(newVal, oldVal) {
            this.rule = {};
            this.rule.ruleType = JSON.parse(JSON.stringify(newVal.ruleType || null));
            this.rule.name = JSON.parse(JSON.stringify(newVal.name || null));
            this.dayTypeTimeRange = JSON.parse(JSON.stringify(newVal.dayTypeTimeRange || {}));
            this.carType = JSON.parse(JSON.stringify(newVal.carType || null));

            // Passenger count
            this.passengerCount.min = JSON.parse(JSON.stringify(newVal.start || null));
            this.passengerCount.max = JSON.parse(JSON.stringify(newVal.end || null));

            // Fixed route
            this.pickupLocation = JSON.parse(JSON.stringify(newVal.pickupLocation || {}));
            this.dropoffLocation = JSON.parse(JSON.stringify(newVal.dropoffLocation || {}));
            this.bidirectional = JSON.parse(JSON.stringify(newVal.bidirectional || false));

            // Distance range
            this.distanceRange.start = JSON.parse(JSON.stringify(newVal.rangeStart || null));
            this.distanceRange.end = JSON.parse(JSON.stringify(newVal.rangeEnd || null));

            this.selectedRuleType = this.rule.ruleType;
        },
        isOpen: function(val, oldVal) {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            this.loading = false;
            this.isWholeDay = false;
        },
        isWholeDay: function(val, oldVal) {
            if (val) {
                this.dayTypeTimeRange.start = "00:00:00";
                this.dayTypeTimeRange.end = "23:59:59";
            }
        },
        selectedRuleType: function(val, oldVal) {
            this.rule.ruleType = val;
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        }
    },
    methods: {
        saveRule() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.fillRule();
            this.$emit("save", this.rule);
        },
        createRule() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.fillRule();
            this.$emit("create", this.rule);
        },
        dayTypeToString(dayType) {
            return DayTypeToString(dayType);
        },
        carTypeToString(carType) {
            return CarTypeToString(carType);
        },
        fillRule() {
            switch (this.rule.ruleType) {
            case this.priceRuleTypes.DayTypeTimeRange:
                this.rule.dayTypeTimeRange = this.dayTypeTimeRange;
                return;
            case this.priceRuleTypes.CarType:
                this.rule.carType = this.carType;
                return;
            case this.priceRuleTypes.PassengerCount:
                this.rule.start = this.passengerCount.min;
                this.rule.end = this.passengerCount.max;
                return;
            case this.priceRuleTypes.Range:
                this.rule.rangeStart = this.distanceRange.start;
                this.rule.rangeEnd = this.distanceRange.end;
                return;
            case this.priceRuleTypes.FixedRoute:
                this.rule.pickupLocation = this.pickupLocation;
                this.rule.dropoffLocation = this.dropoffLocation;
                this.rule.bidirectional = this.bidirectional;
            }
        }
    }
};
</script>

<style scoped>
    .pickup-location-header {
        font-size: 0.9375rem;
        color: black;
        margin: 20px 0 10px 0;
    }
</style>
