<template>
  <div id="readyForSalesInvoicing">
    <v-card class="mt-1">
      <div class="elevation-1 mb-1 pb-2">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-card-title>
              {{$t("invoicing.sales.creationTitle")}}
              <v-icon right>mdi-archive-search-outline</v-icon>
            </v-card-title>
          </v-col>
          <v-col cols="auto">
            <v-select
            v-model="invoiceEventType"
            :items="invoiceEventTypes"
            :label="$t('invoicing.sales.invoiceEventTypes')">
            </v-select>
          </v-col>
          <v-col cols="auto" v-if="showInvoiceCustomerSelector">
            <v-select
              v-model="invoiceCustomerType"
              :items="invoiceCustomerTypes"
              :label="$t('invoicing.sales.invoiceEventTypes')"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="d-flex align-center">
            <v-card-actions>
            <v-btn
              :disabled="!selectedRows"
              color="success"
              @click=showSendToSalesInvoicingDialog>
               {{selectedRows.length > 0 ? $t("invoicing.sales.sendSelectedToSales") : $t("invoicing.sales.sendAllToSales")}}
            </v-btn>
          </v-card-actions>
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col cols="auto">
            <v-text-field
              v-model.lazy="fromDate"
              label="Alkaen"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-text-field
              :value="toDate"
              @change="v => toDate = v"
              label="Asti"
              type="date"
            ></v-text-field>
          </v-col>
          <v-col cols="auto" class="mt-4">
            <v-btn
              color="primary"
              small
              @click="resetDates"
            >{{$t('common.reset')}}</v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      <SendToSalesInvoiceDialog
      ref="salesInvoiceDialog"
      v-model="sendToSalesInvoiceDialog"
      @closed="sendToSalesInvoiceDialog = false">
      </SendToSalesInvoiceDialog>
      <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table" name="readyForSalesInvoicingTable">
        <v-data-table
          v-model="selectedRows"
          calculate-widths
          :headers="showableHeaders"
          :items="readyForSalesInvoicingRowData"
          show-select
          item-key="costCenter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="loadingPage"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
          >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="primary"
              text
              @click="searchEvents(item)"
            >
              Siirry tapahtumiin
            </v-btn>
          </template>
          <template v-slot:item.totalSum="{ item }">
            {{ item.totalSum | formatCurrency }}
          </template>
          <template v-slot:body.append>
            <tr  v-if="readyForSalesInvoicingRowData != null" style="outline: thick solid">
              <th></th>
              <th class="text-md-body-1 font-weight-bold pl-3">{{ footerTotalCostCenterCount }} kpl</th>
              <th></th>
              <th class="text-md-body-1 font-weight-bold">{{ footerTotalTransportEventCount }} kpl</th>
              <th class="text-md-body-1 font-weight-bold">{{ footerTotalSum | formatCurrency }}</th>
              <th></th>
            </tr>
        </template>
        </v-data-table>
      </v-skeleton-loader>
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn text @click="snackbar = false">
          {{$t("common.close")}}
        </v-btn>
      </v-snackbar>
    </v-card>
  </div>
  </template>

<script>
import { InvoiceCreationTypeMixin } from "@/models/InvoiceCreationType";
import { PaymentMethodMixin } from "@/models/PaymentMethods";
import { TransportEventStatusMixin } from "@/models/TransportEventStatusTypes";
import { TransportEventInvoicingMixin } from "@/models/TransportEventInvoicingStates";
import { TransportEventTypeMixin } from "@/models/TransportEventTypes";
import { FeeTypeMixin } from "@/models/FeeTypes";
import { SalesInvoiceCustomersMixin } from "@/models/SalesInvoiceCustomers";
import InputRuleMixin from "@/components/Inputs/InputRuleMixin";
import SendToSalesInvoiceDialog from "@/components/Invoicing/SendToSalesInvoiceDialog";

export default {
    mixins: [
        TransportEventStatusMixin,
        TransportEventInvoicingMixin,
        FeeTypeMixin,
        InputRuleMixin,
        TransportEventTypeMixin,
        PaymentMethodMixin,
        InvoiceCreationTypeMixin,
        SalesInvoiceCustomersMixin
    ],
    data() {
        return {
            loading: true,
            invoiceEventType: "CostCenter",
            invoiceEventTypes: [ { value: "CostCenter", text: "Myynnin laskutus" } ],
            invoiceCustomerType: null,
            costCenterReadyForSalesInvoiceHeaders: [
                { text: "Asiakkuusnumero", value: "costCenter", show: true },
                { text: "Nimi", value: "costCenterName", show: true },
                { text: "Tapahtumien määrä", value: "transportEventCount", show: true },
                { text: "Yhteensä €", value: "totalSum", show: true },
                { text: "Tapahtumat", value: "actions", sortable: false, show: true }
            ],
            sortBy: "serviceNumber",
            sortDesc: true,
            selectedRows: [],
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            sendToSalesInvoiceDialog: false,
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            transportEventDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        readyForSalesInvoicingRowData() {
            if (!this.invoiceCustomerType || this.invoiceCustomerType === "-") return this.readyForSalesInvoicingData?.rowData;
            return this.readyForSalesInvoicingData?.rowData.filter(row => this.ltxSalesInvoiceCustomers.some(customers => customers.vendorId === row.costCenter && customers.type === this.invoiceCustomerType));
        },
        showInvoiceCustomerSelector() {
            return this.invoiceCustomerTypes.length > 0;
        },
        invoiceCustomers() {
            if (this.$store.state.user.tenant === "ltx" && this.invoiceEventType === "CostCenter") {
                return this.ltxSalesInvoiceCustomers;
            }
            return [];
        },
        invoiceCustomerTypes() {
            if (this.$store.state.user.tenant === "ltx" && this.invoiceEventType === "CostCenter") {
                return this.ltxSalesInvoiceCustomerTypes;
            }
            return [];
        },
        fromDate: {
            get() {
                return this.$store.state.createSalesInvoices.fromDate;
            },
            set(value) {
                this.$store.dispatch("createSalesInvoices/setFromDate", value);
            }
        },
        toDate: {
            get() {
                return this.$store.state.createSalesInvoices.toDate;
            },
            set(value) {
                this.$store.dispatch("createSalesInvoices/setToDate", value);
            }
        },
        readyForSalesInvoicingData() {
            if (this.invoiceEventType === "CostCenter") {
                let costCenters = this.$store.state.createSalesInvoices.costCenterReadyForSalesInvoicingSummary;
                if (!costCenters) return [];
                costCenters.rowData.forEach(row => {
                    row.costCenterName = this.costCenterIdToName(row.costCenter);
                });
                return costCenters;
            }
            return [];
        },
        // Headers
        headers() {
            if (this.invoiceEventType === "CostCenter") {
                return this.costCenterReadyForSalesInvoiceHeaders;
            }
            return [];
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },

        // Footer
        footerTotalTransportEventCount() {
            return this.readyForSalesInvoicingRowData.reduce((sum, row) => sum + row.transportEventCount, 0);
        },
        footerTotalSum() {
            return this.readyForSalesInvoicingRowData.reduce((sum, row) => sum + row.totalSum, 0);
        },
        footerTotalCostCenterCount() {
            return this.readyForSalesInvoicingRowData.length;
        },

        // Pagination
        searchTotalCount() {
            return this.readyForSalesInvoicingData?.totalCostCenterCount;
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        tenant() {
            return this.$store.state.user.tenant;
        }
    },
    watch: {
        tenant(val) {
            this.getReadyForSalesInvoicing();
        },
        invoiceEventType: {
            immediate: true,
            handler(val) {
                this.getReadyForSalesInvoicing(val);
            }
        },
        fromDate(val) {
            this.getReadyForSalesInvoicing();
        },
        toDate(val) {
            this.getReadyForSalesInvoicing();
        }
    },
    methods: {
        async getReadyForSalesInvoicing() {
            if (this.invoiceEventType === "CostCenter") {
                this.loading = true;
                this.$store.dispatch("createSalesInvoices/getCostCenterReadyForSalesInvoicing")
                    .catch(error => {
                        this.snackbar = true;
                        this.snackbarColor = "error";
                        this.snackbarText = error;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        showSendToSalesInvoicingDialog() {
            let rows = this.selectedRows.length > 0 ? this.selectedRows : this.readyForSalesInvoicingRowData;
            this.$refs.salesInvoiceDialog.openDialog(rows, this.invoiceEventType);
        },
        searchEvents(rowData) {
            if (this.invoiceEventType === "CostCenter") {
                this.$router.push({
                    name: "Invoicing",
                    query:
          {
              costCenter: rowData.costCenter,
              salesInvoiceStates: [ this.salesInvoiceStates.Unprocessed, this.salesInvoiceStates.Waiting ]
          }
                });
            }
        },
        resetDates() {
            this.$store.dispatch("createSalesInvoices/resetDates");
        },
        costCenterIdToName(costCenterId) {
            return this.invoiceCustomers.filter(c => c.vendorId === costCenterId)[0]?.name;
        }
    },
    filters: {
        statusToColor: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "";
            case 3: return "success";
            default: return "error";
            }
            ;
        },
        statusToIcon: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "mdi-av-timer";
            case 3: return "mdi-check-circle-outline";
            default: return "mdi-alert";
            }
            ;
        },
        statusToToolTipText: function(value) {
            switch (value) {
            case 1:
            case 2:
                return "Waiting";
            case 3: return "No errors";
            default: return "Contains error(s)";
            }
            ;
        }
    },
    components: { SendToSalesInvoiceDialog }
};
</script>

  <style scoped>
  .v-text-field_search {
      padding-top: 0;
      margin-top: 0;
  }
  .extras-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
  }

  .extras-list > li > span:last-of-type {
      float: right;
      margin-left:10px
  }

  .itemRowSideColor-grey {
      border: 5px solid #BDBDBD;;
      background-color: #BDBDBD;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-blue {
      border: 5px solid #42A5F5;
      background-color: #42A5F5;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-green {
      border: 5px solid #66BB6A;
      background-color: #66BB6A;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-yellow {
      border: 5px solid #FFEE58;
      background-color: #FFEE58;
      border-radius: 3px;
      display: inline;
  }
  .itemRowSideColor-orange {
      border: 5px solid #FFA726;
      background-color: #FFA726;
      border-radius: 3px;
      display: inline;
  }

  </style>
