var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.operators && _vm.operators.length > 0
    ? _c(
        "v-card",
        [
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbarColor },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          ),
          _c(
            "v-card-title",
            [
              _vm._v(" " + _vm._s(_vm.$t("userManagement.title")) + " "),
              _c("v-spacer"),
              _c("v-text-field", {
                staticClass: "pt-0 mt-0 mr-2",
                attrs: {
                  "append-icon": "mdi-magnify",
                  label: _vm.$t("common.filter"),
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.filter,
                  callback: function($$v) {
                    _vm.filter = $$v
                  },
                  expression: "filter"
                }
              })
            ],
            1
          ),
          _c("v-data-table", {
            attrs: {
              items: _vm.customers,
              headers: _vm.headers,
              search: _vm.filter,
              "item-key": "id"
            },
            on: { "click:row": _vm.openCustomer },
            scopedSlots: _vm._u(
              [
                {
                  key: "top",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "pl-5" },
                        [
                          _vm.operators.length === 1
                            ? _c("label", [_vm._v(_vm._s(_vm.operators[0]))])
                            : _vm.operators.length > 1
                            ? _c("v-select", {
                                attrs: {
                                  label: _vm.$t("userManagement.operator"),
                                  items: _vm.operators,
                                  value: _vm.operator,
                                  width: "300"
                                },
                                on: { change: _vm.changeOperator }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pl-5" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showCustomerDialog = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("userManagement.addCustomer"))
                              )
                            ]
                          ),
                          _c("CustomerDialog", {
                            attrs: {
                              header: _vm.$t(
                                "userManagement.dialog.createCustomer"
                              ),
                              customer: _vm.customer
                            },
                            on: { created: _vm.createCustomer },
                            model: {
                              value: _vm.showCustomerDialog,
                              callback: function($$v) {
                                _vm.showCustomerDialog = $$v
                              },
                              expression: "showCustomerDialog"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2798742172
            )
          })
        ],
        1
      )
    : _c("div", [
        _c("h1", { staticStyle: { color: "error" } }, [
          _vm._v(_vm._s(_vm.$t("common.error").toUpperCase()))
        ]),
        _c("span", [_vm._v(_vm._s(_vm.$t("userManagement.noOperatorsError")))])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }