var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Add new Favorite Address")
            ])
          ]),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-radio-group",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function() {
                            return [_c("h3", [_vm._v("Address type:")])]
                          },
                          proxy: true
                        }
                      ]),
                      model: {
                        value: _vm.addressType,
                        callback: function($$v) {
                          _vm.addressType = $$v
                        },
                        expression: "addressType"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: { value: "pick", color: "success" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "success--text" },
                                    [_vm._v("Pick")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("v-radio", {
                        attrs: { value: "drop", color: "success" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "success--text" },
                                    [_vm._v("Drop")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("v-radio", {
                        attrs: { value: "checkpoint", color: "success" },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function() {
                              return [
                                _c("div", [
                                  _c(
                                    "strong",
                                    { staticClass: "success--text" },
                                    [_vm._v("Checkpoint")]
                                  )
                                ])
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Mannerheimintie",
                      label: "Street"
                    },
                    model: {
                      value: _vm.street,
                      callback: function($$v) {
                        _vm.street = $$v
                      },
                      expression: "street"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "96",
                      label: "StreetNumber"
                    },
                    model: {
                      value: _vm.streetNumber,
                      callback: function($$v) {
                        _vm.streetNumber = $$v
                      },
                      expression: "streetNumber"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: { outlined: "", placeholder: "D15", label: "Door" },
                    model: {
                      value: _vm.door,
                      callback: function($$v) {
                        _vm.door = $$v
                      },
                      expression: "door"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Vantaa",
                      label: "City"
                    },
                    model: {
                      value: _vm.city,
                      callback: function($$v) {
                        _vm.city = $$v
                      },
                      expression: "city"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "01630",
                      label: "Zipcode"
                    },
                    model: {
                      value: _vm.zipcode,
                      callback: function($$v) {
                        _vm.zipcode = $$v
                      },
                      expression: "zipcode"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Finland",
                      label: "Country"
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Terveyskeskus",
                      label: "Alias"
                    },
                    model: {
                      value: _vm.alias,
                      callback: function($$v) {
                        _vm.alias = $$v
                      },
                      expression: "alias"
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      placeholder: "Aja portista sisään",
                      label: "DriverNotes"
                    },
                    model: {
                      value: _vm.driverNotes,
                      callback: function($$v) {
                        _vm.driverNotes = $$v
                      },
                      expression: "driverNotes"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              !_vm.editFavoriteAddress
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary" },
                      on: { click: _vm.create }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.create")))]
                  )
                : _vm._e(),
              _vm.editFavoriteAddress
                ? _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { color: "primary" },
                      on: { click: _vm.update }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.update")))]
                  )
                : _vm._e(),
              _vm.favoriteAddressId
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "error" },
                      on: { click: _vm.deleteFavoriteAddress }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.delete")))]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "red", text: "" },
                  on: { click: _vm.cancel }
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }