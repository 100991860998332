var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1400" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _vm.vehicleDispatchStatus
        ? _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { short: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("span", [
                                _vm._v(
                                  "Vehicle " +
                                    _vm._s(_vm.vehicleDispatchStatus.id)
                                )
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("close")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("common.cancel")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticStyle: { height: "600px" } },
                        [
                          _c("MapBox", {
                            attrs: {
                              vehicles: _vm.vehicles,
                              center: _vm.vehicleDispatchStatus.location
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }