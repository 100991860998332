export const WaitFeeTypeMixin = {
    data() {
        return {
            waitFeeTypes: []
        };
    },
    filters: {
        waitFeeToToolTipText: function(value) {
            let vat = !value.vat ? "" : ", Vat: " + value.vat;
            return "Sum: " + value.price + vat;
        }
    }
}
;
