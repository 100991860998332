var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      staticClass: "mx-3 mt-2",
      model: {
        value: _vm.searchParamsValid,
        callback: function($$v) {
          _vm.searchParamsValid = $$v
        },
        expression: "searchParamsValid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "6" } },
            [
              _c("v-autocomplete", {
                attrs: { items: _vm.regions, label: "Alue" },
                model: {
                  value: _vm.updatedSearchParams.orderId,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "orderId", $$v)
                  },
                  expression: "updatedSearchParams.orderId"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3", attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "7" } },
            [
              _c("v-select", {
                attrs: {
                  items: Object.values(_vm.validationStatusTypes),
                  multiple: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          { staticStyle: { "margin-top": "-1px" } },
                          [_vm._v(" " + _vm._s("mdi-car-clock") + " ")]
                        ),
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invoicing.validationStatusLabel")
                              ) +
                              " "
                          )
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0 || index === 1
                          ? _c("v-chip", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("validationStatusToText")(item)
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" +
                                  _vm._s(
                                    _vm.updatedSearchParams.validationStates
                                      .length - 2
                                  ) +
                                  " others)"
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var attrs = ref.attrs
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-icon", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "validationStatusToIcon"
                                                    )(item)
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        align: "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "validationStatusToText"
                                                            )(item)
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { "input-value": active }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.updatedSearchParams.validationStates,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "validationStates", $$v)
                  },
                  expression: "updatedSearchParams.validationStates"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "3" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "pt-0",
                  attrs: { outlined: "", small: "" },
                  on: { click: _vm.setFailedValidations }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("invoicing.searchForFailed")) + " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c("v-select", {
                attrs: {
                  items: Object.values(_vm.kelaSalesInvoiceStates),
                  disabled: !!_vm.updatedSearchParams.salesInvoiceNumbers,
                  multiple: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0 || index === 1
                          ? _c("v-chip", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("salesInvoiceStatusToText")(item)
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" +
                                  _vm._s(
                                    _vm.updatedSearchParams.salesInvoiceStates
                                      .length - 2
                                  ) +
                                  " others)"
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          { staticStyle: { "margin-top": "-2px" } },
                          [_vm._v(" " + _vm._s("mdi-file-clock-outline") + " ")]
                        ),
                        _c("span", [_vm._v(" Myyntilaskun tila ")])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var attrs = ref.attrs
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-icon", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "salesInvoiceStatusToIcon"
                                                    )(item)
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        align: "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "salesInvoiceStatusToText"
                                                            )(item)
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { "input-value": active }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.updatedSearchParams.salesInvoiceStates,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "salesInvoiceStates", $$v)
                  },
                  expression: "updatedSearchParams.salesInvoiceStates"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "1" } },
            [_c("v-subheader", [_vm._v("TAI")])],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Myyntilaskun numero" },
                on: {
                  change: function($event) {
                    _vm.updatedSearchParams.salesInvoiceStates = []
                  }
                },
                model: {
                  value: _vm.updatedSearchParams.salesInvoiceNumbers,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "salesInvoiceNumbers",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.salesInvoiceNumbers"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "6" } },
            [
              _c("v-select", {
                attrs: {
                  items: Object.values(_vm.kelaPurchaseInvoiceStates),
                  disabled: !!_vm.updatedSearchParams.purchaseInvoiceNumbers,
                  multiple: "",
                  clearable: ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "label",
                    fn: function() {
                      return [
                        _c(
                          "v-icon",
                          { staticStyle: { "margin-top": "-2px" } },
                          [
                            _vm._v(
                              " " + _vm._s("mdi-archive-clock-outline") + " "
                            )
                          ]
                        ),
                        _c("span", [_vm._v(" Ostolaskun tila ")])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      var index = ref.index
                      return [
                        index === 0 || index === 1
                          ? _c("v-chip", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("purchaseInvoiceStatusToText")(item)
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e(),
                        index === 2
                          ? _c("span", { staticClass: "grey--text caption" }, [
                              _vm._v(
                                "(+" +
                                  _vm._s(
                                    _vm.updatedSearchParams
                                      .purchaseInvoiceStates.length - 2
                                  ) +
                                  " others)"
                              )
                            ])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      var attrs = ref.attrs
                      var on = ref.on
                      return [
                        _c(
                          "v-list-item",
                          _vm._g(
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var active = ref.active
                                        return [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-icon", _vm._g({}, on), [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f(
                                                      "purchaseInvoiceStatusToIcon"
                                                    )(item)
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                        align: "center"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm._f(
                                                              "purchaseInvoiceStatusToText"
                                                            )(item)
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c("v-checkbox", {
                                                attrs: { "input-value": active }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              "v-list-item",
                              attrs,
                              false
                            ),
                            on
                          )
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.updatedSearchParams.purchaseInvoiceStates,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "purchaseInvoiceStates",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.purchaseInvoiceStates"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "1" } },
            [_c("v-subheader", [_vm._v("TAI")])],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "5" } },
            [
              _c("v-text-field", {
                attrs: { label: "Ostolaskun numero" },
                on: {
                  change: function($event) {
                    _vm.updatedSearchParams.purchaseInvoiceStates = []
                  }
                },
                model: {
                  value: _vm.updatedSearchParams.purchaseInvoiceNumbers,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.updatedSearchParams,
                      "purchaseInvoiceNumbers",
                      $$v
                    )
                  },
                  expression: "updatedSearchParams.purchaseInvoiceNumbers"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Palvelutunnus" },
                model: {
                  value: _vm.updatedSearchParams.serviceNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "serviceNumber", $$v)
                  },
                  expression: "updatedSearchParams.serviceNumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Autonumero" },
                model: {
                  value: _vm.updatedSearchParams.carNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "carNumber", $$v)
                  },
                  expression: "updatedSearchParams.carNumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-text-field", {
                attrs: { label: "Henkilötunnus" },
                model: {
                  value: _vm.updatedSearchParams.SSN,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "SSN", $$v)
                  },
                  expression: "updatedSearchParams.SSN"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "3" } },
            [_vm._v("Matka alkanut")]
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Matkan alku alkaen",
                  type: "datetime-local",
                  max: "2099-12-31T00:00"
                },
                model: {
                  value: _vm.updatedSearchParams.tripStartedFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripStartedFrom", $$v)
                  },
                  expression: "updatedSearchParams.tripStartedFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Matkan alku päättyen",
                  type: "datetime-local",
                  max: "2099-12-31T00:00"
                },
                model: {
                  value: _vm.updatedSearchParams.tripStartedTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripStartedTo", $$v)
                  },
                  expression: "updatedSearchParams.tripStartedTo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "3" } },
            [_vm._v("Matka päättynyt")]
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Matkan päätös alkaen",
                  type: "datetime-local",
                  max: "2099-12-31T00:00"
                },
                model: {
                  value: _vm.updatedSearchParams.tripEndedFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripEndedFrom", $$v)
                  },
                  expression: "updatedSearchParams.tripEndedFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Matkan päätös päättyen",
                  type: "datetime-local",
                  max: "2099-12-31T00:00"
                },
                model: {
                  value: _vm.updatedSearchParams.tripEndedTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "tripEndedTo", $$v)
                  },
                  expression: "updatedSearchParams.tripEndedTo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0", attrs: { cols: "12", md: "3" } },
            [_vm._v("Tapahtumaa muokattu")]
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Muokattu alkaen",
                  type: "datetime-local",
                  max: "2099-12-31T00:00",
                  useCurrent: "true"
                },
                model: {
                  value: _vm.updatedSearchParams.modifiedFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "modifiedFrom", $$v)
                  },
                  expression: "updatedSearchParams.modifiedFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pl-0 py-0",
              attrs: { cols: "12", sm: "6", md: "3" }
            },
            [
              _c("v-text-field", {
                attrs: {
                  label: "Muokattu päättyen",
                  type: "datetime-local",
                  max: "2099-12-31T00:00",
                  useCurrent: "true"
                },
                model: {
                  value: _vm.updatedSearchParams.modifiedTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "modifiedTo", $$v)
                  },
                  expression: "updatedSearchParams.modifiedTo"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0 mb-2", attrs: { cols: "12", md: "2" } },
            [_vm._v("Kustannus")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6", sm: "auto" } },
            [
              _c("v-text-field", {
                staticClass: "pt-0 centered-input",
                staticStyle: { width: "70px" },
                attrs: {
                  "single-line": "",
                  type: "number",
                  placeholder: "-",
                  variant: "outlined",
                  dense: ""
                },
                model: {
                  value: _vm.updatedSearchParams.costFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "costFrom", $$v)
                  },
                  expression: "updatedSearchParams.costFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "hidden-xs-only py-0 pb-3",
              staticStyle: { "font-size": "1.5em" },
              attrs: { sm: "auto" }
            },
            [_vm._v(" - ")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6", sm: "auto" } },
            [
              _c("v-text-field", {
                staticClass: "pt-0 centered-input",
                staticStyle: { width: "70px" },
                attrs: {
                  "single-line": "",
                  type: "number",
                  placeholder: "-",
                  variant: "outlined",
                  dense: ""
                },
                model: {
                  value: _vm.updatedSearchParams.costTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "costTo", $$v)
                  },
                  expression: "updatedSearchParams.costTo"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.updatedSearchParams.costFrom = null
                      _vm.updatedSearchParams.costTo = null
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.clear")))]
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0 mb-2", attrs: { cols: "12", md: "2" } },
            [_vm._v("Korvaus")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6", sm: "auto" } },
            [
              _c("v-text-field", {
                staticClass: "pt-0 centered-input",
                staticStyle: { width: "70px" },
                attrs: {
                  "single-line": "",
                  type: "number",
                  placeholder: "-",
                  variant: "outlined",
                  dense: ""
                },
                model: {
                  value: _vm.updatedSearchParams.reimbursementFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "reimbursementFrom", $$v)
                  },
                  expression: "updatedSearchParams.reimbursementFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "hidden-xs-only py-0 pb-3",
              staticStyle: { "font-size": "1.5em" },
              attrs: { sm: "auto" }
            },
            [_vm._v(" - ")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6", sm: "auto" } },
            [
              _c("v-text-field", {
                staticClass: "pt-0 centered-input",
                staticStyle: { width: "70px" },
                attrs: {
                  "single-line": "",
                  type: "number",
                  placeholder: "-",
                  variant: "outlined",
                  dense: ""
                },
                model: {
                  value: _vm.updatedSearchParams.reimbursementTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "reimbursementTo", $$v)
                  },
                  expression: "updatedSearchParams.reimbursementTo"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.updatedSearchParams.reimbursementFrom = null
                      _vm.updatedSearchParams.reimbursementTo = null
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.clear")))]
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "pl-0 py-0 mb-2", attrs: { cols: "12", md: "2" } },
            [_vm._v("Omavastuu")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6", sm: "auto" } },
            [
              _c("v-text-field", {
                staticClass: "pt-0 centered-input",
                staticStyle: { width: "70px" },
                attrs: {
                  "single-line": "",
                  type: "number",
                  placeholder: "-",
                  variant: "outlined",
                  dense: ""
                },
                model: {
                  value: _vm.updatedSearchParams.deductibleFrom,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "deductibleFrom", $$v)
                  },
                  expression: "updatedSearchParams.deductibleFrom"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "hidden-xs-only py-0 pb-3",
              staticStyle: { "font-size": "1.5em" },
              attrs: { sm: "auto" }
            },
            [_vm._v(" - ")]
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "6", sm: "auto" } },
            [
              _c("v-text-field", {
                staticClass: "pt-0 centered-input",
                staticStyle: { width: "70px" },
                attrs: {
                  "single-line": "",
                  type: "number",
                  placeholder: "-",
                  variant: "outlined",
                  dense: ""
                },
                model: {
                  value: _vm.updatedSearchParams.deductibleTo,
                  callback: function($$v) {
                    _vm.$set(_vm.updatedSearchParams, "deductibleTo", $$v)
                  },
                  expression: "updatedSearchParams.deductibleTo"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", sm: "2" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.updatedSearchParams.deductibleFrom = null
                      _vm.updatedSearchParams.deductibleTo = null
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("common.clear")))]
              )
            ],
            1
          ),
          _c("v-spacer")
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-switch", {
            attrs: { label: "Etsi myös poistettuja tapahtumia" },
            model: {
              value: _vm.updatedSearchParams.removed,
              callback: function($$v) {
                _vm.$set(_vm.updatedSearchParams, "removed", $$v)
              },
              expression: "updatedSearchParams.removed"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }