export const DriverStatus = {
    Unknown: 0,
    Initialized: 1,
    Active: 2,
    InActive: 3,
    Deleted: 4
};

export function DriverStatusToString(driverStatus) {
    switch (driverStatus) {
    case DriverStatus.Unknown:
        return "-";
    case DriverStatus.Initialized:
        return "Initialized";
    case DriverStatus.Active:
        return "Active";
    case DriverStatus.InActive:
        return "In Active";
    case DriverStatus.Deleted:
        return "Deleted";
    }
};
