export const PropertyOrigin = {
    Unknown: 0,
    CompanyConfiguration: 1,
    FleetConfiguration: 2,
    VehicleConfiguration: 4,
    Vehicle: 8,
    Global: 16,
    DriverConfiguration: 32
};

export function PropertyOriginToString(propertyOrigin) {
    switch (propertyOrigin) {
    case PropertyOrigin.Unknown:
        return "-";
    case PropertyOrigin.CompanyConfiguration:
        return "Company Configuration";
    case PropertyOrigin.FleetConfiguration:
        return "Fleet Configuration";
    case PropertyOrigin.VehicleConfiguration:
        return "Vehicle Configuration";
    case PropertyOrigin.Vehicle:
        return "Vehicle";
    case PropertyOrigin.Global:
        return "Global";
    case PropertyOrigin.DriverConfiguration:
        return "Driver Configuration";
    }
};
