<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Area Vehicles
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = true"
          class="mx-2">
          Search
        </v-btn>
        <v-spacer></v-spacer>
        <AreaVehiclesSearchDialog
          :show="searchDialog"
          :searchParams="searchParams"
          @search="searchAreaVehicles($event, false)"
        ></AreaVehiclesSearchDialog>
      </v-card-actions>
    </div>
      <DispatchVehiclesTable
        :loading="loading"
        :vehicles="vehicles"
        @close="searchDialog = false"
        @vehicleSelected="vehicleSelected"
      >
      </DispatchVehiclesTable>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { VehicleStatusToString } from "../../models/VehicleStatus";
import { TaxiMeterStateToString } from "../../models/TaxiMeterState";
import { DispatchStateToString } from "../../models/DispatchState";
import AreaVehiclesSearchDialog from "./Dialogs/AreaVehiclesSearchDialog";
import DispatchVehiclesTable from "./DispatchVehiclesTable";
import DataTableOptionsMixin from "../../Mixins/DataTableOptionsMixin";
import EventSourceMixin from "../../Mixins/EventSourceMixin";
import _ from "lodash";

export default {
    components: { AreaVehiclesSearchDialog, DispatchVehiclesTable },
    mixins: [ DataTableOptionsMixin, EventSourceMixin ],
    data() {
        return {
            loading: false,
            searchDialog: false,
            searchParams: {
            },
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        defaultSearchParams() {
            return {
                companyId: _.filter(this.$store.state.user.operators, function(o) { return o !== "all"; })[0],
                page: 1,
                top: 10
            };
        },
        vehicles() {
            return this.$store.state.dispatching.vehiclesInQueriedArea;
        },
        searchTotalCount() {
            return this.vehicles.length;
        }
    },
    methods: {
        setRouteIfChanged() {
            const queryOptions = this.convertApiQueryToOptions(this.$route.query);
            const paramOptions = this.convertApiQueryToOptions(this.searchParams);
            if (JSON.stringify(queryOptions) !== JSON.stringify(paramOptions)) {
                this.$router.push({ path: "/dispatching", query: this.searchParams });
            }
        },
        async getAreaVehicles(searchParams) {
            this.loading = true;
            this.$store.dispatch("dispatching/searchVehiclesInArea", searchParams)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        vehicleStatusToString(vehicleStatus) {
            return VehicleStatusToString(vehicleStatus);
        },
        taxiMeterStateToString(taxiMeterState) {
            return TaxiMeterStateToString(taxiMeterState);
        },
        dispatchStateToString(dispatchState) {
            return DispatchStateToString(dispatchState);
        },
        vehicleSelected(vehicle) {
            this.$emit("vehicleSelected", vehicle);
        },
        searchAreaVehicles(searchParams) {
            this.searchDialog = false;
            this.searchParams = { ...this.searchParams, ...searchParams };
            this.getAreaVehicles(this.searchParams);
        }
    }
};
</script>
