<template>
<v-dialog v-model="isOpen" persistent max-width="1400">
    <v-row class="mx-0" v-if="vehicle">
      <v-col
      class="pa-0">
        <v-card>
          <v-toolbar short dark color="primary">
            <v-btn icon dark @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
                <span>Vehicle {{vehicle.id}}</span>
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-tabs
              v-model="tab"
              fixed-tabs
              background-color="indigo"
              dark
          >
            <v-tab href="#tab-details">
              Details
            </v-tab>
            <v-tab href="#tab-memberships">
              Fleet Memberships
            </v-tab>
            <v-tab href="#tab-attributes">
              Attributes
            </v-tab>
            <v-tab href="#tab-properties">
              Properties
            </v-tab>
            <v-tab href="#tab-reported-properties">
              Reported Properties
            </v-tab>
            <v-tab href="#tab-installations">
              Installations
            </v-tab>
            <v-tab href="#tab-tariffs">
              Tariffs
            </v-tab>
             <v-tab href="#tab-logs">
              Logs
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab">
            <v-tab-item
              :key="1"
              :value="'tab-details'">
              <v-card>
                <v-card-title>Vehicle Info</v-card-title>
                <v-card-text>
                  <VehicleBasicInfoForm
                    :vehicle="vehicle"
                    :editable="true"
                    @save="saveVehicleInfo">>
                  </VehicleBasicInfoForm>
                </v-card-text>
              </v-card>
              <v-card>
                <v-card-title>Contact Information</v-card-title>
                <v-card-text>
                  <ContactInformationForm
                    :contactInformation="vehicle.contactInformation"
                    :editable="true"
                    @save="saveContactInformation">
                  </ContactInformationForm>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              :value="'tab-memberships'">
              <VehicleFleetMembershipsForm
                :vehicle="vehicle"
                @addFleetMembership="addFleetMembership"
                @saveFleetMembership="saveFleetMembership"
                @deleteFleetMembership="deleteFleetMembership">
              </VehicleFleetMembershipsForm>
            </v-tab-item>
            <v-tab-item
              :key="3"
              :value="'tab-attributes'">
              <VehicleAttributesForm
                :vehicle="vehicle"
                @addNewAttribute="addNewAttribute"
                @saveAttribute="saveAttribute"
                @deleteAttribute="deleteAttribute">
              </VehicleAttributesForm>
            </v-tab-item>
            <v-tab-item
              :key="4"
              :value="'tab-properties'">
              <VehiclePropertiesForm
                :vehicle="vehicle"
                @addNewProperty="addNewProperty"
                @saveProperty="saveProperty"
                @deleteProperty="deleteProperty">
              </VehiclePropertiesForm>
            </v-tab-item>
            <v-tab-item
              :key="7"
              :value="'tab-reported-properties'">
              <PropertiesTable
                :properties="vehicle.reportedProperties"
                :canCreate="false"
                :canEdit="false">
              </PropertiesTable>
            </v-tab-item>
            <v-tab-item
              :key="5"
              :value="'tab-installations'">
              <VehicleInstallationsView
                :installations="vehicle.installations">
              </VehicleInstallationsView>
            </v-tab-item>
            <v-tab-item
              :key="6"
              :value="'tab-tariffs'">
              <VehicleTariffsForm
                :vehicle="vehicle"
                @addNewTariffImport="addNewTariffImport"
                @saveTariffImport="saveTariffImport"
                @saveTariffId="saveTariffId"
                @deleteTariffImport="deleteTariffImport">
              </VehicleTariffsForm>
            </v-tab-item>
            <v-tab-item
              :key="8"
              :value="'tab-logs'">
              <VehicleLogsView
                :vehicleId="vehicle.id">
              </VehicleLogsView>
            </v-tab-item>
          </v-tabs-items>
          </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
</template>

<script>
import moment from "moment";
import ContactInformationForm from "../../Shared/Forms/ContactInformationForm";
import VehicleFleetMembershipsForm from "../Forms/VehicleFleetMembershipsForm";
import VehicleBasicInfoForm from "../Forms/VehicleBasicInfoForm";
import VehicleAttributesForm from "../Forms/VehicleAttributesForm";
import VehiclePropertiesForm from "../Forms/VehiclePropertiesForm";
import VehicleTariffsForm from "../Forms/VehicleTariffsForm";
import VehicleInstallationsView from "../Views/VehicleInstallationsView";
import VehicleLogsView from "../Views/VehicleLogsView";
import PropertiesTable from "../../Shared/PropertiesTable";

export default {
    components: {
        ContactInformationForm,
        VehicleBasicInfoForm,
        VehicleFleetMembershipsForm,
        VehicleAttributesForm,
        VehiclePropertiesForm,
        VehicleTariffsForm,
        VehicleInstallationsView,
        VehicleLogsView,
        PropertiesTable
    },
    props: {
        vehicle: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            tab: null
        };
    },
    methods: {
        saveContactInformation: function(contactInformation) {
            this.$emit("saveContactInformation", this.vehicle.id, contactInformation);
        },
        saveVehicleInfo: function(vehicleInfo) {
            this.$emit("saveVehicleInfo", this.vehicle.id, vehicleInfo);
        },
        // Fleet Memberships
        addFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("addFleetMembership", this.vehicle.id, fleetMembership, eventTargetId);
        },
        saveFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("saveFleetMembership", this.vehicle.id, fleetMembership, eventTargetId);
        },
        deleteFleetMembership: function(fleetMembership, eventTargetId) {
            this.$emit("deleteFleetMembership", this.vehicle.id, fleetMembership, eventTargetId);
        },
        // Attributes
        addNewAttribute: function(attribute, eventTargetId) {
            this.$emit("addNewAttribute", this.vehicle.id, attribute, eventTargetId);
        },
        saveAttribute: function(attribute, eventTargetId) {
            this.$emit("saveAttribute", this.vehicle.id, attribute, eventTargetId);
        },
        deleteAttribute: function(attribute, eventTargetId) {
            this.$emit("deleteAttribute", this.vehicle.id, attribute, eventTargetId);
        },
        // Properties
        addNewProperty: function(property, eventTargetId) {
            this.$emit("addNewProperty", this.vehicle.id, property, eventTargetId);
        },
        saveProperty: function(property, eventTargetId) {
            this.$emit("saveProperty", this.vehicle.id, property, eventTargetId);
        },
        deleteProperty: function(property, eventTargetId) {
            this.$emit("deleteProperty", this.vehicle.id, property, eventTargetId);
        },
        // Tariff Imports
        addNewTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("addNewTariffImport", this.vehicle.id, tariffImport, eventTargetId);
        },
        saveTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("saveTariffImport", this.vehicle.id, tariffImport, eventTargetId);
        },
        saveTariffId: function(tariffId, eventTargetId) {
            this.$emit("saveTariffId", this.vehicle.id, tariffId, eventTargetId);
        },
        deleteTariffImport: function(tariffImport, eventTargetId) {
            this.$emit("deleteTariffImport", this.vehicle.id, tariffImport, eventTargetId);
        },
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        close: function() {
            this.$emit("close");
        }
    }
};
</script>
