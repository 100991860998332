var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("invoicing.options.newOption")))
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: _vm.$t("invoicing.options.optionName") },
                    model: {
                      value: _vm.newOptionName,
                      callback: function($$v) {
                        _vm.newOptionName = $$v
                      },
                      expression: "newOptionName"
                    }
                  }),
                  _c("v-select", {
                    attrs: {
                      items: _vm.erpSystems,
                      "item-text": "erpSystemName",
                      "item-value": "erpSystem",
                      label: _vm.$t("invoicing.options.erpSystem")
                    },
                    model: {
                      value: _vm.newOptionERPSystem,
                      callback: function($$v) {
                        _vm.newOptionERPSystem = $$v
                      },
                      expression: "newOptionERPSystem"
                    }
                  }),
                  _vm.errorMessage
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v(_vm._s(_vm.errorMessage))
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.editNewOptions }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.next")))]
                  ),
                  _c("v-btn", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.$t("common.cancel")))
                  ]),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800px" },
          model: {
            value: _vm.showEditOptionsDialog,
            callback: function($$v) {
              _vm.showEditOptionsDialog = $$v
            },
            expression: "showEditOptionsDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("invoicing.options.editOptions")))
              ]),
              _c(
                "v-card-text",
                [
                  _c("InvoiceOptionsForm", {
                    ref: "invoiceOptionsForm",
                    attrs: {
                      invoiceOptions: { invoicingOptions: _vm.editableOptions }
                    },
                    on: { submit: _vm.saveNewOption }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.triggerFormSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("common.save")))]
                  ),
                  _c("v-btn", { on: { click: _vm.cancel } }, [
                    _vm._v(_vm._s(_vm.$t("common.cancel")))
                  ]),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }