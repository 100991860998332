import { RepositoryFactory } from "@/data/repositories/repositoryFactory";

// initial state
const state = {
    fetchedIsochrone: null
};

// getters
const getters = {};

// actions
const actions = {
    async getIsochrone({ commit }, { request, clientName }) {
        await RepositoryFactory.get("geo").calculateIsochrone(request, clientName)
            .then(response => {
                commit("SET_ISOCHRONE", response.data.item);
            });
    }
};

// mutations
const mutations = {
    SET_ISOCHRONE(state, isochrone) {
        state.fetchedIsochrone = isochrone;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
