function formatRule(rule) {
    let _rule = {};

    _rule.name = rule.name;
    _rule.ruleType = rule.ruleType;

    // If rule has other rules inside.
    // Recursively call this method.
    if (rule.ruleType === "OrRule" || rule.ruleType === "NotRule") {
        let _innerRuleCol = [];

        for (let _innerRule of rule.value) {
            _innerRuleCol.push(formatRule(_innerRule));
        }

        _rule.rules = _innerRuleCol;
    }

    if (rule.ruleType === "Geofence") {
        _rule.featureCollection = rule.value;
    }

    if (rule.ruleType === "TimeRange") {
        _rule.timeRange = rule.value;
    }

    if (rule.ruleType === "DayTypeTimeRange") {
        _rule.timeRange = rule.value;
    }

    if (rule.ruleType === "City") {
        _rule.cities = rule.value;
    }

    if (rule.ruleType === "CarType") {
        _rule.carType = rule.value;
    }

    if (rule.ruleType === "PaymentType") {
        _rule.paymentType = rule.value;
    }

    if (rule.ruleType === "Location") {
        _rule.location = rule.value;
    }

    return _rule;
};

function formatRules(rules) {
    let ruleCol = [];

    for (let rule of rules) {
        if (rule.value) {
            ruleCol.push(formatRule(rule));
        } else {
            ruleCol.push(rule);
        }
    }

    return ruleCol;
};

export default {
    mapRules(validation) {
        let rules = formatRules(validation.rules);
        validation.rules = rules;

        return validation;
    }
};
