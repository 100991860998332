export const VehicleTwinType = {
    Unknown: 0,
    RouterGroup: 1,
    Simulation: 2
};

export function VehicleTwinTypeToString(vehicleTwinType) {
    switch (vehicleTwinType) {
    case VehicleTwinType.Unknown:
        return "-";
    case VehicleTwinType.RouterGroup:
        return "Router Group";
    case VehicleTwinType.Simulation:
        return "Simulation";
    }
};
