<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ isNew ? 'Add new condition' : 'Edit condition' }}
        </span>
      </v-card-title>
      <v-card-text style="color: black;">
        <v-form
          ref="conditionForm"
          v-model="valid"
          lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="condition.value"
                  :items="Object.values(transportEventConditionProperties)"
                  label="Value"
                  :rules="[ requiredRule ]"
                  required
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  v-model="condition.comparator"
                  :items="Object.values(comparators).filter(p => p !== 0)"
                  label="Comparator"
                  :rules="[ requiredRule ]"
                  required
                >
                  <template slot="selection" slot-scope="{ item }">
                    {{ item | comparatorToSymbol }}
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    {{ item | comparatorToSymbol }}
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="condition.target"
                  label="Target value"
                  :rules="[ requiredRule ]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn v-if="isNew"
          :disabled="!valid"
          @click="createCondition"
          color="success">
          Add condition
        </v-btn>
        <v-btn v-else
          :disabled="!valid"
          @click="saveCondition"
          color="primary">
          Save changes
        </v-btn>
        <v-btn @click="$emit('close')" class="ml-4">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { RulesMixin } from "@/models/Rules";
import InputRuleMixin from "../Inputs/InputRuleMixin";

export default {
    props: {
        originalCondition: {
            type: Object,
            default: () => {}
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: true
        }
    },
    mixins: [ RulesMixin, InputRuleMixin ],
    data() {
        return {
            condition: {},
            valid: true
        };
    },
    watch: {
        isOpen: function(val, oldVal) {
            if (this.$refs.conditionForm) {
                this.$refs.conditionForm.resetValidation();
            }
        },
        originalCondition: function(val, oldVal) {
            let condition = _.cloneDeep(val);
            this.condition = condition;
        }
    },
    methods: {
        saveCondition() {
            if (!this.$refs.conditionForm.validate()) {
                return;
            }
            this.$emit("save", this.condition);
        },
        createCondition() {
            if (!this.$refs.conditionForm.validate()) {
                return;
            }
            this.$emit("create", this.condition);
        }
    }
};
</script>

<style scoped>
.v-subheader {
  padding: 0;
  font-size: 1.2em;
}

.v-btn:not(.v-btn--round).v-size--default.add-new-btn {
  padding: 0;
}
</style>
