var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "1400" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _vm.driver
        ? _c(
            "v-row",
            { staticClass: "mx-0" },
            [
              _c(
                "v-col",
                { staticClass: "pa-0" },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { short: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c("span", [
                                _vm._v("Driver " + _vm._s(_vm.driver.id))
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-4",
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("close")
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("common.cancel")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "fixed-tabs": "",
                                "background-color": "indigo",
                                dark: ""
                              },
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c("v-tab", { attrs: { href: "#tab-details" } }, [
                                _vm._v(" Details ")
                              ]),
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-attributes" } },
                                [_vm._v(" Attributes ")]
                              ),
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-properties" } },
                                [_vm._v(" Properties ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.tab,
                                callback: function($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { key: 1, attrs: { value: "tab-details" } },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Driver Info")
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c(
                                            "DriverBasicInfoForm",
                                            {
                                              attrs: {
                                                driver: _vm.driver,
                                                editable: true
                                              },
                                              on: { save: _vm.saveDriverInfo }
                                            },
                                            [_vm._v("> ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card",
                                    [
                                      _c("v-card-title", [
                                        _vm._v("Contact Information")
                                      ]),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("ContactInformationForm", {
                                            attrs: {
                                              contactInformation:
                                                _vm.driver.contactInformation,
                                              editable: true
                                            },
                                            on: {
                                              save: _vm.saveContactInformation
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 2, attrs: { value: "tab-attributes" } },
                                [
                                  _c("DriverAttributesForm", {
                                    attrs: { driver: _vm.driver },
                                    on: {
                                      addNewAttribute: _vm.addNewAttribute,
                                      saveAttribute: _vm.saveAttribute,
                                      deleteAttribute: _vm.deleteAttribute
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: 3, attrs: { value: "tab-properties" } },
                                [
                                  _c("DriverPropertiesForm", {
                                    attrs: { driver: _vm.driver },
                                    on: {
                                      addNewProperty: _vm.addNewProperty,
                                      saveProperty: _vm.saveProperty,
                                      deleteProperty: _vm.deleteProperty
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }