/* eslint-disable handle-callback-err */
import * as Msal from "@azure/msal-browser";
import config from "../config";

export default class AuthService {
    constructor() {
        this.applicationConfig = {
            backendScopes: {
                // TODO: Actual scopes
                scopes: [`${config.clientId}/.default`]
            },
            graphApiScopes: {
                scopes: ["https://graph.microsoft.com/User.Read"]
            }
        };

        const msalConfig = {
            auth: {
                clientId: config.clientId,
                authority: config.authority,
                redirectUri: config.redirectUri
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        };

        this.app = new Msal.PublicClientApplication(msalConfig);
    }

    // Core Functionality
    async loginPopup() {
        try {
            return await this.app.loginPopup(this.applicationConfig.backendScopes);
        } catch (err) {
            throw Error(err);
        }
    }

    async getUser() {
        const token = await this.getToken(this.applicationConfig.backendScopes);

        if (token == null) {
            this.app._user = {};
            return {};
        }

        const user = {
            username: token.account.name,
            email: token.account.userName,
            roles: token.idTokenClaims.roles,
            userId: token.uniqueId
        };

        this.app._user = user;
        return this.app._user;
    }

    loginRedirect() {
        this.app.loginRedirect(this.applicationConfig.backendScopes);
    }

    async isAuthenticated() {
        try {
            await this.app.acquireTokenSilent(this.applicationConfig.backendScopes);
            return true;
        } catch {
            return false;
        }
    }

    logout() {
        this.app._user = null;
        this.app.logout();
    }

    canAccessSite(siteName) {
        if (!this.app._user.roles) {
            return false;
        }

        const role = this.app._user.roles.find(r => r.includes(`service.${siteName}service`) || r.includes("service.all"));

        if (!role) {
            return false;
        }
        return true;
    }

    async getToken(scopes) {
        try {
            return await this.app.acquireTokenSilent(scopes);
        } catch (err) {
            try {
                return await this.app.acquireTokenPopup(scopes);
            } catch (err) {
                throw Error(err);
            }
        }
    }

    hasServiceRole(serviceName, role) {
        const userRole = this.app._user.roles.find(r => r.includes("service.all") || r.includes(`service.${serviceName}service`));
        return userRole.includes(role);
    }

    getTenants() {
        return this.getRoles("tenant.");
    }

    getOperators() {
        return this.getRoles("operator.");
    }

    getRoles(type) {
        const operators = this.app._user.roles.filter(r => r.startsWith(type));
        if (operators.length < 1) return [];
        return operators.map(t => t.split(".").pop());
    }
}
