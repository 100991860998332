<template>
  <v-expansion-panel>
        <v-expansion-panel-header>
            <v-row align="center">
                <v-col><b>{{ price.unitPriceInfo.name }}</b></v-col>
                <v-spacer></v-spacer>
                <v-col sm=2>Vendor<br>{{ price.unitPriceInfo.vendorUnitPrice | formatPrice }}</v-col>
                <v-col sm=2>Customer<br>{{ price.unitPriceInfo.customerUnitPrice | formatPrice }}</v-col>
                <v-col sm=2>VAT<br>{{ price.unitPriceInfo.vatPercent }} %</v-col>
                <v-col sm=2>
                    <v-row justify="end" class="mr-2">
                        <v-icon color="primary" @click.stop="$emit('modify', price)">mdi-pencil</v-icon>
                        <v-icon color="error" class="ml-2" @click.stop="$emit('delete', price)">delete</v-icon>
                    </v-row>
                </v-col>
            </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <div v-if="price.rules.some(r => r.ruleType === 'Default')">
                <span style="font-size: 0.9375rem">This is default price. Default price is used when rules of any other price are not fulfilled.</span>
            </div>
            <RuleChipGroup
                ref="ruleChipGroup"
                v-else
                :rules="price.rules.filter(r => r.ruleType !== 'Default')"
                :allowedRuleTypes="allowedRuleTypes"
                :isUpdating="isUpdating"
                @update="updateRules"
            ></RuleChipGroup>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import RuleChipGroup from "./RuleChipGroup";
import _ from "lodash";

export default {
    props: {
        price: Object,
        allowedRuleTypes: Array
    },
    data() {
        return {
            isUpdating: false
        };
    },
    methods: {
        updateRules(rules) {
            if (rules.length < 1) {
                alert("Price must have at least 1 rule if it is not defined as default!");
                return;
            }
            this.isUpdating = true;
            let newPrice = _.cloneDeep(this.price);
            newPrice.rules = rules;
            this.$emit("update", newPrice);
        },
        updateSucceeded() {
            this.isUpdating = false;
            if (this.$refs && this.$refs.ruleChipGroup) {
                this.$refs.ruleChipGroup.closeRuleDialog();
            }
        },
        updateFailed() {
            this.isUpdating = false;
        }
    },
    components: {
        RuleChipGroup
    }
};
</script>

<style scoped>
.v-expansion-panel-header {
    line-height: 1.4;
}
</style>
