var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "div",
        { staticClass: "elevation-1 mb-1 pb-2" },
        [
          _c("v-card-title", [_vm._v(" Companies ")]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.searchDialog = true
                    }
                  }
                },
                [_vm._v(" Search ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: { small: "" },
                  on: {
                    click: function($event) {
                      _vm.companyCreateDialog = true
                    }
                  }
                },
                [_vm._v(" Create ")]
              ),
              _c("v-spacer"),
              _c("CompanySearchDialog", {
                attrs: {
                  show: _vm.searchDialog,
                  searchParams: _vm.searchParams
                },
                on: {
                  search: function($event) {
                    return _vm.searchCompanies($event, false)
                  },
                  close: function($event) {
                    _vm.searchDialog = false
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-skeleton-loader",
        {
          attrs: {
            loading: _vm.loading,
            "transition-group": "scale-transition",
            type: "table"
          }
        },
        [
          _c("v-data-table", {
            attrs: {
              "calculate-widths": "",
              headers: _vm.headers,
              items: _vm.companies,
              "item-key": "id",
              loading: _vm.loading,
              "server-items-length": _vm.searchTotalCount,
              options: _vm.options,
              "footer-props": { itemsPerPageOptions: _vm.itemsPerPageOptions }
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              },
              "click:row": _vm.openCompanyDialog
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.name",
                fn: function(ref) {
                  var value = ref.value
                  return [_c("span", [_vm._v(" " + _vm._s(value))])]
                }
              },
              {
                key: "item.status",
                fn: function(ref) {
                  var value = ref.value
                  return [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.companyStatusToString(value)))
                    ])
                  ]
                }
              },
              {
                key: "item.actions",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "span",
                      [
                        _c(
                          "v-btn",
                          {
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.activateCompany(item.id)
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("mdi-check")])],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("CompanyDialog", {
        attrs: { isOpen: _vm.companyDialog, company: _vm.company },
        on: {
          close: function($event) {
            _vm.companyDialog = false
          },
          saveContactInformation: _vm.saveContactInformation,
          saveCompanyInfo: _vm.saveCompanyInfo,
          addNewAttribute: _vm.addNewAttribute,
          saveAttribute: _vm.saveAttribute,
          deleteAttribute: _vm.deleteAttribute,
          addNewProperty: _vm.addNewProperty,
          saveProperty: _vm.saveProperty,
          deleteProperty: _vm.deleteProperty,
          addNewTariffImport: _vm.addNewTariffImport,
          saveTariffImport: _vm.saveTariffImport,
          saveTariffId: _vm.saveTariffId,
          deleteTariffImport: _vm.deleteTariffImport
        }
      }),
      _c("CompanyCreateDialog", {
        attrs: { isOpen: _vm.companyCreateDialog },
        on: {
          close: function($event) {
            _vm.companyCreateDialog = false
          },
          create: _vm.createCompany
        }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackbarColor },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }