<template>
  <v-select
    :value="updatedSearchParams"
    :items="Object.values(transportEventTypesLtx)"
    :label="this.$t('invoicing.transportEventTypes.title')"
    @input="$emit('update:updatedSearchParams', $event)"
    multiple
    clearable
  >
    <template v-slot:selection="{ item }">
      <v-chip :color="itemChipColor(item)">
        {{ $t(transportEventTypeKeyToTranslationText(item)) }}
      </v-chip>
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              {{ $t(transportEventTypeKeyToTranslationText(item)) }}
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox :input-value="active"></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { TransportEventTypeMixin } from "@/models/TransportEventTypes";
export default {
    props: {
        updatedSearchParams: Array
    },
    emits: [ "update:updatedSearchParams" ],
    mixins: [ TransportEventTypeMixin ],
    computed: {
        transportEventTypesLtx() {
            return [
                this.transportEventType.Likuma,
                this.transportEventType.Receipt,
                this.transportEventType.Keusote,
                this.transportEventType.TaxiPlus
            ];
        }
    }
};
</script>
