<template>
  <mapbox-map
    access-token="pk.eyJ1IjoidHNhYXJpIiwiYSI6ImNraWltenkwNzBheGczNHFwZXEyeXdndXMifQ.c0ho56I2hpOVxTXgg2a6hg"
    map-style="mapbox://styles/mapbox/streets-v11"
    :center="pointToMapLocation(center)"
    :zoom="zoom"
    :doubleClickZoom="doubleClickZoom"
    @dblclick="onDoubleClick">
    <mapbox-marker
      v-for="(v, index) in vehicles"
      :key="'v' + index"
      :lng-lat="vehicleToMapLocation(v)">
        <v-icon :color="getVehicleStatusColor(v)">mdi-taxi</v-icon>
    </mapbox-marker>
    <template v-for="polygon in polygons">
    <mapbox-marker
      v-for="(p, index) in polygon.points"
      :key="'p' + index"
      :lng-lat="pointToLonLatArray(p)">
        <v-icon :color="blue">mdi-map-marker</v-icon>
    </mapbox-marker>
    </template>
  </mapbox-map>
</template>

<script>
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapboxMap, MapboxMarker } from "@studiometa/vue-mapbox-gl";

const GeoRepository = RepositoryFactory.get("geo");

export default {
    components: {
        MapboxMap,
        MapboxMarker
    },
    props: {
        doubleClickZoom: {
            type: Boolean,
            default: true
        },
        showZones: {
            type: Boolean,
            default: false
        },
        showStations: {
            type: Boolean,
            default: false
        },
        cluster: {
            type: Boolean,
            default: true
        },
        gridSize: {
            type: Number,
            default: 40
        },
        zoom: {
            type: Number,
            default: 9
        },
        center: {
            type: Object,
            default: () => {
                return { latitude: 60.326692, longitude: 24.840489 };
            }
        },
        markers: {
            type: Array,
            default: () => {
                return [];
            }
        },
        polylines: {
            type: Array,
            default: () => {
                return [];
            }
        },
        polygons: {
            type: Array,
            default: () => {
                return [];
            }
        },
        routes: {
            type: Array,
            default: () => {
                return [];
            }
        },
        isochrones: {
            type: Array,
            default: () => {
                return [];
            }
        },
        locations: {
            type: Array,
            default: () => {
                return [];
            }
        },
        vehicles: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            routeColor: "#008080",
            isochroneColor: "#800080",
            zoneLayer: null,
            stationLayer: null
        };
    },
    watch: {
        showZones: function(show) {
            this.toggleZones(show);
        },
        showStations: function(show) {
            this.toggleStations(show);
        }
    },
    methods: {
        onDoubleClick: function(event) {
            this.$emit("dblclick", { latitude: event.lngLat.lat, longitude: event.lngLat.lng });
        },
        onVehicleClicked: function(vehicle) {
            this.$emit("vehicleClicked", vehicle);
        },
        routeToPolyline: function(route) {
            return route.points.map(p => {
                return {
                    lat: p.lat,
                    lng: p.lng };
            });
        },
        isochroneToPolygon: function(isochrone) {
            return isochrone.points.map(p => {
                return {
                    lat: p.lat,
                    lng: p.lng };
            });
        },
        locationToMapLocation: function(location) {
            return this.pointToMapLocation(location.point);
        },
        pointToMapLocation: function(point) {
            return {
                lat: point.latitude,
                lng: point.longitude };
        },
        toLonLatArray: function(point) {
            return [point.lng, point.lat];
        },
        pointToLonLatArray: function(point) {
            const mapPoint = this.pointToMapLocation(point);
            return this.toLonLatArray(mapPoint);
        },
        vehicleToMapLocation: function(vehicle) {
            const mapPoint = this.pointToMapLocation(vehicle.location.point);
            return this.toLonLatArray(mapPoint);
        },
        getVehicleStatusColor: function(vehicle) {
            if (vehicle && vehicle.car) {
                switch (vehicle.car.state) {
                case "Free":
                    return "green";
                case "Occupied":
                    return "yellow";
                case "NoOffers":
                    return "red";
                case "SoonFree":
                    return "blue";
                default: return "black";
                }
            }
            return "black";
        },
        toggleZones: function(show) {
            let vm = this;
            if (show && !vm.zoneLayer) {
                GeoRepository.getZoneGeoJson().then((response) => {
                    this.$refs.map.$mapPromise.then((map) => {
                        // eslint-disable-next-line no-undef
                        vm.zoneLayer = new google.maps.Data({ map: map });
                        vm.zoneLayer.addGeoJson(JSON.parse(response.data));
                        vm.zoneLayer.setStyle({
                            strokeColor: "grey",
                            strokeWeight: 1,
                            fillOpacity: 0.0
                        });
                    });
                });
            } else if (show) {
                this.$refs.map.$mapPromise.then((map) => {
                    vm.zoneLayer.setMap(map);
                });
            } else {
                this.$refs.map.$mapPromise.then((map) => {
                    vm.zoneLayer.setMap();
                });
            }
        },
        toggleStations: function(show) {
            let vm = this;
            if (show && !vm.stationLayer) {
                GeoRepository.getStationGeoJson().then((response) => {
                    this.$refs.map.$mapPromise.then((map) => {
                        // eslint-disable-next-line no-undef
                        vm.stationLayer = new google.maps.Data({ map: map });
                        vm.stationLayer.addGeoJson(JSON.parse(response.data));
                        vm.stationLayer.setStyle({
                            strokeColor: "yellow",
                            strokeWeight: 1,
                            fillOpacity: 0.0
                        });
                    });
                });
            } else if (show) {
                this.$refs.map.$mapPromise.then((map) => {
                    vm.stationLayer.setMap(map);
                });
            } else {
                this.$refs.map.$mapPromise.then((map) => {
                    vm.stationLayer.setMap();
                });
            }
        }
    },
    created() {
    },
    mounted() {
        if (this.showZones) {
            this.toggleZones(true);
        }

        if (this.showStations) {
            this.toggleStations(true);
        }
    }
};
</script>

<style>
  .mapboxgl-map {
      width: 100%;
      height: 100%;
  }
</style>
