export const LikumaValidationErrors = {
    "A": "Vat error",
    "F": "Certificate unknown, missing",
    "certificat": "Certificate missing",
    "U": "Unknown card, might be customer of different community",
    "C": "Card on black list",
    "N": "No travel right",
    "V": "Decision not valid",
    "M": "Customer moved",
    "D": "Customer dead",
    "R": "Receipt number",
    "X": "Customer does not use card",
    "T": "Duplicate Transaction",
    "H": "Transaction is already invoked",
    "E": "Mandatory information missing",
    "P": "Error in service extra",
    "I": "Handicap extra error",
    "L": "Taking both handicap and service extra",
    "B": "Stair help extra error",
    "S": "Sum too large",
    "G": "Too many kilometers",
    "O": "Wait time too long",
    "Q": "Unkown operator",
    "Y": "Test/training card",
    "Z": "Unknown error",
    _: "Unknown likuma fail reason"
};

export const InternalValidationErrors = {
    "different_": "Different person card used"
};

export const KelaValidationErrors = {
    "missing_kuntakoodi": "Kuntakoodi puuttuu",
    "invalid_licence_number": "Virheellinen rekisteritunnus",
    "customer_not_eligble": "Suorakorvausoikeus puuttuu",
    "missing_tariffs": "Taksat puuttuvat",
    "tariffs_missing": "Taksat puuttuvat",
    "kela_rejected_event": "Kela hylkäsi tapahtuman",
    "invalid_calculations": "Summat eivät täsmää",
    "negative_reimbursement": "Negatiivinen korvaus",
    "missing_distance": "Matkan pituus puuttuu",
    "invalid_route_number": "Virheellinen reittitunnus",
    "extra_fee_not_allowed": "Lisämaksu ei sallittu",
    "invalid_fare_type": "Virheellinen taksatyyppi",
    "multiple_tariff_fees": "Useita taksamaksuja",
    "invalid_extra_fee": "Virheellinen lisämaksu",
    "kela_returned_event": "Kela palautti tapahtuman",
    "invalid_tariff_category": "Virheellinen taksaluokka",
    "receipt_number_not_numeric": "Virheellinen kuittinumero",
    "multiple_wait_fees": "Useita odotusmaksuja",
    "missing_cost": "Summa puuttuu",
    "invalid_ssn": "Virheellinen henkilötunnus",
    "trip_count_mismatch": "Reitin matkojen määrä ei täsmää",
    "route_contains_invalid": "Reitti sisältää virheellisiä matkoja"
};

export const TranportEventValidationErrorMixin = {
    data() {
        return {
            likumaValidationErrors: LikumaValidationErrors,
            kelaValidationErrors: KelaValidationErrors
        };
    },
    filters: {
        validationFailReasonToText: function(value) {
            if (value in LikumaValidationErrors) { return LikumaValidationErrors[value]; }
            if (value in KelaValidationErrors) { return KelaValidationErrors[value]; }
            if (value in InternalValidationErrors) { return InternalValidationErrors[value]; }
            return value;
        }
    }
};
