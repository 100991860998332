export const DispatchState = {
    Unknown: 0,
    Off: 1,
    Service: 2,
    Private: 3,
    Free: 4,
    OrderAvailable: 5,
    OrderAccepted: 6,
    OrderAcceptedOnTariff: 7,
    OrderAcceptedOnPay: 8,
    AtCustomer: 9,
    AtCustomerOnTariff: 10,
    AtCustomerOnPay: 11,
    OnTariff: 12,
    OnPay: 13,
    PrintReceipt: 14,
    BalancePaid: 15
};

export function DispatchStateToString(dispatchState) {
    switch (dispatchState) {
    case DispatchState.Unknown:
        return "-";
    case DispatchState.Off:
        return "Off";
    case DispatchState.Service:
        return "Service";
    case DispatchState.Private:
        return "Private";
    case DispatchState.Free:
        return "Free";
    case DispatchState.OrderAvailable:
        return "Order Available";
    case DispatchState.OrderAccepted:
        return "Order Accepted";
    case DispatchState.OrderAcceptedOnTariff:
        return "Order Accepted On Tariff";
    case DispatchState.OrderAcceptedOnPay:
        return "Order Accepted On Pay";
    case DispatchState.AtCustomer:
        return "At Customer";
    case DispatchState.AtCustomerOnTariff:
        return "At Customer On Tariff";
    case DispatchState.AtCustomerOnPay:
        return "At Customer On Pay";
    case DispatchState.OnTariff:
        return "On Tariff";
    case DispatchState.OnPay:
        return "On Pay";
    case DispatchState.PrintReceipt:
        return "Print Receipt";
    case DispatchState.BalancePaid:
        return "Balance Paid";
    }
};
