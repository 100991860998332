import ValidationsRepository from "./validationsRepository";
import PricingRepository from "./pricingRepository";
import PaymentRepository from "./paymentRepository";
import InvoicingRepository from "./invoicingRepository";
import GeoRepository from "./geoRepository";
import GatewayRepository from "./gatewayRepository";
import VehicleRepository from "./vehicleRepository";
import CompanyRepository from "./companyRepository";
import FleetRepository from "./fleetRepository";
import DriverRepository from "./driverRepository";
import BookingRepository from "./bookingRepository";
import DispatchingRepository from "./dispatchingRepository";
import TripRepository from "./tripRepository";
import InvoiceRuleRepository from "./InvoiceRuleRepository";
import UserManagementRepository from "./userManagementRepository";
import AvailabilityRepository from "./availabilityRepository";

const repositories = {
    validations: ValidationsRepository,
    pricing: PricingRepository,
    payment: PaymentRepository,
    invoicing: InvoicingRepository,
    invoiceRule: InvoiceRuleRepository,
    geo: GeoRepository,
    vehicle: VehicleRepository,
    company: CompanyRepository,
    fleet: FleetRepository,
    driver: DriverRepository,
    userManagement: UserManagementRepository,
    availability: AvailabilityRepository,
    gateway: GatewayRepository,
    booking: BookingRepository,
    dispatching: DispatchingRepository,
    trip: TripRepository
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
