<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="headline">{{this.$t('invoicing.purchasing.summary.title')}}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="invoiceCount"
                                :label="this.$t('invoicing.purchasing.summary.invoiceCount')"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                v-model="transportEventCount"
                                :label="this.$t('invoicing.purchasing.summary.transportEventCount')"
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                            :value="reimbursementSum | formatCurrency"
                            :label="this.$t('invoicing.purchasing.summary.reimbursementSum')"
                            readonly
                            ></v-text-field>
                        </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="primary" @click="sendToPurchasing">{{$t('invoicing.purchasing.sendToPurchasing')}}</v-btn>
                <v-btn color="error" text @click="dialog = false">{{this.$t('common.close')}}</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn text @click="snackbar = false">
          {{$t("common.close")}}
        </v-btn>
      </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        invoiceCount: 0,
        transportEventCount: 0,
        reimbursementSum: 0,
        snackbar: false,
        snackbarText: "",
        snackbarColor: "",
        eventType: ""
    }),
    methods: {
        openDialog(invoiceCount, transportEventCount, reimbursementSum, eventType) {
            this.invoiceCount = invoiceCount;
            this.transportEventCount = transportEventCount;
            this.reimbursementSum = reimbursementSum;
            this.eventType = eventType;
            this.dialog = true;
        },
        async sendToPurchasing() {
            if (this.eventType === "Likuma") {
                this.$store
                    .dispatch("invoicing/createLikumaPurchaseInvoices")
                    .catch(error => {
                        this.snackbarText = error;
                        this.snackbarColor = "error";
                        this.snackbar = true;
                    });
            }
            if (this.eventType === "Korttimaksu") {
                this.$store
                    .dispatch("invoicing/createEmvPurchaseInvoices")
                    .catch(error => {
                        this.snackbarText = error;
                        this.snackbarColor = "error";
                        this.snackbar = true;
                    });
            }
            if (this.eventType === "Taksini") {
                this.$store
                    .dispatch("invoicing/createTaksiniPurchaseInvoices")
                    .catch(error => {
                        this.snackbarText = error;
                        this.snackbarColor = "error";
                        this.snackbar = true;
                    });
            }
            this.dialog = false;
        }
    }
};
</script>
