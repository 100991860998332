<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title class="headline">{{this.$t('invoicing.sales.summary.title')}}</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-row>
                          <v-col v-if="fromDate" cols="6">
                            <v-text-field
                              v-model="fromDate"
                              label="Alkaen"
                              type="date"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="toDate" cols="6">
                            <v-text-field
                              v-model="toDate"
                              label="Asti"
                              type="date"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                          <v-text-field
                              v-model="invoiceCount"
                              :label="this.$t('invoicing.sales.summary.invoiceCount')"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                          <v-text-field
                              v-model="transportEventCount"
                              :label="this.$t('invoicing.sales.summary.transportEventCount')"
                              readonly
                          ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                          <v-text-field
                          :value="totalSum | formatCurrency"
                          :label="this.$t('invoicing.sales.summary.reimbursementSum')"
                          readonly
                          ></v-text-field>
                      </v-col>
                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="primary" @click="sendToPurchasing">{{$t('invoicing.sales.sendToSales')}}</v-btn>
                <v-btn color="error" text @click="dialog = false">{{this.$t('common.close')}}</v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <v-btn text @click="snackbar = false">
          {{$t("common.close")}}
        </v-btn>
      </v-snackbar>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        invoiceCount: 0,
        costCenters: [],
        transportEventCount: 0,
        totalSum: 0,
        snackbar: false,
        snackbarText: "",
        snackbarColor: "",
        eventType: ""
    }),
    computed: {
        fromDate() {
            return this.$store.state.createSalesInvoices.fromDate;
        },
        toDate() {
            return this.$store.state.createSalesInvoices.toDate;
        }
    },
    methods: {
        openDialog(rows, eventType) {
            this.invoiceCount = rows.length;
            this.costCenters = rows.map(r => r.costCenter);
            this.transportEventCount = rows.map(r => r.transportEventCount).reduce(this.add, 0);
            this.totalSum = rows.map(r => r.totalSum).reduce(this.add, 0);
            this.eventType = eventType;
            this.dialog = true;
        },
        add(accumulator, a) {
            return accumulator + a;
        },
        async sendToPurchasing() {
            if (this.eventType === "CostCenter") {
                this.$store
                    .dispatch("createSalesInvoices/createCostCenterSalesInvoices", this.costCenters)
                    .catch(error => {
                        this.snackbarText = error;
                        this.snackbarColor = "error";
                        this.snackbar = true;
                    });
                this.dialog = false;
            }
        }
    }
};
</script>
