
import moment from "moment";

let requiredRule = v => (!!v || v === 0) || "Value is required";
let enumValueRequiredRule = v => !!v || "Value is required";
let digitRule = v => v === null || v === "" || /^\d+$/.test(v) || "Value must contain only digits";
let decimalRule = v => /^-?\d+((\.|,)\d+)?$/.test(v) || "Value must be decimal value";
let dateTimeRule = v => isValidDateTime(v) || "Value is not valid datetime";
let emailRule = v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail must be valid";
let phoneNumberRule = v => !v || /^\d{5,11}$/.test(v) || "Phone number must be valid. It must contain 5-11 digits";
let nonZeroValueRequiredRule = v => v !== 0 || "Value is required";

let isValidDateTime = (value) => moment(value).isValid();

export default {
    data() {
        return {
            requiredRule: requiredRule,
            enumValueRequiredRule: enumValueRequiredRule,
            digitRule: digitRule,
            decimalRule: decimalRule,
            dateTimeRule: dateTimeRule,
            emailRule: emailRule,
            phoneNumberRule: phoneNumberRule,
            nonZeroValueRequiredRule: nonZeroValueRequiredRule
        };
    },
    methods: {
        maxLengthRule(maxLength) {
            return v => (v || "").toString().length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters`;
        },
        maxLengthWithoutDecimalSeparatorRule(maxLength) {
            return v => (v || "").toString().replace(".", "").replace(",", "").length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters without decimal point`;
        },
        maxLengthWithoutDashRule(maxLength) {
            return v => (v || "").toString().replace("-", "").length <= maxLength ||
                `Value cannot be longer than ${maxLength} characters without a dash`;
        },
        valueInRangeRule(min, max) {
            return v => (!isNaN(v) && v <= max && v >= min) ||
                `Value must be in range from ${min} to ${max}`;
        }
    }
};
