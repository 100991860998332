export default {
    provide() {
        return {
            $triggerEvent: () => this.$triggerEvent
        };
    },
    computed: {
        $triggerEvent() {
            return this.triggerableEvent;
        }
    },
    data() {
        return {
            triggerableEvent: { success: false }
        };
    },
    methods: {
        triggerSuccessEvent(eventTargetId) {
            this.triggerableEvent = { success: true, eventTargetId };
        },
        triggerErrorEvent(error, eventTargetId) {
            const validationErrors = error?.response?.data?.ValidationErrors;
            const message = error;
            this.triggerableEvent = { success: false, message, validationErrors, eventTargetId };
        }
    }
};
