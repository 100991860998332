<template>
  <v-form>
    <v-text-field
      v-model="street"
      :error-messages="addressErrors"
      label="Address"
      required
      :disabled="validated"
      :prepend-inner-icon="validated ? 'done' : null"
      @input="$v.street.$touch()"
      @blur="$v.street.$touch()"
    ></v-text-field>

    <v-text-field
      v-model="streetNumber"
      :error-messages="streetNumberErrors"
      label="Street Number"
      hint="eg. [1-9]|10"
      persistent-hint
      required
      :disabled="validated"
      :prepend-inner-icon="validated ? 'done' : null"
      @input="$v.streetNumber.$touch()"
      @blur="$v.streetNumber.$touch()"
    ></v-text-field>

    <v-text-field
      v-model="city"
      :error-messages="cityErrors"
      label="City"
      required
      :disabled="validated"
      :prepend-inner-icon="validated ? 'done' : null"
      @input="$v.city.$touch()"
      @blur="$v.city.$touch()"
    ></v-text-field>

    <v-btn :disabled="this.$v.$invalid || this.validated" color="success" class="mr-4" @click="validate">Validate / Confirm</v-btn>
    <v-btn color="red" :disabled="!this.validated" text @click="cancel">Cancel</v-btn>
  </v-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],

    validations: {
        street: { required },
        streetNumber: {
            required,
            streetNumberFormat(streetNumber) {
                try {
                    // We just check if its valid.
                    // eslint-disable-next-line
                    new RegExp(streetNumber);
                    return true;
                } catch (e) {
                    return false;
                }
            }
        },
        city: { required }
    },
    data: () => ({
        street: "",
        city: "",
        streetNumber: "",
        validated: false
    }),
    methods: {
        cancel() {
            this.$v.$reset();
            this.street = "";
            this.city = "";
            this.streetNumber = "";
            this.validated = false;
        },
        validate() {
            this.validated = true;
            this.$emit("locationInfoUpdated", {
                street: this.street,
                city: this.city,
                streetNumber: this.streetNumber
            });
        }
    },
    computed: {
        addressErrors() {
            const errors = [];
            if (!this.$v.street.$dirty) return errors;

            !this.$v.street.required && errors.push("Address is required.");
            return errors;
        },
        streetNumberErrors() {
            const errors = [];
            if (!this.$v.streetNumber.$dirty) return errors;

            !this.$v.streetNumber.required && errors.push("StreetNumber is required.");
            !this.$v.streetNumber.streetNumberFormat && errors.push("StreetNumber needs to be regex.");
            return errors;
        },
        cityErrors() {
            const errors = [];
            if (!this.$v.city.$dirty) return errors;

            !this.$v.city.required && errors.push("City is required.");
            return errors;
        }
    }

};
</script>

<style>

</style>
