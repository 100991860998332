var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                { key: _vm.refreshValidation, ref: "form" },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              value: _vm.attributeOriginToString(
                                _vm.editableAttribute.origin
                              ),
                              label: "Origin",
                              disabled: true
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.attributeTypes,
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor("type"),
                              label: "Type"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.attributeTypeToString(item)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.attributeTypeToString(item)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.editableAttribute.type,
                              callback: function($$v) {
                                _vm.$set(_vm.editableAttribute, "type", $$v)
                              },
                              expression: "editableAttribute.type"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.attributeContentTypes,
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor(
                                "contentType"
                              ),
                              label: "Content Type"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.attributeContentTypeToString(item)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              },
                              {
                                key: "item",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.attributeContentTypeToString(item)
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ]),
                            model: {
                              value: _vm.editableAttribute.contentType,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.editableAttribute,
                                  "contentType",
                                  $$v
                                )
                              },
                              expression: "editableAttribute.contentType"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Id",
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor("id")
                            },
                            model: {
                              value: _vm.editableAttribute.id,
                              callback: function($$v) {
                                _vm.$set(_vm.editableAttribute, "id", $$v)
                              },
                              expression: "editableAttribute.id"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Name",
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor("name")
                            },
                            model: {
                              value: _vm.editableAttribute.name,
                              callback: function($$v) {
                                _vm.$set(_vm.editableAttribute, "name", $$v)
                              },
                              expression: "editableAttribute.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Value",
                              rules: [_vm.requiredRule],
                              "error-messages": _vm.validationErrorsFor("value")
                            },
                            model: {
                              value: _vm.editableAttribute.value,
                              callback: function($$v) {
                                _vm.$set(_vm.editableAttribute, "value", $$v)
                              },
                              expression: "editableAttribute.value"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _vm.isNew
                ? _c("v-btn", { on: { click: _vm.addNewAttribute } }, [
                    _vm._v("Add")
                  ])
                : _vm._e(),
              !_vm.isNew
                ? _c("v-btn", { on: { click: _vm.saveAttribute } }, [
                    _vm._v("Save")
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }