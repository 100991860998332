<template>
  <AttributesTable
    :attributes="editableFleet.attributes"
    :editableAttributeOrigin="editableAttributeOrigin"
    @addNewAttribute="addNewAttribute"
    @saveAttribute="saveAttribute"
    @deleteAttribute="deleteAttribute">
  </AttributesTable>
</template>

<script>
import AttributesTable from "../../Shared/AttributesTable";
import { AttributeOrigin } from "../../../models/AttributeOrigin";
import _ from "lodash";

export default {
    components: { AttributesTable },
    props: {
        fleet: {
            type: Object,
            default: function() {
                return null;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            editableFleet: _.cloneDeep(this.fleet),
            editableAttributeOrigin: AttributeOrigin.RG
        };
    },
    watch: {
        fleet(val) {
            this.editableFleet = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        addNewAttribute: function(newAttribute, eventTargetId) {
            this.$emit("addNewAttribute", newAttribute, eventTargetId);
        },
        saveAttribute: function(attribute, eventTargetId) {
            this.$emit("saveAttribute", attribute, eventTargetId);
        },
        deleteAttribute: function(attribute, eventTargetId) {
            this.$emit("deleteAttribute", attribute, eventTargetId);
        }
    }
};
</script>
