<template>
    <v-row no-gutters>
        <v-col cols="auto" class="pr-2">
            <v-text-field pr-1 :label="$t('keyValueInput.key')" v-model="key"></v-text-field>
        </v-col>
        <v-col cols="auto">
            <v-text-field :label="$t('keyValueInput.value')" v-model="value"></v-text-field>
        </v-col>
        <v-col cols=2 v-if="!isEmpty">
            <v-btn class="mx-2" fab dark color="indigo" @click="createKeyValuePair">
                <v-icon dark>mdi-check</v-icon>
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        key: "",
        value: ""
    }),
    methods: {
        createKeyValuePair() {
            this.$emit("keyValueAdded", this.composite);
            this.key = "";
            this.value = "";
        }
    },
    computed: {
        composite() {
            let obj = {
                key: this.key,
                value: this.value
            };
            return obj;
        },
        isEmpty() {
            return this.key === "" || this.value === "";
        }
    }
};
</script>
