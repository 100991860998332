import axios from "axios";
import { Util } from "@microsoft/applicationinsights-web";
import AuthService from "../../msal";
import store from "../../store";

const validationUrl = process.env.VUE_APP_VALIDATIONSERVICE_URL;
const pricingUrl = process.env.VUE_APP_PRICINGSERVICE_URL;
const paymentUrl = process.env.VUE_APP_PAYMENTSERVICE_URL;
const invoicingUrl = process.env.VUE_APP_INVOICESERVICE_URL;
const geoUrl = process.env.VUE_APP_GEOSERVICE_URL;
const vehicleUrl = process.env.VUE_APP_VEHICLESERVICE_URL;
const gatewayUrl = process.env.VUE_APP_GATEWAYSERVICE_URL;
const bookingUrl = process.env.VUE_APP_BOOKINGSERVICE_URL;
const dispatchingUrl = process.env.VUE_APP_DISPATCHINGSERVICE_URL;
const userManagementUrl = process.env.VUE_APP_USERMANAGEMENTSERVICE_URL;
const availabilityUrl = process.env.VUE_APP_AVAILABITYSERVICE_URL;

const authService = new AuthService();

async function getAccessToken() {
    const token = await authService.getToken(authService.applicationConfig.backendScopes);
    return token.accessToken;
}

let defaultConfig = async(config) => {
    config.headers["Request-Id"] = Util.generateW3CId();
    config.headers.Authorization = `Bearer ${await getAccessToken()}`;
    config.headers["RG-TenantId"] = store.getters["user/tenantId"];
    return config;
};

let defaultErrorHandler = (error) => {
    return Promise.reject(error);
};

var validationService = axios.create({ baseURL: validationUrl });
validationService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var pricingService = axios.create({ baseURL: pricingUrl });
pricingService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var paymentService = axios.create({ baseURL: paymentUrl });
paymentService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var invoicingService = axios.create({ baseURL: invoicingUrl });
invoicingService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var invoiceRuleService = axios.create({ baseURL: invoicingUrl });
invoiceRuleService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var geoService = axios.create({ baseURL: geoUrl });
geoService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var vehicleService = axios.create({ baseURL: vehicleUrl });
vehicleService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var gatewayService = axios.create({ baseURL: gatewayUrl });
gatewayService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var companyService = axios.create({ baseURL: vehicleUrl });
companyService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var fleetService = axios.create({ baseURL: vehicleUrl });
fleetService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var driverService = axios.create({ baseURL: vehicleUrl });
driverService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var bookingService = axios.create({ baseURL: bookingUrl });
bookingService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var tripService = axios.create({ baseURL: bookingUrl });
tripService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var dispatchingService = axios.create({ baseURL: dispatchingUrl });
dispatchingService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var userManagementService = axios.create({ baseURL: userManagementUrl });
userManagementService.interceptors.request.use(defaultConfig, defaultErrorHandler);

var availabilityService = axios.create({ baseURL: availabilityUrl });

export {
    validationService,
    pricingService,
    paymentService,
    invoicingService,
    invoiceRuleService,
    geoService,
    vehicleService,
    gatewayService,
    companyService,
    fleetService,
    driverService,
    bookingService,
    tripService,
    dispatchingService,
    userManagementService,
    availabilityService
};
