export const FleetStatus = {
    Unknown: 0,
    Initialized: 1,
    Active: 2,
    InActive: 3,
    Deleted: 4
};

export function FleetStatusToString(fleetStatus) {
    switch (fleetStatus) {
    case FleetStatus.Unknown:
        return "-";
    case FleetStatus.Initialized:
        return "Initialized";
    case FleetStatus.Active:
        return "Active";
    case FleetStatus.InActive:
        return "In Active";
    case FleetStatus.Deleted:
        return "Deleted";
    }
};
