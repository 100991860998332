import { pricingService } from "./repository";

const resource = "v1/pricecatalog";

export default {
    find(operatorId) {
        return pricingService.get(`${resource}`, {
            params: { operatorId }
        });
    },
    create(priceCatalog) {
        return pricingService.post(`${resource}`, priceCatalog);
    },
    update(priceCatalogId, priceCatalog) {
        return pricingService.put(`${resource}/${priceCatalogId}`, priceCatalog);
    }
};
