<template>
  <v-card>
    <v-card-text>
      <TariffIdForm
        :tariffId="tariffId"
        @saveTariffId="saveTariffId"
      >
      </TariffIdForm>
    </v-card-text>
  </v-card>
</template>

<script>
import TariffIdForm from "./Forms/TariffIdForm";
import _ from "lodash";

export default {
    components: { TariffIdForm },
    props: {
        tariffId: {
            type: String,
            default: function() {
                return null;
            }
        },
        result: {
            type: Object,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            triggerableEvent: null
        };
    },
    computed: {
        showableHeaders() {
            return this.headers.filter(h => h.show);
        }
    },
    watch: {
        tariffImports(val) {
            this.editableTariffImports = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        saveTariffId: function(tariffId, eventTargetId) {
            this.$emit("saveTariffId", tariffId, eventTargetId);
        }
    }
};
</script>
