import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedCompanies: [],
    fetchedCompany: {
        tester: 1,
        properties: []
    },
    searchParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null
};

// getters
const getters = {};

// actions
const actions = {
    // Companies
    async searchCompanies({ commit }, { searchParams, top = 100, forceUpdate = false }) {
        if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
            return;
        }
        await RepositoryFactory.get("company").find(searchParams, top)
            .then(response => {
                commit("SET_COMPANIES", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_FETCHED_TIMESTAMP", moment());
            });
    },
    async getCompany({ commit }, id) {
        await RepositoryFactory.get("company").getCompany(id)
            .then(response => {
                commit("SET_COMPANY", response.data.item);
            });
    },
    async loadMoreCompanies({ commit }, { top = 100 }) {
        await RepositoryFactory.get("company").find(
            state.searchParams,
            top,
            Math.floor(state.searchedCompanies.length / top) + 1)
            .then(response => {
                commit("ADD_SEARCHED_COMPANIES", response.data.results);
            });
    },
    async createCompany({ commit }, company) {
        await RepositoryFactory.get("company").create(company)
            .then(() => {
                commit("CREATE_COMPANY", company);
            });
    },
    async activateCompany({ commit }, id) {
        await RepositoryFactory.get("company").activate(id);
    },
    async deleteCompany({ commit }, id) {
        await RepositoryFactory.get("company").delete(id)
            .then(() => {
                commit("REMOVE_COMPANY", id);
            });
    },
    async updateCompanyContactInformation({ commit }, { companyId, contactInformation }) {
        await RepositoryFactory.get("company").patchContactInformation(companyId, contactInformation);
    },
    async updateCompanyInfo({ commit }, { companyId, companyInfo }) {
        await RepositoryFactory.get("company").patchCompanyInfo(companyId, companyInfo);
    },
    // Attributes
    async addNewAttribute({ commit }, { companyId, attribute }) {
        await RepositoryFactory.get("company").postNewAttribute(companyId, { attribute });
    },
    async saveAttribute({ commit }, { companyId, attribute }) {
        await RepositoryFactory.get("company").patchAttribute(companyId, { attribute });
    },
    async deleteAttribute({ commit }, { companyId, attribute }) {
        await RepositoryFactory.get("company").deleteAttribute(companyId, attribute);
    },
    // Properties
    async addNewProperty({ commit }, { companyId, property }) {
        await RepositoryFactory
            .get("company")
            .postNewProperty(companyId, { property })
            .then(() => {
                commit("ADD_COMPANY_ATTRIBUTE", property);
            });
    },
    async saveProperty({ commit }, { companyId, property }) {
        await RepositoryFactory.get("company").patchProperty(companyId, { property });
    },
    async deleteProperty({ commit }, { companyId, property }) {
        await RepositoryFactory.get("company").deleteProperty(companyId, property);
    },
    // Tariffs
    async addNewTariffImport({ commit }, { companyId, tariffImport }) {
        await RepositoryFactory.get("company").postNewTariffImport(companyId, tariffImport);
    },
    async saveTariffImport({ commit }, { companyId, tariffImport }) {
        await RepositoryFactory.get("company").patchTariffImport(companyId, tariffImport);
    },
    async saveTariffId({ commit }, { companyId, tariffId }) {
        await RepositoryFactory.get("company").patchTariffId(companyId, tariffId);
    },
    async deleteTariffImport({ commit }, { companyId, tariffImport }) {
        await RepositoryFactory.get("company").deleteTariffImport(companyId, tariffImport);
    }
};

// mutations
const mutations = {
    SET_COMPANIES(state, companies) {
        state.searchedCompanies = companies;
    },
    SET_COMPANY(state, company) {
        state.fetchedCompany = company;
    },
    ADD_SEARCHED_COMPANIES(state, companies) {
        state.searchedCompanies = state.searchedCompanies.concat(companies);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    },
    ADD_COMPANY(state, company) {
        state.searchedCompanies.push(company);
    },
    UPDATE_COMPANY(state, company) {
        state.searchedCompanies = [
            ...state.searchedCompanies.map(
                item => item.id !== company.id ? item : { ...item, ...company })
        ];
    },
    REMOVE_COMPANY(state, id) {
        let index = state.searchedCompanies.findIndex(te => te.id === id);
        state.searchedCompanies.splice(index, 1);
    },
    ADD_COMPANY_ATTRIBUTE(state, property) {
        state.fetchedCompany.tester += 1;
        state.fetchedCompany.properties.push(property);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
