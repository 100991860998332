import { RepositoryFactory } from "@/data/repositories/repositoryFactory";

// initial state
const state = {
    allPaytrailTransactions: []
};

// getters
const getters = {
};

// actions
const actions = {
    async getAllPaytrailTransactions({ commit }) {
        await RepositoryFactory.get("invoicing").getAllPaytrailTransactions()
            .then(response => {
                commit("SET_PAYTRAIL_TRANSACTIONS", response.data);
            });
    }
};

// mutations
const mutations = {
    SET_PAYTRAIL_TRANSACTIONS(state, events) {
        state.allPaytrailTransactions = events;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
