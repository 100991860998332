import { validationService } from "./repository";
import moment from "moment";

const resource = "/v1/management";

export default {
    find() {
        return validationService.get(`${resource}`);
    },

    get(validationId) {
        return validationService.get(`${resource}/${validationId}`);
    },

    create(payload) {
    // create new Guid for validation.
        const validationId = generateGuidV4();

        payload.id = validationId;

        return validationService.post(`${resource}`, payload);
    },

    test(validationId, payload) {
    },

    update(validationId, payload) {
        return validationService.put(`${resource}/${validationId}`, payload);
    },

    delete(validationId) {
        return validationService.delete(`${resource}/${validationId}`);
    },

    getSampleOrder() {
        return order;
    },

    testValidation(order, experimentalOnly = false) {
        return validationService.post(`${resource}/validate?isExperimental=true&isActive=${!experimentalOnly}`, order);
    }
};

function generateGuidV4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

let order = `{
    "data": {
        "preBooked": false,
        "waypoints": [
            {
                "requestedTime": "${moment().format()}",
                "type": "pick",
                "rider": {
                    "name": "Hhghh Hh",
                    "phone": "358408327936"
                },
                "location": {
                    "street": "Alvar Aallonkatu",
                    "streetNumber": "2",
                    "door": null,
                    "city": "Helsinki",
                    "zipcode": "00130",
                    "country": "Finland",
                    "alias": null,
                    "lat": 60.176090,
                    "lng": 24.9382601,
                    "info": null
                }
            },
            {
                "type": "drop",
                "rider": {
                    "name": "Hhghh Hh",
                    "phone": "358408327936"
                },
                "location": {
                    "street": "Lentoasemantie",
                    "streetNumber": "1",
                    "door": null,
                    "city": "Vantaa",
                    "zipcode": "02150",
                    "country": "Finland",
                    "alias": null,
                    "lat": 60.312971,
                    "lng": 24.973106,
                    "info": null
                }
            }
        ],
        "capacity": [
            {
                "unitId": 1,
                "count": 4
            }
        ],
        "attributes": [
            {
                "id": 1619,
                "name": "minibus"
            },
            {
                "id": 1607,
                "name": "premium"
            }
        ],
        "externalId": "0a53643d-2a2f-4fad-a769-953fe581c05c",
        "customer": {
            "id": "153a13d8-44ad-4ecb-94b5-1350d3e93f52",
            "name": "Acme Ltd 3"
        },
        "fleet": {
            "id": "LTX",
            "name": "Lahitaksi"
        },
        "operator": {
            "id": "Whim",
            "name": "Taksini"
        },
        "id": "376613f2-7ac9-4eec-8c73-7406e6bebed5",
        "createdAt": "2019-08-06T14:22:00+03:00",
        "updatedAt": "2019-08-01"
    }
}`;
