var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "missingVerifoneEvents" } },
    [
      _c(
        "v-card",
        { staticClass: "mt-1" },
        [
          _c(
            "div",
            { staticClass: "elevation-1 mb-1 pb-2" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("invoicing.verifone.missing.title")
                              ) +
                              " "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-archive-search-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.handlingTypes,
                          multiple: "",
                          label: _vm.$t(
                            "invoicing.verifone.missing.handlingStatus"
                          )
                        },
                        model: {
                          value: _vm.selectedHandlingTypes,
                          callback: function($$v) {
                            _vm.selectedHandlingTypes = $$v
                          },
                          expression: "selectedHandlingTypes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c("v-text-field", {
                            staticClass: "pt-0 mt-0 mr-2",
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: this.$t("common.filter"),
                              "single-line": "",
                              "hide-details": ""
                            },
                            model: {
                              value: _vm.filter,
                              callback: function($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("CreateEventsFromMissingDialog", {
            ref: "createEventsDialog",
            on: {
              refreshTableData: function($event) {
                return _vm.refreshData()
              },
              closed: function($event) {
                _vm.createEventsDialog = false
              }
            },
            model: {
              value: _vm.createEventsDialog,
              callback: function($$v) {
                _vm.createEventsDialog = $$v
              },
              expression: "createEventsDialog"
            }
          }),
          _c(
            "v-skeleton-loader",
            {
              attrs: {
                loading: _vm.loading,
                "transition-group": "scale-transition",
                type: "table",
                name: "missingVerifoneEventsTable"
              }
            },
            [
              _c("v-data-table", {
                attrs: {
                  "calculate-widths": "",
                  headers: _vm.headers,
                  items: _vm.indexedMissingVerifoneEvents,
                  "item-key": "id",
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  search: _vm.filter,
                  "show-select": "",
                  loading: _vm.loadingPage,
                  "footer-props": _vm.footerProps,
                  "items-per-page": _vm.itemsPerPage
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.amount",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                item.transactionType === "REFUND"
                                  ? item.amount * -1
                                  : item.amount
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.transactionTimeStamp",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatDateTime")(
                                item.transactionTimeStamp
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.handledAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatDateTime")(item.handledAt)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.handled",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: _vm._f(
                                                "handlingStatusToColor"
                                              )(value)
                                            }
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("handlingStatusToIcon")(
                                                value
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("handlingStatusToToolTipText")(value)
                                )
                              )
                            ])
                          ]
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedMissingVerifoneEvents,
                  callback: function($$v) {
                    _vm.selectedMissingVerifoneEvents = $$v
                  },
                  expression: "selectedMissingVerifoneEvents"
                }
              })
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: {
                        disabled: _vm.selectedMissingVerifoneEvents.length < 1,
                        color: "success"
                      },
                      on: { click: _vm.showCreateEventsDialog }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("invoicing.verifone.missing.createEvents")
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm.selectedMissingVerifoneEvents.length > 0
                ? _c("v-col", [
                    _c(
                      "div",
                      { staticClass: "text-md-body-1 font-weight-bold" },
                      [
                        _vm._v(
                          "Valittujen tapahtumien summa: " +
                            _vm._s(
                              _vm._f("formatCurrency")(_vm.selectedTotalSum)
                            ) +
                            " "
                        ),
                        _vm.selectedMissingVerifoneEvents.some(function(e) {
                          return e.transactionType === "REFUND"
                        })
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "text-md-body-1 font-weight-bold red--text"
                              },
                              [
                                _vm._v(
                                  " Huom! Valittuihin tapahtumiin sisältyy palautuksia. "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbarColor },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }