<template>
    <v-dialog v-model="isOpen" max-width="1000">
      <v-card v-if="this.hasCache()">
        <div v-for="(value, key) in cache" :key="key">
            <v-card-title>
                <span class="subtitle-1">{{ key }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-textarea
                        class="small-text"
                        :value="prettifyJson(value)"
                        outlined
                        auto-grow
                        filled
                        readonly
                    ></v-textarea>
                </v-row>
            </v-card-text>
          </div>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" v-if="$AuthService.hasServiceRole('vehicle', 'edit')"
                @click.stop="$emit('removeFromIndex', vehicleId)"
              >
                <v-icon>mdi-broom</v-icon>
                Remove from index
              </v-btn>
                <v-btn color="warning" v-if="$AuthService.hasServiceRole('gateway', 'edit')"
                @click.stop="$emit('invalidateCache', vehicleId)"
              >
                <v-icon>mdi-broom</v-icon>
                Release cache
              </v-btn>
              <v-btn color="success" v-if="$AuthService.hasServiceRole('gateway', 'edit')"
              @click.stop="$emit('refreshCache', vehicleId)"
            >
              <v-icon>mdi-refresh</v-icon>
              Refresh cache
            </v-btn>
              <v-btn depressed color="primary" @click="$emit('close')">Close</v-btn>
          </v-card-actions>
          </v-card>
        <v-card v-else>
          <v-card-title>
              <span class="headline">No cache</span>
          </v-card-title>
          <v-card-text>
              <v-row>
                  <v-textarea
                      outlined
                      auto-grow
                      filled
                      readonly
                      value="No cache available"
                  ></v-textarea>
              </v-row>
          </v-card-text>
          <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="warning" v-if="$AuthService.hasServiceRole('vehicle', 'edit')"
                @click.stop="$emit('removeFromIndex', vehicleId)"
              >
                <v-icon>mdi-broom</v-icon>
                Remove from index
              </v-btn>
                <v-btn color="warning" v-if="$AuthService.hasServiceRole('gateway', 'edit')"
                @click.stop="$emit('invalidateCache', vehicleId)"
              >
                <v-icon>mdi-broom</v-icon>
                Release cache
              </v-btn>
              <v-btn color="success" v-if="$AuthService.hasServiceRole('gateway', 'edit')"
              @click.stop="$emit('refreshCache', vehicleId)"
            >
              <v-icon>mdi-refresh</v-icon>
              Refresh cache
            </v-btn>
              <v-btn depressed color="primary" @click="$emit('close')">Close</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "VehicleCacheDialog",
    props: {
        cache: {
            type: Object,
            default: function() {
                return null;
            }
        },
        vehicleId: {
            type: String,
            required: true
        },
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    methods:
    {
        hasCache() {
            return this.cache !== null && Object.keys(this.cache).length > 0;
        },
        prettifyJson(json) {
            let cacheObj = JSON.parse(json);
            return JSON.stringify(cacheObj, null, 2);
        }
    }
};
</script>

<style scoped>
  .small-text {
    font-size: 12px;
  }
</style>
