<template>
    <v-form ref="form" @submit.prevent="submitForm">
        <v-row v-for="(opt, key) in invoiceOptions.invoicingOptions" :key="key" class="mx-4">
            <v-col cols="1">
                <v-checkbox v-model="checkedValues[key]" />
            </v-col>
            <v-col cols="11">
                <template v-if="getOptionTypeLabel(opt.type) === 'String'">
                    <v-text-field v-model="opt.value" :label="opt.name" :disabled="!opt.canBeModified"></v-text-field>
                </template>
                <template v-if="getOptionTypeLabel(opt.type) === 'Integer'">
                    <v-text-field v-model="opt.value" :label="opt.name" type="number" :disabled="!opt.canBeModified"></v-text-field>
                </template>
                <template v-if="getOptionTypeLabel(opt.type) === 'Decimal'">
                    <v-text-field v-model="decimalValues[key]" :label="opt.name" type="number" step="0.01" :disabled="!opt.canBeModified"></v-text-field>
                </template>
                <template v-if="getOptionTypeLabel(opt.type) === 'Date'">
                    <v-date-picker v-model="opt.value" :label="opt.name" :disabled="!opt.canBeModified"></v-date-picker>
                </template>
                <template v-if="getOptionTypeLabel(opt.type) === 'Boolean'">
                    <v-switch v-model="booleanValues[key]" :label="opt.name" :disabled="!opt.canBeModified"></v-switch>
                </template>
                <template v-if="getOptionTypeLabel(opt.type) === 'Guid'">
                    <v-text-field v-model="opt.value" :label="opt.name" :disabled="!opt.canBeModified"></v-text-field>
                </template>
                <template v-if="getOptionTypeLabel(opt.type).startsWith('Dictionary')">
                    <v-subheader>{{ opt.name }}</v-subheader>
                    <div v-for="(item, index) in opt.items" :key="index">
                        <v-text-field v-model="item.value" :disabled="!opt.canBeModified">
                            <template v-slot:prepend>
                                <v-chip>{{ formatPercentage(item.key) }}</v-chip>
                            </template>
                        </v-text-field>
                    </div>
                </template>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { InvoiceOptionsTypeMixin } from "../../models/InvoiceOptionsType";

export default {
    mixins: [InvoiceOptionsTypeMixin],
    props: {
        invoiceOptions: {
            type: Object,
            required: true,
            default: () => ({ invoicingOptions: [] })
        }
    },
    data() {
        return {
            booleanValues: {},
            checkedValues: {},
            decimalValues: {},
            dictionaryDecimalValues: {}
        };
    },
    watch: {
        invoiceOptions: {
            handler() {
                this.initializeValues();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        initializeValues() {
            this.booleanValues = {};
            this.checkedValues = {};
            this.decimalValues = {};
            this.dictionaryDecimalValues = {};
            this.invoiceOptions.invoicingOptions.forEach((opt, key) => {
                this.checkedValues[key] = opt.value !== null;
                if (this.getOptionTypeLabel(opt.type) === "Boolean") {
                    this.booleanValues[key] = this.fromBoolean(opt.value);
                } else if (this.getOptionTypeLabel(opt.type) === "Decimal") {
                    this.decimalValues[key] = this.fromDecimal(opt.value);
                } else if (this.getOptionTypeLabel(opt.type).startsWith("Dictionary")) {
                    opt.items = this.parseDictionary(opt.value, opt.type);
                }
            });
        },
        parseDictionary(value, type) {
            const parsed = JSON.parse(value);
            return Object.keys(parsed).map(key => {
                let itemValue = parsed[key];
                if (type === "DictionaryDecimal") {
                    itemValue = this.fromDecimal(itemValue);
                }
                return { key, value: itemValue };
            });
        },
        formatPercentage(value) {
            const formatter = new Intl.NumberFormat("fi-FI", {
                style: "percent",
                minimumFractionDigits: 0,
                maximumFractionDigits: 1
            });
            return formatter.format(value / 10000);
        },
        fromBoolean(value) {
            return value === "true" || value === true;
        },
        fromDecimal(value) {
            return (value / 100).toFixed(2);
        },
        toDecimal(value) {
            return Math.round(parseFloat(value) * 100);
        },
        toDictionary(items, type) {
            const dict = {};
            items.forEach(item => {
                let itemValue = item.value;
                if (type === "DictionaryDecimal") {
                    itemValue = this.toDecimal(itemValue);
                }
                dict[item.key] = itemValue;
            });
            return JSON.stringify(dict);
        },
        submitForm() {
            this.invoiceOptions.invoicingOptions.forEach((opt, key) => {
                if (!this.checkedValues[key]) {
                    opt.value = null;
                } else if (this.getOptionTypeLabel(opt.type) === "Boolean") {
                    opt.value = this.booleanValues[key];
                } else if (this.getOptionTypeLabel(opt.type) === "Decimal") {
                    opt.value = this.toDecimal(this.decimalValues[key]);
                } else if (this.getOptionTypeLabel(opt.type).startsWith("Dictionary")) {
                    opt.value = this.toDictionary(opt.items, opt.type);
                }
            });
            this.$emit("submit", this.invoiceOptions);
        }
    },
    mounted() {
        this.initializeValues();
    }
};
</script>
