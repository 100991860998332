<template>
  <v-form ref="form" :key="refreshValidation">
    <v-row>
      <v-col cols="12">
        <h3>
          Tariff Id
        </h3>
      </v-col>
      <v-col cols="3">
        <v-text-field
            v-model="editableTariffId"
              label="Tariff Id"
              :error-messages="validationErrorsFor('id')"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-btn @click="saveTariffId">Save</v-btn>
      </v-col>
      <v-col cols="3">
        <v-label></v-label>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import InputValidationMixin from "../../Inputs/InputValidationMixin";
import EventListenerMixin from "../../../Mixins/EventListenerMixin";

export default {
    mixins: [ InputRuleMixin, InputValidationMixin, EventListenerMixin ],
    props: {
        tariffId: {
            type: String,
            default: function() {
                return null;
            }
        }
    },
    data() {
        return {
            editableTariffId: this.tariffId
        };
    },
    watch: {
        tariffId(val) {
            this.editableTariffId = val;
        }
    },
    methods: {
        saveTariffId: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("saveTariffId", this.editableTariffId, this.eventTargetId);
        }
    },
    created() {
        this.$on("success", (e) => {
            this.$emit("cancel");
        });
    }
};
</script>
