<template>
    <div :id="this.name">
        <ReadyForInvoicingByFilterTable :id="this.name" :allFilters="allFilters" :invoiceOptions="invoiceOptions"
            :allTransportEventFilters="allTransportEventFilters"
            @start-invoicing="(filterId, invoiceOptionsId) => startPurchaseInvoicing(filterId, invoiceOptionsId)"
            @search-events="(filterId) => searchEvents(filterId)"
            @update-invoicing-options="(invoicingOptions) => updateInvoicingOptions(invoicingOptions)"
            @create-invoicing-options="(invoicingOptions) => createInvoicingOptions(invoicingOptions)"
            @delete-invoicing-options="(invoicingOptionsId) => deleteInvoicingOptions(invoicingOptionsId)"
            @create-invoicing-filter="(invoicingFilter) => createInvoicingFilter(invoicingFilter)"
            @update-invoicing-filter="(invoicingFilterId, invoicingFilter) => updateInvoicingFilter(invoicingFilterId, invoicingFilter)"
            @delete-invoicing-filter="(invoicingFilterId) => deleteInvoicingFilter(invoicingFilterId)">
        </ReadyForInvoicingByFilterTable>
        <!-- <QueryFilterForm :filters="this.filters">
    </QueryFilterForm> -->
        <InvoicingBatchTable :id="this.name" :batchRows="batchRows"
            @show-log-messages="item => toggleLogMessageDialog(item)"
            @verify-batch="batch => verifyPurchaseInvoiceBatch(batch)">
        </InvoicingBatchTable>
        <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarText }}
            <v-btn text @click="snackbar = false">
                {{ $t("common.close") }}
            </v-btn>
            <BatchLogMessagesDialog :messages="logMessages" :visible="isDialogVisible"
                @close="isDialogVisible = false" />
        </v-snackbar>
    </div>
</template>

<script>
import ReadyForInvoicingByFilterTable from "./ReadyForInvoicingByFilterTable.vue";
import InvoicingBatchTable from "./InvoicingBatchTable.vue";
import BatchLogMessagesDialog from "./Dialogs/BatchLogMessagesDialog.vue";
// import QueryFilterForm from "./QueryFilterForm.vue";
import { RepositoryFactory } from "../../data/repositories/repositoryFactory";

export default {
    components: { ReadyForInvoicingByFilterTable, InvoicingBatchTable, BatchLogMessagesDialog }, // , QueryFilterForm },
    data() {
        return {
            allFilters: [],
            batchRows: [],
            invoiceOptions: [],
            allTransportEventFilters: [],
            logMessages: [],
            isDialogVisible: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: "",
            defaultFilter: {
                filterName: "temp",
                eventTypes: [1],
                purchaseInvoiceStates: [5],
                saveQuery: false
            }
        };
    },
    mounted() {
        this.getAllFilters();
        this.getBatchRows();
        this.getInvoicingOptions();
        this.getAllTransportEventFilters();
    },
    computed: {
        name() {
            return "ReadyForPurchaseInvoicingByFilter";
        },
        filters() {
            const filters = [];
            const purchaseInvoicingFilters = this.getFiltersForForm();
            filters.push(this.defaultFilter);
            if (purchaseInvoicingFilters.length > 0) {
                filters.push(...purchaseInvoicingFilters);
            }
            return filters;
        }
    },
    methods: {
        getFiltersForForm() {
            let activeFilters = [];

            return activeFilters;
        },
        async getAllFilters() {
            RepositoryFactory.get("invoicing").getPurchaseInvoicingFilters()
                .then((response) => {
                    this.allFilters = response.data;
                }); // TODO: handle error
        },
        async createInvoicingFilter(invoicingFilter) {
            RepositoryFactory.get("invoicing").createInvoicingFilter(invoicingFilter)
                .then(() => {
                    this.snackbarText = this.$t("invoicing.filters.created");
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    this.getAllFilters();
                }).catch(error => {
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.snackbarText = error.message;
                });
        },
        async updateInvoicingFilter(invoicingFilterId, invoicingFilter) {
            // RepositoryFactory.get("invoicing").updateInvoicingFilter(invoicingFilterId, invoicingFilter)
            //     .then(() => {
            //         this.snackbarText = this.$t("invoicing.filters.updated");
            //         this.snackbarColor = "success";
            //         this.snackbar = true;
            //         this.getAllFilters();
            //     }).catch(error => {
            //         this.snackbarColor = "error";
            //         this.snackbar = true;
            //         this.snackbarText = error.message;
            //     });
        },
        async deleteInvoicingFilter(invoicingFilterId) {
            // RepositoryFactory.get("invoicing").deleteInvoicingFilter(invoicingFilterId)
            //     .then(() => {
            //         this.snackbarText = this.$t("invoicing.filters.deleted");
            //         this.snackbarColor = "success";
            //         this.snackbar = true;
            //         this.getAllFilters();
            //     }).catch(error => {
            //         this.snackbarColor = "error";
            //         this.snackbar = true;
            //         this.snackbarText = error.message;
            //     });
        },
        async getBatchRows() {
            RepositoryFactory.get("invoicing").getPurchaseInvoicingBatchRows()
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.batchRows = response.data;
                    } else {
                        this.batchRows = [];
                    }
                }); // TODO: handle error
        },
        async getInvoicingOptions() {
            RepositoryFactory.get("invoicing").getAllInvoicingOptions()
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.invoiceOptions = response.data;
                    } else {
                        this.invoiceOptions = [];
                    }
                });
        },
        async getAllTransportEventFilters() {
            RepositoryFactory.get("invoicing").getAllTransportEventFilters()
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        this.allTransportEventFilters = response.data;
                    } else {
                        this.allTransportEventFilters = [];
                    }
                });
        },
        async updateInvoicingOptions(invoicingOptions) {
            RepositoryFactory.get("invoicing").updateInvoicingOptions(invoicingOptions.options.id, invoicingOptions.options)
                .then(() => {
                    this.snackbarText = this.$t("invoicing.options.updated");
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    this.getInvoicingOptions();
                }).catch(error => {
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.snackbarText = error.message;
                });
        },
        async createInvoicingOptions(invoicingOptions) {
            RepositoryFactory.get("invoicing").createInvoicingOptions(invoicingOptions)
                .then(() => {
                    this.snackbarText = this.$t("invoicing.options.created");
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    this.getInvoicingOptions();
                }).catch(error => {
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.snackbarText = error.message;
                });
        },
        async deleteInvoicingOptions(invoicingOptionsId) {
            RepositoryFactory.get("invoicing").deleteInvoicingOptions(invoicingOptionsId)
                .then(() => {
                    this.snackbarText = this.$t("invoicing.options.deleted");
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    this.getInvoicingOptions();
                }).catch(error => {
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    this.snackbarText = error.message;
                });
        },
        async verifyPurchaseInvoiceBatch(batch) {
            RepositoryFactory.get("invoicing").verifyPurchaseInvoiceBatch(batch.batchId, batch.erpSystem)
                .then(() => {
                    this.snackbarText = this.$t("invoicing.batches.verifyStarted");
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    this.getBatchRows();
                }).catch(error => {
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    if (error.response.data) {
                        this.snackbarText = this.getErrorMessageFromServer(error.response.data);
                    } else {
                        this.snackbarText = error.message;
                    }
                });
        },
        startPurchaseInvoicing(filterId, invoiceOptionsId) {
            RepositoryFactory.get("invoicing").startPurchaseInvoicing(filterId, invoiceOptionsId)
                .then(() => {
                    this.snackbarText = this.$t("invoicing.purchasing.invoicingStarted");
                    this.snackbarColor = "success";
                    this.snackbar = true;
                    this.getAllFilters();
                    this.getBatchRows();
                }).catch(error => {
                    this.snackbarColor = "error";
                    this.snackbar = true;
                    if (error.response.data) {
                        this.snackbarText = this.getErrorMessageFromServer(error.response.data);
                    } else {
                        this.snackbarText = error.message;
                    }
                });
        },
        searchEvents(filterId) {
            this.$router.push({
                name: "Invoicing",
                query: { queryFilterId: filterId }
            });
        },
        getErrorMessageFromServer(message) {
            if (message.toString().startsWith("<") &&
                message.toString().indexOf("One or more validation errors") > -1) {
                return "Tarkista tiedot ja yritä uudelleen.";
            }

            message = JSON.stringify(message);
            let titleInx = message.indexOf("\"Title\":");
            let statusInx = message.indexOf("\"Status\":");
            let errorCodeAndMessage = message.substring(titleInx + 9, statusInx - 2);
            let errorMessageFromServer = errorCodeAndMessage.substring(errorCodeAndMessage.indexOf(": ") + 1);

            return errorMessageFromServer || errorCodeAndMessage;
        },
        toggleLogMessageDialog(item) {
            this.isDialogVisible = true;
            this.logMessages = item;
        }
    }
};
</script>
