var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "blue-grey lighten-5" } },
    [
      _vm.$route.query.edit
        ? _c(
            "v-row",
            {
              staticClass: "py-2 px-6",
              attrs: { justify: "space-between", align: "center" }
            },
            [
              _c("span", { staticClass: "invoicing-rule-panel-header" }, [
                _vm._v(_vm._s(_vm.header))
              ]),
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.openNewRuleDialog()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                  _vm._v(" Add new rule ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-expansion-panels",
        { attrs: { multiple: "", accordion: "" } },
        _vm._l(_vm.rules, function(rule, i) {
          return _c("InvoicingRulePanelItem", {
            key: i,
            attrs: { rule: rule },
            on: {
              modify: function($event) {
                return _vm.openRuleDialog(rule, i)
              },
              delete: function($event) {
                return _vm.deleteRule(i)
              }
            }
          })
        }),
        1
      ),
      _c("InvoicingRuleDialog", {
        attrs: {
          isOpen: _vm.ruleDialog,
          originalRule: _vm.selectedRule,
          isNew: _vm.newRule,
          isUpdating: _vm.isUpdating
        },
        on: {
          create: _vm.addRule,
          save: _vm.modifySelectedRule,
          close: _vm.closeRuleDialog
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }