var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "readyForSalesInvoicing" } },
    [
      _c(
        "v-card",
        { staticClass: "mt-1" },
        [
          _c(
            "div",
            { staticClass: "elevation-1 mb-1 pb-2" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-between" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-card-title",
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("invoicing.sales.creationTitle")) +
                              " "
                          ),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-archive-search-outline")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.invoiceEventTypes,
                          label: _vm.$t("invoicing.sales.invoiceEventTypes")
                        },
                        model: {
                          value: _vm.invoiceEventType,
                          callback: function($$v) {
                            _vm.invoiceEventType = $$v
                          },
                          expression: "invoiceEventType"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.showInvoiceCustomerSelector
                    ? _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.invoiceCustomerTypes,
                              label: _vm.$t("invoicing.sales.invoiceEventTypes")
                            },
                            model: {
                              value: _vm.invoiceCustomerType,
                              callback: function($$v) {
                                _vm.invoiceCustomerType = $$v
                              },
                              expression: "invoiceCustomerType"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "auto" }
                    },
                    [
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: !_vm.selectedRows,
                                color: "success"
                              },
                              on: { click: _vm.showSendToSalesInvoicingDialog }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.selectedRows.length > 0
                                      ? _vm.$t(
                                          "invoicing.sales.sendSelectedToSales"
                                        )
                                      : _vm.$t("invoicing.sales.sendAllToSales")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ml-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Alkaen", type: "date" },
                        model: {
                          value: _vm.fromDate,
                          callback: function($$v) {
                            _vm.fromDate = $$v
                          },
                          expression: "fromDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          value: _vm.toDate,
                          label: "Asti",
                          type: "date"
                        },
                        on: {
                          change: function(v) {
                            return (_vm.toDate = v)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "mt-4", attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", small: "" },
                          on: { click: _vm.resetDates }
                        },
                        [_vm._v(_vm._s(_vm.$t("common.reset")))]
                      )
                    ],
                    1
                  ),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          ),
          _c("SendToSalesInvoiceDialog", {
            ref: "salesInvoiceDialog",
            on: {
              closed: function($event) {
                _vm.sendToSalesInvoiceDialog = false
              }
            },
            model: {
              value: _vm.sendToSalesInvoiceDialog,
              callback: function($$v) {
                _vm.sendToSalesInvoiceDialog = $$v
              },
              expression: "sendToSalesInvoiceDialog"
            }
          }),
          _c(
            "v-skeleton-loader",
            {
              attrs: {
                loading: _vm.loading,
                "transition-group": "scale-transition",
                type: "table",
                name: "readyForSalesInvoicingTable"
              }
            },
            [
              _c("v-data-table", {
                attrs: {
                  "calculate-widths": "",
                  headers: _vm.showableHeaders,
                  items: _vm.readyForSalesInvoicingRowData,
                  "show-select": "",
                  "item-key": "costCenter",
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  loading: _vm.loadingPage,
                  page: _vm.page,
                  "items-per-page": _vm.itemsPerPage,
                  "footer-props": {
                    pagination: _vm.pagination,
                    itemsPerPageOptions: _vm.itemsPerPageOptions
                  }
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:page": function($event) {
                    _vm.page = $event
                  },
                  "update:itemsPerPage": function($event) {
                    _vm.itemsPerPage = $event
                  },
                  "update:items-per-page": function($event) {
                    _vm.itemsPerPage = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "primary", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.searchEvents(item)
                              }
                            }
                          },
                          [_vm._v(" Siirry tapahtumiin ")]
                        )
                      ]
                    }
                  },
                  {
                    key: "item.totalSum",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("formatCurrency")(item.totalSum)) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "body.append",
                    fn: function() {
                      return [
                        _vm.readyForSalesInvoicingRowData != null
                          ? _c(
                              "tr",
                              { staticStyle: { outline: "thick solid" } },
                              [
                                _c("th"),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-md-body-1 font-weight-bold pl-3"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.footerTotalCostCenterCount) +
                                        " kpl"
                                    )
                                  ]
                                ),
                                _c("th"),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-md-body-1 font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.footerTotalTransportEventCount
                                      ) + " kpl"
                                    )
                                  ]
                                ),
                                _c(
                                  "th",
                                  {
                                    staticClass:
                                      "text-md-body-1 font-weight-bold"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          _vm.footerTotalSum
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c("th")
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: { color: _vm.snackbarColor },
              model: {
                value: _vm.snackbar,
                callback: function($$v) {
                  _vm.snackbar = $$v
                },
                expression: "snackbar"
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.snackbarText) + " "),
              _c(
                "v-btn",
                {
                  attrs: { text: "" },
                  on: {
                    click: function($event) {
                      _vm.snackbar = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }