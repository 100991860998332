import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import router from "./router";
import store from "./store";
import moment from "moment";
import AuthService from "./msal";
import VueAppInsights from "./plugins/appInsights";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import Vuelidate from "vuelidate";
// import vehicleHub from "./plugins/vehicleHub";
// import { LogLevel } from "@microsoft/signalr";
Vue.config.productionTip = false;
Vue.prototype.$AuthService = new AuthService();

Vue.filter("formatValidThrough", function(validThrough) {
    let start = moment(String(validThrough.startTime)).format("DD.MM.YYYY");
    let end = moment(String(validThrough.endTime)).format("DD.MM.YYYY");
    return `${start} -> ${end}`;
});

const currencyFormatter = new Intl.NumberFormat("fi-FI", {
    style: "currency",
    currency: "EUR"
});
Vue.filter("formatCurrency", function(price) {
    return currencyFormatter.format(price);
});

Vue.filter("formatPrice", function(price) {
    if (!price) return 0.00;
    let float = parseFloat(price);
    if (!float) return 0.00;
    return float.toFixed(2);
});

Vue.filter("formatDistanceToKm", function(distanceM) {
    if (!distanceM) return 0;
    let distanceInKm = distanceM / 1000;
    return distanceInKm.toLocaleString("fi-FI", { style: "decimal", maximumFractionDigits: 2, minimumFractionDigits: 2 });
});

// takes c# timespan as parameter and converts it to format e.g 1h 30min
Vue.filter("formatTimespan", function(timespan) {
    if (!timespan) return "";
    let hours = Math.floor(timespan / 60);
    let minutes = timespan % 60;
    let result = "";
    if (hours > 0) {
        result += hours + "h ";
    }
    if (minutes > 0) {
        if (minutes < 1) {
            result += "<1min";
        } else {
            minutes = Math.round(minutes);
            result += minutes + "min";
        }
    }
    return result;
});

Vue.filter("formatDateTime", function(dateTime) {
    if (!dateTime) return "";
    let localDate = new Date(dateTime).toLocaleString("fi-FI",
        { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" });
    if (localDate.charAt(localDate.length - 3) === ".") {
        localDate = replaceChar(localDate, ":", localDate.length - 3);
    }

    return localDate;
});

Vue.filter("formatDateTimeShort", function(dateTime) {
    if (!dateTime) return "";
    let localDate = new Date(dateTime).toLocaleString("fi-FI", { year: "numeric", month: "2-digit", day: "2-digit" });
    return localDate;
});

Vue.use(VueAppInsights, {
    id: process.env.VUE_APP_APPINSIGHTSKEY,
    router
});

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLEMAPSAPIKEY,
        libraries: "places,drawing,visualization"
    },
    installComponents: true
});

Vue.component("GmapCluster", GmapCluster);

Vue.use(Vuelidate);
// Vue.use(vehicleHub, {
//   hubUrl: process.env.VUE_APP_VEHICLEHUB_URL,
//   logLevel: LogLevel.Error,
//   enabled: process.env.VUE_APP_VEHICLEHUB_ENABLED
// });

new Vue({
    router,
    vuetify,
    i18n,
    store,
    render: h => h(App)
}).$mount("#app");

function replaceChar(origString, replaceChar, index) {
    let firstPart = origString.substr(0, index);
    let lastPart = origString.substr(index + 1);
    let newString = firstPart + replaceChar + lastPart;

    return newString;
}
