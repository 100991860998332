<template>
     <div>
        <v-col cols="2">
          <v-btn @click="getLogs">Get</v-btn>
        </v-col>
        <v-data-table
        calculate-widths
        :headers="showableHeaders"
        :items="logs"
        item-key="id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="filter"
        :loading="loadingPage"
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
      >
        <template v-slot:item.timestamp="{ value }">
          <span> {{ formatDateTime(value) }}</span>
        </template>
        <template v-slot:item.level="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.message="{ value }">
          <span> {{ value }}</span>
        </template>
      </v-data-table>
    </div>
</template>

<script>
import moment from "moment";

export default {
    props: {
        vehicleId: String
    },
    data() {
        return {
            loading: true,
            filter: "",
            columnDialog: false,
            headers: [
                { text: "Timestamp", value: "timestamp", show: true },
                { text: "Level", value: "level", show: true },
                { text: "Message", value: "message", show: true }
            ],
            sortBy: "timestamp",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            searchParams: {
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100]
        };
    },
    computed: {
        logs() {
            return this.$store.state.vehicles.searchedLogs;
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    },
    methods: {
        formatDateTime: function(datetime) {
            return moment(datetime).format("L LT");
        },
        async getLogs() {
            this.loading = true;
            this.$store.dispatch("vehicles/searchLogs", { vehicleId: this.vehicleId })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
};
</script>
