var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          "calculate-widths": "",
          headers: _vm.showableHeaders,
          items: _vm.installations,
          "item-key": "id",
          "sort-by": _vm.sortBy,
          "sort-desc": _vm.sortDesc,
          search: _vm.filter,
          loading: _vm.loadingPage,
          page: _vm.page,
          "items-per-page": _vm.itemsPerPage,
          "footer-props": {
            pagination: _vm.pagination,
            itemsPerPageOptions: _vm.itemsPerPageOptions
          }
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:itemsPerPage": function($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function($event) {
            _vm.itemsPerPage = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.timestamp",
            fn: function(ref) {
              var value = ref.value
              return [
                _c("span", [_vm._v(" " + _vm._s(_vm.formatDateTime(value)))])
              ]
            }
          },
          {
            key: "item.vehicleNumber",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.registrationNumber",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.companyName",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.phoneNumber",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.imei",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          },
          {
            key: "item.installer",
            fn: function(ref) {
              var value = ref.value
              return [_c("span", [_vm._v(" " + _vm._s(value))])]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }