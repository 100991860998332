<template>
 <v-dialog
    v-model="show"
    @click:outside="cancel"
    max-width="1000">
    <v-card>
        <v-toolbar short dark color="primary">
          <v-btn icon dark @click="cancel">
              <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
              {{$t("common.search")}}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-model="searchParamsValid" class="mx-3 mt-2">
            <v-row align="center" class="mt-3">
              <v-col md=6>
                <v-select
                  :items="operators"
                  v-model="editableSearchParams.companyId"
                  label="Company"
                ></v-select>
              </v-col>
              <v-col md=6>
                <v-text-field
                    v-model="editableSearchParams.fleetId"
                    label="Fleet"
                ></v-text-field>
              </v-col>
              <v-col md=6>
                <v-select
                  :items="isochroneTypes"
                  v-model="isochroneType"
                  label="Isochrone Type"
                >
                  <template v-slot:selection="{ item }">
                    {{ IsochroneTypeToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ IsochroneTypeToString(item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col md=6>
                <v-text-field
                    v-model="unitValue"
                    label="Distance/Time"
                ></v-text-field>
              </v-col>
              <v-col style="height: 600px; width: 600px">
                <MapBox
                  :doubleClickZoom="false"
                  @dblclick="onDoubleClick"
                ></MapBox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="search">
              {{$t("common.search")}}
            </v-btn>
            <v-btn
              @click="cancel"
              class="ml-2">
              {{$t("common.cancel")}}
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MapBox from "../../Geo/MapBox";
import { IsochroneType, IsochroneTypeToString } from "../../../models/IsochroneType";
import _ from "lodash";

export default {
    components: {
        MapBox
    },
    props: {
        show: Boolean,
        searchParams: Object
    },
    data() {
        return {
            editableSearchParams: _.cloneDeep(this.searchParams),
            point: null,
            points: [],
            unitValue: 1,
            isochroneType: IsochroneType.Time,
            isochroneTypes: Object.values(IsochroneType),
            searchParamsValid: true
        };
    },
    watch: {
        searchParams(val) {
            if (!val) {
                this.editableSearchParams = null;
            } else {
                this.editableSearchParams = _.cloneDeep(val);
            }
        }
    },
    computed: {
        operators() {
            return _.filter(this.$store.state.user.operators, function(o) { return o !== "all"; });
        }
    },
    methods: {
        onDoubleClick(point) {
            this.point = point;
        },
        search() {
            const params = {
                companyId: this.editableSearchParams.companyId,
                fleetId: this.editableSearchParams.fleetId,
                query: {
                    polygon: {
                        points: this.points
                    }
                }
            };
            this.getIsochrone(params);
            // this.$emit("search", params);
        },
        async getIsochrone() {
            this.loading = true;
            this.$store.dispatch("geo/getIsochrone", {
                isochroneType: this.isochroneType,
                unitValue: this.unitValue,
                location: {
                    point: this.point
                }
            }).catch(error => {
                this.snackbar = true;
                this.snackbarColor = "error";
                this.snackbarText = error;
            }).finally(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.points = [];
            this.$emit("close");
        },
        IsochroneTypeToString(isochroneType) {
            return IsochroneTypeToString(isochroneType);
        }
    }
};
</script>
