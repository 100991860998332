import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import ValidationsList from "./views/ValidationsList";
import UserManagementList from "./views/UserManagementList";
import CustomerDetails from "./views/CustomerDetails";
import Vehicles from "./views/Vehicles";
import Companies from "./views/Companies";
import Fleets from "./views/Fleets";
import Drivers from "./views/Drivers";
import Bookings from "./views/Bookings";
import Trips from "./views/Trips";
import Dispatching from "./views/Dispatching";
import Geo from "./views/Geo";
import Pricing from "./views/Pricing";
import Invoicing from "./views/Invoicing";
import InvoicingRules from "./views/InvoicingRules";
import PurchaseInvoicing from "./views/PurchaseInvoicing";
import SalesInvoicing from "./views/SalesInvoicing";
import MissingVerifoneEvents from "./views/MissingVerifoneEvents";
import PaytrailTransactions from "./views/PaytrailTransactions";
import PaymentManagementAvailability from "./views/availability/PaymentManagementAvailability";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "Home",
            component: Home
        },
        {
            path: "/validations",
            name: "Validations",
            component: ValidationsList,
            meta: { authorize: { role: "validation" } }
        },
        {
            path: "/pricing",
            name: "Pricing",
            component: Pricing,
            meta: { authorize: { role: "pricing" } }
        },
        {
            path: "/invoicing",
            name: "Invoicing",
            component: Invoicing,
            meta: { authorize: { role: "paymentmanagement" } }
        },
        {
            path: "/invoicing/rules",
            name: "InvoicingRules",
            component: InvoicingRules,
            meta: { authorize: { role: "paymentmanagement" } }
        },
        {
            path: "/invoicing/salesinvoicing",
            name: "SalesInvoicing",
            component: SalesInvoicing,
            meta: { authorize: { role: "paymentmanagement" } }
        },
        {
            path: "/invoicing/purchaseinvoicing",
            name: "PurchaseInvoicing",
            component: PurchaseInvoicing,
            meta: { authorize: { role: "paymentmanagement" } }
        },
        {
            path: "/invoicing/verifone",
            name: "MissingVerifoneEvents",
            component: MissingVerifoneEvents,
            meta: { authorize: { role: "paymentmanagement" } }
        },
        {
            path: "/invoicing/paytrail",
            name: "PaytrailTransactions",
            component: PaytrailTransactions,
            meta: { authorize: { role: "paymentmanagement" } }
        },
        {
            path: "/user-management",
            name: "User Management",
            component: UserManagementList,
            meta: { authorize: { role: "usermanagement" } }
        },
        {
            path: "/customers/:id",
            name: "CustomerDetails",
            component: CustomerDetails,
            meta: { authorize: { role: "usermanagement" } }
        },
        {
            path: "/geo",
            name: "GeoService",
            component: Geo,
            meta: { authorize: { role: "geo" } }
        },
        {
            path: "/vehicles",
            name: "Vehicles",
            component: Vehicles,
            meta: { authorize: { role: "vehicle" } }
        },
        {
            path: "/companies",
            name: "Companies",
            component: Companies
        },
        {
            path: "/fleets",
            name: "Fleets",
            component: Fleets
        },
        {
            path: "/drivers",
            name: "Drivers",
            component: Drivers
        },
        {
            path: "/bookings",
            name: "Bookings",
            component: Bookings
        },
        {
            path: "/trips",
            name: "Trips",
            component: Trips
        },
        {
            path: "/dispatching",
            name: "Dispatching",
            component: Dispatching
        },
        {
            path: "/availability/payment-management",
            name: "PaymentManagement availability",
            component: PaymentManagementAvailability
        }
    ]
});

export default router;

router.beforeEach(async(to, from, next) => {
    const { authorize } = to.meta;
    const user = await Vue.prototype.$AuthService.getUser();
    if (authorize) {
        if (!user) {
            alert("You are not authenticated.");
            return next({ name: "Home" });
        }

        const canAccess = Vue.prototype.$AuthService.canAccessSite(authorize.role);

        if (!canAccess) {
            alert("You do not have permission to enter this page.");
            return next({ name: "Home" });
        }
    }
    next();
});
