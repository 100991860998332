var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1400" },
      on: {
        "click:outside": function($event) {
          return _vm.$emit("close")
        }
      },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0" },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { short: "", dark: "", color: "primary" } },
                    [
                      _c("v-card-title", [
                        _vm.correctionMode
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("invoicing.correctTransportEvent")
                                )
                              )
                            ])
                          : !_vm.value
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("invoicing.createTransportEvent"))
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("invoicing.editTransportEvent"))
                              )
                            ])
                      ]),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-select", {
                        staticClass: "my-3",
                        attrs: {
                          label: this.$t("invoicing.editModelLabel"),
                          items: _vm.models,
                          disabled: !_vm.isModelChangeable,
                          hint: this.$t("invoicing.editModelHint"),
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.selectedModel,
                          callback: function($$v) {
                            _vm.selectedModel = $$v
                          },
                          expression: "selectedModel"
                        }
                      }),
                      _vm.readOnly
                        ? _c(
                            "v-container",
                            [
                              _c("v-row", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "invoicing.transportEventCannotBeModified"
                                      )
                                    ) +
                                    " "
                                ),
                                _c("br"),
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("common.reason", {
                                      reason: _vm.readOnlyReason
                                    })
                                  ) + " "
                                )
                              ]),
                              _vm.transportEvent.removed
                                ? _c(
                                    "v-container",
                                    { staticClass: "pa-0 mt-4" },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: {
                                                click: function($event) {
                                                  _vm.transportEvent.removed = false
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "invoicing.undoRemove"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c("v-row", [
                                        _c("span", [
                                          _vm._v(
                                            "Tapahtuma pysyy poistettuna, jos et tallenna sitä muokkaamisen jälkeen"
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-slide-y-transition",
                        { attrs: { group: "", "hide-on-leave": "" } },
                        [
                          _vm.selectedModel === _vm.transportEventType.Likuma
                            ? _c("LikumaForm", {
                                key: "1",
                                ref: "form",
                                attrs: {
                                  isNew: !_vm.value,
                                  readOnly: _vm.readOnly,
                                  originalTransportEvent: _vm.transportEvent
                                },
                                on: {
                                  correctionRequested: _vm.setCorrectionMode,
                                  "close-dialog": function($event) {
                                    return _vm.$emit("close")
                                  }
                                }
                              })
                            : _vm.selectedModel === _vm.transportEventType.Kela
                            ? _c("KelaForm", {
                                key: "2",
                                ref: "form",
                                attrs: {
                                  isNew: !_vm.value,
                                  readOnly: _vm.readOnly,
                                  originalTransportEvent: _vm.transportEvent
                                },
                                on: {
                                  validationFailed: _vm.showValidationErrors,
                                  requestSSN: _vm.getSSN,
                                  saveIncomplete: _vm.modifyTransportEvent
                                }
                              })
                            : _vm.selectedModel ===
                              _vm.transportEventType.Receipt
                            ? _c("ReceiptForm", {
                                key: "3",
                                ref: "form",
                                attrs: {
                                  isNew: !_vm.value,
                                  readOnly: _vm.readOnly,
                                  originalTransportEvent: _vm.transportEvent
                                },
                                on: {
                                  validationFailed: _vm.showValidationErrors,
                                  requestSSN: _vm.getSSN,
                                  "close-dialog": function($event) {
                                    return _vm.$emit("close")
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm.selectedModel === _vm.transportEventType.Keusote
                            ? _c("KeusoteForm", {
                                key: "4",
                                ref: "form",
                                attrs: {
                                  isNew: !_vm.value,
                                  readOnly: _vm.readOnly,
                                  originalTransportEvent: _vm.transportEvent
                                }
                              })
                            : _vm._e(),
                          _vm.selectedModel === _vm.transportEventType.TaxiPlus
                            ? _c("TaxiPlusForm", {
                                key: "5",
                                ref: "form",
                                attrs: {
                                  isNew: !_vm.value,
                                  readOnly: _vm.readOnly,
                                  originalTransportEvent: _vm.transportEvent
                                }
                              })
                            : _vm.selectedModel ===
                              _vm.transportEventType.Normal
                            ? _c("TransportEventForm", {
                                key: "6",
                                ref: "form",
                                attrs: {
                                  originalTransportEvent: _vm.transportEvent
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "pb-4" },
                    [
                      _c("v-spacer"),
                      _vm.showComboDialogButton
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "success" },
                              on: { click: _vm.openCombineDialog }
                            },
                            [_vm._v(" Yhdistä tilaukseen ")]
                          )
                        : _vm._e(),
                      _vm.correctionMode
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "success", loading: _vm.sending },
                              on: { click: _vm.correctTransportEvent }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("invoicing.sendCorrectedEvent")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : !_vm.value
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "success",
                                loading: _vm.sending,
                                disabled: _vm.readOnly
                              },
                              on: { click: _vm.createTransportEvent }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("invoicing.addEvent")) + " "
                              )
                            ]
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                loading: _vm.sending,
                                disabled: _vm.readOnly,
                                color: "primary"
                              },
                              on: { click: _vm.modifyTransportEvent }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("common.saveChanges")) + " "
                              )
                            ]
                          ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
                      ),
                      _vm.showSetNotToBeInvoiced
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                loading: _vm.sendingNotToBeInvoiced,
                                color: "error"
                              },
                              on: { click: _vm.setNotToBeInvoiced }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("invoicing.setNotToBeInvoiced")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      !!_vm.value
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                loading: _vm.sendingDelete,
                                disabled: _vm.readOnly,
                                color: "error"
                              },
                              on: { click: _vm.deleteTransportEvent }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("invoicing.deleteEvent")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-slide-x-transition",
            { attrs: { "hide-on-leave": "" } },
            [
              _vm.browseReceipts
                ? _c(
                    "v-col",
                    { staticClass: "pa-0 ml-1", attrs: { cols: "5" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: { short: "", dark: "", color: "primary" }
                            },
                            [_c("v-card-title", [_vm._v(" Selvitykset ")])],
                            1
                          ),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-list",
                                [
                                  _c("v-subheader", [
                                    _vm._v("Valitse selvitys")
                                  ]),
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { color: "primary" },
                                      model: {
                                        value: _vm.selectedReceiptIndex,
                                        callback: function($$v) {
                                          _vm.selectedReceiptIndex = $$v
                                        },
                                        expression: "selectedReceiptIndex"
                                      }
                                    },
                                    _vm._l(_vm.receiptForms, function(
                                      receiptForm,
                                      i
                                    ) {
                                      return _c(
                                        "v-list-item",
                                        { key: i },
                                        [
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  _vm._s(
                                                    receiptForm.companyName
                                                  ) +
                                                    " " +
                                                    _vm._s(receiptForm.region) +
                                                    " " +
                                                    _vm._s(receiptForm.tripDate)
                                                )
                                              ]),
                                              _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  "Klo. " +
                                                    _vm._s(
                                                      receiptForm.started
                                                    ) +
                                                    " - " +
                                                    _vm._s(receiptForm.ended) +
                                                    ", auto " +
                                                    _vm._s(
                                                      receiptForm.carNumber
                                                    )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-slide-y-transition",
                                { attrs: { "hide-on-leave": "" } },
                                [
                                  _vm.selectedReceipt
                                    ? _c(
                                        "div",
                                        [
                                          _c("v-subheader", [
                                            _vm._v("Selvityksen tiedot")
                                          ]),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Yrityksen nimi",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .companyName,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "companyName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.companyName"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Y-tunnus",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .companyId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "companyId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.companyId"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Sähköposti",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.email"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Puhelinnumero",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .phoneNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "phoneNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.phoneNumber"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Matkapäivä",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .tripDate,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "tripDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.tripDate"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Aloitusaika",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .started,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "started",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.started"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Lopetusaika",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .ended,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "ended",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.ended"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Maakunta",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .region,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "region",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.region"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Auton numero",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .carNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "carNumber",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.carNumber"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Tilaustunnus",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .orderId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "orderId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.orderId"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Lähtöosoite",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .startingPoint,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "startingPoint",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.startingPoint"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Kohdeosoite",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .destination,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "destination",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.destination"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "6" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: "Omavastuu",
                                                      uffix: "€",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedReceipt
                                                          .deductible,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedReceipt,
                                                          "deductible",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedReceipt.deductible"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _vm.selectedReceipt.noReceipt
                                                ? _c("p", [
                                                    _vm._v("Ei kuvaa kuitista!")
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            { staticClass: "pb-4" },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.mergeReceiptToEvent }
                                },
                                [_vm._v(" Vie tiedot tapahtumalle ")]
                              ),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("CombineEventsDialog", {
        attrs: {
          isOpen: _vm.combineEventsDialog,
          transportEventId: _vm.transportEvent.id,
          transportEventOrderId: _vm.transportEvent.orderId,
          openTransportEvent: _vm.transportEvent
        },
        on: {
          combined: function(newTpEvent) {
            return _vm.$emit("changed", newTpEvent)
          },
          failed: _vm.showCombiningFailed,
          close: function($event) {
            _vm.combineEventsDialog = false
          }
        }
      }),
      _c(
        "v-snackbar",
        {
          staticStyle: { "white-space": "pre" },
          attrs: { vertical: "", color: "error" },
          model: {
            value: _vm.errorSnackbar,
            callback: function($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
          _c(
            "v-btn",
            {
              attrs: { text: "" },
              on: {
                click: function($event) {
                  _vm.errorSnackbar = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("common.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }