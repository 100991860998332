import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedBookings: [],
    fetchedBooking: null,
    searchParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null
};

// getters
const getters = {};

// actions
const actions = {
    // Bookings
    async searchBookings({ commit }, { searchParams, top = 100, forceUpdate = false }) {
        if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
            return;
        }
        await RepositoryFactory.get("booking").find(searchParams, top)
            .then(response => {
                commit("SET_BOOKINGS", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_FETCHED_TIMESTAMP", moment());
            });
    },
    async getBooking({ commit }, id) {
        await RepositoryFactory.get("booking").getBooking(id)
            .then(response => {
                commit("SET_BOOKING", response.data.item);
            });
    },
    async loadMoreBookings({ commit }, { top = 100 }) {
        await RepositoryFactory.get("booking").find(
            state.searchParams,
            top,
            Math.floor(state.searchedBookings.length / top) + 1)
            .then(response => {
                commit("ADD_SEARCHED_BOOKINGS", response.data.results);
            });
    }
};

// mutations
const mutations = {
    SET_BOOKINGS(state, bookings) {
        state.searchedBookings = bookings;
    },
    SET_BOOKING(state, booking) {
        state.fetchedBooking = booking;
    },
    ADD_SEARCHED_BOOKINGS(state, bookings) {
        state.searchedBookings = state.searchedBookings.concat(bookings);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
