<template>
	<v-container fluid>
    <h1>{{$t("home.title")}}</h1>
    <InvoicingOverview v-if="canAccessInvoicing"></InvoicingOverview>
    <p v-else>{{$t("home.noPermission")}}</p>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        {{$t("common.close")}}
      </v-btn>
    </v-snackbar>
	</v-container>
</template>

<script>
import InvoicingOverview from "@/components/Invoicing/Charts/Overview.vue";

export default {
    components: {
        "InvoicingOverview": InvoicingOverview
    },
    data() {
        return {
            canAccessInvoicing: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    created() {
        this.canAccessInvoicing = this.$AuthService.canAccessSite("paymentmanagement");
    }
};
</script>

<style scoped>
.clickable:hover {
  cursor:pointer;
}
</style>
