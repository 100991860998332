<template>
  <v-dialog v-model="isOpen" persistent max-width="800">
    <v-card>
      <v-card-text>
        <v-form ref="form" :key="refreshValidation">
          <v-row class="mt-3">
              <v-col cols="4">
                  <v-text-field
                      :value="attributeOriginToString(editableAttribute.origin)"
                      label="Origin"
                      :disabled="true"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="attributeTypes"
                  v-model="editableAttribute.type"
                  :rules="[ requiredRule ]"
                  :error-messages="validationErrorsFor('type')"
                  label="Type"
                >
                  <template v-slot:selection="{ item }">
                    {{attributeTypeToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ attributeTypeToString(item) }}
                  </template>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-select
                  :items="attributeContentTypes"
                  v-model="editableAttribute.contentType"
                  :rules="[ requiredRule ]"
                  :error-messages="validationErrorsFor('contentType')"
                  label="Content Type"
                >
                  <template v-slot:selection="{ item }">
                    {{attributeContentTypeToString(item) }}
                  </template>
                  <template v-slot:item="{ item }">
                    {{ attributeContentTypeToString(item) }}
                  </template>
                </v-select>
              </v-col>
          </v-row>
          <v-row class="mt-3">
              <v-col cols="4">
                  <v-text-field
                      v-model="editableAttribute.id"
                      label="Id"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('id')"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      v-model="editableAttribute.name"
                      label="Name"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('name')"
                  ></v-text-field>
              </v-col>
              <v-col cols="4">
                  <v-text-field
                      v-model="editableAttribute.value"
                      label="Value"
                      :rules="[ requiredRule ]"
                      :error-messages="validationErrorsFor('value')"
                  ></v-text-field>
              </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn @click="addNewAttribute" v-if="isNew">Add</v-btn>
          <v-btn @click="saveAttribute" v-if="!isNew">Save</v-btn>
          <v-btn @click="$emit('cancel')">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AttributeSourceType, AttributeSourceTypeToString } from "../../../models/AttributeSourceType";
import { AttributeOrigin, AttributeOriginToString } from "../../../models/AttributeOrigin";
import { AttributeType, AttributeTypeToString } from "../../../models/AttributeType";
import { AttributeContentType, AttributeContentTypeToString } from "../../../models/AttributeContentType";
import InputRuleMixin from "../../Inputs/InputRuleMixin";
import InputValidationMixin from "../../Inputs/InputValidationMixin";
import EventListenerMixin from "../../../Mixins/EventListenerMixin";
import _ from "lodash";

export default {
    mixins: [ InputRuleMixin, InputValidationMixin, EventListenerMixin ],
    props: {
        attribute: {
            type: Object,
            default: function() {
                return null;
            }
        },
        attributeOrigin: {
            type: Number,
            default: function() {
                return AttributeSourceType.Unknown;
            }
        },
        isOpen: Boolean
    },
    data() {
        return {
            editableAttribute: this.attribute ? _.cloneDeep(this.attribute) : {
                origin: this.attributeOrigin
            },
            attributeSourceTypes: Object.values(AttributeSourceType),
            attributeOrigins: Object.values(AttributeOrigin),
            attributeTypes: Object.values(AttributeType),
            attributeContentTypes: Object.values(AttributeContentType)
        };
    },
    watch: {
        attribute(val) {
            this.editableAttribute = (!val) ? {
                origin: this.attributeOrigin
            } : _.cloneDeep(val);
        }
    },
    computed: {
        isNew() {
            return !this.attribute;
        }
    },
    methods: {
        addNewAttribute: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("addNewAttribute", this.editableAttribute, this.eventTargetId);
        },
        saveAttribute: function() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.$emit("saveAttribute", this.editableAttribute, this.eventTargetId);
        },
        deleteAttribute: function() {
            this.$emit("deleteAttribute", this.editableAttribute, this.eventTargetId);
        },
        attributeSourceTypeToString(attributeSourceType) {
            return AttributeSourceTypeToString(attributeSourceType);
        },
        attributeOriginToString(attributeOrigin) {
            return AttributeOriginToString(attributeOrigin);
        },
        attributeTypeToString(attributeType) {
            return AttributeTypeToString(attributeType);
        },
        attributeContentTypeToString(attributeContentType) {
            return AttributeContentTypeToString(attributeContentType);
        }
    },
    created() {
        this.$on("success", (e) => {
            this.$emit("cancel");
        });
    }
};
</script>
