import { RepositoryFactory } from "@/data/repositories/repositoryFactory";
import moment from "moment";

// initial state
const state = {
    searchedFleets: [],
    fetchedFleet: null,
    searchParams: null,
    searchTotalCount: null,
    fetchedTimestamp: null
};

// getters
const getters = {};

// actions
const actions = {
    // Fleets
    async searchFleets({ commit }, { searchParams, top = 100, forceUpdate = false }) {
        if (!forceUpdate &&
            moment(state.fetchedTimeStamp).diff(moment(), "minutes") < 2 &&
            JSON.stringify(state.searchParams) === JSON.stringify(searchParams)) {
            return;
        }
        await RepositoryFactory.get("fleet").find(searchParams, top)
            .then(response => {
                commit("SET_FLEETS", response.data.results);
                commit("SET_SEARCH_TOTAL_COUNT", response.data.totalCount);
                commit("SET_SEARCH_PARAMS", JSON.parse(JSON.stringify(searchParams)));
                commit("SET_FETCHED_TIMESTAMP", moment());
            });
    },
    async getFleet({ commit }, { companyId, fleetId }) {
        await RepositoryFactory.get("fleet").getFleet(companyId, fleetId)
            .then(response => {
                commit("SET_FLEET", response.data.item);
            });
    },
    async loadMoreFleets({ commit }, { top = 100 }) {
        await RepositoryFactory.get("fleet").find(
            state.searchParams,
            top,
            Math.floor(state.searchedFleets.length / top) + 1)
            .then(response => {
                commit("ADD_SEARCHED_FLEETS", response.data.results);
            });
    },
    async createFleet({ commit }, fleet) {
        await RepositoryFactory.get("fleet").create(fleet)
            .then(() => {
                commit("CREATE_FLEET", fleet);
            });
    },
    async deleteFleet({ commit }, { companyId, fleetId }) {
        await RepositoryFactory.get("fleet").delete(companyId, fleetId)
            .then(() => {
                commit("REMOVE_FLEET", { companyId, fleetId });
            });
    },
    async updateFleetContactInformation({ commit }, { companyId, fleetId, contactInformation }) {
        await RepositoryFactory.get("fleet").patchContactInformation(companyId, fleetId, contactInformation);
    },
    async updateFleetInfo({ commit }, { companyId, fleetId, fleetInfo }) {
        await RepositoryFactory.get("fleet").patchFleetInfo(companyId, fleetId, fleetInfo);
    },
    // Attributes
    async addNewAttribute({ commit }, { companyId, fleetId, attribute }) {
        await RepositoryFactory.get("fleet").postNewAttribute(companyId, fleetId, { attribute });
    },
    async saveAttribute({ commit }, { companyId, fleetId, attribute }) {
        await RepositoryFactory.get("fleet").patchAttribute(companyId, fleetId, { attribute });
    },
    async deleteAttribute({ commit }, { companyId, fleetId, attribute }) {
        await RepositoryFactory.get("fleet").deleteAttribute(companyId, fleetId, attribute);
    },
    // Properties
    async addNewProperty({ commit }, { companyId, fleetId, property }) {
        await RepositoryFactory.get("fleet").postNewProperty(companyId, fleetId, { property });
    },
    async saveProperty({ commit }, { companyId, fleetId, property }) {
        await RepositoryFactory.get("fleet").patchProperty(companyId, fleetId, { property });
    },
    async deleteProperty({ commit }, { companyId, fleetId, property }) {
        await RepositoryFactory.get("fleet").deleteProperty(companyId, fleetId, property);
    },
    // Tariffs
    async addNewTariffImport({ commit }, { companyId, fleetId, tariffImport }) {
        await RepositoryFactory.get("fleet").postNewTariffImport(companyId, fleetId, tariffImport);
    },
    async saveTariffImport({ commit }, { companyId, fleetId, tariffImport }) {
        await RepositoryFactory.get("fleet").patchTariffImport(companyId, fleetId, tariffImport);
    },
    async saveTariffId({ commit }, { companyId, fleetId, tariffId }) {
        await RepositoryFactory.get("fleet").patchTariffId(companyId, fleetId, tariffId);
    },
    async deleteTariffImport({ commit }, { companyId, fleetId, tariffImport }) {
        await RepositoryFactory.get("fleet").deleteTariffImport(companyId, fleetId, tariffImport);
    }
};

// mutations
const mutations = {
    SET_FLEETS(state, fleets) {
        state.searchedFleets = fleets;
    },
    SET_FLEET(state, fleet) {
        state.fetchedFleet = fleet;
    },
    ADD_SEARCHED_FLEETS(state, fleets) {
        state.searchedFleets = state.searchedFleets.concat(fleets);
    },
    SET_SEARCH_TOTAL_COUNT(state, searchTotalCount) {
        state.searchTotalCount = searchTotalCount;
    },
    SET_SEARCH_PARAMS(state, searchParams) {
        state.searchParams = searchParams;
    },
    SET_FETCHED_TIMESTAMP(state, timestamp) {
        state.fetchedTimestamp = timestamp;
    },
    ADD_FLEET(state, fleet) {
        state.searchedFleets.push(fleet);
    },
    UPDATE_FLEET(state, fleet) {
        state.searchedFleets = [
            ...state.searchedFleets.map(
                item => item.id !== fleet.id ? item : { ...item, ...fleet })
        ];
    },
    REMOVE_FLEET(state, id) {
        let index = state.searchedFleets.findIndex(te => te.id === id);
        state.searchedFleets.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
