<template>
  <v-card>
    <v-card-text>
      <v-data-table
          calculate-widths
          :headers="headers"
          :items="editableAttributes"
          item-key="id"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :search="filter"
          :items-per-page="10"
        >
          <template v-slot:item.origin="{ value }">
            <span> {{ attributeOriginToString(value) }}</span>
          </template>
          <template v-slot:item.type="{ value }">
            <span> {{ attributeTypeToString(value) }}</span>
          </template>
          <template v-slot:item.id="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.name="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.value="{ value }">
            <span> {{ value }}</span>
          </template>
          <template v-slot:item.contentType="{ value }">
            <span> {{ attributeContentTypeToString(value) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <span>
              <v-btn
                @click="editAttribute(item)"
                :disabled="!isEditable(item)"
              >
                Edit
              </v-btn>
            </span>
            <span>
              <v-btn
                @click="deleteAttribute(item)"
                :disabled="!isEditable(item)"
              >
                Delete
              </v-btn>
            </span>
          </template>
        </v-data-table>
    </v-card-text>
    <v-card-actions class="pb-4">
      <v-spacer></v-spacer>
        <v-btn @click="createAttribute">Create</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <AttributeForm
        :isOpen="isEditFormOpen"
        :attribute="selectedAttribute"
        :canAddNew="true"
        :attributeOrigin="editableAttributeOrigin"
        @cancel="closeEditForm"
        @addNewAttribute="addNewAttribute"
        @saveAttribute="saveAttribute"
        @deleteAttribute="deleteAttribute">
    </AttributeForm>
  </v-card>
</template>

<script>
import AttributeForm from "./Forms/AttributeForm";
import { AttributeSourceTypeToString } from "../../models/AttributeSourceType";
import { AttributeOrigin, AttributeOriginToString } from "../../models/AttributeOrigin";
import { AttributeTypeToString } from "../../models/AttributeType";
import { AttributeContentTypeToString } from "../../models/AttributeContentType";
import _ from "lodash";

export default {
    components: { AttributeForm },
    props: {
        attributes: {
            type: Array,
            default: function() {
                return [];
            }
        },
        editableAttributeOrigin: AttributeOrigin
    },
    data() {
        return {
            filter: "",
            sortBy: "id",
            sortDesc: true,
            headers: [
                { text: "Origin", value: "origin", show: true },
                { text: "Id", value: "id", show: true },
                { text: "Name", value: "name", show: true },
                { text: "Value", value: "value", show: true },
                { text: "Content Type", value: "contentType", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            editableAttributes: _.cloneDeep(this.attributes),
            selectedAttribute: null,
            isEditFormOpen: false
        };
    },
    watch: {
        attributes(val) {
            this.editableAttributes = (!val) ? {} : _.cloneDeep(val);
        }
    },
    methods: {
        createAttribute() {
            this.selectedAttribute = null;
            this.isEditFormOpen = true;
        },
        editAttribute(attribute) {
            this.selectedAttribute = attribute;
            this.isEditFormOpen = true;
        },
        addNewAttribute: function(newAttribute, eventTargetId) {
            this.$emit("addNewAttribute", newAttribute, eventTargetId);
        },
        saveAttribute: function(attribute, eventTargetId) {
            this.$emit("saveAttribute", attribute, eventTargetId);
        },
        deleteAttribute: function(attribute, eventTargetId) {
            this.$emit("deleteAttribute", attribute, eventTargetId);
        },
        attributeSourceTypeToString(attributeSourceType) {
            return AttributeSourceTypeToString(attributeSourceType);
        },
        attributeOriginToString(attributeOrigin) {
            return AttributeOriginToString(attributeOrigin);
        },
        attributeTypeToString(attributeType) {
            return AttributeTypeToString(attributeType);
        },
        attributeContentTypeToString(attributeContentType) {
            return AttributeContentTypeToString(attributeContentType);
        },
        isEditable(attribute) {
            return attribute.origin === AttributeOrigin.RG ||
      attribute.origin === AttributeOrigin.Unknown ||
      attribute.origin === AttributeOrigin.MTI;
        },
        closeEditForm() {
            this.isEditFormOpen = false;
            this.selectedAttribute = null;
        }
    }
};
</script>
