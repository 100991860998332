var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip-group",
    { attrs: { column: "", dark: "" } },
    [
      _vm._l(_vm.waitFees, function(waitFee, n) {
        return [
          _c(
            "v-tooltip",
            {
              key: n,
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-chip",
                          _vm._g(
                            {
                              key: n,
                              attrs: {
                                close: "",
                                disabled: _vm.readOnly,
                                "close-icon": "mdi-delete",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.openWaitFeeDialog(waitFee, n)
                                },
                                "click:close": function($event) {
                                  return _vm.deleteWaitFee(n)
                                }
                              }
                            },
                            on
                          ),
                          [
                            _vm.verbose
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(waitFee.tariffCategory) +
                                      ": " +
                                      _vm._s(waitFee.hourPrice) +
                                      " €/h, " +
                                      _vm._s(waitFee.minutes) +
                                      " min, " +
                                      _vm._s(waitFee.price) +
                                      " €"
                                  )
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(waitFee.tariffCategory) +
                                      ": " +
                                      _vm._s(waitFee.price)
                                  )
                                ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm._f("waitFeeToToolTipText")(waitFee)))
              ])
            ]
          )
        ]
      }),
      !_vm.readOnly && _vm.waitFees.length < _vm.maxAmount
        ? _c(
            "v-chip",
            {
              attrs: { color: "success" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.openNewWaitFeeDialog($event)
                }
              }
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("invoicing.addNewWaitFee")) + " "),
              _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")])
            ],
            1
          )
        : _vm._e(),
      _c("WaitFeeDialog", {
        attrs: {
          isOpen: _vm.waitFeeDialog,
          originalWaitFee: _vm.selectedWaitFee,
          isNewWaitFee: _vm.newWaitFee,
          allowedWaitFeeTypes: _vm.allowedWaitFeeTypes
        },
        on: {
          create: _vm.addWaitFee,
          save: _vm.modifyWaitFee,
          close: _vm.closeWaitFeeDialog
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }