export const PaymentMethod = {
    Unknown: 0,
    NotReceived: 1, // Lost drive/no payment received
    Cash: 2,
    DebitCard: 3,
    CreditCard: 4,
    Invoice: 5,
    TaxiCard: 6,
    SocialServiceCard: 7,
    App: 8,
    ExternalPaymentService: 9
};

export const PaymentMethodMixin = {
    data() {
        return {
            paymentMethods: PaymentMethod
        };
    },
    filters: {
        paymentMethodToIcon: function(value) {
            switch (value) {
            case PaymentMethod.Cash:
                return "mdi-cash-multiple";
            case PaymentMethod.DebitCard:
                return "mdi-credit-card";
            case PaymentMethod.CreditCard:
                return "mdi-credit-card-outline";
            case PaymentMethod.Invoice:
                return "mdi-file-document-outline";
            case PaymentMethod.TaxiCard:
                return "mdi-taxi";
            case PaymentMethod.SocialServiceCard:
                return "mdi-smart-card-outline";
            case PaymentMethod.NotReceived:
                return "mdi-alert-box-outline";
            case PaymentMethod.App:
                return "mdi-cellphone";
            case PaymentMethod.ExternalPaymentService:
                return "mdi-credit-card-multiple-outline";
            case PaymentMethod.Unknown:
            default:
                return "mdi-alert";
            }
        }
    },
    methods: {
        paymentMethodToTranslationKey(value) {
            switch (value) {
            case PaymentMethod.NotReceived:
                return "invoicing.paymentMethod.notReceived";
            case PaymentMethod.Cash:
                return "invoicing.paymentMethod.cash";
            case PaymentMethod.DebitCard:
                return "invoicing.paymentMethod.debitCreditCard";
            case PaymentMethod.CreditCard:
                return "invoicing.paymentMethod.creditDebitCard";
            case PaymentMethod.Invoice:
                return "invoicing.paymentMethod.invoice";
            case PaymentMethod.TaxiCard:
                return "invoicing.paymentMethod.taxiCard";
            case PaymentMethod.SocialServiceCard:
                return "invoicing.paymentMethod.socialServiceCard";
            case PaymentMethod.App:
                return "invoicing.paymentMethod.app";
            case PaymentMethod.ExternalPaymentService:
                return "invoicing.paymentMethod.externalPaymentService";
            case PaymentMethod.Unknown:
            default: return "invoicing.paymentMethod.unknown";
            }
        }
    }
};
