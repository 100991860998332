var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("vehicles.dialogs.vehicleId"),
                  counter: "12",
                  disabled: true
                },
                model: {
                  value: _vm.editableVehicle.id,
                  callback: function($$v) {
                    _vm.$set(_vm.editableVehicle, "id", $$v)
                  },
                  expression: "editableVehicle.id"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("vehicles.dialogs.vehicleNumber"),
                  disabled: !_vm.editable
                },
                model: {
                  value: _vm.editableVehicle.vehicleNumber,
                  callback: function($$v) {
                    _vm.$set(_vm.editableVehicle, "vehicleNumber", $$v)
                  },
                  expression: "editableVehicle.vehicleNumber"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("vehicles.dialogs.registrationPlateNumber"),
                  disabled: !_vm.editable
                },
                model: {
                  value: _vm.editableVehicle.registrationPlateNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.editableVehicle,
                      "registrationPlateNumber",
                      $$v
                    )
                  },
                  expression: "editableVehicle.registrationPlateNumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.vehicleStatuses,
                  label: _vm.$t("vehicles.dialogs.status"),
                  disabled: true
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.vehicleStatusToString(item)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.vehicleStatusToString(item)) + " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.editableVehicle.status,
                  callback: function($$v) {
                    _vm.$set(_vm.editableVehicle, "status", $$v)
                  },
                  expression: "editableVehicle.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.vehicleTwinTypes,
                  label: _vm.$t("vehicles.dialogs.twinType"),
                  disabled: !_vm.editable
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.vehicleTwinTypeToString(item)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.vehicleTwinTypeToString(item)) + " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.editableVehicle.twinType,
                  callback: function($$v) {
                    _vm.$set(_vm.editableVehicle, "twinType", $$v)
                  },
                  expression: "editableVehicle.twinType"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c("IntegrationSourcesField", {
                attrs: {
                  editableIntegrationSources: _vm.editableIntegrationSources,
                  editable: _vm.editable
                },
                on: {
                  "update:editableIntegrationSources": function(newValue) {
                    return (_vm.editableIntegrationSources = newValue)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.editable
        ? _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-4",
                      attrs: { disabled: !_vm.dirty },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }