import { PriceRuleType, PriceRuleTypeToString } from "../../models/PriceRuleTypes";
import { DayTypeToString } from "../../models/DayTypes";
import { CarTypeToString } from "../../models/CarTypes";

export default {
    data() {
        return {
            priceRuleTypes: PriceRuleType
        };
    },
    // Should be filters but then it does not work properly in v-expansion-panels
    // Property or method "formatRuleTypeToString" is not defined on the instance but referenced during render.
    methods: {
        formatRuleTypeToString(priceRuleType) {
            return PriceRuleTypeToString(priceRuleType);
        },
        formatRuleToToolTip(rule) {
            switch (rule.ruleType) {
            case PriceRuleType.DayTypeTimeRange:
                let start = rule.dayTypeTimeRange.start;
                let end = rule.dayTypeTimeRange.end;
                let dayType = DayTypeToString(rule.dayTypeTimeRange.dayTypes);
                return start + " - " + end + " on day types: " + dayType;
            case PriceRuleType.CarType:
                return "Car type: " + CarTypeToString(rule.carType);
            case PriceRuleType.FixedRoute:
                let startLocation = rule.pickupLocation.street + " " + rule.pickupLocation.streetNumber + ", " + rule.pickupLocation.city;
                let endLocation = rule.dropoffLocation.street + " " + rule.dropoffLocation.streetNumber + ", " + rule.dropoffLocation.city;
                return "From " + startLocation + " to " + endLocation;
            case PriceRuleType.PreBooked:
                return "Pre-booked ride";
            case PriceRuleType.PassengerCount:
                return rule.start + " - " + rule.end + " passengers";
            default:
                return PriceRuleTypeToString(rule.ruleType);
            }
        }
    }
};
