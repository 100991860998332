var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { height: "500" } },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(_vm.$t("home.noOfTrips")) + " ")
                  ]),
                  _c(
                    "v-skeleton-loader",
                    {
                      attrs: {
                        loading: _vm.numberOfTripsLoading,
                        "transition-group": "scale-transition",
                        type: "list-item@7"
                      }
                    },
                    [
                      _c("BarChart", {
                        attrs: {
                          data: _vm.tripsByDayData,
                          options: _vm.tripsByDayDataOptions
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { height: "500" } },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "font-weight-bold" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "mx-0", attrs: { align: "center" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("home.statusesOfLastXDays", {
                                  days: _vm.tripStatusesFromDays
                                })
                              ) +
                              " "
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn-toggle",
                            {
                              attrs: { mandatory: "", dense: "", rounded: "" },
                              model: {
                                value: _vm.tripStatusesFromDays,
                                callback: function($$v) {
                                  _vm.tripStatusesFromDays = $$v
                                },
                                expression: "tripStatusesFromDays"
                              }
                            },
                            [
                              _c(
                                "v-btn",
                                { attrs: { value: "30", small: "" } },
                                [_vm._v(" 30 ")]
                              ),
                              _c(
                                "v-btn",
                                { attrs: { value: "60", small: "" } },
                                [_vm._v(" 60 ")]
                              ),
                              _c(
                                "v-btn",
                                { attrs: { value: "90", small: "" } },
                                [_vm._v(" 90 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-skeleton-loader",
                    {
                      attrs: {
                        loading: _vm.tripStatusesLoading,
                        "transition-group": "scale-transition",
                        type: "list-item@5"
                      }
                    },
                    [
                      _c("DoughnutChart", {
                        attrs: {
                          data: _vm.tripsByTimeRangeData,
                          options: _vm.tripsByTimeRangeDataOptions
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { attrs: { color: "error lighten-1" } },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("home.transportEventErrors")) + " "
                    )
                  ]),
                  _c(
                    "v-skeleton-loader",
                    {
                      attrs: {
                        loading: _vm.failedValidationsLoading,
                        "transition-group": "scale-transition",
                        type: "list-item@3"
                      }
                    },
                    [
                      _c("v-simple-table", {
                        attrs: { height: "400px" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(_vm.$t("home.errorType")))
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(_vm.$t("home.errorCount")))
                                    ])
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.failReasons, function(failReason) {
                                    return _c(
                                      "tr",
                                      {
                                        key: failReason.item1,
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.searchFailed(
                                              failReason.item1
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "validationFailReasonToText"
                                              )(failReason.item1)
                                            )
                                          )
                                        ]),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "error--text font-weight-black"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(failReason.item2) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { attrs: { color: "primary lighten-1" } },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("home.latestSalesInvoices")) + " "
                    )
                  ]),
                  _c(
                    "v-skeleton-loader",
                    {
                      attrs: {
                        loading: _vm.salesInvoicesLoading,
                        "transition-group": "scale-transition",
                        type: "list-item@3"
                      }
                    },
                    [
                      _c("v-simple-table", {
                        attrs: { height: "400px" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("home.invoiceNumber"))
                                      )
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(_vm.$t("home.events")))
                                    ])
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.salesInvoices, function(
                                    salesInvoice
                                  ) {
                                    return _c(
                                      "tr",
                                      {
                                        key: salesInvoice.item1,
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.searchSalesInvoice(
                                              salesInvoice.item1
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(salesInvoice.item1))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(salesInvoice.item2))
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { attrs: { color: "primary lighten-1" } },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("home.latestPurchaseInvoices")) + " "
                    )
                  ]),
                  _c(
                    "v-skeleton-loader",
                    {
                      attrs: {
                        loading: _vm.purchaseInvoicesLoading,
                        "transition-group": "scale-transition",
                        type: "list-item@3"
                      }
                    },
                    [
                      _c("v-simple-table", {
                        attrs: { height: "400px" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("home.invoiceNumber"))
                                      )
                                    ]),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(_vm._s(_vm.$t("home.events")))
                                    ])
                                  ])
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.purchaseInvoices, function(
                                    purchaseInvoice
                                  ) {
                                    return _c(
                                      "tr",
                                      {
                                        key: purchaseInvoice.item1,
                                        staticClass: "clickable",
                                        on: {
                                          click: function($event) {
                                            return _vm.searchPurchaseInvoice(
                                              purchaseInvoice.item1
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("td", [
                                          _vm._v(_vm._s(purchaseInvoice.item1))
                                        ]),
                                        _c("td", [
                                          _vm._v(_vm._s(purchaseInvoice.item2))
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }