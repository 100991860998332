<template>
  <v-card>
    <div class="elevation-1 mb-1 pb-2">
      <v-card-title>
        Bookings
      </v-card-title>
      <v-card-actions>
        <v-btn
          small
          @click="searchDialog = true"
          class="mx-2">
          Search
        </v-btn>
        <v-dialog v-model="columnDialog" max-width="1000">
          <template v-slot:activator="{ on }">
            <v-btn small v-on="on" class="ml-2">
              Toggle Columns
            </v-btn>
          </template>
          <v-card>
            <v-toolbar short dark color="primary">
              <v-btn icon dark @click="columnDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>
                Toggle Columns
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn text dark @click="hideAllColumns">
                Hide All
              </v-btn>
              <v-btn text dark @click="showAllColumns">
                Show All
              </v-btn>
            </v-toolbar>
            <v-layout wrap align-center class="mx-3">
              <v-flex v-for="(header, n) in headers" :key="n" xs6 md3>
                <v-switch v-model="header.show" :label="header.text"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
        <v-spacer></v-spacer>
        <BookingSearchDialog
          :show="searchDialog"
          :searchParams="searchParams"
          @search="searchBookings($event, false)"
          @close="searchDialog = false"
        ></BookingSearchDialog>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="filter"
          append-icon="mdi-magnify"
          :label="Filter"
          single-line
          hide-details
          class="pt-0 mt-0 mr-2"></v-text-field>
      </v-card-actions>
    </div>
    <v-skeleton-loader :loading="loading" :transition-group="'scale-transition'" type="table">
      <v-data-table
        calculate-widths
        :headers="showableHeaders"
        :items="bookings"
        item-key="id"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :search="filter"
        :loading="loadingPage"
        :page.sync="page"
        :items-per-page.sync="itemsPerPage"
        :footer-props="{ pagination, itemsPerPageOptions: itemsPerPageOptions}"
        @click:row="openBookingDialog"
        @update:options="loadMoreBookings"
      >
        <template v-slot:item.id="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.tripId="{ value }">
          <span> {{ value }}</span>
        </template>
        <template v-slot:item.status="{ value }">
          <span> {{ bookingStatusToString(value) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <span>
            <v-btn>
              <v-icon>mdi-test</v-icon>
            </v-btn>
          </span>
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <BookingDialog
      :isOpen="bookingDialog"
      :booking="booking"
      @close="bookingDialog = false"
    ></BookingDialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
import { BookingStatusToString } from "../../models/BookingStatus";
import BookingSearchDialog from "./Dialogs/BookingSearchDialog";
import BookingDialog from "./Dialogs/BookingDialog";

export default {
    components: { BookingDialog, BookingSearchDialog },
    data() {
        return {
            loading: true,
            filter: "",
            columnDialog: false,
            searchDialog: false,
            headers: [
                { text: "Id", value: "id", show: true },
                { text: "Trip Id", value: "tripId", show: true },
                { text: "Status", value: "status", show: true },
                { text: "Actions", value: "actions", show: true }
            ],
            sortBy: "id",
            sortDesc: true,
            page: 1,
            itemsPerPage: 10,
            loadingPage: false,
            searchParams: {
                id: ""
            },
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            bookingDialog: false,
            snackbar: false,
            snackbarText: "",
            snackbarColor: ""
        };
    },
    computed: {
        bookings() {
            return this.$store.state.bookings.searchedBookings;
        },
        booking() {
            return this.$store.state.bookings.fetchedBooking;
        },
        searchTotalCount() {
            return this.$store.state.bookings.searchTotalCount;
        },
        showableHeaders() {
            return this.headers.filter(h => h.show);
        },
        pagination() {
            return {
                page: this.page,
                itemsPerPage: this.itemsPerPage,
                pageStart: ((this.page - 1) * this.itemsPerPage),
                pageStop: (this.page * this.itemsPerPage),
                pageCount: Math.ceil(this.searchTotalCount / this.itemsPerPage),
                itemsLength: this.searchTotalCount
            };
        },
        headerModel() {
            return "All";
        }
    },
    methods: {
        async getBookings(searchParams = null, forceUpdate = false) {
            this.loading = true;
            this.$store.dispatch("bookings/searchBookings", { searchParams, top: 100, forceUpdate: forceUpdate })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getBooking(bookingId) {
            this.loading = true;
            this.$store.dispatch("bookings/getBooking", bookingId)
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async loadMoreBookings() {
            if (!this.bookings) return;
            if (this.bookings.length === this.searchTotalCount) return;
            if ((this.page + 1) * this.itemsPerPage < this.bookings.length) {
                return;
            }
            this.loadingPage = true;
            this.$store.dispatch("bookings/loadMoreBookings", {
                top: 100
            })
                .catch(error => {
                    this.snackbar = true;
                    this.snackbarColor = "error";
                    this.snackbarText = error;
                })
                .finally(() => {
                    this.loadingPage = false;
                });
        },
        openBookingDialog(booking) {
            this.getBooking(booking.id);
            this.bookingDialog = true;
        },
        closeBookingDialog() {
            this.bookingDialog = false;
        },
        hideAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = false;
            }
        },
        showAllColumns() {
            for (let i = 0; i < this.headers.length; i++) {
                this.headers[i].show = true;
            }
        },
        bookingStatusToString(status) {
            return BookingStatusToString(status);
        },
        searchBookings(searchParams = null, forceUpdate = false) {
            if (searchParams) this.searchParams = searchParams;
            this.getBookings(this.searchParams, forceUpdate);
            this.searchDialog = false;
        }
    },
    created() {
        this.searchBookings(this.$route.query);
    }
};
</script>

<style scoped>
.v-text-field_search {
    padding-top: 0;
    margin-top: 0;
}
</style>
