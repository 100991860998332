import { RepositoryFactory } from "@/data/repositories/repositoryFactory";

// initial state
const state = {
    vehiclesInQueriedArea: [],
    vehiclesInQueriedPostalCode: [],
    vehiclesInGeoArea: []
};

// getters
const getters = {};

// actions
const actions = {
    async searchVehiclesInArea({ commit }, { companyId, fleetId, areaId }) {
        await RepositoryFactory.get("dispatching").getVehiclesInArea(companyId, fleetId, areaId)
            .then(response => {
                commit("SET_VEHICLES_IN_AREA", response.data.results);
            });
    },
    async searchVehiclesInPostalCode({ commit }, { companyId, fleetId, postalCode }) {
        await RepositoryFactory.get("dispatching").getVehiclesInPostalCode(companyId, fleetId, postalCode)
            .then(response => {
                commit("SET_VEHICLES_IN_POSTALCODE", response.data.results);
            });
    },
    async searchVehiclesNearLocation({ commit }, { companyId, fleetId, query }) {
        await RepositoryFactory.get("dispatching").getVehiclesNearLocation(companyId, fleetId, query)
            .then(response => {
                commit("SET_VEHICLES_NEAR_LOCATION", response.data.results);
            });
    },
    async searchVehiclesInPolygon({ commit }, { companyId, fleetId, query }) {
        await RepositoryFactory.get("dispatching").getVehiclesInPolygon(companyId, fleetId, query)
            .then(response => {
                commit("SET_VEHICLES_IN_POLYGON", response.data.results);
            });
    }
};

// mutations
const mutations = {
    SET_VEHICLES_IN_AREA(state, vehicles) {
        state.vehiclesInQueriedArea = vehicles;
    },
    SET_VEHICLES_IN_POSTALCODE(state, vehicles) {
        state.vehiclesInQueriedPostalCode = vehicles;
    },
    SET_VEHICLES_NEAR_LOCATION(state, vehicles) {
        state.vehiclesInGeoArea = vehicles;
    },
    SET_VEHICLES_IN_POLYGON(state, vehicles) {
        state.vehiclesInGeoArea = vehicles;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
