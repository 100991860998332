var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("AttributesTable", {
    attrs: {
      attributes: _vm.editableVehicle.attributes,
      editableAttributeOrigin: _vm.editableAttributeOrigin
    },
    on: {
      addNewAttribute: _vm.addNewAttribute,
      saveAttribute: _vm.saveAttribute,
      deleteAttribute: _vm.deleteAttribute
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }